import { AssetStatus, AssetType } from 'constants/Asset';
import { Document, DocumentConfig } from 'constants/Document';
import { FieldType, FormConfig, FormType, usStates } from 'constants/form/Form';
import { generateComplianceTab, generateFilesTab } from 'constants/form/formConfig';

import { vinRegExp } from 'components/BaseForm/Utils';
import useVehicleAPI from 'services/vin/VehicleAPI';
import { formatUnitSelect } from 'screens/dispatch/board/schemas/unit/formatUnitValues';

import { formatGroupSelect } from '../groups/schemas/formatGroupValues';

const ConfigName = 'Asset';

export enum AssetDocumentType {
  Registration = 'Registration',
  Liability = 'Liability',
  Cargo = 'Cargo',
  PD = 'PD',
  Inspection = 'Inspection',
  Maintenance = 'Maintenance',
  IFTA = 'IFTA',
  HUT = 'HUT',
  NM = 'NM',
  KYU = 'KYU',
  OR = 'OR',
  CA = 'CA',
  CT = 'CT',
  Title = 'Title',
  Financing = 'Financing',
  LeasedTo = 'LeasedTo',
  Misc = 'Misc',
}

export const AssetRegistration: Document = {
  name: AssetDocumentType.Registration,
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.Registration,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [AssetType.TRUCK, AssetType.TRAILER],
  },
  version: '1',
};

export const AssetAutoLiability: Document = {
  name: AssetDocumentType.Liability,
  type: AssetDocumentType.Liability,
  issueTimestamp: 0,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  extraFields: {
    insuranceCompany: {
      name: 'insuranceCompany',
      label: 'Insurance Company',
      type: FieldType.TEXT,
    },
    policyNumber: {
      name: 'policyNumber',
      label: 'Policy Number',
      type: FieldType.TEXT,
      composition: {
        weight: 6,
      },
    },
    policyLimit: {
      name: 'policyLimit',
      label: 'Policy Limit',
      type: FieldType.NUMBER,
      composition: {
        weight: 6,
      },
    },
    policyExclusions: {
      name: 'policyExclusions',
      label: 'Exclusions',
      type: FieldType.TEXT,
    },
  },
  role: {
    visibleFor: [AssetType.TRUCK],
    requiredFor: [AssetType.TRUCK],
  },
  version: 'New',
};

export const AssetCargo: Document = {
  name: AssetDocumentType.Cargo,
  issueTimestamp: 0,
  type: AssetDocumentType.Cargo,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  extraFields: {
    insuranceCompany: {
      name: 'insuranceCompany',
      label: 'Insurance Company',
      type: FieldType.TEXT,
    },
    policyNumber: {
      name: 'policyNumber',
      label: 'Policy Number',
      type: FieldType.TEXT,
      composition: {
        weight: 6,
      },
    },
    policyLimit: {
      name: 'policyLimit',
      label: 'Policy Limit',
      type: FieldType.NUMBER,
      composition: {
        weight: 6,
      },
    },
    policyExclusions: {
      name: 'policyExclusions',
      label: 'Exclusions',
      type: FieldType.TEXT,
    },
  },
  role: {
    visibleFor: [AssetType.TRUCK],
    requiredFor: [AssetType.TRUCK],
  },
  version: 'New',
};

export const AssetPD: Document = {
  name: AssetDocumentType.PD,
  issueTimestamp: 0,
  type: AssetDocumentType.PD,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  extraFields: {
    insuranceCompany: {
      name: 'insuranceCompany',
      label: 'Insurance Company',
      type: FieldType.TEXT,
    },
    policyNumber: {
      name: 'policyNumber',
      label: 'Policy Number',
      type: FieldType.TEXT,
      composition: {
        weight: 6,
      },
    },
    policyLimit: {
      name: 'policyLimit',
      label: 'Policy Limit',
      type: FieldType.NUMBER,
      composition: {
        weight: 6,
      },
    },
    policyExclusions: {
      name: 'policyExclusions',
      label: 'Exclusions',
      type: FieldType.TEXT,
    },
  },
  role: {
    requiredFor: [],
  },
  version: 'New',
};

export const AssetDOTInspection: Document = {
  name: AssetDocumentType.Inspection,
  issueTimestamp: 0,
  type: AssetDocumentType.Inspection,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [AssetType.TRUCK, AssetType.TRAILER],
  },
  version: 'New',
};

export const AssetMaintenance: Document = {
  name: AssetDocumentType.Maintenance,
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.Maintenance,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [],
  },
  version: 'New',
};

export const AssetIFTA: Document = {
  name: AssetDocumentType.IFTA,
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.IFTA,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: { visibleFor: [AssetType.TRUCK], requiredFor: [AssetType.TRUCK] },
  version: '1',
};

export const AssetHUT: Document = {
  name: AssetDocumentType.HUT,
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.HUT,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    visibleFor: [AssetType.TRUCK],
    requiredFor: [AssetType.TRUCK],
  },
  version: 'New',
};

export const AssetNM: Document = {
  name: AssetDocumentType.NM,
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.NM,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    visibleFor: [AssetType.TRUCK],
    requiredFor: [AssetType.TRUCK],
  },
  version: 'New',
};

export const AssetKYU: Document = {
  name: AssetDocumentType.KYU,
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.KYU,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    visibleFor: [AssetType.TRUCK],
    requiredFor: [],
  },
  version: 'New',
};

export const AssetOR: Document = {
  name: AssetDocumentType.OR,
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.OR,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    visibleFor: [AssetType.TRUCK],
    requiredFor: [],
  },
  version: 'New',
};

export const AssetCA: Document = {
  name: AssetDocumentType.CA,
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.CA,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [],
  },
  version: 'New',
};

export const AssetCT: Document = {
  name: AssetDocumentType.CT,
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.CT,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    visibleFor: [AssetType.TRUCK],
    requiredFor: [],
  },
  version: 'New',
};

export const AssetTitle: Document = {
  name: AssetDocumentType.Title,
  issueTimestamp: 0,
  type: AssetDocumentType.Title,
  role: {
    requiredFor: [],
  },
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  version: 'New',
};

export const AssetFinacialAgreement: Document = {
  name: AssetDocumentType.Financing,
  issueTimestamp: 0,
  type: AssetDocumentType.Financing,
  role: {
    requiredFor: [],
  },
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  version: 'New',
};

export const AssetLeaseAgreement: Document = {
  name: AssetDocumentType.LeasedTo,
  issueTimestamp: 0,
  type: AssetDocumentType.LeasedTo,
  role: {
    visibleFor: [AssetType.TRUCK],
    requiredFor: [],
  },
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  version: 'New',
};

export const AssetMisc: Document = {
  name: 'Misc',
  issueTimestamp: 0,
  id: '',
  type: AssetDocumentType.Misc,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [],
  },
  version: 'New',
};

export const AssetDocumentConfig: DocumentConfig = {
  [AssetDocumentType.Registration]: AssetRegistration,
  [AssetDocumentType.Liability]: AssetAutoLiability,
  [AssetDocumentType.Cargo]: AssetCargo,
  [AssetDocumentType.PD]: AssetPD,
  [AssetDocumentType.Inspection]: AssetDOTInspection,
  [AssetDocumentType.Title]: AssetTitle,
  [AssetDocumentType.Financing]: AssetFinacialAgreement,
  [AssetDocumentType.LeasedTo]: AssetLeaseAgreement,
  [AssetDocumentType.Misc]: AssetMisc,
};

export const ComplianceDocumentConfig: DocumentConfig = {
  [AssetDocumentType.IFTA]: AssetIFTA,
  [AssetDocumentType.HUT]: AssetHUT,
  [AssetDocumentType.NM]: AssetNM,
  [AssetDocumentType.KYU]: AssetKYU,
  [AssetDocumentType.OR]: AssetOR,
  [AssetDocumentType.CA]: AssetCA,
  [AssetDocumentType.CT]: AssetCT,
};

const processVINRequest = (source: any, setFieldValue: any) =>
  source?.length === 17 &&
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useVehicleAPI()
    .decodeVin(source)
    .then((result) => {
      if (result) {
        !source.year && !source.year?.length && setFieldValue('year', result.year);
        !source.make && !source.make?.length && setFieldValue('make', result.make);
        !source.type && !source.type?.length && setFieldValue('type', result.type);
      }
    });

export const getExtendedAssetsFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(AssetType),
          },
        ],
        [
          {
            name: 'unitId',
            label: 'Unit Id',
            type: FieldType.TEXT,
            composition: {
              weight: 5,
            },
            validation: {
              errorMsg: 'Unit Id is required.',
            },
          },
          {
            name: 'plate',
            label: 'IRP/Plate',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
          {
            name: 'state',
            label: 'Base State',
            type: FieldType.SELECT,
            options: usStates,
            composition: {
              weight: 3,
            },
          },
        ],
        [
          {
            name: 'vin',
            label: 'VIN',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'VIN Id is required.',
              invalidMsg: 'VIN is not valid.',
              regex: vinRegExp,
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) =>
                  processVINRequest(source, setFieldValue),
              },
            },
          },
        ],
        [
          {
            name: 'year',
            label: 'Year',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
          {
            name: 'make',
            label: 'Make',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'color',
            label: 'Color',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
          {
            name: 'miles',
            label: 'Miles',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
        ],
      ],
    },
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName} Setup`,
      content: [
        [
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(AssetStatus),
          },
        ],
        [
          {
            name: 'unit',
            label: 'Unit',
            type: FieldType.SELECT,
            options: values?.units.map((unit: any) => formatUnitSelect(unit)),
            select: {
              infoArray: [
                {
                  icon: 'inventory_2',
                  color: 'warning',
                  note: 'Unit is already assigned',
                  show: (unitId: string | undefined, load: any | undefined) => {
                    const unit = values?.units?.filter((unit: any) => unit.id === unitId)?.[0];

                    return unit?.load?.id && unit?.load?.id !== load?.id;
                  },
                },
              ],
            },
            control: {
              editable: 'showUnitForm',
              addable: 'showUnitForm',
            },
          },
        ],
        [
          {
            name: 'groups',
            label: 'Groups',
            multiple: true,
            type: FieldType.SELECT,
            options: Object.values(values?.groups).map((group: any) => formatGroupSelect(group)),
            control: {
              editable: 'showGroupForm',
              addable: 'showGroupForm',
            },
          },
        ],
      ],
    },
    Compliance: generateComplianceTab(ConfigName, ComplianceDocumentConfig, values),
    Files: generateFilesTab(ConfigName, AssetDocumentConfig, values),
  } as FormConfig);

export const getSimplifiedAssetsFormConfig = (values: string): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(AssetType),
            composition: {
              weight: 6,
            },
          },
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(AssetStatus),
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'unitId',
            label: 'Unit Id',
            type: FieldType.TEXT,
            composition: {
              weight: 5,
            },
            validation: {
              errorMsg: 'Unit Id is required.',
            },
          },
          {
            name: 'plate',
            label: 'IRP/Plate',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
          {
            name: 'state',
            label: 'Base State',
            type: FieldType.SELECT,
            options: usStates,
            composition: {
              weight: 3,
            },
          },
        ],
        [
          {
            name: 'vin',
            label: 'VIN',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'VIN Id is required.',
              invalidMsg: 'VIN is not valid.',
              regex: vinRegExp,
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) =>
                  processVINRequest(source, setFieldValue),
              },
            },
          },
        ],
        [
          {
            name: 'year',
            label: 'Year',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
          {
            name: 'make',
            label: 'Make',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'color',
            label: 'Color',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
          {
            name: 'miles',
            label: 'Miles',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
        ],
      ],
    },
  } as FormConfig);

const getAssetsFormConfig = (values: string, extended: boolean) =>
  extended ? getExtendedAssetsFormConfig(values) : getSimplifiedAssetsFormConfig(values);

export const assetFormSetup = (id: number, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getAssetsFormConfig,
});
