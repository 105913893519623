import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import ROUTES from 'navigation/const';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';

const withAuthorization = (condition: any) => (Component: any) => {
  const withAuthorization = (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const auth = useSelector((state: any) => state.firebase.auth);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    const isAuthorized = () => isLoaded(auth) && !isEmpty(auth);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!isAuthorized()) {
        navigate(ROUTES.AUTHENTICATION);
      }
    }, [auth]);

    return condition(auth) ? <Component {...props} /> : null;
  };

  return withAuthorization;
};

export default withAuthorization;
