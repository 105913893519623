/* eslint-disable no-loop-func */
import { DispatchStatusPriority } from 'constants/Dispatch';

import { getPriorityStatus } from 'utils/status';

import { AssetDimensions } from '../../constants/Asset';
import { Load, LoadRouteStop, LoadSize } from '../../constants/Load';

const getLoadedLength = (loads: Load[], loadsIndex: { [key: string]: number }): number => {
  let length = 0;

  loads.forEach((load: Load) => {
    if (loadsIndex[load.id] > 0) {
      length += load?.info?.length || 0;
    }
  });

  return length;
};

const filterCapacityLoads = (
  loads: Load[],
  loadsIndex: { [key: string]: number },
  trailerDimencions: AssetDimensions
): Load[] =>
  loads.filter((load: Load) => {
    //If load is already loaded or there is enough room in the trailer
    return (
      loadsIndex[load.id] > 0 ||
      !trailerDimencions?.length ||
      (trailerDimencions.length &&
        trailerDimencions.length > getLoadedLength(loads, loadsIndex) + (load.info?.length || 0) &&
        trailerDimencions.length > (load.info?.length || 0))
    );
  });

const findNextLoad = (loads: Load[], loadsIndex: { [key: string]: number }): Load | undefined =>
  loads.find((load: Load) => (load.route?.stops?.length || 0) > loadsIndex[load.id]);

// Find the next load based on stop timestamp, status, and load info size
export const getArrangedLoadStops = (loads: Load[], trailerDimencions: AssetDimensions = {}) => {
  const nextStops: LoadRouteStop[] = [];
  const loadsIndex: { [key: string]: number } = {};

  let stopsNumber = 0;

  loads.forEach((load: Load) => {
    stopsNumber += load.route?.stops.length || 0;
    loadsIndex[load.id] = 0;
  });

  while (stopsNumber) {
    let leftoverStops: LoadRouteStop[] | undefined = [];

    let leftoverLoads = filterCapacityLoads(loads, loadsIndex, trailerDimencions);

    //If there are no leftover loads, it means the trailer is full and we can porcess the leftover stops
    if (!findNextLoad(leftoverLoads, loadsIndex) && stopsNumber > 0) {
      leftoverLoads = loads;
    }

    //Sort the leftover loads by the timestamp of the next stop
    leftoverLoads.sort((a, b) => {
      return (
        (a.route?.stops[loadsIndex[a.id]]?.timestamp || 0) -
        (b.route?.stops[loadsIndex[b.id]]?.timestamp || 0)
      );
    });

    //Find first leftover load with stops
    const leftoverLoad = findNextLoad(leftoverLoads, loadsIndex);

    if (leftoverLoad) {
      leftoverStops = leftoverLoad.route?.stops?.slice(loadsIndex[leftoverLoad.id]);

      leftoverStops?.every((stop: LoadRouteStop) => {
        nextStops.push(stop);
        stopsNumber--;
        loadsIndex[leftoverLoad.id] = loadsIndex[leftoverLoad.id] + 1;

        if (stop.info?.size === LoadSize.LTL) {
          return false;
        } else if (stop.info?.size === LoadSize.PTL) {
          return false;
        } else if (stop.info?.size === LoadSize.Full) {
          return true;
        }
      });
    }
  }

  return nextStops;
};

export const getCurrentLoad = (loads: Load[]) => {
  loads.sort(
    (currentLoad: Load, nextLoad: Load) =>
      DispatchStatusPriority[currentLoad.status] -
      DispatchStatusPriority[getPriorityStatus(currentLoad.status, nextLoad.status) || currentLoad.status]
  );

  return loads[0];
};

export const testExport = {
  filterCapacityLoads,
  getLoadedLength,
};
