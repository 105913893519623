import { Asset } from 'constants/Asset';
import { Service, ServiceStatus } from 'constants/Service';

import { formatFiles } from 'constants/Util';

import { formatExpenseSelect } from 'screens/accounting/expenses/formatExpenses';

import formatServiceData, { ServiceVersion } from 'screens/fleet/service/migration';
import { formatAssetSelect } from 'screens/roster/assets/formatAsset';

import { ServiceDocumentType } from './configService';

const formatServiceValues = (values: any) => ({
  id: values?.id,
  items: values?.items,
  type: values?.type,
  status: values?.status || ServiceStatus.SCHEDULED,
  asset: values?.asset,
  expense: values?.expense,
  provider: values?.provider,
  location: values?.location,
  scheduleDate: values?.scheduleDate,
  dueDate: values?.dueDate,
  notes: values?.notes,
  reporter: values?.reporter,
  version: values.version || ServiceVersion,
  files: formatFiles(ServiceDocumentType, values?.files),
});

export const formatServiceSelect = (service: any) => ({
  ...service,
  name: `${service.serviceId} | ${service.type}${
    service?.info?.provider ? ` | ${service?.info?.provider}` : ''
  }`,
});

export const getExpense = (service: Service, expenses: any) => {
  return (
    service?.expense?.id &&
    expenses[service.expense.id] &&
    formatExpenseSelect(expenses[service.expense.id])
  );
};

export const formatServicesValuesList = (services: any, groups: any, assets: {[key: string]: Asset}, expenses: any) =>
  services && groups && assets
    ? Object.values(services)
        .filter((service) => service)
        .map((service: any) => {
          const formatedService = formatServiceValues({
            ...formatServiceData(service),
            expense: expenses && getExpense(service, expenses),
            asset: assets && service?.asset?.id && assets[service.asset.id],
            dependent: {
              expense: {
                id: service?.expense?.id,
                files: {
                  Invoice: service?.files?.Invoice,
                },
              },
            },
          });

          return {
            ...formatedService,
            asset:
              assets &&
              service?.asset?.id &&
              formatAssetSelect(assets[service.asset.id]),
            files: {
              ...formatedService.files,
              Invoice:
                expenses && service?.expense?.id && expenses[service?.expense?.id]?.files?.Invoice,
            },
          };
        })
    : [];

export default formatServiceValues;
