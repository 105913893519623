/* eslint-disable no-unused-vars */
import { FieldType, FormConfig, FormType, ValidationType } from 'constants/form/Form';
import { UnitStatus } from 'constants/Unit';

import { Accessory } from 'constants/Accessory';

import { resolvePath } from 'components/BaseForm/Utils';

import { formatLoadSelect } from 'screens/freight/loads/schemas/formatLoadValues';
import { formatAssetSelect } from 'screens/roster/assets/formatAsset';
import { formatDriverSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';
import { formatAccessorySelect } from 'screens/roster/accessories/formatAccessory';

const ConfigName = 'Unit';

export const getSimplifiedUnitsFormConfig = (values: any): FormConfig =>
  ({
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName} Setup`,
      content: [
        [
          {
            name: 'unitId',
            label: 'Unit Id',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'Unit Id is required.',
            },
          },
        ],
        [
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(UnitStatus),
            validation: {
              errorMsg: 'Status is required.',
            },
          },
        ],
        [
          {
            name: 'drivers',
            label: 'Drivers',
            type: FieldType.SELECT,
            options: values?.drivers?.map((driver: any) => formatDriverSelect(driver)),
            multiple: true,
            validation: {
              errorMsg: 'Driver is required.',
              type: ValidationType.ARRAY,
            },
            control: {
              editable: 'showEmployeeForm',
              addable: 'showEmployeeForm',
            },
            select: {
              // max: 2,
              infoArray: [
                {
                  icon: 'local_shipping',
                  color: 'warning',
                  note: 'Driver is already assigned',
                  show: (driverId: string | undefined, unit: any | undefined) => {
                    const driver: any = Object.values(values.drivers || []).filter(
                      (driver: any) => driver.id === driverId
                    )?.[0];

                    return (
                      driver?.unit?.id &&
                      driver.unit.id !== unit.id &&
                      !Object.values(unit?.drivers || {}).some(
                        (unitDriver: any) => unitDriver.id === driver?.id
                      )
                    );
                  },
                },
              ],
            },
          },
        ],
        [
          {
            name: 'truck',
            label: 'Truck',
            type: FieldType.SELECT,
            options: values.trucks.map((truck: any) => formatAssetSelect(truck)),
            validation: {
              errorMsg: 'Truck is required.',
              type: ValidationType.OBJECT,
            },
            control: {
              editable: 'showAssetForm',
              addable: 'showAssetForm',
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) =>
                  !resolvePath(values, 'unitId')?.length &&
                  source?.unitId &&
                  setFieldValue('unitId', source.unitId),
              },
            },
            select: {
              infoArray: [
                {
                  icon: 'local_shipping',
                  color: 'warning',
                  note: 'Truck is already assigned',
                  show: (truckId: string | undefined, unit: any | undefined) => {
                    const truck: any = Object.values(values?.trucks || []).filter(
                      (truck: any) => truck?.id === truckId
                    )?.[0];

                    return (
                      truck?.unit?.id &&
                      truck?.id !== unit?.truck?.id &&
                      truck?.unit?.id !== unit.id
                    );
                  },
                },
              ],
            },
          },
        ],
        [
          {
            name: 'trailer',
            label: 'Trailer',
            type: FieldType.SELECT,
            options: values.trailers.map((trailer: any) => formatAssetSelect(trailer)),
            control: {
              editable: 'showAssetForm',
              addable: 'showAssetForm',
            },
            select: {
              infoArray: [
                {
                  icon: 'local_shipping',
                  color: 'warning',
                  note: 'Trailer is already assigned',
                  show: (trailerId: string | undefined, unit: any | undefined) => {
                    const trailer: any = Object.values(values?.trailers || []).filter(
                      (trailer: any) => trailer.id === trailerId
                    )?.[0];

                    return (
                      trailer?.unit?.id &&
                      trailer?.id !== unit?.trailer?.id &&
                      trailer?.unit?.id !== unit.id
                    );
                  },
                },
              ],
            },
          },
        ],
        [
          {
            name: 'loads',
            label: 'Loads',
            type: FieldType.SELECT,
            options: values.loads.map((load: any) => formatLoadSelect(load)),
            multiple: true,
            control: {
              editable: 'showLoadForm',
              addable: 'showLoadForm',
            },
            select: {
              infoArray: [
                {
                  icon: 'local_shipping',
                  color: 'warning',
                  note: 'Load is already assigned',
                  show: (loadId: string | undefined, unit: any | undefined) => {
                    const load: any = Object.values(values?.loads || []).filter(
                      (load: any) => load.id === loadId
                    )?.[0];

                    return (
                      load?.assignedTo?.id &&
                      load?.id !== unit?.load?.id &&
                      load?.assignedTo?.id !== unit.id
                    );
                  },
                },
              ],
            },
          },
        ],
      ],
    },
  } as FormConfig);

  const getExtendedUnitsFormConfig = (values: any): FormConfig => ({
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName} Setup`,
      content: [
        [
          {
            name: 'unitId',
            label: 'Unit Id',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'Unit Id is required.',
            },
          },
        ],
        [
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(UnitStatus),
            validation: {
              errorMsg: 'Status is required.',
            },
          },
        ],
        [
          {
            name: 'drivers',
            label: 'Drivers',
            type: FieldType.SELECT,
            options: values?.drivers?.map((driver: any) => formatDriverSelect(driver)),
            multiple: true,
            validation: {
              errorMsg: 'Driver is required.',
              type: ValidationType.ARRAY,
            },
            control: {
              editable: 'showEmployeeForm',
              addable: 'showEmployeeForm',
            },
            select: {
              // max: 2,
              infoArray: [
                {
                  icon: 'local_shipping',
                  color: 'warning',
                  note: 'Driver is already assigned',
                  show: (driverId: string | undefined, unit: any | undefined) => {
                    const driver: any = Object.values(values.drivers || []).filter(
                      (driver: any) => driver.id === driverId
                    )?.[0];

                    return (
                      driver?.unit?.id &&
                      driver.unit.id !== unit.id &&
                      !Object.values(unit?.drivers || {}).some(
                        (unitDriver: any) => unitDriver.id === driver?.id
                      )
                    );
                  },
                },
              ],
            },
          },
        ],
        [
          {
            name: 'truck',
            label: 'Truck',
            type: FieldType.SELECT,
            options: values.trucks.map((truck: any) => formatAssetSelect(truck)),
            validation: {
              errorMsg: 'Truck is required.',
              type: ValidationType.OBJECT,
            },
            control: {
              editable: 'showAssetForm',
              addable: 'showAssetForm',
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) =>
                  !resolvePath(values, 'unitId')?.length &&
                  source?.unitId &&
                  setFieldValue('unitId', source.unitId),
              },
            },
            select: {
              infoArray: [
                {
                  icon: 'local_shipping',
                  color: 'warning',
                  note: 'Truck is already assigned',
                  show: (truckId: string | undefined, unit: any | undefined) => {
                    const truck: any = Object.values(values?.trucks || []).filter(
                      (truck: any) => truck?.id === truckId
                    )?.[0];

                    return (
                      truck?.unit?.id &&
                      truck?.id !== unit?.truck?.id &&
                      truck?.unit?.id !== unit.id
                    );
                  },
                },
              ],
            },
          },
        ],
        [
          {
            name: 'trailer',
            label: 'Trailer',
            type: FieldType.SELECT,
            options: values.trailers.map((trailer: any) => formatAssetSelect(trailer)),
            control: {
              editable: 'showAssetForm',
              addable: 'showAssetForm',
            },
            select: {
              infoArray: [
                {
                  icon: 'local_shipping',
                  color: 'warning',
                  note: 'Trailer is already assigned',
                  show: (trailerId: string | undefined, unit: any | undefined) => {
                    const trailer: any = Object.values(values?.trailers || []).filter(
                      (trailer: any) => trailer.id === trailerId
                    )?.[0];

                    return (
                      trailer?.unit?.id &&
                      trailer?.id !== unit?.trailer?.id &&
                      trailer?.unit?.id !== unit.id
                    );
                  },
                },
              ],
            },
          },
        ],
        [
          {
            name: 'loads',
            label: 'Loads',
            type: FieldType.SELECT,
            options: values.loads.map((load: any) => formatLoadSelect(load)),
            multiple: true,
            control: {
              editable: 'showLoadForm',
              addable: 'showLoadForm',
            },
            select: {
              infoArray: [
                {
                  icon: 'local_shipping',
                  color: 'warning',
                  note: 'Load is already assigned',
                  show: (loadId: string | undefined, unit: any | undefined) => {
                    const load: any = Object.values(values?.loads || []).filter(
                      (load: any) => load.id === loadId
                    )?.[0];

                    return (
                      load?.assignedTo?.id &&
                      load?.id !== unit?.load?.id &&
                      load?.assignedTo?.id !== unit.id
                    );
                  },
                },
              ],
            },
          },
        ],
      ],
    },
    Accessories: {
      type: FormType.GENERAL,
      label: 'Accessories',
      title: `${ConfigName} Accessories`,
      content: [
        [
          {
            name: 'accessories.fuelCard',
            label: 'Fuel Cards',
            type: FieldType.SELECT,
            options: values?.fuelCards?.map((accessory: Accessory) => formatAccessorySelect(accessory)),
            multiple: true,
            //TODO Add add/edit functionality
            //TODO Add check for already assigned accessories
          },
        ],
        [
          {
            name: 'accessories.transponder',
            label: 'Toll Transponders',
            type: FieldType.SELECT,
            options: values?.transponders?.map((accessory: Accessory) => formatAccessorySelect(accessory)),
            multiple: true,
            //TODO Add add/edit functionality
            //TODO Add check for already assigned accessories
          },
        ],
        [
          {
            name: 'accessories.eld',
            label: 'ELD Devices',
            type: FieldType.SELECT,
            options: values?.elds?.map((accessory: Accessory) => formatAccessorySelect(accessory)),
            multiple: true,
            //TODO Add add/edit functionality
            //TODO Add check for already assigned accessories
          },
        ],
      ],
    },
  } as FormConfig);

const getUnitsFormConfig = (values: any, extended: boolean) =>
  extended ? getExtendedUnitsFormConfig(values) : getSimplifiedUnitsFormConfig(values);

export const unitFormSetup = (id: any, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getUnitsFormConfig,
});
