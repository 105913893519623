import { ConnectionStatus, ConnectionType } from 'constants/Connection';

import formatConnectionData, { ConnectionVersion } from '../data/migration';

const formatConnectionValues = (values: any) => ({
  id: values?.id || null,
  status: values?.status || ConnectionStatus.PENDING,
  type: values?.type || ConnectionType.OTHER,
  version: ConnectionVersion,
  events: values?.events || [],
  settings: values?.settings || undefined,
  logo: values?.logo || '',
  title: values?.title || '',
  description: values?.description || '',
  enabled: values?.enabled || false,
  eld: values?.eld,
  gps: values?.gps,
  provider: values?.provider,
});

export const formatConnectionsValuesList = (connections: any) =>
  connections &&
  Object.keys(connections)
    .filter((key: any) => connections?.[key])
    .map((key: string) => {
      const connection = connections[key];

      return formatConnectionValues({
        ...formatConnectionData(connection),
        id: key,
      });
    });

export default formatConnectionValues;
