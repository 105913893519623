import { LoadStatus } from 'constants/Load';
import { UnitStatus } from 'constants/Unit';

import MDBadge from 'components/common/MDBadge';

export const StatusBadge = (values: any) => {
  // 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark'

  const getStatusColor = (status: UnitStatus | LoadStatus) => {
    switch (status) {
      case UnitStatus.READY:
      case LoadStatus.DELIVERED:
      case LoadStatus.UNLOADED:
        return 'success';
      case UnitStatus.ACCIDENT:
      case UnitStatus.BREAKDOWN:
      case UnitStatus.INSPECTION:
        return 'error';
      case UnitStatus.MAINTENANCE:
      case UnitStatus.FUEL:
      case UnitStatus.SCALE:
      case UnitStatus.STOP:
      case LoadStatus.LOADING:
      case LoadStatus.ENROUTE:
        return 'info';
      case UnitStatus.DELAY:
      case UnitStatus.EXHANGE:
      case UnitStatus.WAITING:
      case LoadStatus.UNLOADING:
      case LoadStatus.LOADED:
      case LoadStatus.ARRIVED:
        return 'warning';
      case UnitStatus.LOCATION:
      case UnitStatus.OFF:
      case UnitStatus.RESET:
      case LoadStatus.BOOKED:
      case LoadStatus.DISPATCHED:
      default:
        return 'secondary';
    }
  };

  return (
    <MDBadge
      variant="contained"
      size="lg"
      badgeContent={values.status}
      color={getStatusColor(values.status)}
      container
    />
  );
};
