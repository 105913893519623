import { Group } from 'constants/Group';

import { formatUnitSelect } from 'screens/dispatch/board/schemas/unit/formatUnitValues';
import { getUnit } from 'screens/freight/loads/schemas/formatLoadValues';
import { formatGroupSelect } from 'screens/roster/groups/schemas/formatGroupValues';

import formatEmployeeData, { employeeDataVersion } from '../data/migration';

const formatEmployeeValues = (values: any) => ({
  firstName: values?.firstName || '',
  lastName: values?.lastName || '',
  name: `${values?.firstName} ${values?.lastName}`,
  dob: values?.dob || '',
  gender: values.gender || '',
  contact: values?.contact || {
    phone: '',
    extension: '',
    email: '',
  },
  address: values?.address || {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  },
  status: values?.status,
  roles: values?.roles || {},
  files: values?.files || {},
  id: values.id || '',
  unit: values?.unit && formatUnitSelect(values.unit),
  groups: values?.groups?.map((group: any) => formatGroupSelect(group)),
  code: values?.code,
  version: values.version || employeeDataVersion,
  initialTabs: values?.initialTabs || {},
  banking: values?.banking || null,
});

export const formatEmployeeSelect = (employee: any) => ({
  ...employee,
  name: `${employee.firstName} ${employee.lastName}`,
});

export const formatDispatcherSelect = (employee: any) => ({
  ...employee,
  name: `${employee.firstName} ${employee.lastName}`,
});

export const formatDriverSelect = (employee: any) => ({
  ...employee,
  name: `${employee.firstName} ${employee.lastName}`,
});

const formatEmployeeGroups = (employeeGroups: Group[] | undefined, groups: Group[] | undefined) =>
  (employeeGroups &&
    groups &&
    Object.values(employeeGroups).map((employeeGroup: any) =>
      groups.find((group: any) => employeeGroup.id === group.id)
    )) ||
  [];

export const formatEmployeesValuesList = (employees: any, units: any, groups: any, assets: any) =>
  employees
    ? Object.values(employees)
        .filter((employee) => employee)
        .map((employee: any) => {
          const driverUnit =
            units &&
            assets &&
            employees &&
            employee?.unit?.id &&
            getUnit(employee?.unit?.id, units, assets, employees);
            
          const employeeGroups =
            groups && formatEmployeeGroups(employee?.groups, Object.values(groups));

          return formatEmployeeValues({
            ...formatEmployeeData(employee),
            unit: driverUnit,
            groups: employeeGroups,
          });
        })
    : [];

export default formatEmployeeValues;
