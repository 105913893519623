import { Unit, UnitEventAction, UnitStatus } from 'constants/Unit';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { formatUnitData } from 'screens/dispatch/board/data/migration';
import { formatUnitsValuesList } from 'screens/dispatch/board/schemas/unit/formatUnitValues';
import { UnitAPI } from 'services/data/UnitAPI';

import updatedDependentFields from './updatedDependentFields';

const useFirebaseUnitAPI = (): UnitAPI => {
  const firestore = useFirestore();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const units: Unit[] = useSelector((state: any) => {
    const data = state?.firestore?.data;
    let result: Unit[] = [];

    if (data) {
      const { units, employees, assets, loads, accessories } = data;
      result = formatUnitsValuesList(units, employees, assets, loads, accessories);
    }

    return result;
  });

  const defualtUnitAddEvent = {
    timestamp: Date.now(),
    status: UnitStatus.READY,
    user: defaultCompany?.employeeId,
    action: UnitEventAction.ADD,
  };

  const add = (path: string, data: any) => {
    try {
      return firestore.collection(path).add(formatUnitData(data));
    } catch (error) {
      console.error('FIRESTORE:ADD error in useFirebaseUnitAPI: ', error);

      return Promise.reject(error);
    }
  }

  const update = (path: string, data: any) => {
    try {
      return firestore.doc(path).update(formatUnitData(data));
    } catch (error) {
      console.error('FIRESTORE:UPDATE error in useFirebaseUnitAPI: ', error);

      return Promise.reject(error);
    }
  }

  const remove = (id: string) => {
    try {
      return firestore.doc(`company/${defaultCompany.companyId}/units/${id}`).delete();
    } catch (error) {
      console.error('FIRESTORE:REMOVE error in useFirebaseUnitAPI: ', error);

      return Promise.reject(error);
    }
  }

  const addUnit = (
    data: any = {},
    company: any = defaultCompany,
    event: any = defualtUnitAddEvent
  ) =>
    add(`company/${company.companyId}/units`, {
      ...data,
      events: {
        [`${Date.now()}`]: event,
      },
    });

  const updateUnit = (
    id: string,
    data: any,
    company: any = defaultCompany,
    event: any = defualtUnitAddEvent
  ) =>
    update(`company/${company.companyId}/units/${id}`, {
      ...data,
      events: {
        ...data.events,
        [`${Date.now()}`]: event,
      },
    });

  const addUnitEvent = (event: any, companyId: string = defaultCompany.companyId) =>
    add(`company/${companyId}/unitsEvents`, event);

  const removeUnit = (id: string, companyId: string) => remove(`company/${companyId}/units/${id}`);

  const onRemoveUnit = (unit: Unit, company: any = defaultCompany) =>
    removeUnit(unit.id, company.companyId).then(() =>
      addUnitEvent({
        [`${Date.now()}`]: {
          timestamp: Date.now(),
          user: company.employeeId,
          action: UnitEventAction.REMOVE,
          target: unit.id,
        },
      }).then(() => updatedDependentFields('unit', unit, {}, firestore, defaultCompany))
    );

  const onEditUnit = (
    unit: Unit,
    initialValues: Unit | undefined = undefined,
    company: any = defaultCompany
  ) =>
    updateUnit(unit.id, unit, company).then(() =>
      updatedDependentFields('unit', initialValues, unit, firestore, defaultCompany)
    );

  const onAddUnit = (
    unit: Unit,
    initialValues: Unit | undefined = undefined,
    company: any = defaultCompany
  ) =>
    addUnit(unit, company).then(() =>
      updatedDependentFields('unit', initialValues, unit, firestore, defaultCompany)
    );

  return {
    onAddUnit,
    onEditUnit,
    onRemoveUnit,
    addUnitEvent,
    units,
  };
};

export default useFirebaseUnitAPI;
