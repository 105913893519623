import { LoadStatus } from './Load';
import { UnitStatus } from './Unit';

export enum UpdateType {
  DISPATCH,
  UNIT,
  ACCOUNTING,
  STOP,
  UPDATE,
  FLEET,
  SAFETY,
}

export enum AccountingUpdateStatus {
  DOC_MISSING = 'DOC MISSING',
  DOC_REQUESTED = 'DOC REQUESTED',
  DOC_SUBMITTED = 'DOC SUBMITTED',
  DOC_READY = 'DOC_READY',
  INVOICED = 'INVOICED',
  FACTORED = 'FACTORED',
  DENIED = 'DENIED',
  AGED_30 = 'AGED_30',
  AGED_60 = 'AGED_60',
  AGED_90 = 'AGED_90',
  AGED_120 = 'AGED_120',
  PAID = 'PAID',
  COLLECTION = 'COLLECTION',
  LOSS = 'LOSS',
  FUNDED = 'FUNDED',
  UNDERPAID = 'UNDERPAID',
}

export interface UpdateStatusNode {
  next: (LoadStatus | UnitStatus | AccountingUpdateStatus)[] | null;
  label: string;
  type: LoadStatus | UnitStatus | AccountingUpdateStatus;
  role: UpdateType[];
  source?: any;
  target?: any;
  location?: any;
}

const CancelledStatus: UpdateStatusNode = {
  next: null,
  label: 'Cancelled',
  type: LoadStatus.CANCELLED,
  role: [UpdateType.DISPATCH, UpdateType.UPDATE],
};

const RejectedStatus: UpdateStatusNode = {
  next: null,
  label: 'Rejected',
  type: LoadStatus.REJECTED,
  role: [UpdateType.DISPATCH],
};

const DelayStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.ENROUTE, UnitStatus.WAITING],
  label: 'Delay',
  type: UnitStatus.DELAY,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const StopStatus: UpdateStatusNode = {
  next: [LoadStatus.ENROUTE, UnitStatus.WAITING, LoadStatus.CANCELLED, LoadStatus.REJECTED],
  label: 'Stop',
  type: UnitStatus.STOP,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP],
};

const WaitingStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.ENROUTE, UnitStatus.STOP],
  label: 'Waiting',
  type: UnitStatus.WAITING,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP],
};

const ResetStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.ENROUTE, UnitStatus.WAITING],
  label: 'Reset',
  type: UnitStatus.RESET,
  role: [
    UpdateType.DISPATCH,
    UpdateType.UNIT,
    UpdateType.UPDATE,
    UpdateType.STOP,
    UpdateType.SAFETY,
  ],
};

const OffStatus: UpdateStatusNode = {
  next: [UnitStatus.READY, LoadStatus.CANCELLED, LoadStatus.REJECTED],
  label: 'OFF',
  type: UnitStatus.OFF,
  role: [
    UpdateType.DISPATCH,
    UpdateType.UNIT,
    UpdateType.UPDATE,
    UpdateType.STOP,
    UpdateType.SAFETY,
  ],
};

const ScaleStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.ENROUTE, UnitStatus.WAITING],
  label: 'Scale',
  type: UnitStatus.SCALE,
  role: [
    UpdateType.DISPATCH,
    UpdateType.UNIT,
    UpdateType.UPDATE,
    UpdateType.STOP,
    UpdateType.ACCOUNTING,
  ],
};

const InspectionStatus: UpdateStatusNode = {
  next: [
    LoadStatus.CANCELLED,
    LoadStatus.REJECTED,
    LoadStatus.ENROUTE,
    UnitStatus.WAITING,
    UnitStatus.RESET,
  ],
  label: 'Inspection',
  type: UnitStatus.INSPECTION,
  role: [
    UpdateType.DISPATCH,
    UpdateType.UNIT,
    UpdateType.UPDATE,
    UpdateType.STOP,
    UpdateType.SAFETY,
  ],
};

const AccidentStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.ENROUTE, UnitStatus.WAITING],
  label: 'Accident',
  type: UnitStatus.ACCIDENT,
  role: [
    UpdateType.DISPATCH,
    UpdateType.UNIT,
    UpdateType.UPDATE,
    UpdateType.STOP,
    UpdateType.SAFETY,
  ],
};

const FuelStatus: UpdateStatusNode = {
  next: [
    LoadStatus.CANCELLED,
    LoadStatus.REJECTED,
    LoadStatus.ENROUTE,
    UnitStatus.WAITING,
    UnitStatus.OFF,
    UnitStatus.RESET,
  ],
  label: 'Fuel',
  type: UnitStatus.FUEL,
  role: [
    UpdateType.DISPATCH,
    UpdateType.UNIT,
    UpdateType.UPDATE,
    UpdateType.STOP,
    UpdateType.ACCOUNTING,
  ],
};

const MaintananceStatus: UpdateStatusNode = {
  next: [
    LoadStatus.CANCELLED,
    LoadStatus.REJECTED,
    LoadStatus.ENROUTE,
    UnitStatus.WAITING,
    UnitStatus.OFF,
    UnitStatus.RESET,
  ],
  label: 'Maintanance',
  type: UnitStatus.MAINTENANCE,
  role: [
    UpdateType.DISPATCH,
    UpdateType.UNIT,
    UpdateType.UPDATE,
    UpdateType.STOP,
    UpdateType.ACCOUNTING,
    UpdateType.FLEET,
  ],
};

const ExchangeStatus: UpdateStatusNode = {
  next: [
    LoadStatus.CANCELLED,
    LoadStatus.REJECTED,
    LoadStatus.ENROUTE,
    UnitStatus.WAITING,
    UnitStatus.OFF,
    UnitStatus.RESET,
  ],
  label: 'Exchange',
  type: UnitStatus.EXHANGE,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE, UpdateType.STOP],
};

const BreakdownStatus: UpdateStatusNode = {
  next: [
    LoadStatus.CANCELLED,
    LoadStatus.REJECTED,
    LoadStatus.ENROUTE,
    UnitStatus.WAITING,
    UnitStatus.OFF,
    UnitStatus.RESET,
  ],
  label: 'Breakdown',
  type: UnitStatus.BREAKDOWN,
  role: [
    UpdateType.DISPATCH,
    UpdateType.UNIT,
    UpdateType.UPDATE,
    UpdateType.STOP,
    UpdateType.ACCOUNTING,
    UpdateType.FLEET,
  ],
};

const PaidStatus: UpdateStatusNode = {
  next: null,
  label: 'Paid',
  type: AccountingUpdateStatus.PAID,
  role: [UpdateType.ACCOUNTING],
};

const LossStatus: UpdateStatusNode = {
  next: null,
  label: 'Loss',
  type: AccountingUpdateStatus.LOSS,
  role: [UpdateType.ACCOUNTING],
};

const CollectionStatus: UpdateStatusNode = {
  next: [AccountingUpdateStatus.PAID, AccountingUpdateStatus.LOSS],
  label: 'Collection',
  type: AccountingUpdateStatus.COLLECTION,
  role: [UpdateType.ACCOUNTING],
};

const Aged120Status: UpdateStatusNode = {
  next: [AccountingUpdateStatus.PAID, AccountingUpdateStatus.LOSS],
  label: 'Aged 120 days',
  type: AccountingUpdateStatus.AGED_120,
  role: [UpdateType.ACCOUNTING],
};

const Aged90Status: UpdateStatusNode = {
  next: [
    AccountingUpdateStatus.PAID,
    AccountingUpdateStatus.LOSS,
    AccountingUpdateStatus.AGED_120,
    AccountingUpdateStatus.COLLECTION,
  ],
  label: 'Aged 90 days',
  type: AccountingUpdateStatus.AGED_90,
  role: [UpdateType.ACCOUNTING],
};

const Aged60Status: UpdateStatusNode = {
  next: [
    AccountingUpdateStatus.PAID,
    AccountingUpdateStatus.LOSS,
    AccountingUpdateStatus.AGED_90,
    AccountingUpdateStatus.COLLECTION,
  ],
  label: 'Aged 60 days',
  type: AccountingUpdateStatus.AGED_60,
  role: [UpdateType.ACCOUNTING],
};

const Aged30Status: UpdateStatusNode = {
  next: [
    AccountingUpdateStatus.PAID,
    AccountingUpdateStatus.LOSS,
    AccountingUpdateStatus.AGED_60,
    AccountingUpdateStatus.COLLECTION,
  ],
  label: 'Aged 30 days',
  type: AccountingUpdateStatus.AGED_30,
  role: [UpdateType.ACCOUNTING],
};

const InvoicedStatus: UpdateStatusNode = {
  next: [
    AccountingUpdateStatus.PAID,
    AccountingUpdateStatus.LOSS,
    AccountingUpdateStatus.AGED_30,
    AccountingUpdateStatus.COLLECTION,
  ],
  label: 'Invoiced',
  type: AccountingUpdateStatus.INVOICED,
  role: [UpdateType.ACCOUNTING],
};

const UnderpaidStatus: UpdateStatusNode = {
  next: [
    AccountingUpdateStatus.COLLECTION,
    AccountingUpdateStatus.INVOICED,
    AccountingUpdateStatus.PAID,
  ],
  label: 'Underpaid',
  type: AccountingUpdateStatus.UNDERPAID,
  role: [UpdateType.ACCOUNTING],
};

const FundedStatus: UpdateStatusNode = {
  next: [
    AccountingUpdateStatus.PAID,
    AccountingUpdateStatus.LOSS,
    AccountingUpdateStatus.AGED_30,
    AccountingUpdateStatus.COLLECTION,
  ],
  label: 'Funded',
  type: AccountingUpdateStatus.FUNDED,
  role: [UpdateType.ACCOUNTING],
};

const DeniedStatus: UpdateStatusNode = {
  next: [AccountingUpdateStatus.INVOICED, AccountingUpdateStatus.COLLECTION],
  label: 'Denied',
  type: AccountingUpdateStatus.DENIED,
  role: [UpdateType.ACCOUNTING],
};

const FactoredStatus: UpdateStatusNode = {
  next: [AccountingUpdateStatus.FUNDED, AccountingUpdateStatus.DENIED],
  label: 'Factored',
  type: AccountingUpdateStatus.FACTORED,
  role: [UpdateType.ACCOUNTING],
};

const DocReadyStatus: UpdateStatusNode = {
  next: [AccountingUpdateStatus.FACTORED, AccountingUpdateStatus.INVOICED],
  label: 'Documents Ready',
  type: AccountingUpdateStatus.DOC_READY,
  role: [UpdateType.ACCOUNTING],
};

let DocMissingStatus: any = {};

const DocSubmittedStatus: UpdateStatusNode = {
  next: [DocReadyStatus, DocMissingStatus],
  label: 'Documents Submitted',
  type: AccountingUpdateStatus.DOC_SUBMITTED,
  role: [UpdateType.ACCOUNTING],
};

const DocRequestedStatus: UpdateStatusNode = {
  next: [AccountingUpdateStatus.DOC_SUBMITTED],
  label: 'Documents Requested',
  type: AccountingUpdateStatus.DOC_REQUESTED,
  role: [UpdateType.ACCOUNTING],
};

DocMissingStatus = {
  next: [AccountingUpdateStatus.DOC_SUBMITTED, DocRequestedStatus],
  label: 'Documents Missing',
  type: AccountingUpdateStatus.DOC_MISSING,
  role: [UpdateType.ACCOUNTING],
};

const DeliveredStatus: UpdateStatusNode = {
  next: [DocMissingStatus, DocReadyStatus],
  label: 'Delivered',
  type: LoadStatus.DELIVERED,
  role: [UpdateType.DISPATCH, UpdateType.UPDATE, UpdateType.ACCOUNTING],
};

const UnloadedStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.ENROUTE, LoadStatus.DELIVERED],
  label: 'Unloaded',
  type: LoadStatus.UNLOADED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const UnloadingStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.UNLOADED],
  label: 'Unloading',
  type: LoadStatus.UNLOADING,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const LoadedStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.ENROUTE],
  label: 'Loaded',
  type: LoadStatus.LOADED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const LoadingStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.LOADED],
  label: 'Loading',
  type: LoadStatus.LOADING,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const ArrivedStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.LOADING, LoadStatus.UNLOADING],
  label: 'Arrived',
  type: LoadStatus.ARRIVED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const DispatchedStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.ENROUTE],
  label: 'Dispatched',
  type: LoadStatus.DISPATCHED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const BookedStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.DISPATCHED],
  label: 'Booked',
  type: LoadStatus.BOOKED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT],
};

const QuotedStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.BOOKED],
  label: 'Quoted',
  type: LoadStatus.QUOTED,
  role: [UpdateType.DISPATCH],
};

const InquiredStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.QUOTED, LoadStatus.BOOKED],
  label: 'Inquired',
  type: LoadStatus.INQUIRED,
  role: [UpdateType.DISPATCH],
};

const OfferedStatus: UpdateStatusNode = {
  next: [
    LoadStatus.CANCELLED,
    LoadStatus.REJECTED,
    LoadStatus.INQUIRED,
    LoadStatus.QUOTED,
    LoadStatus.BOOKED,
  ],
  label: 'Offered',
  type: LoadStatus.OFFERED,
  role: [UpdateType.DISPATCH],
};

const MatchedStatus: UpdateStatusNode = {
  next: [
    LoadStatus.CANCELLED,
    LoadStatus.REJECTED,
    LoadStatus.INQUIRED,
    LoadStatus.QUOTED,
    LoadStatus.BOOKED,
  ],
  label: 'Matched',
  type: LoadStatus.MATCHED,
  role: [UpdateType.DISPATCH],
};

const ReadyStatus: UpdateStatusNode = {
  next: [LoadStatus.DISPATCHED, UnitStatus.STOP],
  label: 'Ready',
  type: UnitStatus.READY,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const UpdateStatus: UpdateStatusNode = {
  next: [LoadStatus.CANCELLED, LoadStatus.REJECTED, LoadStatus.ENROUTE, UnitStatus.WAITING],
  label: 'Update',
  type: UnitStatus.LOCATION,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const EnrouteStatus: UpdateStatusNode = {
  next: [
    LoadStatus.ARRIVED,
    UnitStatus.STOP,
    UnitStatus.DELAY,
    LoadStatus.CANCELLED,
    LoadStatus.REJECTED,
  ],
  label: 'Enroute',
  type: LoadStatus.ENROUTE,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

const ScheduledStatus: UpdateStatusNode = {
  next: [LoadStatus.DISPATCHED, LoadStatus.CANCELLED, LoadStatus.REJECTED],
  label: 'Scheduled',
  type: LoadStatus.SCHEDULED,
  role: [UpdateType.DISPATCH, UpdateType.UNIT, UpdateType.UPDATE],
};

export const UpdateStatusList: { [key: string]: UpdateStatusNode } = {
  [LoadStatus.MATCHED]: MatchedStatus,
  [LoadStatus.OFFERED]: OfferedStatus,
  [LoadStatus.INQUIRED]: InquiredStatus,
  [LoadStatus.QUOTED]: QuotedStatus,
  [LoadStatus.BOOKED]: BookedStatus,
  [LoadStatus.REJECTED]: RejectedStatus,
  [LoadStatus.CANCELLED]: CancelledStatus,
  [LoadStatus.DISPATCHED]: DispatchedStatus,
  [LoadStatus.ENROUTE]: EnrouteStatus,
  [LoadStatus.ARRIVED]: ArrivedStatus,
  [LoadStatus.LOADING]: LoadingStatus,
  [LoadStatus.LOADED]: LoadedStatus,
  [LoadStatus.UNLOADING]: UnloadingStatus,
  [LoadStatus.UNLOADED]: UnloadedStatus,
  [UnitStatus.READY]: ReadyStatus,
  [UnitStatus.WAITING]: WaitingStatus,
  [UnitStatus.RESET]: ResetStatus,
  [UnitStatus.OFF]: OffStatus,
  [UnitStatus.SCALE]: ScaleStatus,
  [UnitStatus.INSPECTION]: InspectionStatus,
  [UnitStatus.ACCIDENT]: AccidentStatus,
  [UnitStatus.FUEL]: FuelStatus,
  [UnitStatus.MAINTENANCE]: MaintananceStatus,
  [UnitStatus.EXHANGE]: ExchangeStatus,
  [UnitStatus.BREAKDOWN]: BreakdownStatus,
  [UnitStatus.STOP]: StopStatus,
  [UnitStatus.DELAY]: DelayStatus,
  [LoadStatus.DELIVERED]: DeliveredStatus,
  [LoadStatus.SCHEDULED]: ScheduledStatus,
  [UpdateType.UPDATE]: UpdateStatus,
  [AccountingUpdateStatus.DOC_MISSING]: DocMissingStatus,
  [AccountingUpdateStatus.DOC_REQUESTED]: DocReadyStatus,
  [AccountingUpdateStatus.DOC_SUBMITTED]: DocSubmittedStatus,
  [AccountingUpdateStatus.DOC_READY]: DocReadyStatus,
  [AccountingUpdateStatus.INVOICED]: InvoicedStatus,
  [AccountingUpdateStatus.FACTORED]: FactoredStatus,
  [AccountingUpdateStatus.DENIED]: DeniedStatus,
  [AccountingUpdateStatus.AGED_30]: Aged30Status,
  [AccountingUpdateStatus.AGED_60]: Aged60Status,
  [AccountingUpdateStatus.AGED_90]: Aged90Status,
  [AccountingUpdateStatus.AGED_120]: Aged120Status,
  [AccountingUpdateStatus.PAID]: PaidStatus,
  [AccountingUpdateStatus.COLLECTION]: CollectionStatus,
  [AccountingUpdateStatus.LOSS]: LossStatus,
  [AccountingUpdateStatus.FUNDED]: FundedStatus,
  [AccountingUpdateStatus.UNDERPAID]: UnderpaidStatus,
};

export const DispatchStatusPriority: { [key: string]: number } = {
  [LoadStatus.MATCHED]: 0,
  [LoadStatus.OFFERED]: 0,
  [LoadStatus.INQUIRED]: 0,
  [LoadStatus.QUOTED]: 0,
  [LoadStatus.REJECTED]: 0,
  [LoadStatus.CANCELLED]: 0,
  [LoadStatus.BOOKED]: 1,
  [LoadStatus.DELIVERED]: 1,
  [LoadStatus.SCHEDULED]: 2,
  [UnitStatus.READY]: 3,
  [LoadStatus.DISPATCHED]: 4,
  [LoadStatus.LOADED]: 5,
  [LoadStatus.UNLOADED]: 5,
  [LoadStatus.ENROUTE]: 6,
  [UnitStatus.DELAY]: 7,
  [LoadStatus.ARRIVED]: 8,
  [LoadStatus.LOADING]: 10,
  [LoadStatus.UNLOADING]: 10,
  [UnitStatus.STOP]: 12,
  [UnitStatus.WAITING]: 13,
  [UnitStatus.EXHANGE]: 14,
  [UnitStatus.FUEL]: 15,
  [UnitStatus.SCALE]: 15,
  [UnitStatus.MAINTENANCE]: 16,
  [UnitStatus.RESET]: 17,
  [UnitStatus.OFF]: 18,
  [UnitStatus.BREAKDOWN]: 19,
  [UnitStatus.INSPECTION]: 20,
  [UnitStatus.ACCIDENT]: 20,
};
