import { useFirestore } from 'react-redux-firebase';

import { CodeAPI } from '../CodeAPI';

const useFirebaseCodeAPI = (): CodeAPI => {
  const firestore = useFirestore();

  const updateCode = (data: any) =>
    firestore.doc(`codes/${data.code}`).update({
      roles: data.roles,
    });

  const addCode = (data: any) => {
    try {
      return firestore.collection('codes').add(data);
    } catch (error) {
      console.error('FIRESTORE:ADD_CODE error', error);
      
      return Promise.reject(error);
    }
  };

  const deleteCode = (codeId: string) => firestore.doc(`codes/${codeId}`).delete();

  return {
    addCode,
    updateCode,
    deleteCode,
  };
};

export default useFirebaseCodeAPI;
