import { ExpenseType } from 'constants/Expense';

import { Grid, Icon, IconButton } from '@mui/material';
import MDTypography from 'components/common/MDTypography';

export const getExpenseIcon = (type: ExpenseType): string => {
  switch (type) {
    case ExpenseType.FUEL:
      return 'local_gas_station_outline';
    case ExpenseType.TOLL:
      return 'add_road_outline';
    case ExpenseType.SCALE:
      return 'scale_outlined';
    case ExpenseType.PARKING:
      return 'local_parking_outlined';
    case ExpenseType.SERVICE:
      return 'build';
    case ExpenseType.REIMBURSEMENT:
      return 'replay_outlined';
    case ExpenseType.ADVANCE:
      return 'account_balance_outlined';
    case ExpenseType.ACCESSORY:
      return 'devices_other_outlined';
    case ExpenseType.LUMPER:
      return 'local_shipping_outlined';
    case ExpenseType.OTHER:
    default:
      return 'receipt_long';
  }
};

const getExpenseLabel = (type: ExpenseType): string => {
  switch (type) {
    case ExpenseType.FUEL:
      return 'Fuel';
    case ExpenseType.TOLL:
      return 'Toll';
    case ExpenseType.SCALE:
      return 'Scale';
    case ExpenseType.PARKING:
      return 'Park';
    case ExpenseType.SERVICE:
      return 'Servc';
    case ExpenseType.REIMBURSEMENT:
      return 'Reimb';
    case ExpenseType.ADVANCE:
      return 'Adv';
    case ExpenseType.ACCESSORY:
      return 'Acces';
    case ExpenseType.LUMPER:
      return 'Lump';
    case ExpenseType.OTHER:
    default:
      return 'Other';
  }
};

function DataTableExpenseCell({ type }: any, action: any = null): JSX.Element {
  return (
    <Grid key={getExpenseIcon(type)} container direction="column" alignItems='center'>
      <IconButton disableRipple style={{ paddingTop: 0, paddingBottom: 0 }} onClick={() => action}>
        <Icon fontSize="small" color="secondary">
          {getExpenseIcon(type)}
        </Icon>
      </IconButton>
      <MDTypography variant="icon" color="secondary">
        {getExpenseLabel(type).toUpperCase()}
      </MDTypography>
    </Grid>
  );
}

export default DataTableExpenseCell;
