import { configureStore } from '@reduxjs/toolkit';
import { actionTypes, firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import formsSlice from 'redux/slices/formsSlice';
import unitsSlice from 'redux/slices/unitsSlice';

const store = configureStore({
  reducer: {
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    units: unitsSlice,
    forms: formsSlice,
  },
  preloadedState: {
    firebase: {
      auth: localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')!) : null,
      profile: localStorage.getItem('profile')
        ? JSON.parse(localStorage.getItem('profile')!)
        : null,
    },
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [actionTypes.LOGIN, actionTypes.AUTH_LINK_ERROR],
      },
    }),
});

export type AppState = ReturnType<typeof store.getState>;
export default store;
