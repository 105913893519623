/* eslint-disable no-unused-vars */
import { RoleType } from 'constants/Roles';
import { Document } from 'constants/Document';

import { Contact } from './Contact';
import { Location } from './Location';

export enum EmployeeGender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

enum EmployeeStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  HIDDEN = 'HIDDEN',
}

enum EmployeeEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

interface EmployeeEvent {
  timestamp: number;
  status: EmployeeStatus;
  note?: string;
  user?: string;
  action: EmployeeEventAction;
}

interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  roles: RoleType[];
  contact: Contact;
  status: EmployeeStatus;
  address: Location;
  version: number;
  events?: EmployeeEvent[];
  files: { [key: string]: { [key: string]: Document } };
  code: any;
  groups?: any;
  banking?: any;
  unit: any;
  gender: EmployeeGender;
  dob: number;
}

export { EmployeeStatus, EmployeeEventAction };
export type { Employee };
