import { FormField } from 'constants/form/Form';

import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  IconContainerProps,
  Rating,
  styled,
  useTheme,
} from '@mui/material';
import MDTypography from 'components/common/MDTypography';

interface Props {
  field: FormField;
  roles: { [key: string]: number };
  actions: any;
}

const permissionIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: (
      <IconButton color="inherit">
        <Icon fontSize="small" color="inherit">
          visibility
        </Icon>
      </IconButton>
    ),
    label: 'View',
  },
  2: {
    icon: (
      <IconButton color="inherit">
        <Icon fontSize="small" color="inherit">
          add
        </Icon>
      </IconButton>
    ),
    label: 'Add',
  },
  3: {
    icon: (
      <IconButton color="inherit">
        <Icon fontSize="small" color="inherit">
          edit
        </Icon>
      </IconButton>
    ),
    label: 'Edit',
  },
  4: {
    icon: (
      <IconButton color="inherit">
        <Icon fontSize="small" color="inherit">
          search
        </Icon>
      </IconButton>
    ),
    label: 'Audit',
  },
  5: {
    icon: (
      <IconButton color="inherit">
        <Icon fontSize="small" color="inherit">
          manage_accounts
        </Icon>
      </IconButton>
    ),
    label: 'Manager',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;

  return <span {...other}>{permissionIcons[value].icon}</span>;
}

function RolesSection(formData: Props) {
  const { field, roles, actions } = formData;
  const { label, options, simplified } = field;

  const theme = useTheme();

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: theme.palette.info.main,
    },
    '& .MuiRating-iconEmpty': {
      color: theme.palette.grey[300],
    },
  });

  const onChangeRole = (name: string, isChecked: boolean) => {
    let newRoles;

    if (isChecked) {
      newRoles = { ...roles, [name]: 3 };
    } else {
      newRoles = { ...roles };
      delete newRoles[name];
    }

    actions.setFieldValue('roles', newRoles);
  };

  const handleRoleChange = (name: string, newValue: number) => {
    let newRoles;

    if (newValue > 0) {
      newRoles = { ...roles, [name]: newValue };
    } else {
      delete roles[name];
      newRoles = roles;
    }

    actions.setFieldValue('roles', newRoles);
  };

  return (
    <>
      <MDTypography variant="caption" color="secondary" fontWeight="regular">
        {label}
      </MDTypography>
      <Grid
        container
        columnSpacing={1}
        alignItems="center"
        justifyContent={simplified ? 'space-around' : 'space-around'}
      >
        {options.map((role: any) => (
          <>
            <Grid
              item
              xs={simplified ? 1.5 : 5}
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <FormControlLabel
                sx={{ p: 0, m: 0, justifyContent: 'center' }}
                control={
                  <Checkbox
                    sx={{ alignContent: 'center' }}
                    name={role.name}
                    checked={roles[role.name] > 0 || false}
                    onChange={(event) => onChangeRole(event.target.name, event.target.checked)}
                    icon={
                      <Icon fontSize="small" color="inherit">
                        {`${role.icon}_outlined`}
                      </Icon>
                    }
                    checkedIcon={
                      <Icon fontSize="small" color="inherit">
                        {role.icon}
                      </Icon>
                    }
                  />
                }
                label={simplified ? '' : role.name}
                labelPlacement={simplified ? 'bottom' : 'end'}
              />
            </Grid>
            {!simplified && (
              <Grid
                container
                item
                xs={7}
                alignContent="center"
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <StyledRating
                  name={`permissions-${role.name}`}
                  value={roles[role.name] || 0}
                  IconContainerComponent={IconContainer}
                  getLabelText={(value: number) => permissionIcons[value].label}
                  onChange={(_event, newValue) => {
                    handleRoleChange(role.name, newValue || 0);
                  }}
                />
                {roles[role.name] !== null && (
                  <MDTypography variant="caption" color="inherit" fontWeight="regular">
                    {permissionIcons[roles[role.name]]?.label || 'None'}
                  </MDTypography>
                )}
              </Grid>
            )}
          </>
        ))}
        {/* TODO PROPER ERROR MESSAGE
    <div className="error-text">{errors.roles}</div> */}
      </Grid>
    </>
  );
}

export default RolesSection;
