import { Asset } from 'constants/Asset';
import { formatFiles } from 'constants/Util';

import { AssetDocumentType } from './configAsset';

export const AssetVersion: number = 1;

const formatAssetV1 = (data: any): Asset => ({
  id: data?.id || null,
  type: data?.type || null,
  status: data?.status || null,
  version: AssetVersion,
  unitId: data?.unitId || null,
  year: data?.year || null,
  make: data?.make || null,
  miles: data?.miles || null,
  color: data?.color || null,
  vin: data?.vin || null,
  plate: data?.plate || null,
  state: data?.state || null,
  unit: data?.unit ? { id: data.unit.id } : null,
  files: formatFiles(AssetDocumentType, data?.files),
  groups:
  data?.groups?.map((group: any) => ({
    id: group.id,
  })) || null,
});

const formatAssetData = (data: any): Asset => {
  const version = data?.version || AssetVersion;

  switch (version) {
    case 1: {
      return formatAssetV1(data);
    }

    default: {
      return formatAssetV1(data);
    }
  }
};

export const formatAssetsData = (data: any): Asset[] =>
  Object.values(data).map((value: any) => formatAssetData(value));

export default formatAssetData;
