import { Grid } from '@mui/material';
import MDTypography from 'components/common/MDTypography';

function DataTableLocationCell({ name, address1, city, state, zip }: any): JSX.Element {

  return (
    <Grid key={zip} container item direction="column">
      {name && (
        <MDTypography variant="overline" color="text" fontWeight="bold" lineHeight="inherit">
          {name}
        </MDTypography>
      )}
      {address1 && (
        <MDTypography variant="overline" color="text" lineHeight="inherit">
          {address1}
        </MDTypography>
      )}
        <MDTypography variant="overline" color="text" lineHeight="inherit">
          {city ? city + ' ' : ''}{state ? state + ', ' : ''}{zip}
        </MDTypography>
    </Grid>
  );
}

export default DataTableLocationCell;
