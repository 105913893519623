import { AccessoryType } from 'constants/Accessory';

import { Grid, Icon, IconButton } from '@mui/material';
import MDTypography from 'components/common/MDTypography';

export const getAccessoryIcon = (type: AccessoryType): string => {
  switch (type) {
    case AccessoryType.TRANSPONDER:
      return 'add_road_outline';
    case AccessoryType.FUEL_CARD:
      return 'local_gas_station_outline';
    case AccessoryType.BYPASS:
      return 'sensor_door_outline';
    case AccessoryType.CAMERA:
      return 'camera_alt_outline';
    case AccessoryType.TABLET:
      return 'stay_primary_landscape_outlined';
    case AccessoryType.ELD:
      return 'departure_board_outline';
    case AccessoryType.LOAD_ACCESSORY:
      return 'local_shipping_outline';
    case AccessoryType.OTHER:
    default:
      return 'add_circle_outline';
  }
};

const getAccessoryLabel = (type: AccessoryType): string => {
  switch (type) {
    case AccessoryType.TRANSPONDER:
      return 'Toll';
    case AccessoryType.FUEL_CARD:
      return 'Fuel';
    case AccessoryType.BYPASS:
      return 'Bypass';
    case AccessoryType.CAMERA:
      return 'Camera';
    case AccessoryType.TABLET:
      return 'Tablet';
    case AccessoryType.ELD:
      return 'ELD';
    case AccessoryType.LOAD_ACCESSORY:
      return 'Load';
    case AccessoryType.OTHER:
    default:
      return 'Other';
  }
};

function DataTableAccessoryCell({ type }: any, action: any = null): JSX.Element {
  return (
    <Grid key={getAccessoryIcon(type)} container direction="column" alignItems='center'>
      <IconButton disableRipple style={{ paddingTop: 0, paddingBottom: 0 }} onClick={() => action}>
        <Icon fontSize="small" color="secondary">
          {getAccessoryIcon(type)}
        </Icon>
      </IconButton>
      <MDTypography variant="icon" color="secondary">
        {getAccessoryLabel(type).toUpperCase()}
      </MDTypography>
    </Grid>
  );
}

export default DataTableAccessoryCell;
