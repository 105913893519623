import { PartyType } from 'constants/Expense';

import withAuthorization from 'session/withAuthorization';
import { Card, Icon, IconButton } from '@mui/material';
import { useDeepCompareMemo } from 'use-deep-compare';

import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import DataTableInfoCell from 'components/DataTable/DataTableInfoCell';
import {
  ExpenseStatusFilterGroup,
  ExpenseTypeFilterGroup,
  InfoFilterGroup,
  SimpleDateRangeFilterGroup,
} from 'components/DataTable/Filters';
import MDButton from 'components/common/MDButton';

import useBaseForm from 'hooks/useBaseForm';

import DataTableExpenseCell from 'components/DataTable/DataTableExpenseCell';

import useFirebaseExpenseAPI from 'services/data/Firebase/firebaseExpenseAPI';

import { ExpenseDocumentConfig } from './configExpenses';

const expenseTable = {
  columns: [
    {
      Header: 'Invoice id',
      accessor: 'invoiceId',
      width: '10%',
      Cell: ({ row }: any) => row?.original?.files?.Invoice?.[0]?.invoiceId || 'N/A',
    },
    {
      Header: 'status',
      accessor: 'status',
      width: '8%',
      ...ExpenseStatusFilterGroup,
    },
    {
      Header: 'type',
      accessor: 'type',
      width: '7%',
      Cell: ({ row }: any) => <DataTableExpenseCell type={row.original.type} />,
      ...ExpenseTypeFilterGroup,
    },
    {
      Header: 'Invoice Date',
      accessor: 'issueTimestamp',
      width: '8%',
      Cell: ({ value }: any) => value && new Date(value).toISOString()?.split('T')[0],
      ...SimpleDateRangeFilterGroup,
    },
    {
      Header: 'amount',
      accessor: 'amount',
      width: '8%',
      Cell: ({ row }: any) => row?.original?.files?.Invoice?.[0]?.amount || 'N/A',
    },
    {
      Header: 'paid by',
      accessor: 'paidBy',
      width: '15%',
      Cell: ({ row }: any) => {
        const expenseParty = row?.original?.paidBy;

        switch (expenseParty?.type) {
          case PartyType.CARRIER:
            return PartyType.CARRIER;
          case PartyType.BROKER:
            return expenseParty?.transactor?.name || PartyType.BROKER;
          case PartyType.DRIVER:
            return expenseParty?.transactor?.name || PartyType.DRIVER;
          default:
            return 'N/A';
        }
      },
    },
    {
      Header: 'pay to',
      accessor: 'payTo',
      width: '15%',
      Cell: ({ row }: any) => {
        const expenseParty = row?.original?.payTo;

        switch (expenseParty?.type) {
          case PartyType.CARRIER:
            return PartyType.CARRIER;
          case PartyType.BROKER:
            return expenseParty?.transactor?.name || PartyType.BROKER;
          case PartyType.DRIVER:
            return expenseParty?.transactor?.name || PartyType.DRIVER;
          default:
            return 'N/A';
        }
      },
    },
    { Header: 'notes', accessor: 'notes', width: '20%' },
    {
      Header: 'info',
      accessor: 'info',
      ...InfoFilterGroup(ExpenseDocumentConfig),
      Cell: ({ row }: any) => (
        <DataTableInfoCell
          row={row.original}
          actions={row.values.actions}
          documentConfig={ExpenseDocumentConfig}
        />
      ),
    },
    {
      Header: 'actions',
      accessor: 'actions',
      Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
      alignCell: 'right',
      isSortable: false,
      width: '5%',
    },
  ],
};

function ExpensesScreen() {
  const { expenses } = useFirebaseExpenseAPI();
  const { showExpenseForm, showExpenseDeleteDialog } = useBaseForm();

  const onHistory = (events: any) => console.log('handleHistory for', events);

  const mapExpenses =
    (expenses &&
      expenses?.map((expense: any) => {
        if (!expense) {
          return null;
        }

        return {
          ...expense,
          issueTimestamp: expense?.files?.Invoice?.[0]?.issueTimestamp,
          actions: {
            history: () => onHistory(expense.events),
            edit: (values: any = expense, tabs: any = {}) =>
              showExpenseForm({ ...values, initialTabs: tabs }),
            delete: () => showExpenseDeleteDialog(expense),
          },
        };
      })) ||
    [];

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={expenseTable.columns}
        data={mapExpenses}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={() => showExpenseForm()}
              sx={{
                ml: '0.5rem',
                p: 0,
                pl: '0.5rem',
                pr: '1rem',
                height: '25px',
                borderRadius: '5px',
              }}
            >
              <Icon>add</Icon>&nbsp; Expense
            </MDButton>
          </>
        }
      />
    ),
    [expenses]
  );

  return (
    <DashboardLayout>
      <Card>{generateTable}</Card>
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(ExpensesScreen);
