/* eslint-disable jsx-a11y/media-has-caption */
// @ts-nocheck
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from 'react';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/common/MDBox';

// Material Dashboard 2 PRO React TS examples components
import DefaultNavbar from 'navigation/navbars/DefaultNavbar';
import PageLayout from 'components/containers/PageLayout';

// Material Dashboard 2 PRO React page layout routes
import navbarRoutes from 'navigation/navbar.routes';

// Authentication pages components
import Footer from 'components/Footer';

// Declaring props types for BasicLayout
interface Props {
  image: string;
  children: ReactNode;
}

// {/* <MDBox position="absolute" width="100%" minHeight="100vh" sx={{ overflow: "hidden" }}>
//   <video id="video-source" muted="muted" preload="auto" autoPlay loop src={image} /> */}

function BasicLayout({ image, children }: Props): JSX.Element {
  return (
    <PageLayout>
      <DefaultNavbar routes={navbarRoutes} action={null} transparent light />
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }: any) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        {children}
      </MDBox>
      <Footer light />
    </PageLayout>
  );
}

export default BasicLayout;
