/* eslint-disable no-use-before-define */
import React, { forwardRef, useState } from 'react';
import {
  Dialog,
  Grid,
  Icon,
  Paper,
  PaperProps,
  Slide,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { Form, Formik } from 'formik';

import MDButton from 'components/common/MDButton';
import Draggable from 'react-draggable';
import { TransitionProps } from '@mui/material/transitions';
import useFirebaseEmpoyeeAPI from 'services/data/Firebase/firebaseEmpoyeeAPI';
import useFirebaseGroupAPI from 'services/data/Firebase/firebaseGroupAPI';
import useFirebaseUnitAPI from 'services/data/Firebase/firebaseUnitAPI';
import useFirebaseLoadAPI from 'services/data/Firebase/firebaseLoadAPI';
import useFirebaseAccessoryAPI from 'services/data/Firebase/firebaseAccessoryAPI';
import useFirebaseAssetAPI from 'services/data/Firebase/firebaseAssetAPI';
import useFirebaseExpenseAPI from 'services/data/Firebase/firebaseExpenseAPI';

import useFirebaseServiceAPI from 'services/data/Firebase/firebaseServiceAPI';

import MainContainer from './MainContainer';
import { formatFormikForm, formatFormikValidation } from './Utils';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="down" ref={ref} {...props} />
);

function BaseForm({ setup, actions, isAdding, formValues }: any): JSX.Element {
  const { id, generateConfig, configValues } = setup;
  const { initialTabs, ...rest } = formValues;

  const { employees, dispatchers, drivers } = useFirebaseEmpoyeeAPI();
  const { groups, brokers } = useFirebaseGroupAPI();
  const { units } = useFirebaseUnitAPI();
  const { loads } = useFirebaseLoadAPI();
  const { assets, trucks, trailers } = useFirebaseAssetAPI();
  const { accessories, elds, fuelCards, transponders } = useFirebaseAccessoryAPI();
  const { expenses } = useFirebaseExpenseAPI();
  const { services} = useFirebaseServiceAPI();

  const [extendedForm, setExtendedForm] = useState(!isAdding);

  const valueMap: any = {
    employees,
    groups,
    loads,
    units,
    dispatchers,
    brokers,
    drivers,
    trucks,
    trailers,
    assets,
    accessories,
    elds,
    fuelCards,
    transponders,
    expenses,
    services,
  };

  const mapConfigValues = (values: string) => {
    const result: any = {};

    Object.keys(values).forEach((key: any) => {
      result[key] = valueMap[values[key]];
    });

    return result;
  };

  const config = (values: any, configValues: string) =>
    generateConfig(
      {
        ...values,
        ...mapConfigValues(configValues),
      },
      extendedForm
    );

  const validations = formatFormikValidation(formatFormikForm(id, config(rest, configValues)));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open
      onClose={() => actions.close(id)}
      PaperComponent={isMobile ? Paper : PaperComponent}
      TransitionComponent={Transition}
      aria-labelledby="draggable-dialog"
      fullScreen={isMobile}
      maxWidth={setup.width || 'sm'}
      fullWidth
    >
      <Formik
        initialValues={formValues}
        validationSchema={validations}
        onSubmit={(values, { setSubmitting }) =>
          actions.submit(values).then(() => {
            console.log('submitted');
            setSubmitting(false);
          })
        }
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form id={id} autoComplete="off" className="custom-scroll">
            <Grid
              className="form custom-scroll"
              container
              sx={{ minHeight: '40rem', minWidth: '30rem', p: 3, pt: 1.5 }}
              direction="column"
              justifyContent="space-between"
              xs
            >
              <MainContainer
                mainConfig={config(values, configValues)}
                formValues={{ values, initialTabs }}
                validation={{ touched, errors }}
                actions={{
                  setFieldValue,
                  setFieldTouched,
                  form: { extendedForm, setExtendedForm },
                  ...actions,
                  getConfigValues: (key: string) => key ? mapConfigValues(configValues)[key] : mapConfigValues(configValues),
                }}
              />
            </Grid>
            <Grid
              container
              item
              justifyContent="space-between"
              direction="row"
              sx={{ p: 5, pt: 0 }}
            >
              <MDButton
                variant="gradient"
                color="light"
                sx={{ width: '120px', height: '50px', p: 0, borderRadius: '5px' }}
                onClick={() => actions.close(id)}
              >
                <Icon>close</Icon>&nbsp; Cancel
              </MDButton>
              <MDButton
                type="submit"
                disabled={isSubmitting}
                variant="gradient"
                color="dark"
                sx={{ width: '120px', height: '50px', p: 0, borderRadius: '5px' }}
              >
                <Icon>check</Icon>&nbsp; Submit
              </MDButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default BaseForm;
