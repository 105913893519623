import { RoleType } from 'constants/Roles';
import { Document, DocumentConfig } from 'constants/Document';
import { FieldType, FormConfig, FormType } from 'constants/form/Form';
import { EmployeeStatus } from 'constants/Employee';
import {
  AddressSection,
  CityStateZipRow,
  DobField,
  EmailFaxRow,
  FullNameRow,
  GenderField,
  PhoneExtensionRow,
  generateBankingTab,
  generateFilesTab,
  generateRolesSection,
  generateTypeTab,
} from 'constants/form/formConfig';

import { formatGroupSelect } from 'screens/roster/groups/schemas/formatGroupValues';
import { fetchCDLinfo } from 'services/documentAI/parseCDL';

const ConfigName = 'Employee';

export enum EmployeeDocumentType {
  CDL = 'CDL',
  Contract = 'Contract',
  DrugTest = 'DrugTest',
  MEDCard = 'MEDCard',
  MVR = 'MVR',
  PSP = 'PSP',
  Misc = 'Misc',
}

export const CDL: Document = {
  name: EmployeeDocumentType.CDL,
  id: '',
  issueTimestamp: 0,
  type: EmployeeDocumentType.CDL,
  role: {
    allowedFor: [RoleType.DRIVER],
    visibleFor: [
      RoleType.DRIVER,
      RoleType.ADMIN,
      RoleType.DISPATCHER,
      RoleType.ACCOUNTANT,
      RoleType.HR,
      RoleType.SAFETY,
    ],
    requiredFor: [RoleType.DRIVER],
  },
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  actions: {
    parse: (file: File, setFieldValue: any, fileId: string) => fetchCDLinfo(file).then((data: any) => {
      /*
      Data example:
      firstName "PATRICK"
      lastName "LERICHE"
      dob "12/09/1980"
      address1 "87113 GOLF COLONY CT APT 202"
      city "LAKE WORTH"
      state "FL"
      zip "33467"
      id "L620-660-80-449-0"
      issueTimestamp "2022-04-18T00:00:00.000Z"
      expireTimestamp "2023-10-25T00:00:00.000Z"
      */
    
      //CDL update
      data.id && setFieldValue(`${fileId}.id`, data.id);
      data.issueTimestamp && setFieldValue(`${fileId}.issueTimestamp`, data.issueTimestamp);
      data.expireTimestamp && setFieldValue(`${fileId}.expiration.expireTimestamp`, data.expireTimestamp);

      //Info update
      data.firstName && setFieldValue('firstName', data.firstName);
      data.lastName && setFieldValue('lastName', data.lastName);
      data.dob && setFieldValue('dob', data.dob);

      setFieldValue('address', {
        address1: data.address1,
        city: data.city,
        state: data.state,
        zip: data.zip,
      });
    
    }),
  },
  version: '1',
};

export const PSP: Document = {
  name: EmployeeDocumentType.PSP,
  issueTimestamp: 0,
  type: EmployeeDocumentType.PSP,
  role: {
    allowedFor: [RoleType.DRIVER],
    visibleFor: [RoleType.DRIVER, RoleType.ADMIN, RoleType.SAFETY, RoleType.HR],
    requiredFor: [RoleType.DRIVER],
  },
  version: '1',
};

export const MEDICAL_CARD: Document = {
  name: 'MED Card',
  issueTimestamp: 0,
  type: EmployeeDocumentType.MEDCard,
  role: {
    allowedFor: [RoleType.DRIVER],
    visibleFor: [RoleType.DRIVER, RoleType.ADMIN, RoleType.SAFETY, RoleType.HR],
    requiredFor: [RoleType.DRIVER],
  },
  expiration: {
    expireInDays: 2 * 365,
    notifyInDays: 30,
  },
  version: '1',
};

export const MVR: Document = {
  name: EmployeeDocumentType.MVR,
  issueTimestamp: 0,
  type: EmployeeDocumentType.MVR,
  role: {
    allowedFor: [RoleType.DRIVER],
    visibleFor: [RoleType.DRIVER, RoleType.ADMIN, RoleType.SAFETY, RoleType.HR],
    requiredFor: [RoleType.DRIVER],
  },
  expiration: {
    expireInDays: 365,
    notifyInDays: 7,
  },
  version: '1',
};

export const DRUG_TEST: Document = {
  name: 'Drug Test',
  issueTimestamp: 0,
  type: EmployeeDocumentType.DrugTest,
  role: {
    allowedFor: [RoleType.DRIVER],
    visibleFor: [RoleType.DRIVER, RoleType.ADMIN, RoleType.SAFETY, RoleType.HR],
    requiredFor: [RoleType.DRIVER],
  },
  expiration: {
    expireInDays: 365,
    notifyInDays: 7,
  },
  version: '1',
};

export const CONTRACT: Document = {
  name: 'Contract',
  issueTimestamp: 0,
  type: EmployeeDocumentType.Contract,
  role: {
    visibleFor: [RoleType.DRIVER, RoleType.ADMIN, RoleType.HR],
    requiredFor: [RoleType.DRIVER],
  },
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  version: '1',
};

export const MISC: Document = {
  name: 'Misc',
  issueTimestamp: 0,
  id: '',
  type: EmployeeDocumentType.Misc,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [],
  },
  version: '1',
};

export const EmployeeDocumentConfig: DocumentConfig = {
  [EmployeeDocumentType.CDL]: CDL,
  [EmployeeDocumentType.Contract]: CONTRACT,
  [EmployeeDocumentType.DrugTest]: DRUG_TEST,
  [EmployeeDocumentType.MEDCard]: MEDICAL_CARD,
  [EmployeeDocumentType.MVR]: MVR,
  [EmployeeDocumentType.PSP]: PSP,
  [EmployeeDocumentType.Misc]: MISC,
};

export const getExtendedEmployeeFormConfig = (values: any): FormConfig => ({
  Info: {
    type: FormType.GENERAL,
    label: 'Info',
    title: `${ConfigName} Info`,
    content: [FullNameRow, [DobField, GenderField], PhoneExtensionRow, EmailFaxRow, ...AddressSection],
  },
  Setup: {
    type: FormType.GENERAL,
    label: 'Setup',
    title: `${ConfigName} Setup`,
    content: [
      [
        {
          name: 'status',
          label: 'Status',
          type: FieldType.SELECT,
          options: Object.values(EmployeeStatus),
          validation: {
            errorMsg: 'Status is required.',
          },
        },
      ],
      [
        {
          name: 'groups',
          label: 'Groups',
          multiple: true,
          type: FieldType.SELECT,
          options: Object.values(values?.groups).map((group: any) => formatGroupSelect(group)),
          control: {
            editable: 'showGroupForm',
            addable: 'showGroupForm',
          },
        },
      ],
      [generateRolesSection()],
    ],
  },
  Banking: generateBankingTab(ConfigName),
  Files: generateFilesTab(ConfigName, EmployeeDocumentConfig, values),
});

const generateSimpleFile = (values: any) => {
  const result = (
    generateTypeTab('files', EmployeeDocumentConfig, values)?.CDL?.content as any
  )?.[0]?.content?.[0];

  return result || [];
};

const getSimplifiedEmployeeFormConfig = (values: any) => ({
  Info: {
    type: FormType.GENERAL,
    label: 'Info',
    title: `Add ${ConfigName}`,
    content: [
      generateSimpleFile(values),
      [generateRolesSection(true)],
      FullNameRow,
      [DobField, GenderField],
      PhoneExtensionRow,
      EmailFaxRow,
      CityStateZipRow,
    ],
  },
});

const getEmployeeFormConfig = (values: string, extended: boolean) =>
  extended ? getExtendedEmployeeFormConfig(values) : getSimplifiedEmployeeFormConfig(values);

export const employeeFormSetup = (id: number, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getEmployeeFormConfig,
});
