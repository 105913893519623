import { Autocomplete, Box, Grid, Icon, IconButton, createFilterOptions } from '@mui/material';
import MDBox from 'components/common/MDBox';
import MDInput from 'components/common/MDInput';
import MDTypography from 'components/common/MDTypography';
import { ErrorMessage } from 'formik';
// import  './SelectField.css';

import { getStopIcon } from 'components/CustomComponents/UpdateTimeline';

import { resolvePath } from '../Utils';

function SelectField(formData: any) {
  const filter = createFilterOptions<any>();

  const {
    config,
    values,
    validation: { errors, touched } = { errors: {}, touched: {} },
    actions,
  } = formData;

  const { label, name, options, validation } = config;

  const getEmptyValue = () => (config?.multiple ? [] : '');

  const setSelectValue = (name: string, value: any) => {
    actions.setFieldValue(name, value || getEmptyValue());
    const onChange = config?.control?.onChange;

    if (onChange) {
      if (onChange.action) {
        onChange.action(onChange.target, onChange.source(value), actions.setFieldValue);
      } else {
        actions.setFieldValue(onChange.target, onChange.source(value));
      }
    }
  };

  const generateDialogConfig = (dialogId: any, selectedValue: any, value: any) => ({
    id: dialogId,
    title: 'Info',
    text: (
      <Grid container>
        <Grid item>Do you want to reassign the {label}?</Grid>
        {config?.select?.infoArray?.map(
          (info: any) =>
            (info.show(selectedValue?.id, values) && (
              <Grid item container direction="row" alignItems="center">
                <IconButton size="small">
                  <Icon fontSize="small" color={info.color || 'inherit'}>
                    {info?.icon}
                  </Icon>
                </IconButton>
                {info.note}
              </Grid>
            )) || <div />
        )}
      </Grid>
    ),
    actions: {
      confirm: {
        text: 'Accept',
        action: () => {
          setSelectValue(name, value);
          actions.close(dialogId);
        },
      },
      cancel: {
        text: 'Cancel',
        action: () => {
          actions.close(dialogId);
        },
      },
    },
  });

  const onChangeSelect = (name: string, value: any) => {
    actions.setFieldTouched(name);
    const selectedValue = config?.multiple ? value.length && value[value.length - 1] : value;

    if (
      selectedValue &&
      config?.select?.infoArray?.some((info: any) => info.show(selectedValue?.id, values))
    ) {
      setTimeout(() => {
        actions?.dialog((dialogId: any) => generateDialogConfig(dialogId, selectedValue, value));
      });
    } else if (
      (config.multiple && value.find((item: any) => item?.id === 'NEW')) ||
      value?.id === 'NEW'
    ) {
      setTimeout(() => {
        actions[config?.control?.addable]();
      });
    } else {
      setSelectValue(name, value);
    }
  };

  const getSelectedValue = () => resolvePath(values, name, getEmptyValue());

  return (
    <Autocomplete
      openOnFocus={config?.select?.openOnFocus}
      groupBy={config?.select?.groupBy && ((option) => option[config?.select?.groupBy])}
      multiple={config?.multiple}
      disabled={config?.composition?.disabled}
      options={options}
      value={getSelectedValue()}
      // eslint-disable-next-line no-unused-vars
      getOptionDisabled={(options) =>
        config?.multiple &&
        config?.select?.max &&
        resolvePath(values, name, getEmptyValue())?.length >= config.select.max
      }
      getOptionLabel={(option: any) => option?.label ? option?.label : option?.name || option}
      isOptionEqualToValue={(option, value) =>
        option?.id && value?.id ? option.id === value?.id : option === value
      }
      onChange={(_, value) => onChangeSelect(name, value)}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 1, justifyContent: 'space-around' } }}
          {...props}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <MDTypography variant="inherit">{option?.label ? option?.label : option?.id ? option.name : option}</MDTypography>
            {option?.id !== 'NEW' && (
              <Grid direction="row">
                {config?.select?.infoArray?.map(
                  (info: any) =>
                    (info.show(option?.id, values) && (
                      <IconButton size="small">
                        <Icon fontSize="small" color={info.color || 'inherit'}>
                          {info?.icon}
                        </Icon>
                      </IconButton>
                    )) || <div/>
                )}
                {config?.control?.onView && (
                  <IconButton size="small">
                    <Icon fontSize="small" color="inherit">
                      visibility
                    </Icon>
                  </IconButton>
                )}
                {config?.control?.editable && (
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      actions[config?.control?.editable]({ ...option });
                    }}
                  >
                    <Icon fontSize="small" color="inherit">
                      edit
                    </Icon>
                  </IconButton>
                )}
              </Grid>
            )}
            {option?.icon && (
              <Icon fontSize="small" color="inherit">
                {getStopIcon(option.icon)}
              </Icon>
            )}
          </Grid>
        </Box>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (config?.control?.addable && (params?.inputValue !== '' || !options?.length)) {
          filtered.push({
            id: 'NEW',
            name: `Add ${params.inputValue?.length ? `"${params.inputValue}"` : `New ${label}`}`,
          });
        }

        return filtered;
      }}
      renderInput={(params) => (
        <MDBox mb={1.5}>
          <MDInput
            {...params}
            name={name}
            variant="outlined"
            label={label}
            fullWidth
            error={resolvePath(errors, name, false) && resolvePath(touched, name, false)}
            success={
              validation !== undefined &&
              resolvePath(values, name, false) &&
              resolvePath(values, name, false).length &&
              !resolvePath(errors, name, false)
            }
          />
          <MDBox mt={0.75}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              <ErrorMessage name={name} {...params} />
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
    />
  );
}

export default SelectField;
