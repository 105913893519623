import { Grid } from '@mui/material';
import MDTypography from 'components/common/MDTypography';

function DataTableContactCell({ name, phone, email }: any): JSX.Element {
  console.log('DataTableContactCell', name, phone, email);

  return (
    <Grid key={phone + name} container item direction="column">
      {name && (
        <MDTypography variant="overline" color="text" fontWeight="bold" lineHeight="inherit">
          {name}
        </MDTypography>
      )}
      {phone && (
        <MDTypography variant="overline" color="text" lineHeight="inherit">
          {phone}
        </MDTypography>
      )}
      {email && (
        <MDTypography variant="overline" color="text" lineHeight="inherit">
          {email}
        </MDTypography>
      )}
    </Grid>
  );
}

export default DataTableContactCell;
