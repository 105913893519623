import { RoleType } from 'constants/Roles';

import React from 'react';
import { Grid, Icon, IconButton } from '@mui/material';
import MDBox from 'components/common/MDBox';
import MDTypography from 'components/common/MDTypography';

interface Props {
  row: any;
  actions: any;
}

const getIconByRole = (role: string): string => {
  switch (role) {
    case RoleType.ADMIN:
      return 'settings';
    case RoleType.ACCOUNTANT:
      return 'request_quote_outlined';
    case RoleType.DRIVER:
      return 'local_shipping_outlined';
    case RoleType.DISPATCHER:
      return 'explore';
    case RoleType.HR:
      return 'people_alt';
    case RoleType.SAFETY:
      return 'shield';
    case RoleType.SERVICE:
      return 'build';
    default:
      return '';
  }
};

const getRoleLabel = (role: string): string => {
  switch (role) {
    case RoleType.ADMIN:
      return 'ADMIN';
    case RoleType.ACCOUNTANT:
      return 'ACCOUNT';
    case RoleType.DRIVER:
      return 'DRIVER';
    case RoleType.DISPATCHER:
      return 'DISP';
    case RoleType.HR:
      return 'HR';
    case RoleType.SAFETY:
      return 'SAFETY';
    case RoleType.SERVICE:
      return 'FLET';
    default:
      return '';
  }
}

function DataTableRolesCell({ row, actions }: Props): JSX.Element {
  return (
    <MDBox display="flex" justifyContent="space-between">
      {row?.roles &&
        Object.keys(row.roles).map((role: string) => (
          <Grid key={getIconByRole(role)} container direction="column" >
            <IconButton
              disableRipple
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={() => {
                actions.edit(row, { main: 'Setup' });
              }}
            >
              <Icon fontSize="small" color="secondary">
                {getIconByRole(role)}
              </Icon>
            </IconButton>
            <MDTypography variant="icon" color="secondary">
              {getRoleLabel(role)}
            </MDTypography>
          </Grid>
        ))}
    </MDBox>
  );
}

export default DataTableRolesCell;
