/* eslint-disable no-param-reassign */
import { useState } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Grid } from '@mui/material';

import MapboxStaticMapField from '../fields/StaticMapField';
import BaseForm from '../tabs/BaseTab';

function RouteAccordion({ config, values, validation, actions }: any) {
  const [expanded, setExpanded] = useState<string | false>(values.route?.expanded || '');

  const handleChange = (panel: string) => (event: any, newExpanded: boolean) => {
    if (event.target.id !== 'AccordionExpandButton') {
return;
}

    actions.setFieldValue('route.expanded', panel);
    setExpanded(newExpanded ? panel : false);
  };

  const canMoveStop = (stop: string, moveUp: boolean) => {
    const key1 = parseInt(stop, 10);

    return (key1 > 0 && moveUp) || (key1 < values.route.stops.length - 1 && !moveUp);
  };

  const moveStop = (stop: string, moveUp: boolean) => {
    if (canMoveStop(stop, moveUp)) {
      const key1 = parseInt(stop, 10);
      const key2 = key1 + (moveUp ? -1 : 1);

      [values.route.stops[key1], values.route.stops[key2]] = [
        values.route.stops[key2],
        values.route.stops[key1],
      ];

      actions.setFieldValue('route.stops', values.route.stops);
      actions.setFieldValue('route.expanded', key2);
      setExpanded(key2.toString());
    }
  };

  const canRemoveStop = () => values.route.stops.length > 2;

  const removeStop = (stop: string) => {
    const key = parseInt(stop, 10);

    if (canRemoveStop()) {
      delete values.route.stops[key];

      actions.setFieldValue(
        'route.stops',
        values.route.stops.filter((value: any) => value === null)
      );
    }
  };

  return (
    <Grid container justifyContent="top">
      <MapboxStaticMapField values={values} actions={actions} />
      {config &&
        Object.keys(config).map((stop: string) => {
          const [first, ...rest] = config[stop];

          return (
            <Grid container item direction="column">
              <MuiAccordion expanded={expanded === stop} onChange={handleChange(stop)}>
                <MuiAccordionSummary>
                  <BaseForm
                    config={[first]}
                    formValues={{ values }}
                    validation={validation}
                    actions={actions}
                  />
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                  <BaseForm
                    config={rest}
                    formValues={{ values }}
                    validation={validation}
                    actions={{
                      ...actions,
                      canMoveStopUp: () => canMoveStop(stop, true),
                      moveStopUp: () => moveStop(stop, true),
                      canMoveStopDown: () => canMoveStop(stop, false),
                      moveStopDown: () => moveStop(stop, false),
                      canRemoveStop: () => canRemoveStop(),
                      removeStop: () => removeStop(stop),
                    }}
                  />
                </MuiAccordionDetails>
              </MuiAccordion>
            </Grid>
          );
        })}
    </Grid>
  );
}

export default RouteAccordion;
