import {
  Accessory,
  AccessoryEventAction,
  AccessoryStatus,
  AccessoryType,
} from 'constants/Accessory';
import { FileUploadResult } from 'constants/Firebase';
import { intersectFilesUpload } from 'constants/Util';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import formatAccessoryData from 'screens/roster/accessories/migration';
import { formatAccessorysValuesList } from 'screens/roster/accessories/formatAccessory';

import { AccessoryAPI } from '../AccessoryAPI';

import useFirebaseFileAPI from './firebaseFileAPI';
import updatedDependentFields from './updatedDependentFields';

const API_PATH_ACCESSORIES = 'accessories';

const useFirebaseAccessoryAPI = (): AccessoryAPI => {
  const firestore = useFirestore();
  const { uploadBatch } = useFirebaseFileAPI();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const accessories: Accessory[] = useSelector((state: any) => {
    const data = state?.firestore?.data;
    let result: Accessory[] = [];

    if (data) {
      const { accessories, assets, units, employees, expenses } = data;
      result = formatAccessorysValuesList(accessories, assets, employees, units, expenses);
    }

    return result;
  });

  const transponders = accessories?.filter(
    (accessory: Accessory) => accessory.type === AccessoryType.TRANSPONDER
  );

  const fuelCards = accessories?.filter(
    (accessory: Accessory) => accessory.type === AccessoryType.FUEL_CARD
  );

  const elds = accessories?.filter((accessory: Accessory) => accessory.type === AccessoryType.ELD);

  const bypasses = accessories?.filter((accessory: Accessory) => accessory.type === AccessoryType.BYPASS);

  const cameras = accessories?.filter((accessory: Accessory) => accessory.type === AccessoryType.CAMERA);

  const tablets = accessories?.filter((accessory: Accessory) => accessory.type === AccessoryType.TABLET);

  const loadAccessories = accessories?.filter((accessory: Accessory) => accessory.type === AccessoryType.LOAD_ACCESSORY);

  const defualtAccessoryAddEvent = {
    timestamp: Date.now(),
    status: AccessoryStatus.PENDING,
    user: defaultCompany?.employeeId,
    action: AccessoryEventAction.ADD,
  };

  const add = (path: string, data: any) =>
    firestore.collection(path).add(formatAccessoryData(data));

  const update = (path: string, data: any) => firestore.doc(path).update(formatAccessoryData(data));

  const remove = (id: string) => firestore.doc(id).delete();

  const addAccessory = (
    data: any = {},
    company: any = defaultCompany,
    event: any = defualtAccessoryAddEvent
  ) =>
    add(`company/${company.companyId}/accessories`, {
      ...data,
      events: {
        [`${Date.now()}`]: event,
      },
    });

  const updateAccessory = (
    id: string,
    data: any,
    company: any = defaultCompany,
    event: any = defualtAccessoryAddEvent
  ) =>
    update(`company/${company.companyId}/accessories/${id}`, {
      ...data,
      events: {
        ...data.events,
        [`${Date.now()}`]: event,
      },
    });

  const addAccessoryEvent = (event: any, companyId: string = defaultCompany.companyId) =>
    add(`company/${companyId}/accessoriesEvents`, event);

  const removeAccessory = (id: string, companyId: string) =>
    remove(`company/${companyId}/accessories/${id}`);

  const onRemoveAccessory = (accessory: Accessory, company: any = defaultCompany) =>
    removeAccessory(accessory.id, company.companyId).then(() =>
      updatedDependentFields('accessory', accessory, {}, firestore, defaultCompany).then(() =>
        addAccessoryEvent({
          [`${Date.now()}`]: {
            timestamp: Date.now(),
            user: company.employeeId,
            action: AccessoryEventAction.REMOVE,
            target: accessory.id,
            source: accessory,
          },
        })
      )
    );

  const onEditAccessory = (
    { files, ...rest }: Accessory,
    initialValues: Accessory | undefined = undefined,
    company: any = defaultCompany
  ) =>
    uploadBatch(files, rest.id, API_PATH_ACCESSORIES).then((promiseResults: FileUploadResult[]) =>
      updateAccessory(
        rest.id,
        {
          ...rest,
          files: intersectFilesUpload(promiseResults, files),
        },
        company
      )
        .then(() => updatedDependentFields('accessory', initialValues, rest, firestore, defaultCompany))
        .then(() =>
          addAccessoryEvent({
            [`${Date.now()}`]: {
              timestamp: Date.now(),
              user: company.employeeId,
              action: AccessoryEventAction.EDIT,
              target: rest.id,
              source: { files, ...rest },
            },
          })
        )
    );

  const onAddAccessory = (
    { files, ...rest }: Accessory,
    initialValues: Accessory | undefined = undefined,
    company: any = defaultCompany
  ) =>
    addAccessory({}, company).then((createResult: any) =>
      uploadBatch(files, createResult.id, API_PATH_ACCESSORIES).then((promiseResults: FileUploadResult[]) =>
        updateAccessory(createResult.id, {
          ...rest,
          files: intersectFilesUpload(promiseResults, files),
          id: createResult.id,
        })
          .then(() => updatedDependentFields('accessory', initialValues, rest, firestore, defaultCompany))
          .then(() =>
            addAccessoryEvent({
              [`${Date.now()}`]: {
                timestamp: Date.now(),
                user: company.employeeId,
                action: AccessoryEventAction.ADD,
                target: createResult.id,
                source: { files, ...rest },
              },
            })
          )
      )
    );

  return {
    onAddAccessory,
    onEditAccessory,
    onRemoveAccessory,
    accessories,
    transponders,
    fuelCards,
    elds,
    bypasses,
    cameras,
    tablets,
    loadAccessories,
  };
};

export default useFirebaseAccessoryAPI;
