import { PaymentStatus } from 'constants/Expense';

import { Card, Divider, Grid, Icon, IconButton } from '@mui/material';
import MDTypography from 'components/common/MDTypography';
import MDBadge from 'components/common/MDBadge';
import dayjs from 'dayjs';

interface PaymentCardProps {
  index: number;
  payment: any;
  actions: any;
}

const getStatusColor = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.PAID:
      return 'success';
    case PaymentStatus.PROCESSING:
    case PaymentStatus.SENT:
      return 'warning';
    default:
      return 'info';
  }
};

const PaymentCard: React.FC<PaymentCardProps> = ({ index, payment, actions }) => {
  const renderPaymentInfo = () => {
    return (
      <Grid item container direction="column" xs={10} gap={1}>
        <Grid item container direction="row" justifyContent="space-between">
          <MDTypography variant="h6">{payment?.type?.toUpperCase()}</MDTypography>
          <MDBadge
            badgeContent={payment?.status}
            width='5rem'
            size="xs"
            color={getStatusColor(payment?.status)}
            container
          />
        </Grid>
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item container direction="column" xs={5}>
            <Grid item container direction="row" alignItems='center' gap={1}>
              <MDTypography variant="body2" fontWeight="bold">
                ${payment?.amount}
              </MDTypography>
              <MDTypography variant="body2" fontWeight="bold" color="text">
                {dayjs(payment?.paymentDate).format('YYYY-MM-DD')}
              </MDTypography>
            </Grid>
            <Grid item direction="row" alignItems='center'>
              <MDTypography variant="body2" fontWeight="medium" color="text">
                {payment?.reference}
              </MDTypography>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={7} justifyContent="space-between">
            <Grid item direction="row" alignItems='center'>
              <MDTypography variant="body2" noWrap fontWeight="medium" color="text">
                {payment?.paidBy?.type} {payment?.paidBy?.transactor}
              </MDTypography>
            </Grid>
            <Grid item direction="row" alignItems='center'>
              <MDTypography variant="body2" noWrap fontWeight="medium" color="text">
                {payment?.payTo?.type} {payment?.payTo?.transactor}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderActions = () => {
    return (
      <Grid item container direction="row" justifyContent="right" xs={2}>
        <Divider orientation="vertical" flexItem />
        <Grid item container direction="column" justifyContent="space-around" xs={6}>
          <IconButton onClick={() => actions?.editPayment(index, payment, actions)}>
            <Icon fontSize="small" color="inherit">
              edit
            </Icon>
          </IconButton>
          <IconButton onClick={() => actions?.deletePayment(index)}>
            <Icon fontSize="small" color="inherit">
              delete
            </Icon>
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card key={payment?.name}>
      <Grid container p={2} direction="row" justifyContent="space-between">
        {renderPaymentInfo()}
        {renderActions()}
      </Grid>
    </Card>
  );
};

export default PaymentCard;
