/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FieldType } from 'constants/form/Form';

import { useEffect, useMemo, useState } from 'react';

// react-table components
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

// @mui material components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Icon from '@mui/material/Icon';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/common/MDBox';
import MDTypography from 'components/common/MDTypography';
import MDInput from 'components/common/MDInput';
import MDPagination from 'components/common/MDPagination';

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from 'components/DataTable/DataTableHeadCell';
import DataTableBodyCell from 'components/DataTable/DataTableBodyCell';
import { useDeepCompareMemo } from 'use-deep-compare';

// Declaring props types for DataTable
interface Props {
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries: number[];
      };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  columns: any;
  data: { [key: string]: any }[];
  pagination?: {
    variant: 'contained' | 'gradient';
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark' | 'light';
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  actions?: any;
  initialState?: any;
}

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  columns,
  data,
  pagination,
  isSorted,
  noEndBorder,
  actions,
  initialState,
}: Props): JSX.Element {
  let defaultValue: any;
  let entries!: string[] | number[];

  if (entriesPerPage) {
    defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : '10';
    entries = entriesPerPage.entries ? entriesPerPage.entries : ['10', '25', '50', '100'];
  }

  const getInitialFiletersState = (initialState: any) => {
    const filters: any = {};

    initialState?.filters?.forEach((filter: any) => {
      filter?.value &&
        Object.keys(filter?.value).forEach((key) => {
          filters[key] = filter.value[key];
        });
    });

    return filters;
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: initialState || {},
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    setFilter,
    state: { pageIndex, pageSize, globalFilter },
  }: any = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: any) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option: any) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }: any) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }: any) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  const [filters, setFilters] = useState<any>(getInitialFiletersState(initialState));

  const handleFilterChange = (event: any, newFilter: any, id: string, type?: FieldType) => {
    if (type === FieldType.DATE) {
      setFilters({ ...filters, [event.target.name]: event.target.value });

      setFilter(id, {
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        [event.target.name]: event.target.value,
      });
    } else {
      setFilters({ ...filters, [id]: Array.from(new Set(newFilter)) });
      setFilter(id, Array.from(new Set(newFilter)));
    }
  };

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column: any) => {
    let sortedValue;

    if (isSorted && column.isSortaable) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (column.isSortable === false) {
      sortedValue = false;
    } else {
      sortedValue = 'none';
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd: any;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const renderHeader = useMemo(
    () =>
      actions || showTotalEntries || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {canSearch && (
            <MDBox display="flex">
              <MDBox display="flex" alignItems="center" width="12rem">
                <MDInput
                  placeholder="Search..."
                  name="search"
                  value={search}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }: any) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </MDBox>
              {headerGroups?.[0].headers
                .filter((group: any) => group.Filter)
                .map((group: any) => group.Filter(filters, handleFilterChange))}
            </MDBox>
          )}
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                Showing {entriesStart} to {entriesEnd} of {rows.length} entries
              </MDTypography>
            </MDBox>
          )}
          {actions && (
            <MDBox display="flex" alignItems="center">
              {actions}
            </MDBox>
          )}
        </MDBox>
      ) : null,
    [actions || showTotalEntries, canSearch, filters]
  );

  const renderFooter = useMemo(
    () => (
      <MDBox
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        p={!entriesPerPage && pageOptions.length === 1 ? 0 : 3}
      >
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entries}
              onChange={(event, newValue) => {
                setEntriesPerPage(parseInt(newValue, 10));
              }}
              size="small"
              sx={{ width: '5rem' }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;entries per page
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination?.variant ? pagination?.variant : 'gradient'}
            color={pagination?.color ? pagination?.color : 'info'}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: 'number', min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(event: any) => {
                    handleInputPagination(event);
                    handleInputPaginationValue(event);
                  }}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    ),
    [pageOptions, pageSize, canPreviousPage, canNextPage]
  );

  const renderTable = useDeepCompareMemo(
    () => (
      <TableContainer sx={{ boxShadow: 'none' }}>
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup: any) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : 'auto'}
                    align={column.alignHeader ? column.alignHeader : 'left'}
                    sorted={setSortedValue(column)}
                  >
                    {column.render('Header')}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row: any, key: any) => {
              prepareRow(row);

              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.alignCell ? cell.column.alignCell : 'left'}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [page, rows, headerGroups]
  );

  return (
    <>
      {renderHeader}
      {renderTable}
      {renderFooter}
    </>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ['5', '10', '15', '20', '25'] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
  actions: null,
};

export default DataTable;
