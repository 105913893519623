import { Grid, Icon, IconButton } from '@mui/material';

function ActionSection({ config, actions }: any) {
  const { options } = config;

  return (
    <Grid container item justifyContent="end" alignContent="end" sx={{ mt: '1rem' }}>
      {Object.values(options).map((option: any) => (
        <IconButton key={option.icon} disabled={!actions[option.conditionId]()} onClick={actions[option.actionId]}>
          <Icon fontSize="small" color="inherit">
            {option.icon}
          </Icon>
        </IconButton>
      ))}
    </Grid>
  );
}

export default ActionSection;
