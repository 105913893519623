type Types = any;

const accordion: Types = {
  defaultProps: {
    disableGutters: true,
  },

  styleOverrides: {
    root: {
      margin: 0,
      padding: 0,
    },

    '&:not(:last-child)': {
      borderBottom: 0,
    },
  },
};

export default accordion;
