import { Load } from 'constants/Load';
import { UpdateStatusList, UpdateType } from 'constants/Dispatch';
import { Unit } from 'constants/Unit';

import withAuthorization from 'session/withAuthorization';
import Card from '@mui/material/Card';
import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import { Grid, Icon, IconButton } from '@mui/material';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import DataTableRouteCell, { DataTableLoadCell } from 'components/DataTable/DataTableRouteCell';
import DataTableUnitCell from 'components/DataTable/DataTableUnitCell';
import LastLocationCell from 'components/DataTable/LastLocationCell';
import UnitETACell from 'components/DataTable/UnitETACell';
import useFirebaseUnitAPI from 'services/data/Firebase/firebaseUnitAPI';
import useFirebaseLoadAPI from 'services/data/Firebase/firebaseLoadAPI';
import { useDeepCompareMemo } from 'use-deep-compare';
import useBaseForm from 'hooks/useBaseForm';
import './styles/board.css';
import { getRoutes } from 'utils/getRoutes';
import { getCurrentStatus } from 'utils/status';
import { getNextStop, getNextStopSelectOption } from 'utils/stop';

import { StatusBadge } from 'components/StatusBadge';
import { useSelector } from 'react-redux';

import {
  formatLoadSelect,
  formatUnitLocationSelect,
} from 'screens/freight/loads/schemas/formatLoadValues';

import { getCurrentLoad } from 'utils/load';

import MDButton from 'components/common/MDButton';

import DataTableContactCell from 'components/DataTable/DataTableContactCell';

import { formatUnitSelect } from './schemas/unit/formatUnitValues';

const columns = [
  {
    Header: 'Status',
    accessor: 'status',
    width: '8%',
    Cell: ({ row }: any) => (
      <StatusBadge status={getCurrentStatus(row?.original?.status, row?.original?.loads)} />
    ),
  },
  {
    Header: 'Unit',
    accessor: ({ truck }: any) => (truck ? `${truck?.unitId} ${truck?.year} ${truck?.make}` : ''),
    width: '7%',
    Cell: ({ data, row }: any) => <DataTableUnitCell unit={data[row.id].truck} />,
  },
  {
    Header: 'Trailer',
    accessor: ({ trailer }: any) =>
      trailer ? `${trailer?.unitId} ${trailer?.year} ${trailer?.make}` : '',
    width: '7%',
    Cell: ({ data, row }: any) => <DataTableUnitCell unit={data[row.id].trailer} />,
  },
  {
    Header: 'Contact',
    accessor: ({ drivers }: any) =>
      drivers?.map((driver: any) => `${driver.name} ${driver.contact?.phone}`).join(''),
    width: '10%',
    Cell: ({ data, row }: any) => (
      <Grid container item direction="row">
        {data[row.id].drivers?.map((driver: any) => (
          <DataTableContactCell
            key={driver.contact?.phone + driver.name}
            name={driver.name}
            phone={driver.contact?.phone}
            email={driver.contact?.email}
          />
        ))}
      </Grid>
    ),
  },
  {
    Header: 'Last Location',
    accessor: 'id',
    width: '15%',
    Cell: ({ value }: any) => <LastLocationCell unitId={value} />,
  },
  {
    Header: 'Next Stop',
    accessor: 'loads',
    width: '15%',
    Cell: ({ value }: any) => <DataTableLoadCell stop={getNextStop(value) || []} />,
  },
  {
    Header: 'Eta',
    accessor: 'eta',
    Cell: ({ row }: any) => (
      <UnitETACell unitId={row?.values.id} nextStop={getNextStop(row?.original?.loads)} />
    ),
    width: '7%',
  },
  { Header: 'Notes', accessor: 'notes' },
  {
    Header: 'actions',
    accessor: 'actions',
    Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
    alignCell: 'right',
    isSortable: false,
    width: '3%',
  },
];

const columnsTodo = [
  {
    Header: 'route',
    accessor: ({ route }: any) => getRoutes(route?.stops),
    Cell: ({ data, row }: any) => (
      <DataTableRouteCell values={data[row.id]} actions={data[row.id]?.actions} />
    ),
  },
  {
    Header: 'actions',
    accessor: 'actions',
    Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
    alignCell: 'right',
    isSortable: false,
    width: '5%',
  },
];

function Units() {
  const { showUnitForm, showUnitDeleteDialog, showLoadForm, showUpdateForm } = useBaseForm();
  const { units } = useFirebaseUnitAPI();
  const { loads } = useFirebaseLoadAPI();

  const unitsInfo = useSelector((state: any) => state?.units?.lastUnitInfo);

  const mapUnits = (units: any) =>
    units?.map((unit: Unit) => ({
      ...unit,
      actions: {
        dispatch: (values: any = unit, tabs: any = {}) =>
          showUpdateForm({
            ...values,
            currentStatus: getCurrentStatus(unit.status, unit.loads),
            status: getCurrentStatus(unit.status, unit.loads),
            unitLocations: unitsInfo,
            unit: unit && formatUnitSelect(unit),
            unitLocation:
              unitsInfo?.[unit.id]?.location &&
              formatUnitLocationSelect(unitsInfo?.[unit.id]?.location),
            load: unit.loads && formatLoadSelect(getCurrentLoad(unit.loads)),
            nextStop: unit.loads && getNextStopSelectOption(unit.loads),
            loadRate:
              loads &&
              unit.loads &&
              loads.find((load: Load) => load.id === getCurrentLoad(unit.loads)?.id)?.accounting
                ?.rate,
            initialTabs: tabs,
          }),
        edit: (values: any = unit, tabs: any = {}) =>
          showUnitForm({ ...values, initialTabs: tabs }),
        delete: () => showUnitDeleteDialog(unit),
      },
    })) || [];

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={columns}
        data={mapUnits(units)}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled={process.env.FEATURE_EMPLOYEE_EVENTS !== 'true'}>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={() => showUnitForm()}
              sx={{
                ml: '0.5rem',
                p: 0,
                pl: '0.5rem',
                pr: '1rem',
                height: '25px',
                borderRadius: '5px',
              }}
            >
              <Icon>add</Icon>&nbsp; Unit
            </MDButton>
          </>
        }
      />
    ),
    [units]
  );

  const filterAssignableLoad = (load: Load) =>
    load &&
    (!load.assignedTo || load.assignedTo === '') &&
    load.status &&
    UpdateStatusList[load.status]?.role.includes(UpdateType.UNIT);

  const mapAssignableLoads = (loads: any) =>
    Object.values(loads)
      .filter((load: any) => filterAssignableLoad(load))
      .map((load: any) => ({
        ...load,
        actions: {
          dispatch: (values: any = load, tabs: any = {}) =>
            showUnitForm({ ...values, initialTabs: tabs }),
          confirm: () => {},
          edit: (values: any = load, tabs: any = {}) =>
            showLoadForm({ ...values, initialTabs: tabs }),
        },
      }));

  const generateAssignableTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={columnsTodo}
        data={mapAssignableLoads(loads)}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled={process.env.FEATURE_EMPLOYEE_EVENTS !== 'true'} color="inherit">
              <Icon fontSize="small">manage_search</Icon>
            </IconButton>
            <IconButton disabled color="inherit">
              <Icon fontSize="small">settings</Icon>
            </IconButton>
            <IconButton onClick={() => showLoadForm()} color="inherit">
              <Icon fontSize="small">add</Icon>
            </IconButton>
          </>
        }
      />
    ),
    [loads]
  );

  const shouldShowLoadTable = false;

  return (
    <DashboardLayout>
      {shouldShowLoadTable && (
        <Grid item className="route" xs={4}>
          <Card>{generateAssignableTable}</Card>
        </Grid>
      )}
      <Grid item xs={shouldShowLoadTable ? 8 : 12}>
        <Card>{generateTable}</Card>
      </Grid>
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(Units);
