import { FormConfig, FormField, FormTab, ValidationType } from 'constants/form/Form';

import * as Yup from 'yup';

export const resolvePath = (object: any, path: string, defaultValue: any = undefined) =>
  path.split('.').reduce((o, p) => (o && o[p] ? o[p] : defaultValue), object);

export const setPath = (object: any, path: string, value: any) =>
  path
    .split('.')
    // eslint-disable-next-line no-return-assign, no-plusplus, no-param-reassign
    .reduce((o, p, i) => (o[p] = path.split('.').length === ++i ? value : o[p] || {}), object);

const formatField = (content: FormField, formikForm: any) =>
  setPath(formikForm, content.name, {
    name: content.name,
    label: content.label,
    type: content.type,
    options: content.options,
    placeholder: content.placeholder,
    validation: content.validation,
  });

const formatFieldArray = (content: FormField[] | FormField[][], formikForm: any) => {
  content.forEach((field) => {
    if (Array.isArray(field)) {
      formatFieldArray(field, formikForm);
    } else {
      formatField(field, formikForm);
    }
  });
};

const formatTab = (formTab: FormTab, formikForm: any) => {
  let contentKeys: string[] | FormField[][] | FormField[][][];

  if (Array.isArray(formTab.content)) {
    contentKeys = formTab.content;
  } else {
    contentKeys = Object.keys(formTab.content);
  }

  contentKeys.forEach((contentItem) => {
    if (Array.isArray(contentItem)) {
      formatFieldArray(contentItem, formikForm);
    } else {
      formatTab((formTab.content as FormConfig)[contentItem], formikForm);
    }
  });
};

export const formatFormikForm = (formName: string, formData: FormConfig) => {
  const formikForm = {};

  Object.keys(formData).forEach((key) => {
    const formTab: FormTab = formData[key];
    formatTab(formTab, formikForm);
  });

  return formikForm;
};

const formatObject = (formField: any) => {
  const tempValidations = {};

  Object.keys(formField).forEach((field) => {
    const fieldConfig = formField[field];

    if (fieldConfig.validation) {
      const { validation } = fieldConfig;
      let yup;
      const fieldName = fieldConfig.name.split('.').pop();

      if (validation?.type === ValidationType.ARRAY) {
        if (validation.errorMsg) {
          yup = Yup.array()
            .of(
              Yup.object().shape({
                id: Yup.string(),
                name: Yup.string(),
              })
            )
            .min(1, validation.errorMsg)
            .required(validation.errorMsg);
        }

        Object.assign(tempValidations, { [fieldName]: yup });
      } else if (validation?.type === ValidationType.OBJECT) {
        if (validation.errorMsg) {
          yup = Yup.object().required(validation.errorMsg);
        }

        Object.assign(tempValidations, { [fieldName]: yup });
      } else {
        if (validation.errorMsg) {
          yup = Yup.string().required(validation.errorMsg);
        }

        if (validation.regex) {
          yup = Yup.string()
            .required(validation.errorMsg)
            .matches(validation.regex, validation.invalidMsg || validation.errorMsg);
        } else if (validation.oneOf) {
          yup = Yup.string()
            .oneOf(validation.oneOf, validation.invalidMsg || validation.errorMsg)
            .required(validation.errorMsg);
        }

        // eslint-disable-next-line prefer-object-spread
        Object.assign(tempValidations, { [fieldName]: yup });
      }
    } else if (!fieldConfig.name) {
      // eslint-disable-next-line no-param-reassign, prefer-object-spread
      Object.assign(tempValidations, { [field]: formatObject(fieldConfig) });
    }
  });

  return Yup.object().shape(tempValidations).nullable().default(null);
};

export const formatFormikValidation = (formikForm: any) => formatObject(formikForm);

export const zipRegExp: RegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const emailRegExp: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const ssnRegExp: RegExp = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;

export const phoneRegExp: RegExp = /^[0-9]{10}$/;

export const dateRegExp: RegExp =
  /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;

export const timeRegExp: RegExp = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const currencyRegExp: RegExp = /^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?(\.\d{1,2})?$/;

export const einRegExp: RegExp = /^[0-9]{2}-[0-9]{7}$/;

export const taxIdRegExp: RegExp = /^([0-9]{2}-[0-9]{7} | [0-9]{3}-[0-9]{2}-[0-9]{4})$/;

export const vinRegExp: RegExp = /^[a-zA-Z0-9]{17}$/;
