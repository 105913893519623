import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import MDTypography from 'components/common/MDTypography';
import { Grid, Icon } from '@mui/material';
import { useMaterialUIController } from 'context';

interface Props {
  events?: any;
}

function TimelineList({ events }: Props): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const getEventIcon = (type: any) => {
    switch (type) {
      case 'BOOKED':
        return 'inventory_2';
      case 'DISPATCHED':
        return 'support_agent';
      case 'LOADING':
        return 'file_download';
      case 'ENROUTE':
        return 'local_shipping';
      case 'UNLOADING':
        return 'upload';
      case 'EXCHANGED':
        return 'sync_alt';
      case 'CANCELLED':
        return 'do_disturb';
      case 'DELIVERED':
        return 'file_download_done';
      case 'REPAIR':
        return 'build';
      default:
        return 'inventory_2';
    }
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      {events &&
        events.map((event: any) => (
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0', minWidth: '7rem' }}>
              <MDTypography variant="button" fontWeight="medium">
                {event.status}
              </MDTypography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: 'success' }} />
              <TimelineDot sx={{ m: 0 }}>
                <Icon>{getEventIcon(event.status)}</Icon>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Grid container direction="column" sx={{ p: 0 }}>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color={darkMode ? 'white' : 'dark'}
                >
                  {`${event.title} ${event.target}`}
                </MDTypography>
                <MDTypography variant="caption" color={darkMode ? 'secondary' : 'text'}>
                  {`${event.timestamp} by ${event.user}`}
                </MDTypography>
                {event.note && (
                  <MDTypography
                    variant="button"
                    color={darkMode ? 'white' : 'dark'}
                    sx={{ py: '0.5rem' }}
                  >
                    {event.note}
                  </MDTypography>
                )}
              </Grid>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}

TimelineList.defaultProps = {
  events: [
    {
      status: 'BOOKED',
      title: 'New load was booked',
      action: '',
      timestamp: '167543345663',
      user: 'Alex D',
      source: '',
      target: '#12344',
      note: undefined,
    },
    {
      status: 'DISPATCHED',
      title: 'Load was dispatched',
      action: '',
      timestamp: '167543345663',
      user: 'Alex D',
      source: '',
      target: '#112',
      note: undefined,
    },
    {
      status: 'LOADING',
      title: 'Loading started',
      action: '',
      timestamp: '167543345663',
      user: 'Alex D',
      source: '',
      target: '#112',
      note: undefined,
    },
    {
      status: 'ENROUTE',
      title: 'Load is enroute',
      action: '',
      timestamp: '167543345663',
      user: 'Alex D',
      source: '',
      target: '#112',
      note: undefined,
    },
    {
      status: 'UNLOADING',
      title: 'Unloading',
      action: '',
      timestamp: '167543345663',
      user: 'Alex D',
      source: '',
      target: '#112',
      note: undefined,
    },
    {
      status: 'EXCHANGED',
      title: 'Trailer is exchanged #345 ->',
      action: '',
      timestamp: '167543345663',
      user: 'Alex D',
      source: '',
      target: '#112',
      note: undefined,
    },
    {
      status: 'DELIVERED',
      title: 'Load is delivered',
      action: '',
      timestamp: '167543345663',
      user: 'Alex D',
      source: '',
      target: '#112',
      note: undefined,
    },
    {
      status: 'CANCELLED',
      title: 'Load is cancelled',
      action: '',
      timestamp: '167543345663',
      user: 'Alex D',
      source: '',
      target: '#112',
      note: undefined,
    },
  ],
};

export default TimelineList;
