import { useEffect } from 'react';
import FormField from 'screens/authentication/components/RegistrationCard/form/steps/FormField';
// import  './InputField.css';

import { resolvePath } from '../Utils';

function InputField(formData: any) {
  const {
    config,
    values,
    validation: { errors, touched },
    actions,
  } = formData;

  const {
    type,
    label,
    name,
    placeholder,
    composition: { disabled } = { disabled: false },
    validation,
    control: { formatBy, source } = { formatBy: undefined, source: undefined },
  } = config;

  const getValue = (values: string) =>
    formatBy ? formatBy(resolvePath(values, source, '')) : resolvePath(values, name, '');

  const shouldShrinkLabel = () => resolvePath(values, name, undefined)?.length || undefined;

  const onChange = (event: any) => {
    actions.setFieldValue(event.target.name, event.target.value || '');
    const onChange = config?.control?.onChange;

    if (onChange) {
      if (onChange.action) {
        onChange.action(
          onChange.target,
          onChange.source(event.target.value),
          actions.setFieldValue
        );
      } else {
        actions.setFieldValue(onChange.target, onChange.source(event.target.value));
      }
    }
  };

  useEffect(() => {
    const onChange = config?.control?.onChange;

    if (onChange) {
      if (onChange.action) {
        onChange.action(onChange.target, onChange.source(getValue(values)), actions.setFieldValue);
      } else {
        actions.setFieldValue(onChange.target, onChange.source(getValue(values)));
      }
    }
  }, []);

  return (
    <FormField
      type={type}
      label={label}
      name={name}
      value={getValue(values)}
      placeholder={placeholder}
      onChange={(event: any) => onChange(event)}
      disabled={disabled}
      error={resolvePath(errors, name, null) && resolvePath(touched, name, null)}
      success={
        validation !== undefined &&
        resolvePath(values, name, '')?.length > 0 &&
        !resolvePath(errors, name, null)
      }
      InputLabelProps={{
        shrink: shouldShrinkLabel(),
      }}
    />
  );
}

export default InputField;
