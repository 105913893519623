import { FieldType } from 'constants/form/Form';

import { LoadRouteStop } from 'constants/Load';

import { formatTimestamp } from 'components/BaseForm/fields/DateField';

export function getStopAppointment(stop: LoadRouteStop) {
  const date = formatTimestamp(stop?.timestamp);
  const timeFrom = formatTimestamp(stop?.timeFrom, FieldType.TIME);
  const timeTill = formatTimestamp(stop?.timeTill, FieldType.TIME);
  const until = timeFrom && timeTill ? `${timeFrom} -  ${timeTill}` : '';

  return date ? date + until : until;
}
