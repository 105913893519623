import { ExpenseStatus, ExpenseType, PartyType } from 'constants/Expense';
import { Document, DocumentConfig } from 'constants/Document';
import { FieldType, FormConfig, FormType } from 'constants/form/Form';
import { generateFilesTab, generateTypeTab } from 'constants/form/formConfig';

import { formatBrokerSelect } from 'screens/roster/groups/schemas/formatGroupValues';
import { formatDriverSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';
import { resolvePath } from 'components/BaseForm/Utils';
import { formatAccessorySelect } from 'screens/roster/accessories/formatAccessory';

const ConfigName = 'Expense';

export enum ExpenseDocumentType {
  Invoice = 'Invoice',
  Receipt = 'Receipt',
  Picture = 'Picture',
  Misc = 'Misc',
}

const getPartyOptions = (party: PartyType, values: any) => {
  switch (party) {
    case PartyType.BROKER:
      return Object.values(values?.brokers || [])?.map((broker: any) => formatBrokerSelect(broker));
    case PartyType.DRIVER:
      return Object.values(values?.drivers || [])?.map((driver: any) => formatDriverSelect(driver));
    case PartyType.CARRIER:
    default:
      return [];
  }
};

const getSourceOptions = (type: ExpenseType, values: any) => {
  if (!type || !values) {
    return [];
  }

  switch (type) {
    case ExpenseType.ACCESSORY:
      return Object.values(values?.accessories || [])?.map((accessory: any) =>
        formatAccessorySelect(accessory)
      );
    default:
      return [];
  }
};

export const ExpenseInvoice: Document = {
  name: ExpenseDocumentType.Invoice,
  type: ExpenseDocumentType.Invoice,
  expiration: {
    notifyInDays: 30,
  },
  extraFields: {
    invoiceId: {
      name: 'invoiceId',
      label: 'Id',
      type: FieldType.TEXT,
      composition: {
        weight: 8,
      },
    },
    amount: {
      name: 'amount',
      label: 'Amount',
      type: FieldType.NUMBER,
      composition: {
        weight: 4,
      },
    },
    issueTimestamp: {
      name: 'issueTimestamp',
      label: 'Issue Date',
      type: FieldType.DATE,
      composition: {
        weight: 6,
      },
    },
    invoiceDueDate: {
      name: 'expiration.expireTimestamp',
      label: 'Due Date',
      type: FieldType.DATE,
      control: {
        onChange: {
          source: (value: any) => value,
          action: (target: any, source: any, setFieldValue: any = null) => {
            source?.length && setFieldValue('files.Invoice.0.expiration.notifyInDays', 15);
          },
        },
      },
      composition: {
        weight: 6,
      },
    },
  },
  role: {
    requiredFor: [ExpenseStatus.ACTIVE, ExpenseStatus.PENDING, ExpenseStatus.CHARGED, ExpenseStatus.COLLECTED],
  },
  version: '0',
};

export const ExpenseReceipt: Document = {
  name: ExpenseDocumentType.Receipt,
  type: ExpenseDocumentType.Receipt,
  extraFields: {
    invoiceId: {
      name: 'receiptId',
      label: 'Id',
      type: FieldType.TEXT,
    },
    paymentDate: {
      name: 'paymentTimestamp',
      label: 'Date',
      type: FieldType.DATE,
    },
  },
  role: {
    requiredFor: [],
  },
  version: '0',
};

export const ExpensePicture: Document = {
  name: ExpenseDocumentType.Picture,
  type: ExpenseDocumentType.Picture,
  role: {
    requiredFor: [],
  },
  version: '0',
};

export const ExpenseMisc: Document = {
  name: ExpenseDocumentType.Misc,
  issueTimestamp: 0,
  id: '',
  type: ExpenseDocumentType.Misc,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [],
  },
  version: '0',
};

export const ExpenseDocumentConfig: DocumentConfig = {
  [ExpenseDocumentType.Invoice]: ExpenseInvoice,
  [ExpenseDocumentType.Receipt]: ExpenseReceipt,
  [ExpenseDocumentType.Picture]: ExpensePicture,
  [ExpenseDocumentType.Misc]: ExpenseMisc,
};

export const getExtendedExpensesFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        [
          {
            name: 'files.Invoice.0.invoiceId', //TODO Calculate proper index
            label: 'Invoice Id',
            type: FieldType.TEXT,
            composition: {
              weight: 8,
            },
          },

          {
            name: 'files.Invoice.0.amount', //TODO Calculate proper index
            label: 'Amount',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
        ],
        [
          {
            name: 'files.Invoice.0.issueTimestamp', //TODO Calculate proper index
            label: 'Invoice Date',
            type: FieldType.DATE,
          },
        ],
        [
          {
            name: 'files.Invoice.0.expiration.expireTimestamp', //TODO Calculate proper index
            label: 'Due Date',
            type: FieldType.DATE,
          },
        ],
        [
          {
            name: 'files.Receipt.0.paymentTimestamp', //TODO Calculate proper index
            label: 'Payment Date',
            type: FieldType.DATE,
          },
        ],
        [
          {
            name: 'notes',
            label: 'Notes',
            type: FieldType.TEXT,
          },
        ],
      ],
    },
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName} Setup`,
      content: [
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(ExpenseType),
          },
        ],
        [
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(ExpenseStatus),
          },
        ],
        [
          {
            name: 'source',
            label: 'Source',
            type: FieldType.SELECT,
            options: getSourceOptions(resolvePath(values, 'type', null), values),
            composition: {
              hideIf: (values: any) => resolvePath(values, 'type', null) !== ExpenseType.ACCESSORY,
            },
          },
        ],
        [
          {
            name: 'paidBy.type',
            label: 'Paid By Type',
            type: FieldType.SELECT,
            options: Object.values(PartyType),
            composition: {
              weight: 4,
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) => {
                  setFieldValue('paidBy.transactor', undefined);

                  setFieldValue(
                    'paidBy.options',
                    getPartyOptions(
                      resolvePath(values, 'paidBy.type', PartyType.CARRIER),
                      values
                    )
                  );
                },
              },
            },
          },
          {
            name: 'paidBy.transactor',
            label: 'Paid By',
            type: FieldType.SELECT,
            options: getPartyOptions(
              resolvePath(values, 'paidBy.type', PartyType.CARRIER),
              values
            ),
            composition: {
              weight: 8,
            },
          },
        ],
        [
          {
            name: 'payTo.type',
            label: 'Pay To Type',
            type: FieldType.SELECT,
            options: Object.values(PartyType),
            composition: {
              weight: 4,
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) => {
                  setFieldValue('payTo.transactor', undefined);
                },
              },
            },
          },
          {
            name: 'payTo.transactor',
            label: 'Pay To',
            type: FieldType.SELECT,
            options: getPartyOptions(
              resolvePath(values, 'payTo.type', PartyType.CARRIER),
              values
            ),
            composition: {
              weight: 8,
            },
          },
        ],
        [
          {
            name: 'billTo.type',
            label: 'Bill To Type',
            type: FieldType.SELECT,
            options: Object.values(PartyType),
            composition: {
              weight: 4,
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) => {
                  setFieldValue('billTo.transactor', undefined);
                },
              },
            },
          },
          {
            name: 'billTo.transactor',
            label: 'Bill To',
            type: FieldType.SELECT,
            options: getPartyOptions(
              resolvePath(values, 'billTo.type', PartyType.CARRIER),
              values
            ),
            composition: {
              weight: 8,
            },
          },
        ],
      ],
    },
    Payments: {
      type: FormType.PAYMENTS,
      label: 'Payments',
      title: `${ConfigName} Payments`,
      content: [[]],
    },
    Files: generateFilesTab(ConfigName, ExpenseDocumentConfig, values),
  } as FormConfig);

const generateInvoiceFileField = (values: any) => {
  const result = (
    generateTypeTab('files', ExpenseDocumentConfig, values)?.Invoice?.content as any
  )?.[0]?.content?.[0];

  return result || [];
};

export const getSimplifiedExpensesFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        generateInvoiceFileField(values),
        [
          {
            name: 'files.Invoice.0.invoiceId', //TODO Calculate proper index
            label: 'Invoice Id',
            type: FieldType.TEXT,
            composition: {
              weight: 8,
            },
          },

          {
            name: 'files.Invoice.0.amount', //TODO Calculate proper index
            label: 'Amount',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
        ],
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(ExpenseType),
            composition: {
              weight: 6,
            },
          },
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(ExpenseStatus),
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'paidBy.type',
            label: 'Paid By Type',
            type: FieldType.SELECT,
            options: Object.values(PartyType),
            composition: {
              weight: 4,
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) => {
                  setFieldValue('paidBy.transactor', undefined);
                },
              },
            },
          },
          {
            name: 'paidBy.transactor',
            label: 'Paid By',
            type: FieldType.SELECT,
            options: getPartyOptions(
              resolvePath(values, 'paidBy.type', PartyType.CARRIER),
              values
            ),
            composition: {
              weight: 8,
            },
          },
        ],
        [
          {
            name: 'billTo.type',
            label: 'Bill To Type',
            type: FieldType.SELECT,
            options: Object.values(PartyType),
            composition: {
              weight: 4,
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) => {
                  setFieldValue('billTo.transactor', undefined);
                },
              },
            },
          },
          {
            name: 'billTo.transactor',
            label: 'Bill To',
            type: FieldType.SELECT,
            options: getPartyOptions(
              resolvePath(values, 'billTo.type', PartyType.CARRIER),
              values
            ),
            composition: {
              weight: 8,
            },
          },
        ],
        [
          {
            name: 'files.Invoice.0.issueTimestamp', //TODO Calculate proper index
            label: 'Invoice Date',
            type: FieldType.DATE,
          },
        ],
        [
          {
            name: 'notes',
            label: 'Notes',
            type: FieldType.TEXT,
          },
        ],
      ],
    },
  } as FormConfig);

const getExpensesFormConfig = (values: string, extended: boolean) =>
  extended ? getExtendedExpensesFormConfig(values) : getSimplifiedExpensesFormConfig(values);

export const expenseFormSetup = (id: number, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getExpensesFormConfig,
});
