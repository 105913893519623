import { Unit, UnitStatus } from 'constants/Unit';

import { formatAccessories } from 'screens/roster/accessories/migration';

export const UnitVersion: number = 1;

const formatUnitV1 = (data: any): Unit => ({
  id: data?.id || null,
  unitId: data?.unitId || null,
  status: data?.status || UnitStatus.READY,
  drivers: data?.drivers?.map((driver: any) => ({ id: driver.id })) || null,
  truck: (data?.truck && { id: data?.truck.id }) || null,
  trailer: (data?.trailer && { id: data?.trailer.id }) || null,
  loads: data?.loads?.map((load: any) => ({ id: load.id })) || null,
  accessories: formatAccessories(data?.accessories),
  version: UnitVersion,
  events: data?.events || [],
});

export const formatUnitData = (data: any): Unit => {
  const version = data?.version || 1;

  switch (version) {
    case 1: {
      return formatUnitV1(data);
    }

    default: {
      return formatUnitV1(data);
    }
  }
};

export const formatUnitsData = (dataObject: any) =>
  dataObject
    ? Object.keys(dataObject).map((key: any) => formatUnitData({ ...dataObject[key], id: key }))
    : [];
