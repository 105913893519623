import { Load, LoadRouteType } from 'constants/Load';

import { UnitEvent, UnitEventAction, UnitStatus } from 'constants/Unit';

import { getStopAddress } from 'constants/Location';

import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { ReactComponent as PickIcon } from 'assets/images/pick.svg';
import { ReactComponent as FuelIcon } from 'assets/images/fuel.svg';
import { ReactComponent as DropIcon } from 'assets/images/drop.svg';
import { ReactComponent as TimeIcon } from 'assets/images/time.svg';
import { ReactComponent as MapIcon } from 'assets/images/map.svg';
import { ReactComponent as RouteIcon } from 'assets/images/timeline.svg';
import { ReactComponent as FileIcon } from 'assets/images/file.svg';
import { ReactComponent as TruckIcon } from 'assets/images/local_shipping.svg';
import { ReactComponent as AccidentIcon } from 'assets/images/accident.svg';
import { ReactComponent as ReadyIcon } from 'assets/images/ready.svg';
import { ReactComponent as OffIcon } from 'assets/images/off.svg';
import { ReactComponent as MaintenanceIcon } from 'assets/images/maintenance.svg';
import { ReactComponent as ExchangeIcon } from 'assets/images/exchange.svg';
import { ReactComponent as ScaleIcon } from 'assets/images/scale.svg';
import { ReactComponent as StopIcon } from 'assets/images/stop.svg';
import { ReactComponent as LocationIcon } from 'assets/images/location.svg';
//TODO add icons
// import { ReactComponent as BreakdownIcon } from 'assets/images/breakdown.svg';
// import { ReactComponent as DelayIcon } from 'assets/images/delay.svg';
// import { ReactComponent as InspectionIcon } from 'assets/images/inspection.svg';
// import { ReactComponent as WaitingIcon } from 'assets/images/waiting.svg';
// import { ReactComponent as ResetIcon } from 'assets/images/reset.svg';

import { useEffect, useState } from 'react';

import { FileUpload } from 'components/CustomComponents/FileUpload';

import { getStep } from 'utils/helpers';

import useMapboxAPI from 'services/map/MapboxAPI';

import MapBoxStaticMap from '../MapBoxStaticMap';

import CapacityPreview from '../CapacityPreview';

import { getStopsCoordinates } from '../MapBoxStaticMap/helpers';

import {
  StatusContainer,
  StyledAddress,
  StyledContainer,
  StyledData,
  StyledInfo,
  StyledLine,
  StyledName,
  StyledStatusName,
} from './styled';

import { UpdateStop, UpdateStopColor, UpdateStopColorList, UpdateTimelineProps } from './types';

/** Example for calling Status component where needed
 * 
 * <Status
        ...UpdateStop[]
      />
 */

export const getStopIcon = (type: LoadRouteType | UnitStatus, color: UpdateStopColor = UpdateStopColor.GREY) => {
  switch (type) {
    case LoadRouteType.Pick:
      return <PickIcon fill={color} />;
    case UnitStatus.FUEL:
      return <FuelIcon fill={color} />;
    case LoadRouteType.Drop:
      return <DropIcon fill={color} />;
    case UnitStatus.ACCIDENT:
      return <AccidentIcon fill={color} />;
    case UnitStatus.READY:
      return <ReadyIcon fill={color} />;
    case UnitStatus.OFF:
      return <OffIcon fill={color} />;
    case UnitStatus.MAINTENANCE:
      return <MaintenanceIcon fill={color} />;
    case UnitStatus.EXHANGE:
      return <ExchangeIcon fill={color} />;
    case UnitStatus.STOP:
      return <StopIcon fill={color} />;
    case UnitStatus.SCALE:
      return <ScaleIcon fill={color} />;
    case UnitStatus.LOCATION:
      return <LocationIcon fill={color} />;
    // case UnitStatus.BREAKDOWN:
    //   return <BreakdownIcon fill={color} />;
    // case UnitStatus.INSPECTION:
    //   return <InspectionIcon fill={color} />;
    // case UnitStatus.WAITING:
    //   return <WaitingIcon fill={color} />;
    // case UnitStatus.DELAY:
    //   return <DelayIcon fill={color} />;
    // case UnitStatus.RESET:
    //   return <ResetIcon fill={color} />;
  }
};

const UpdateTimeline: React.FC<UpdateTimelineProps> = ({
  unitNumber,
  loads,
  unitEvents,
  unitLocation,
}) => {
  const [step, setStep] = useState<number>(1);
  const [alignment, setAlignment] = useState('timeline');
  const [stops, setStops] = useState<UpdateStop[]>([]);
  const [route, setRoute] = useState<any>();

  const [activeRoute, setActiveRoute] = useState<string | undefined>();

  const getDefaultChecks = (unitNumber: string, loads: Load[]) => {
    const defaultChecks: any = {};

    if (unitNumber) {
      defaultChecks[unitNumber] = true;
    }

    loads.forEach((load: Load) => {
      defaultChecks[load.info.id] = true;
    });

    return defaultChecks;
  };

  const [routeSelectors, setRouteSelectors] = useState<{ [key: string]: boolean }>(
    getDefaultChecks(unitNumber, loads)
  );

  /* TODO
    Get icon based on type of the stop:
      PICK, DROP, STOP, FUEL, SCALE, ACCIDENT, INSPECTION, BREAKDOWN, MAINTENANCE, RESET, OFF, EXHANGE 
    and color: gray for empty truck or color coded by load: 1st is green, 2nd is blue, 3rd is orange, etc
  */

  useEffect(() => {
    if (unitNumber && !routeSelectors[unitNumber]) {
      setRouteSelectors((oldValue: any) => ({ ...oldValue, [unitNumber]: true }));
    }
  }, [unitNumber]);

  useEffect(() => {
    const coordinates = getStopsCoordinates(stops);

    if (coordinates) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMapboxAPI()
        .directions(coordinates)
        .then((actualData) => {
          if (actualData?.routes) {
            setRoute(actualData.routes[0]);
          }
        })
        .catch((err) => {
          return err;
        });
    }
  }, [stops]);

  useEffect(() => {
    let updateStop: UpdateStop[] = [];
    let startTimestamp: number | null = null;

    loads.forEach((load: Load, indexLoad: number) => {
      if (load.route?.stops && routeSelectors[load.info.id]) {
        load.route.stops.forEach((stop, indexStop) => {
          if (
            !startTimestamp ||
            (indexStop === 0 && stop?.timestamp && startTimestamp > stop?.timestamp)
          ) {
            startTimestamp = stop!.timestamp;
          }

          updateStop = [
            ...updateStop,
            {
              status: stop?.event?.status || 'Pending', //stop status
              timestamp: stop?.timestamp || 0,
              type: stop.type,
              distance: 0,
              appointment:
                stop?.timeFrom && stop?.timeTill
                  ? `${stop?.timestamp || 0 + stop.timeFrom} - ${
                      stop?.timestamp || 0 + stop.timeTill
                    }`
                  : `${stop.timestamp}`,
              address: getStopAddress(stop.location),
              color: UpdateStopColorList[indexLoad],
              isLastStop: indexStop === load.route!.stops.length - 1,
              isFirstStop: indexStop === 0,
              location: stop.location,
              stopId: load.info.id,
              info: stop.info,
            },
          ];
        });
      }
    });

    if (unitLocation) {
      unitEvents = Object.assign({ [unitLocation.timestamp]: unitLocation }, unitEvents);
    }

    if (routeSelectors[unitNumber] && unitEvents) {
      Object.keys(unitEvents).forEach((key: any) => {
        const event: UnitEvent | undefined = unitEvents[key];

        if (
          event &&
          event.action === UnitEventAction.UPDATE &&
          (!startTimestamp || startTimestamp < event.timestamp)
        ) {
          updateStop.push({
            status: event.status,
            timestamp: event.timestamp || 0,
            type: event.status,
            distance: 0,
            appointment: '',
            address: event?.location?.name
              ? event.location.name
              : `${event.location?.city} ${event.location?.state} ${event.location?.zip}`,
            color: UpdateStopColor.GREY,
            location: event.location,
            stopId: unitNumber,
          });
        }
      });
    }

    setStops(updateStop.sort((a, b) => a.timestamp - b.timestamp));
  }, [loads, routeSelectors, unitNumber, unitEvents, unitLocation]);

  const getRouteColor = (stops: UpdateStop[], index: number) => {
    let color = 'transperant';

    if (stops[index].color === UpdateStopColor.GREY && !stops[index + 1].isFirstStop) {
      color = stops[index + 1].color;
    } else if (stops[index].color === stops[index + 1].color || !stops[index].isLastStop) {
      color = stops[index].color;
    } else if (!stops[index + 1].isFirstStop) {
      color = stops[index + 1].color;
    }

    return color !== UpdateStopColor.GREY ? color : 'transperant';
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
    setStep(getStep(newAlignment));
  };

  const generateRouteCheckbox = (unitNumber: string, loads: Load[], routeSelectors: any) => (
    <Stack direction="row" justifyContent="space-around" height="46px" alignItems="end">
      {unitNumber && (
        <FormControlLabel
          onMouseOver={() => {
            setActiveRoute(UpdateStopColor.GREY);
          }}
          onMouseOut={() => setActiveRoute(undefined)}
          sx={{ p: 0, m: 0, justifyContent: 'center' }}
          componentsProps={{
            typography: {
              variant: 'h6',
              color: `${UpdateStopColor.GREY}${routeSelectors[unitNumber] ? '' : '4d'} !important`,
            },
          }}
          control={
            <Checkbox
              sx={{ alignContent: 'center' }}
              name={unitNumber}
              checked={routeSelectors[unitNumber]}
              defaultChecked
              icon={<TruckIcon fill={UpdateStopColor.GREY} opacity={0.3} />}
              checkedIcon={<TruckIcon fill={UpdateStopColor.GREY} />}
              onChange={(event) =>
                setRouteSelectors({ ...routeSelectors, [event.target.name]: event.target.checked })
              }
            />
          }
          label={unitNumber}
          labelPlacement="end"
        />
      )}
      {loads.map((load: any, index: any) => (
        //Whenever checkbox disabled hide related load
        <FormControlLabel
          key={load.info.id}
          onMouseOver={() => {
            setActiveRoute(UpdateStopColorList[index]);
          }}
          onMouseOut={() => setActiveRoute(undefined)}
          sx={{ p: 0, m: 0, justifyContent: 'center' }}
          componentsProps={{
            typography: {
              variant: 'h6',
              color: `${UpdateStopColorList[index]}${
                routeSelectors[load.info.id] ? '' : '4d'
              } !important`,
            },
          }}
          control={
            <Checkbox
              sx={{ alignContent: 'center' }}
              name={load.info.id}
              icon={<RouteIcon fill={UpdateStopColorList[index]} opacity={0.3} />}
              checkedIcon={<RouteIcon fill={UpdateStopColorList[index]} />}
              checked={routeSelectors[load.info.id]}
              onChange={(event) =>
                setRouteSelectors({ ...routeSelectors, [event.target.name]: event.target.checked })
              }
            />
          }
          label={load?.info?.id}
          labelPlacement="end"
        />
      ))}
    </Stack>
  );

  return (
    <>
      <Stack
        direction="row"
        paddingBottom="16px"
        display="flex"
        justifyItems="center"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <ToggleButtonGroup
          size="small"
          value={alignment}
          exclusive
          onChange={handleChange}
          style={{ height: '40px' }}
        >
          <ToggleButton value="timeline">
            <RouteIcon />
          </ToggleButton>
          <ToggleButton value="map">
            <MapIcon />
          </ToggleButton>
          <ToggleButton value="file">
            <FileIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <Autocomplete
          sx={{ width: '200px' }}
          disablePortal
          disableClearable
          defaultValue="FTL"
          options={['FTL', 'LTL', 'PTL']}
          renderInput={(params) => <TextField {...params} label="Loading Type" />}
        />
      </Stack>
      {step === 1 && (
        <div
          style={{
            width: '380px',
            height: '551px',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <StatusContainer>
            {stops?.map((stop, index) => {
              return (
                <div
                  key={index}
                  onMouseOver={() => {
                    setActiveRoute(stop.color);
                  }}
                  onMouseOut={() => setActiveRoute(undefined)}
                  style={{
                    opacity: !activeRoute || activeRoute === stop.color ? 1 : 0.6,
                    cursor: 'pointer',
                  }}
                  onClick={() => console.log('Selected stop', stop)}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    position="relative"
                    paddingLeft={index === stops.length - 1 ? '24px' : ''}
                    marginTop={index !== 0 ? '55px' : ''}
                  >
                    {stop.status === 'Delayed' && <TimeIcon />}
                    <StyledStatusName>
                      <StyledName>{stop.status}</StyledName>
                      <StyledData>{stop.timestamp}</StyledData>
                    </StyledStatusName>
                    <Stack position="absolute" left="135px">
                      {getStopIcon(stop.type, stop.color)}
                      {index !== stops.length - 1 && (
                        <StyledContainer>
                          <StyledLine color={getRouteColor(stops, index)} />
                          {route?.legs?.[index]?.distance && (
                            <Typography variant="caption">
                              {Math.round((route.legs[index].distance / 1609) * 10) / 10} miles
                            </Typography>
                          )}
                        </StyledContainer>
                      )}
                    </Stack>
                    <StyledInfo>
                      <StyledAddress>{stop.address}</StyledAddress>
                      <StyledData>{stop.appointment}</StyledData>
                    </StyledInfo>
                  </Stack>
                </div>
              );
            })}
          </StatusContainer>
          {generateRouteCheckbox(unitNumber, loads, routeSelectors)}
        </div>
      )}
      {step === 2 && (
        <Stack direction="column" gap="16px" height="551px">
          <MapBoxStaticMap stops={stops} route={route} />
          <CapacityPreview stops={stops} />
          {generateRouteCheckbox(unitNumber, loads, routeSelectors)}
        </Stack>
      )}
      {step === 3 && (
        <div style={{ height: '551px' }}>
          <FileUpload />
        </div>
      )}
    </>
  );
};

export default UpdateTimeline;
