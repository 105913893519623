import withAuthorization from 'session/withAuthorization';
import { Card, Grid, Icon, IconButton } from '@mui/material';
import { useDeepCompareMemo } from 'use-deep-compare';
import useFirebaseAccidentAPI from 'services/data/Firebase/firebaseAccidentAPI';

import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import DataTableInfoCell from 'components/DataTable/DataTableInfoCell';
import { AccidentStatusFilterGroup, InfoFilterGroup, SimpleDateRangeFilterGroup } from 'components/DataTable/Filters';
import DataTableUnitCell from 'components/DataTable/DataTableUnitCell';
import MDButton from 'components/common/MDButton';

import useBaseForm from 'hooks/useBaseForm';

import DataTableContactCell from 'components/DataTable/DataTableContactCell';

import DataTableLocationCell from 'components/DataTable/DataTableLocationCell';

import { AccidentDocumentConfig } from './configAccident';

const accidentTable = {
  columns: [
    {
      Header: 'report id',
      accessor: 'reportId',
      width: '10%',
    },
    {
      Header: 'status',
      accessor: 'status',
      width: '8%',
      ...AccidentStatusFilterGroup,
    },
    {
      Header: 'Accident Date',
      accessor: 'issueTimestamp',
      width: '8%',
      Cell: ({ value }: any) => value && new Date(value).toISOString()?.split('T')[0],
      ...SimpleDateRangeFilterGroup,
    },
    {
      Header: 'type',
      accessor: 'type',
      width: '7%',
    },
    {
      Header: 'unit',
      accessor: 'unitId',
      width: '10%',
      Cell: ({ row }: any) => (
        <DataTableUnitCell
          unit={{
            unitId: row.original.unit?.truck?.unitId,
            year: row.original.unit?.truck?.year,
            make: row.original.unit?.truck?.make,
          }}
        />
      ),
    },
    {
      Header: 'Driver',
      accessor: 'drivers',
      width: '10%',
      Cell: ({ value }: any) => (
        <Grid container item direction="row">
          {value?.map((driver: any) => (
            <DataTableContactCell
              key={driver.contact?.phone + driver.name}
              name={driver.name}
              phone={driver.contact?.phone}
              email={driver.contact?.email}
            />
          ))}
        </Grid>
      ),
    },
    {
      Header: 'location',
      accessor: 'location',
      width: '15%',
      Cell: ({ value }: any) => DataTableLocationCell(value),
    },
    { Header: 'notes', accessor: 'notes', width: '20%' },
    {
      Header: 'info',
      accessor: 'info',
      ...InfoFilterGroup(AccidentDocumentConfig),
      Cell: ({ row }: any) => (
        <DataTableInfoCell
          row={row.original}
          actions={row.values.actions}
          documentConfig={AccidentDocumentConfig}
        />
      ),
    },
    {
      Header: 'actions',
      accessor: 'actions',
      Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
      alignCell: 'right',
      isSortable: false,
      width: '10%',
    },
  ],
};

function AccidentsScreen() {
  const { accidents } = useFirebaseAccidentAPI();
  const { showAccidentForm, showAccidentDeleteDialog } = useBaseForm();

  const onHistory = (events: any) => console.log('handleHistory for', events);

  const mapAccidents =
    (accidents &&
      accidents?.map((accident: any) => {
        if (!accident) {
          return null;
        }

        console.log('DataTableContactCell', accident);

        return {
          ...accident,
          actions: {
            history: () => onHistory(accident.events),
            edit: (values: any = accident, tabs: any = {}) =>
              showAccidentForm({ ...values, initialTabs: tabs }),
            delete: () => showAccidentDeleteDialog(accident),
          },
        };
      })) ||
    [];

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={accidentTable.columns}
        data={mapAccidents}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={() => showAccidentForm()}
              sx={{
                ml: '0.5rem',
                p: 0,
                pl: '0.5rem',
                pr: '1rem',
                height: '25px',
                borderRadius: '5px',
              }}
            >
              <Icon>add</Icon>&nbsp; Accident
            </MDButton>
          </>
        }
      />
    ),
    [accidents]
  );

  return (
    <DashboardLayout>
      <Card>{generateTable}</Card>
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(AccidentsScreen);
