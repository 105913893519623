import withAuthorization from 'session/withAuthorization';

import Card from '@mui/material/Card';

import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import { Icon, IconButton } from '@mui/material';
import DataTableRolesCell from 'components/DataTable/DataTableRolesCell';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import useFirebaseGroupAPI from 'services/data/Firebase/firebaseGroupAPI';
import { useDeepCompareMemo } from 'use-deep-compare';
import useBaseForm from 'hooks/useBaseForm';
import { GroupStatusFilterGroup, GroupTypeFilterGroup } from 'components/DataTable/Filters';
import MDButton from 'components/common/MDButton';
import DataTableContactCell from 'components/DataTable/DataTableContactCell';

const groupTable = {
  columns: [
    { Header: 'name', accessor: 'companyName', width: '20%' },
    { Header: 'status', accessor: 'status', width: '7%', ...GroupStatusFilterGroup },
    { Header: 'type', accessor: 'type', width: '7%', ...GroupTypeFilterGroup },
    {
      Header: 'roles',
      accessor: 'roles',
      Cell: ({ row: { original } }: any) => (
        <DataTableRolesCell row={original} actions={original.actions} />
      ),
      alignCell: 'center',
    },
    {
      Header: 'contact',
      accessor: 'contact',
      Cell: ({ data, row }: any) => (
        <DataTableContactCell
          phone={row?.original?.contact?.phone}
          email={row?.original?.contact?.email}
        />
      ),
    },
    {
      Header: 'actions',
      accessor: 'actions',
      Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
      alignCell: 'right',
      isSortable: false,
    },
  ],
};

function Groups() {
  const { groups } = useFirebaseGroupAPI();
  const { showGroupForm, showGroupDeleteDialog, showGroupCodeDialog } = useBaseForm();

  const onHistory = (events: any) => console.log('handleHistory for', events);

  const mapGroups =
    (groups &&
      groups?.map((group: any) => {
        if (!group) {
          return null;
        }

        return {
          ...group,
          actions: {
            code: group.code ? () => showGroupCodeDialog(group.id, group.code) : undefined,
            history: () => onHistory(group.events),
            edit: (values: any = group, tabs: any = {}) =>
              showGroupForm({ ...values, initialTabs: tabs }),
            delete: () => showGroupDeleteDialog(group),
          },
        };
      })) ||
    [];

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={groupTable.columns}
        data={mapGroups}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={() => showGroupForm()}
              sx={{ ml: '0.5rem', p: 0, pl: '0.5rem', pr: '1rem', height: '25px',  borderRadius: '5px' }}
            >
              <Icon>add</Icon>&nbsp; Group
            </MDButton>
          </>
        }
      />
    ),
    [groups]
  );

  return (
    <DashboardLayout>
      <Card>{generateTable}</Card>
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(Groups);
