import { formatEventData } from 'constants/Event';
import { Service } from 'constants/Service';
import { formatFiles } from 'constants/Util';

import { ServiceDocumentType } from './configService';

export const ServiceVersion: number = 1;

const formatServiceV1 = (data: any): Service => ({
  id: data?.id || null,
  items: data?.items || null,
  type: data?.type || null,
  status: data?.status || null,
  asset: data?.asset ? { id: data.asset.id } : null,
  expense: data?.expense ? { id: data.expense.id } : null,
  provider: data?.provider ? { id: data.provider.id } : null,
  location: data?.location || null,
  scheduleDate: data?.scheduleDate || null,
  dueDate: data?.dueDate || null,
  notes: data?.notes || null,
  events: data?.events ? formatEventData(data.events) : null,
  version: ServiceVersion,
  files: formatFiles(ServiceDocumentType, data?.files),
});

const formatServiceData = (data: any): Service => {
  const version = data?.version || ServiceVersion;

  switch (version) {
    case 1: {
      return formatServiceV1(data);
    }

    default: {
      return formatServiceV1(data);
    }
  }
};

export const formatServices = (services?: any): { [key: string]: Service[] } | null => {
  if (!services || !Object.keys(services).length) {
    return null;
  }

  const result: { [key: string]: Service[] } = {};

  Object.keys(services).forEach((key: any) => {
    result[key] = services[key]?.length
      ? services[key].map((Service: Service) => ({ id: Service.id }))
      : null;
  });

  return result;
};

export const formatServicesData = (data: any): Service[] =>
  Object.values(data).map((value: any) => formatServiceData(value));

export default formatServiceData;
