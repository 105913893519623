import { FieldType, FormTab, FormType, usStates } from 'constants/form/Form';
import { LoadRouteType } from 'constants/Load';

export const generateStopRows = (title: string, key: string, values: any) =>
  Object.keys((values[title] && values[title][key]) || []).map((stop) => [
    [
      {
        name: `${title}.${key}.${stop}.type`,
        label: 'Type',
        type: FieldType.SELECT,
        options: Object.keys(LoadRouteType),
        composition: {
          weight: 2.5,
        },
        validation: {
          errorMsg: 'Type is required.',
          oneOf: Object.keys(LoadRouteType),
          invalidMsg: 'Type is invalid.',
        },
      },
      {
        name: `${title}.${key}.${stop}.location`,
        label: 'Location',
        type: FieldType.GOOGLE_AUTOCOMPLETE,
        options: Object.keys(values?.locations || []),
        composition: {
          weight: 6,
        },
        validation: {
          errorMsg: 'Location is required',
        },
      },
      {
        name: `${title}.${key}.${stop}.timestamp`,
        label: 'Date',
        type: FieldType.DATE,
        composition: {
          weight: 3.5,
        },
        validation: {
          errorMsg: 'Date is required',
        },
      },
    ],
    [
      {
        name: `${title}.${key}.${stop}.location.address`,
        label: 'Address',
        type: FieldType.TEXT,
        composition: {
          weight: 8.5,
        },
      },
      {
        name: `${title}.${key}.${stop}.timeFrom`,
        label: 'Time From',
        type: FieldType.TIME,
        composition: {
          weight: 3.5,
        },
      },
    ],
    [
      {
        name: `${title}.${key}.${stop}.location.city`,
        label: 'City',
        type: FieldType.TEXT,
        composition: {
          weight: 4,
        },
      },
      {
        name: `${title}.${key}.${stop}.location.state`,
        label: 'State',
        type: FieldType.SELECT,
        options: Object.values(usStates),
        composition: {
          weight: 2.5,
        },
      },
      {
        name: `${title}.${key}.${stop}.location.zip`,
        label: 'Zip',
        type: FieldType.TEXT,
        composition: {
          weight: 2,
        },
      },
      {
        name: `${title}.${key}.${stop}.timeTill`,
        label: 'Time Till',
        type: FieldType.TIME,
        composition: {
          weight: 3.5,
        },
      },
    ],
    [
      {
        name: `${title}.${key}.${stop}.weight`,
        label: 'Weight',
        type: FieldType.TEXT,
        composition: {
          weight: 3,
        },
      },
      {
        name: `${title}.${key}.${stop}.pallets`,
        label: 'Pallets',
        type: FieldType.TEXT,
        composition: {
          weight: 2,
        },
      },
      {
        name: `${title}.${key}.${stop}.po`,
        label: 'PO#',
        type: FieldType.TEXT,
        composition: {
          weight: 7,
        },
      },
    ],
    [
      {
        name: `${title}.${key}.${stop}.notes`,
        label: 'Notes',
        type: FieldType.TEXT,
        composition: {
          weight: 8,
        },
      },
      {
        name: 'actions',
        label: 'Actions',
        type: FieldType.ACTIONS,
        options: [
          {
            conditionId: 'canMoveStopUp',
            actionId: 'moveStopUp',
            icon: 'arrow_upward',
          },
          {
            conditionId: 'canMoveStopDown',
            actionId: 'moveStopDown',
            icon: 'arrow_downward',
          },
          {
            conditionId: 'canRemoveStop',
            actionId: 'removeStop',
            icon: 'delete',
          },
        ],
        composition: {
          weight: 4,
        },
      },
    ],
  ]);

const generateRouteConfig = (title: string, values: any): FormTab => ({
  title: `${title} Route`,
  label: 'Route',
  type: FormType.ROUTE,
  content: generateStopRows('route', 'stops', values),
});

export default generateRouteConfig;
