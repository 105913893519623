import { filterFilesByRoles, filterFilesByStatus, filterFilesByTypes } from 'constants/Util';

import {
  Document,
  DocumentConfig,
  DocumentRoleType,
  DocumentStatus,
  DocumentType,
} from 'constants/Document';

import React from 'react';
import MDBox from 'components/common/MDBox';
import { Icon, IconButton, Tooltip, styled } from '@mui/material';
import MDTypography from 'components/common/MDTypography';

import {
  EmployeeDocumentConfig,
  EmployeeDocumentType,
} from 'screens/roster/employees/schemas/employeeConfig';
import {
  AssetDocumentConfig,
  AssetDocumentType,
  ComplianceDocumentConfig,
} from 'screens/roster/assets/configAsset';
import { LoadDocumentConfig } from 'screens/freight/loads/schemas/loadsConfig';
import { ExpenseDocumentConfig } from 'screens/accounting/expenses/configExpenses';
import { AccidentDocumentConfig } from 'screens/compliance/accidents/configAccident';

export const StyledIconButton = styled(IconButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

interface Props {
  row: any;
  actions: any;
  documentConfig: DocumentConfig;
}

const getInfoLabel = (type: DocumentType) => {
  switch (type) {
    case EmployeeDocumentType.CDL:
      return 'CDL';
    case EmployeeDocumentType.MVR:
      return 'MVR';
    case EmployeeDocumentType.Contract:
      return 'CONT';
    case EmployeeDocumentType.PSP:
      return 'PSP';
    case EmployeeDocumentType.DrugTest:
      return 'DRUG';
    case EmployeeDocumentType.MEDCard:
      return 'MED';

    case AssetDocumentType.Registration:
      return 'REG';
    case AssetDocumentType.Inspection:
      return 'INSP';
    case AssetDocumentType.Title:
      return 'TITL';
    case AssetDocumentType.Liability:
      return 'AUTO';
    case AssetDocumentType.Cargo:
      return 'CARG';
    case AssetDocumentType.PD:
      return 'PD';
    case AssetDocumentType.HUT:
      return 'HUT';
    case AssetDocumentType.IFTA:
      return 'IFTA';
    case AssetDocumentType.NM:
      return 'NM';
    case AssetDocumentType.LeasedTo:
      return 'LEASE';

    default:
      return type.toUpperCase().slice(0, 4);
  }
};

export const getStatusIcon = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.MISSING:
      return 'content_paste_search';
    case DocumentStatus.PENDING:
    case DocumentStatus.EXPIRING:
      return 'content_paste_go';
    case DocumentStatus.REJECTED:
    case DocumentStatus.EXPIRED:
    case DocumentStatus.CANCELLED:
      return 'content_paste_off';
    default:
      return 'content_paste';
  }
};

const getStatusColor = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.EXPIRING:
      return 'warning';
    case DocumentStatus.REJECTED:
    case DocumentStatus.EXPIRED:
    case DocumentStatus.CANCELLED:
    case DocumentStatus.MISSING:
      return 'error';
    case DocumentStatus.SUBMITTED:
    case DocumentStatus.PENDING:
      return 'info';
    case DocumentStatus.APPROVED:
    default:
      return 'success';
  }
};

const getStatusNote = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.MISSING:
      return 'Missing';
    case DocumentStatus.PENDING:
      return 'Pending';
    case DocumentStatus.EXPIRING:
      return 'Expiring';
    case DocumentStatus.REJECTED:
      return 'Rejected';
    case DocumentStatus.EXPIRED:
      return 'Expired';
    case DocumentStatus.CANCELLED:
      return 'Cancelled';
    default:
      return 'Valid';
  }
};

export const InfoStatus = (
  status: DocumentStatus,
  key: string,
  type?: DocumentType,
  onEdit?: any
) => (
  <StyledIconButton color={getStatusColor(status)} key={key} onClick={() => onEdit}>
    <Icon fontSize="small">
      {getStatusIcon(status)} color={getStatusColor(status)}
    </Icon>
    {type && (
      <MDTypography variant="icon" color="inherit">
        {getInfoLabel(type)}
      </MDTypography>
    )}
  </StyledIconButton>
);

export const InfoIcon = (status: DocumentStatus) => (
  <Icon fontSize="small" color={getStatusColor(status)}>
    {getStatusIcon(status)}
  </Icon>
);

const getMainTabLabel = (documentConfig: DocumentConfig) => {
  switch (documentConfig) {
    case ComplianceDocumentConfig:
      return 'Compliance';
    default:
      return 'Files';
  }
};

const getLatesVersionKey = (availableVersionKeys: string[]) => availableVersionKeys[0];

const getExpireTimesttamp = (file: Document) => {
  let expireTimestamp;
  const expirationConfig = EmployeeDocumentConfig[file.name]?.expiration;

  if (file?.expiration?.expireTimestamp) {
    expireTimestamp = file.expiration.expireTimestamp;
  }

  if (file?.issueTimestamp && expirationConfig) {
    expireTimestamp = file.issueTimestamp + (expirationConfig?.expireInDays || 15) * 24 * 60 * 60 * 1000;
  }

  return expireTimestamp;
};

const isFileExpired = (expireTimestamp: number | undefined, now: number) =>
  expireTimestamp && expireTimestamp < now;

const isFileExpiring = (expireTimestamp: number | undefined, now: number, notifyInDays: number) =>
  expireTimestamp && notifyInDays && expireTimestamp < now + notifyInDays * 24 * 60 * 60 * 1000;

export const getRequiredKeys = (documentConfig: DocumentConfig, row: any) => {
  let requiredFileKeys: string[] = [];

  switch (documentConfig) {
    case EmployeeDocumentConfig:
      requiredFileKeys = filterFilesByRoles(documentConfig, row.roles, DocumentRoleType.REQUIRED);
      break;

    case AssetDocumentConfig:
    case ComplianceDocumentConfig:
      requiredFileKeys = filterFilesByTypes(documentConfig, [row.type], DocumentRoleType.REQUIRED);
      break;

    case LoadDocumentConfig:
      requiredFileKeys = filterFilesByStatus(documentConfig, [row.status], DocumentRoleType.REQUIRED);
      break;

    case ExpenseDocumentConfig:
      requiredFileKeys = filterFilesByStatus(documentConfig, [row.status], DocumentRoleType.REQUIRED);
      break;
    case AccidentDocumentConfig:
      requiredFileKeys = filterFilesByTypes(documentConfig, [row.type], DocumentRoleType.REQUIRED);
      break;
  }

  return requiredFileKeys;
};

export const getFileInfo = (
  files: any,
  documentConfig: DocumentConfig,
  requiredFileKey: string,
) => {
  let status: DocumentStatus = DocumentStatus.MISSING;
  let version: string = '0';

  const availableFileKeys: string[] = Object.keys(files || {});

  console.log('expireTimestamp availableFileKeys', availableFileKeys);

  if (availableFileKeys.includes(requiredFileKey)) {
    const requiredFiles: { [key: string]: Document } = files[requiredFileKey];
    const requiredVersionKeys: string[] = Object.keys(requiredFiles).reverse();

    const key = getLatesVersionKey(requiredVersionKeys);

    if (requiredFiles[key].file) {
      const expireTimestamp = getExpireTimesttamp(requiredFiles[key]);
      const notifyInDays = documentConfig[requiredFileKey]?.expiration?.notifyInDays || 0;
      const now = Date.now();

      if (isFileExpired(expireTimestamp, now)) {
        status = DocumentStatus.EXPIRED;
        version = key;
      } else if (isFileExpiring(expireTimestamp, now, notifyInDays)) {
        status = DocumentStatus.EXPIRING;
        version = key;
      } else {
        return null;
      }
    } else {
      status = DocumentStatus.MISSING;
    }
  }

  return {
    status,
    version,
  };
};

const generateInfoRow = (row: any, onEdit: any, documentConfig: DocumentConfig) => {
  const requiredFileKeys: string[] = getRequiredKeys(documentConfig, row);

  console.log('expireTimestamp', requiredFileKeys);

  const infoIcons = requiredFileKeys.map((requiredFileKey) => {
    const fileInfo = getFileInfo(row?.files, documentConfig, requiredFileKey);

    console.log('expireTimestamp', fileInfo);

    if (!fileInfo) {
      return null;
    }

    const status = fileInfo.status;
    const version = fileInfo.version;

    return (
      <Tooltip
        title={`${documentConfig[requiredFileKey].name} ${getStatusNote(status)}`}
        key={requiredFileKey}
      >
        <StyledIconButton
          color={getStatusColor(status)}
          key={requiredFileKey as string}
          onClick={() => {
            onEdit(row, {
              main: getMainTabLabel(documentConfig),
              options: requiredFileKey,
              version,
            });
          }}
        >
          <Icon fontSize="small">{getStatusIcon(status)}</Icon>
          <MDTypography variant="icon" color="inherit">
            {getInfoLabel(documentConfig[requiredFileKey].type)}
          </MDTypography>
        </StyledIconButton>
      </Tooltip>
    );
  });

  return infoIcons;
};

function DataTableInfoCell({
  row,
  actions,
  documentConfig,
}: Props): JSX.Element {
  return (
    <MDBox display="flex" justifyContent="space-between">
      {generateInfoRow(row, actions.edit, documentConfig)}
    </MDBox>
  );
}

export default DataTableInfoCell;
