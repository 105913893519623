import { LoadRouteType } from 'constants/Load';

import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import useMapboxAPI from 'services/map/MapboxAPI';
import polyline from '@mapbox/polyline';

function MapboxStaticMapField({ config, values, actions: { setFieldValue } }: any) {
  const theme = useTheme();
  const smSize = useMediaQuery(theme.breakpoints.up('sm'));

  const [overlayPolyline, setOverlayPolyline] = useState<string>();

  const isVertical = config?.composition?.vertical;

  const width = isVertical ? 24 : smSize ? 34 : 23.3;
  const height = isVertical ? 24 : smSize ? 22 : 15;

  const mapBaseUrl = 'https://api.mapbox.com/styles/v1/mapbox/light-v10/static';
  const mapConfigUrl = `/auto/${Math.round(width * 15)}x${Math.round(
    height * 15
  )}@2x?attribution=false&logo=false&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;

  const generateMarker = (index: any, color: string, coordinates: any, icon: string = 'pin-s') =>
    `${icon}-${index}+${color}(${coordinates.lng},${coordinates.lat}),`;

  const getStopsCoordinates = () =>
    values?.route?.stops ? values.route.stops.map((stop: any) => stop?.location?.coordinates) : [];

  useEffect(() => {
    if (values?.route?.origin?.location?.coordinates) {
      const coordinates = [values?.route?.origin?.location?.coordinates, ...getStopsCoordinates()];

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMapboxAPI()
        .directions(coordinates)
        .then((actualData) => {
          if (actualData?.routes) {
            const route = actualData.routes[0];
            const totalDurationMeters = route.distance;
            // const totalDurationSeconds = route.duration; // /60 for hours
            // const internation = route.country_crossed;
            const overlayCoordinates = route.geometry.coordinates.map((coordinates: any) => [
              coordinates[1],
              coordinates[0],
            ]);

            const legs = route.legs.map((leg: any) => ({
              distanceMeters: leg.distance,
              durationSeconds: leg.duration,
            }));

            const emptyMeters = legs[0].distanceMeters;
            const loadedMeters = totalDurationMeters - emptyMeters;

            if (setFieldValue) {
              setFieldValue(`${config.name}.distance`, {
                loadedMiles: (loadedMeters / 1609.344).toFixed(1),
                emptyMiles: (emptyMeters / 1609.344).toFixed(1),
                legs,
              });
            }

            setOverlayPolyline(encodeURIComponent(polyline.encode(overlayCoordinates)));
          }
        })
        .catch((err) => {
          return err;
        });
    }
  }, [values?.route?.origin?.location?.coordinates]);

  const generateMapSnapshotUrl = ({ stops, origin }: any) => {
    if (!stops?.length) {
return mapBaseUrl + mapConfigUrl;
}

    let mapUrl = `${mapBaseUrl}/`;

    if (overlayPolyline) {
      mapUrl += `path(${overlayPolyline}),`;
    }

    if (origin?.location?.coordinates) {
      mapUrl += generateMarker(
        '0',
        theme.palette.warning.main.replace('#', ''),
        origin?.location?.coordinates
      );
    }

    stops.forEach((stop: any, index: string) => {
      if (stop?.location?.coordinates) {
        mapUrl += generateMarker(
          index + 1,
          theme.palette[stop.type === LoadRouteType.Pick ? 'success' : 'info'].main.replace(
            '#',
            ''
          ),
          stop?.location?.coordinates
        );
      }
    });

    mapUrl = mapUrl.substring(0, mapUrl.length - 1) + mapConfigUrl;

    return mapUrl;
  };

  return (
    <img
      loading="lazy"
      src={generateMapSnapshotUrl(values?.route || {})}
      alt="directions"
      style={{
        position: 'relative',
        width: `${width}rem`,
        height: `${height}rem`,
      }}
    />
  );
}

export default MapboxStaticMapField;
