import colors from './colors';

type Types = any;

const { dark } = colors;

const shapes: Types = {
  '& #circle-filled': {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    background: dark.main,
  },

  '& #circle-empty': {
    width: '0.5rem',
    height: '0.5rem',
    border: '0.15rem solid',
    borderRadius: '50%',
    overflow: 'visible',
  },

  '& #triangle-right': {
    width: 0,
    height: 0,
    borderTop: '0.25rem solid transparent',
    borderLeft: '0.5rem solid',
    borderBottom: '0.25rem solid transparent',
  },

  '& #line-horizontal': {
    width: '2rem',
    height: '0.15rem',
    marginLeft: '-0.05rem',
    marginRight: '-0.05rem',
    background: dark.main,
  },

  ' .txt-top': {
    position: 'relative',
    bottom: '1.5rem',
    left: '1.5rem',
    marginLeft: '-2.5rem !important',
  },
};

export default shapes;
