import { baseUrl } from '../../constants/helpers/constants';

const useSJFactoringAPI = () => {
  const creditCheck = (authorityNumber: string, authoirtyType: string = 'mc') =>
    fetch(`${baseUrl + authoirtyType.toLowerCase()}=${authorityNumber}`, {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result: any) => ({
        status: (!result?.message?.message.includes('not') && 'approved') || 'declined',
        companyName:
          (!result?.message?.message.includes('not') && JSON.parse(result?.message?.data)?.name) ||
          '',
      }))
      .catch((err) => {
        console.log(err.message);
      });

  return { creditCheck };
};

export default useSJFactoringAPI;
