import { formatStopSelect } from 'utils/update';

import { AssetDimensions } from '../../constants/Asset';
import { DispatchStatusPriority } from '../../constants/Dispatch';
import { Load, LoadRouteStop, LoadStatus } from '../../constants/Load';
import { getArrangedLoadStops as arrangedLoadStops, getCurrentLoad } from '../load';

const alreadyArrivedStatus = (status: LoadStatus) =>
  [
    LoadStatus.ARRIVED,
    LoadStatus.DELIVERED,
    LoadStatus.LOADING,
    LoadStatus.UNLOADING,
    LoadStatus.LOADED,
    LoadStatus.UNLOADED,
  ].includes(status);

const alreadyScheduledStatus = (status: LoadStatus) =>
  DispatchStatusPriority[status] >= DispatchStatusPriority[LoadStatus.SCHEDULED];

const isLoadedStop = (stop: LoadRouteStop) =>
  stop.status &&
  [LoadStatus.LOADING, LoadStatus.UNLOADING, LoadStatus.LOADED].includes(stop.status);

export const isActiveStopStatus = (status: LoadStatus) => alreadyArrivedStatus(status) && alreadyScheduledStatus(status);

export const isNextStopStatus = (status: LoadStatus) => !alreadyArrivedStatus(status) && alreadyScheduledStatus(status);

export const filterLoadedStops = (load: Load) =>
  load?.route?.stops?.filter((stop: LoadRouteStop) => isLoadedStop(stop)) || [];

export const isNextStop = (stop: LoadRouteStop) => !stop.status || isNextStopStatus(stop.status);

export const filterNextStops = (load: Load) =>
  load?.route?.stops?.filter((stop: LoadRouteStop) => isNextStop(stop)) || [];

const getNextStops = (loads: Load[], trailerDimensions?: AssetDimensions): LoadRouteStop[] =>
  arrangedLoadStops(loads, trailerDimensions).filter((stop: LoadRouteStop) => isNextStop(stop));

export const getNextStop = (
  loads?: Load[],
  trailerDimensions?: AssetDimensions
) => loads && getNextStops(loads, trailerDimensions)[0];

export const getNextStopSelectOption = (loads: Load[]) => {
  const load = getCurrentLoad(loads);
  const nextStop = filterNextStops(load)?.[0];

  return formatStopSelect(nextStop, nextStop?.info?.id?.toString() || '0');
};

export const testExport = {
  alreadyArrivedStatus,
  alreadyScheduledStatus,
  isActiveStop: isNextStop,
  filterNextStops,
  getNextStops,
};
