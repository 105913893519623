import React, { SetStateAction, useState } from 'react';

import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';

import MDBox from 'components/common/MDBox';
import MDTypography from 'components/common/MDTypography';
import MDInput from 'components/common/MDInput';
import MDButton from 'components/common/MDButton';

import ROUTES from 'navigation/const';
import Grid from '@mui/material/Grid';

function SigninCard(props: any): JSX.Element {
  const [rememberMe, setRememberMe] = useState<boolean>(
    localStorage.getItem('rememberMe') === 'true'
  );

  const [email, setEmail] = useState<string>(localStorage.getItem('rememberEmail') ?? '');
  const [password, setPassword] = useState('');

  const { loading, handleSignin, showRegisterFlow, showResetFlow } = props;

  const handleSetRememberMe = () => {
    localStorage.setItem('rememberEmail', email);
    setRememberMe(!rememberMe);
  };

  const handleSubmit = () => {
    if (rememberMe) {
      localStorage.setItem('rememberMe', rememberMe.toString());
    } else {
      localStorage.removeItem('rememberMe');
    }

    handleSignin(email, password);
  };

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
      <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
        <Card>
          <MDBox pt={4} pb={3} px={3}>
            <MDTypography variant="h3" fontWeight="medium" color="text" textAlign="center" mb={2}>
              Sign In
            </MDTypography>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  onChange={(e: { target: { value: SetStateAction<string> } }) =>
                    setEmail(e.target.value)
                  }
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  onChange={(e: { target: { value: SetStateAction<string> } }) =>
                    setPassword(e.target.value)
                  }
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  disabled={loading || !email || !password}
                  onClick={handleSubmit}
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} display="flex" justifyContent="space-between">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{' '}
                  <MDTypography
                    component={Link}
                    onClick={showRegisterFlow}
                    to={ROUTES.AUTHENTICATION}
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
                <MDTypography
                  component={Link}
                  onClick={showResetFlow}
                  to={ROUTES.AUTHENTICATION}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  ml={1}
                >
                  Reset Password
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default SigninCard;
