import styled from 'styled-components';

export const StyledFileContainer = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
`;

export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FileUploadLabel = styled.label`
  width: 245px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  color: #464646;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;

  background-clip: border-box;

  height: 32px;

  p {
    font-size: 13px;
  }
`;

export const FileUploadInput = styled.input`
  display: none;
`;

export const SelectedFileName = styled.div`
  margin-top: 10px;
  font-size: 10px;
`;

export const StyledFileText = styled.p`
  font-size: 13px;
  color: #7f849d;
`;

export const StyledBackground = styled.div`
  background: #abb9c2;
  height: 485px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 5px 5px 0 0;
`;
