import { Grid } from '@mui/material';
import MDTypography from 'components/common/MDTypography';

function DataTableUnitCell({ unit }: any): JSX.Element {
  return (
    <Grid container direction="column">
      <Grid item>
        <MDTypography fontWeight="bold" variant="body2" lineHeight="inherit">
          {unit?.unitId || ''}
        </MDTypography>
      </Grid>
      <Grid itemScope>
        <MDTypography variant="icon" color="text" lineHeight="0">
          {`${unit?.year || ''} ${unit?.make || ''}`}
        </MDTypography>
      </Grid>
    </Grid>
  );
}

export default DataTableUnitCell;
