const baseUrl = 'https://us.tteld.com/api/driverInfo/info?accessToken=';
const testToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkcml2ZXJJZCI6MjQyOTYsImlhdCI6MTY3MjY5ODI5OCwiZXhwIjoyNTM2NjExODk4fQ._OUYIhty6U2r04D8VCpGKl7IXNoS1fadokbrlY6XpTE';

const useTTEldAPI = () => {
  // eslint-disable-next-line no-unused-vars
  const location = (token: string = testToken) =>
    fetch(baseUrl + token)
      .then((response) => response.json())
      .then(
        (actualData: any) =>
          actualData?.driver?.vehicle?.truck_number && {
            coordinates: actualData?.driver?.tracking?.coordinates,
            speed: actualData?.driver?.tracking?.speed,
            timessamp: new Date(actualData?.driver?.tracking?.date).getTime(),
            odometr: actualData?.driver?.tracking?.odometr,
            address: {
              name: actualData?.driver?.tracking?.address,
            },
            unit: {
              unitId: actualData?.driver?.vehicle?.truck_number,
              make: actualData?.driver?.vehicle?.make,
              year: actualData?.driver?.vehicle?.year,
            },
          }
      );

  return {
    location,
  };
};

export default useTTEldAPI;
