import { Connection, ConnectionStatus, ConnectionType } from 'constants/Connection';

export const ConnectionVersion: number = 1;

const formatConnectionDataV1 = (data: any): Connection => ({
  id: data?.id || null,
  status: data?.status || ConnectionStatus.PENDING,
  type: data?.type || ConnectionType.OTHER,
  version: ConnectionVersion,
  events: data?.events || [],
  eld: data?.eld || {},
  gps: data?.gps || {},
  enabled: data?.enabled,
  title: data?.title || '',
  description: data?.description || '',
  logo: data?.logo || '',
  provider: data?.provider,
});

const formatConnectionData = (data: any): Connection => {
  const version = data?.version || ConnectionVersion;

  switch (version) {
    case 1: {
      return formatConnectionDataV1(data);
    }

    default: {
      return formatConnectionDataV1(data);
    }
  }
};

export default formatConnectionData;
