import { LoadRouteStop } from 'constants/Load';

import { getStopAppointment } from './getDate';

export function getRoutes(stops: LoadRouteStop[]) {
  return `${getFields(stops[0])} ${getFields(stops[stops.length - 1])}`;
}

function getFields(stop: LoadRouteStop) {
  const { name, city, state, zip } = stop.location;

  return `${name} ${city} ${state}, ${zip} ${getStopAppointment(stop)}`;
}
