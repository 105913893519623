import { AccessoryStatus } from 'constants/Accessory';

import formatExpenseValues from 'screens/accounting/expenses/formatExpenses';

import { formatUnitSelect } from 'screens/dispatch/board/schemas/unit/formatUnitValues';
import { getUnit } from 'screens/freight/loads/schemas/formatLoadValues';

import formatAccessoryData, { AccessoryVersion } from 'screens/roster/accessories/migration';

import { formatGroupSelect } from '../groups/schemas/formatGroupValues';

const formatAccessoryValues = (values: any) => ({
  accessoryId: values?.accessoryId,
  type: values?.type,
  status: values?.status || AccessoryStatus.PENDING,
  unit: values?.unit ? formatUnitSelect(values?.unit) : null,
  files: values?.files || {},
  id: values.id || '',
  version: values.version || AccessoryVersion,
  initialTabs: values?.initialTabs || {},
  notes: values?.notes,
  info: {
    price: values?.info?.price,
    provider: values?.info?.provider,
    model: values?.info?.model,
    serialNumber: values?.info?.serialNumber,
  },
  expense: values?.expense,
  groups: values?.groups?.map((group: any) => formatGroupSelect(group)),
});

export const formatAccessorySelect = (accessory: any) => ({
  ...accessory,
  name: `${accessory.accessoryId} | ${accessory.type}${accessory?.info?.provider ? ` | ${accessory?.info?.provider}` : ''}`,
});

export const formatAccessorysValuesList = (accessories: any, assets: any, employees: any, units: any, expenses: any) =>
  accessories
    ? Object.values(accessories)
        .filter((accessory) => accessory)
        .map((accessory: any) => {
          const loadUnit =
            units &&
            accessory &&
            employees &&
            accessory?.unit?.id &&
            getUnit(accessory?.unit?.id, units, assets, employees);

          return formatAccessoryValues({
            ...formatAccessoryData(accessory),
            unit: loadUnit,
            expense: expenses && accessory?.expense ? formatExpenseValues(expenses[accessory?.expense?.id]) : null,
          });
        })
    : [];

export default formatAccessoryValues;
