import { Asset } from 'constants/Asset';

import { Connection, ConnectionStatus, testConnection } from 'constants/Connection';

import TruckIcon from 'assets/images/truck.png';
import TrailerIcon from 'assets/images/trailer.png';

import { Card, Divider, Grid, Icon, IconButton, Stack } from '@mui/material';
import MDBadge from 'components/common/MDBadge';
import MDTypography from 'components/common/MDTypography';

interface AssetCardProps {
  asset: Asset;
  actions: {
    edit: any;
    delete: any;
  };
}

const getConnectionColor = (status: ConnectionStatus) => {
  switch (status) {
    case ConnectionStatus.ACTIVE:
      return 'success';
    case ConnectionStatus.INACTIVE:
      return 'error';
    case ConnectionStatus.PENDING:
      return 'warning';
    default:
      return 'info';
  }
};

const getItemColor = (status: string) => {
  switch (status) {
    case 'ACTIVE':
      return 'success';
    case 'INACTIVE':
      return 'error';
    case 'PENDING':
      return 'warning';
    default:
      return 'info';
  }
};

export const AssetCard = ({ asset, actions }: AssetCardProps): JSX.Element => {
  return (
    <Card key={asset.id}>
      <Grid container p={2} direction="column" justifyContent="space-between" height="20rem">
        <Grid item direction="column">
          <Grid container item direction="row" justifyContent="space-between" xs={3}>
            <Grid container item xs={4} direction="column" style={{ zIndex: 2 }}>
              <MDTypography variant="h5">{asset?.unitId}</MDTypography>
              <MDTypography variant="overline" noWrap fontWeight="medium" color="text">
                {asset?.year + ' ' + asset?.make}
              </MDTypography>
              <MDTypography variant="h6" color={getItemColor(asset.status)} fontWeight="bold">
                {asset.status}
              </MDTypography>
            </Grid>
            <Grid container item xs={8} justifyContent="right">
              <img
                src={asset?.type === 'Truck' ? TruckIcon : TrailerIcon}
                alt="truck"
                height={75}
              />
            </Grid>
          </Grid>
          <Grid container item direction="column" gap={1}>
            <Grid item direction="column" pt={2}>
              <MDTypography variant="subtitle2" fontWeight="medium" color="text" noWrap>
                {asset?.vin && 'VIN: ' + asset.vin}
              </MDTypography>
              <MDTypography variant="subtitle2" fontWeight="medium" color="text" noWrap>
                {asset?.plate ? 'Plate: ' + asset?.state + ' ' + asset.plate : ' '}
              </MDTypography>
              <MDTypography variant="subtitle2">
                {asset?.miles ? 'Miles: ' + asset?.miles : 'Miles: TEST'}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction="column">
          <Grid item direction="row" >
            {/* {asset?.connections?.map((connection: Connection) => (
              <>
                <MDBadge
                  badgeContent={connection.type}
                  size="xs"
                  color={getConnectionColor(connection.status)}
                  container
                />{' '}
              </>
            ))} */}
            {[testConnection, testConnection].map((connection: Connection) => (
              <>
                <MDBadge
                  badgeContent={connection.type}
                  size="xs"
                  color={getConnectionColor(connection.status)}
                  container
                />{' '}
              </>
            ))}
          </Grid>
          <Divider sx={{ m: 1 }} />
          <Grid container item alignItems="center" direction="row" justifyContent="space-between">
            <Grid>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" gap={1}>
                  <Icon sx={{ color: '#ff8a00' }} fontSize="small" color="inherit">
                    content_paste_off
                  </Icon>
                  <Icon sx={{ color: '#ff312f' }} fontSize="small" color="inherit">
                    content_paste_off
                  </Icon>
                </Stack>
              </Stack>
            </Grid>
            <Grid>
              <IconButton disabled>
                <Icon fontSize="small" color="inherit">
                  manage_search
                </Icon>
              </IconButton>
              <IconButton>
                <Icon fontSize="small" color="inherit" onClick={() => actions?.edit()}>
                  edit
                </Icon>
              </IconButton>
              <IconButton onClick={() => actions?.delete()}>
                <Icon fontSize="small" color="inherit">
                  delete
                </Icon>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
