import { FieldType, FormConfig, FormType } from 'constants/form/Form';
import { UnitStatus } from 'constants/Unit';

import { LoadStatus } from 'constants/Load';

import { UpdateStatusList } from 'constants/Dispatch';

import { resolvePath } from 'components/BaseForm/Utils';

import {
  getActiveStopOptions,
  getBrokerContactOptions,
  getLocationOptions,
  // getNextStatusOptions,
  getNextStopOptions,
  getUpdateLoadOptions,
  shouldShowLoadInput,
  showActiveStopInput,
  showDispatcherBrokerInput,
  showInvoiceInpt,
  showLoadRateInput,
  showNextStopInput,
  showUnitInput,
} from 'utils/update';

import { formatDispatcherSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';
import { formatBrokerSelect } from 'screens/roster/groups/schemas/formatGroupValues';

import { getNextStopSelectOption } from 'utils/stop';

import { formatUnitSelect } from '../unit/formatUnitValues';

const ConfigName = 'Update';

/*
Status / Autocomplete
Load Id / Autocomplete
Rate / Text
Pick Location
Next Location
Stop Location 
Stop Date & Time / Date
ETA Date & Time / Date
Unit / Autocomplete
Unit Location / Autocomplete
Dispatcher / Select - Add
Broker / Select - Add
Broker Contact / Select - Add
Paid By / Select
Rate / Numbers
Note / Text
*/

const getUpdateStatusType = (source: any) =>
  UpdateStatusList[resolvePath(source, 'status', undefined) || LoadStatus.SCHEDULED]?.type;

const updateStatus = (source: any, setFieldValue: any) => {
  setFieldValue('currentStatus', getUpdateStatusType(source));

  setFieldValue('status', getUpdateStatusType(source));
};

export const getUpdateFormConfig = (values: any): FormConfig =>
  ({
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName}`,
      content: [
        // Status
        [
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: [...Object.keys(LoadStatus), ...Object.keys(UnitStatus)], // getNextStatusOptions(values.currentStatus),
            validation: {
              errorMsg: 'Status is required.',
            },
          },
        ],
        // Unit
        [
          {
            name: 'unit',
            label: 'Unit',
            type: FieldType.SELECT,
            options: values.units.map((unit: any) => formatUnitSelect(unit)),
            multiple: false,
            composition: {
              hideIf: (values: any) => !showUnitInput(values),
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any) => {
                  const unitLocation = values?.unitLocations?.[source.id]?.location;

                  unitLocation &&
                    setFieldValue('unitLocation', {
                      ...unitLocation,
                      label: unitLocation.name,
                      id: '-1',
                      icon: UnitStatus.LOCATION,
                    });

                  updateStatus(source, setFieldValue);
                },
              },
            },
          },
        ],
        // Unit Location
        [
          {
            name: 'unitLocation',
            label: 'Unit Location',
            type: FieldType.SELECT,
            options: getLocationOptions(
              resolvePath(values, 'load', undefined)?.route?.stops,
              resolvePath(values, 'unitLocations', undefined)?.[
                resolvePath(values, 'unit', undefined)?.id
              ]?.location
            ),
            multiple: false,
            composition: {
              hideIf: (values: any) => !showUnitInput(values),
            },
          },
        ],
        // Load
        [
          {
            name: 'load',
            label: 'Load',
            type: FieldType.SELECT,
            options: getUpdateLoadOptions(values),
            multiple: false,
            composition: {
              hideIf: (values: any) => !shouldShowLoadInput(values),
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any) => {
                  setFieldValue('rate', resolvePath(source, 'accounting.rate', undefined)) ||
                    setFieldValue('rate', undefined);

                  (source.bookedBy &&
                    setFieldValue('dispatcher', formatDispatcherSelect(source.bookedBy))) ||
                    setFieldValue('dispatcher', undefined);

                  (source.broker && setFieldValue('broker', formatBrokerSelect(source.broker))) ||
                    setFieldValue('broker', undefined);

                  (source.assignedTo &&
                    setFieldValue('unit', formatUnitSelect(source.assignedTo))) ||
                    setFieldValue('unit', undefined);

                  const unitLocation = values?.unitLocations?.[source.assignedTo?.id]?.location;

                  (unitLocation &&
                    setFieldValue('unitLocation', {
                      ...unitLocation,
                      label: unitLocation.name,
                      id: '-1',
                      icon: UnitStatus.LOCATION,
                    })) ||
                    setFieldValue('unitLocation', undefined);

                  setFieldValue('nextStop', getNextStopSelectOption([source]));

                  updateStatus(source, setFieldValue);
                },
              },
            },
          },
        ],
        // Load Rate
        [
          {
            name: 'loadRate',
            label: 'Load Rate',
            type: FieldType.NUMBER,
            composition: {
              hideIf: (values: any) => !showLoadRateInput(values),
            },
          },
        ],
        // Active Stop
        [
          {
            name: 'activeStop',
            label: 'Active Stop',
            type: FieldType.SELECT,
            options: getActiveStopOptions(resolvePath(values, 'load', undefined)?.route?.stops),
            multiple: false,
            composition: {
              hideIf: (values: any) => !showActiveStopInput(values),
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any) => {
                  updateStatus(source, setFieldValue);
                },
              },
            },
          },
        ],
        // Next Stop
        [
          {
            name: 'nextStop',
            label: 'Next Stop',
            type: FieldType.SELECT,
            options: getNextStopOptions(resolvePath(values, 'load', undefined)?.route?.stops),
            multiple: false,
            composition: {
              hideIf: (values: any) => !showNextStopInput(values),
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any) => {
                  updateStatus(source, setFieldValue);
                },
              },
            },
          },
        ],
        // Dispatcher
        [
          {
            name: 'dispatcher',
            label: 'Dispatcher',
            type: FieldType.SELECT,
            options: values.dispatchers.map((dispatcher: any) =>
              formatDispatcherSelect(dispatcher)
            ),
            multiple: false,
            composition: {
              hideIf: (values: any) => !showDispatcherBrokerInput(values),
            },
          },
        ],
        // Broker
        [
          {
            name: 'broker',
            label: 'Broker',
            type: FieldType.SELECT,
            options: values.brokers.map((broker: any) => formatBrokerSelect(broker)),
            multiple: false,
            composition: {
              hideIf: (values: any) => !showDispatcherBrokerInput(values),
            },
          },
        ],
        // Broker Contact
        [
          {
            name: 'brokerContact',
            label: 'Broker Contact',
            type: FieldType.SELECT,
            options: getBrokerContactOptions(values),
            multiple: false,
            composition: {
              hideIf: (values: any) => !showDispatcherBrokerInput(values),
            },
          },
        ],
        // Invoice
        [
          {
            name: 'invoice.paidBy',
            label: 'Paid By',
            type: FieldType.SELECT,
            options: ['Driver', 'Broker', 'Company'],
            validation: {
              errorMsg: 'Field is required.',
            },
            composition: {
              hideIf: (values: any) => !showInvoiceInpt(values),
            },
          },
        ],
        // Invoice Rate
        [
          {
            name: 'invoice.rate',
            label: 'Invoice Rate',
            type: FieldType.NUMBER,
            validation: {
              errorMsg: 'Rate is required.',
            },
            composition: {
              hideIf: (values: any) => !showInvoiceInpt(values),
            },
          },
        ],
        // Notes
        [
          {
            name: 'notes',
            label: 'Notes',
            type: FieldType.TEXT,
          },
        ],
      ],
    },
  } as FormConfig);

export const updateFormSetup = (id: any, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getUpdateFormConfig,
});
