/* eslint-disable no-unused-vars */
enum PayStatus {
  Pending = 'Pending',
  Requested = 'Requested',
  Approved = 'Approved',
  Declined = 'Declined',
  Paid = 'Paid',
  DoNotBill = 'Do Not Bill',
}

export default PayStatus;
