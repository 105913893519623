import { Grid } from '@mui/material';
import MDTypography from 'components/common/MDTypography';
import { useEffect } from 'react';
import moment from 'moment';
import useTTEldAPI from 'services/eld/TTEld';
import { useDispatch, useSelector } from 'react-redux';
import { setLastUnitInfo } from 'redux/slices/unitsSlice';
import useFirebaseConnectionAPI from 'services/data/Firebase/firebaseConnectionAPI';

const getStopAppointment = (timestamp: number) => {
  const date = moment(timestamp).format('MM/DD HH:mm');

  return (
    <Grid container direction="row" alignItems="center">
      <MDTypography variant="overline" color="text" lineHeight="inherit">
        {date && `${date}`}
      </MDTypography>
    </Grid>
  );
};

function LastLocationCell({ unitId }: any) {
  const dispatcher = useDispatch();
  const lastUnitInfo = useSelector((state: any) => state?.units?.lastUnitInfo[unitId]);

  const { getELDToken } = useFirebaseConnectionAPI();

  const token = getELDToken(unitId, 'TTELD');

  const fetchLocation = (token: string) =>
    token &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTTEldAPI()
      .location(token)
      .then((response: any) => {
        if (response) {
          dispatcher(
            setLastUnitInfo({
              id: unitId,
              info: {
                location: {
                  coordinates: response.coordinates,
                  name: response.address.name,
                },
                speed: response.speed,
                timestamp: response.timessamp,
                odometr: response.odometr,
                rotation: response.rotation,
              },
            })
          );
        }
      });

  useEffect(() => {
    fetchLocation(token);
    const interval = setInterval(() => {
      fetchLocation(token);
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [token]);

  return (
    <Grid container item direction="column" justifyContent="center" sx={{ minWidth: '7rem' }}>
      {lastUnitInfo && (
        <MDTypography variant="overline" color="text" lineHeight="inherit">
          {`${lastUnitInfo?.location.name}`}
        </MDTypography>
      )}
      {lastUnitInfo && getStopAppointment(lastUnitInfo.timestamp)}
    </Grid>
  );
}

export default LastLocationCell;
