/* eslint-disable no-unused-vars */
import { Document } from 'constants/Document';

import { Contact } from './Contact';

export enum LoadStatus {
  MATCHED = 'MATCHED', // matched by system
  OFFERED = 'OFFERED', // offered by broker
  INQUIRED = 'INQUIRED', // inquired by dispatcher
  QUOTED = 'QUOTED', // quoted by broker
  BOOKED = 'BOOKED',
  REJECTED = 'REJECTED', // rejected for any driver, dispatcher or shipper
  CANCELLED = 'CANCELLED', // cancelled by the broker
  SCHEDULED = 'SCHEDULED', // defult status
  DISPATCHED = 'DISPATCHED',
  ENROUTE = 'ENROUTE',
  ARRIVED = 'ARRIVED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  UNLOADING = 'UNLOADING',
  UNLOADED = 'UNLOADED',
  DELIVERED = 'DELIVERED',
}

enum LoadType {
  PO = 'Power Only',
  DryVan = 'Dry Van',
  Reefer = 'Reefer',
  Flatbedd = 'Flatbed',
  OTHER = 'Other',
}

export enum LoadSize {
  Full = 'Full',
  LTL = 'LTL',
  PTL = 'PTL',
}

// https://www.shipamerican.com/files/uploads/Truck_LoadingTerms.pdf
export enum LoadPattern {
  Straight = 'Straight',
  Turned = 'Turned',
  Pinwheeled = 'Pinwheeled',
  DoubleStraight = 'Double Straight',
  DoubleTurned = 'Double Turned',
  DoublePinwheeled = 'Double Pinwheeled',
}

enum LoadEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

interface LoadEvent {
  timestamp: number;
  status: LoadStatus;
  note?: string;
  user?: string;
  action: LoadEventAction;
}

export interface LoadInfo {
  id: string;
  size: LoadSize | null;
  milesLoaded: string;
  type: LoadType | null;
  width?: number;
  height?: number;
  length?: number;
  pattern?: LoadPattern;
  pallets?: number;
  comodity?: string;
  weight?: number;
}

export interface LoadAccounting {
  rate: string;
}

export enum LoadRouteType {
  Pick = 'Pick',
  Drop = 'Drop',
}

export interface LoadRouteStop {
  type: LoadRouteType;
  status?: LoadStatus;
  event?: LoadEvent;
  location: any;
  timestamp: number | null;
  timeFrom?: number | null;
  timeTill?: number | null;
  info?: LoadInfo;
}

export interface LoadRoute {
  stops: LoadRouteStop[];
  directions?: any;
}

interface Load {
  id: string;
  info: LoadInfo;
  accounting: LoadAccounting;
  status: LoadStatus;
  bookedBy?: any;
  assignedTo?: any;
  broker?: any;
  brokerContact?: Contact;
  route: LoadRoute | null;
  version: number;
  events?: LoadEvent[];
  files: { [key: string]: { [key: string]: Document } };
  initialValues?: any;
}

export { LoadEventAction, LoadType };
export type { Load };
