import { SetStateAction, useState } from 'react';

import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import MDBox from 'components/common/MDBox';
import MDInput from 'components/common/MDInput';
import MDButton from 'components/common/MDButton';
import MDTypography from 'components/common/MDTypography';
import ROUTES from 'navigation/const';
import Grid from '@mui/material/Grid';

function PasswordResetCard(props: any): JSX.Element {
  const [email, setEmail] = useState('');

  const { handlePasswordReset, showSigninFlow } = props;

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
      <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
        <Card>
          <MDBox pt={4} pb={3} px={3}>
            <MDTypography variant="h3" fontWeight="medium" color="text" textAlign="center" mb={2}>
              Reset Password
            </MDTypography>
            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  onChange={(e: { target: { value: SetStateAction<string> } }) =>
                    setEmail(e.target.value)
                  }
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => handlePasswordReset(email)}
                >
                  reset
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="left">
                <MDTypography variant="button" color="text">
                  Back to{' '}
                  <MDTypography
                    component={Link}
                    onClick={showSigninFlow}
                    to={ROUTES.AUTHENTICATION}
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign in
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default PasswordResetCard;
