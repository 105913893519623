import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface UnitsState {
  lastUnitInfo: any;
  eta: any;
}

const initialState: UnitsState = {
  lastUnitInfo: {},
  eta: {},
};

export const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setLastUnitInfo: (state, action: PayloadAction<any>) => {
      state.lastUnitInfo = { ...state.lastUnitInfo, [action.payload.id]: action.payload.info };
    },
    setETA: (state, action: PayloadAction<any>) => {
      state.eta = { ...state.eta, [action.payload.id]: action.payload.eta };
    },
  },
});

export const { setLastUnitInfo, setETA } = unitsSlice.actions;

export default unitsSlice.reducer;
