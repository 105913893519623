import { Employee } from './Employee';
import { Group } from './Group';
import { Document } from './Document';
import { Connection } from './Connection';

export interface Payment {
  type: PaymentType;
  status: PaymentStatus;
  paymentDate: number;
  amount: number;
  reference?: string;
  paidBy: TransactionParty | null;
  payTo: TransactionParty | null;
}

export enum PaymentStatus {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  PROCESSING = 'PROCESSING',
  PAID = 'PAID',
}

export enum PaymentType {
  CASH = 'Cash',
  CHECK = 'Check',
  CARD = 'Card',
  ACH = 'ACH',
  WIRE = 'Wire',
  ZELE = 'Zele',
  EFS = 'EFS',
  COMCHECK = 'Comcheck',
  DEDUCTION = 'Deduction',
  OTHER = 'Other',
}

export enum PartyType {
  CARRIER = 'Carrier',
  BROKER = 'Broker',
  DRIVER = 'Driver',
  OTHER = 'Other',
}

export interface TransactionParty {
  type: PartyType | null;
  transactor: any;
}

export interface Expense {
  id: string;
  type: ExpenseType;
  status: ExpenseStatus;
  notes?: string;
  billTo?: TransactionParty | null;
  paidBy?: TransactionParty | null;
  payTo?: TransactionParty | null;
  payments?: Payment[];
  reporter?: Employee | Connection;
  source?: any;
  version: number;
  events?: ExpenseEvent[];
  files: { [key: string]: { [key: string]: Document } };
}

export enum ExpenseStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  CHARGED = 'CHARGED',
  COLLECTED = 'COLLECTED',
  PAID = 'PAID',
}

export enum ExpenseType {
  FUEL = 'Fuel',
  TOLL = 'Toll',
  SCALE = 'Scale',
  PARKING = 'Parking',
  SERVICE = 'Service',
  REIMBURSEMENT = 'Reimbursement',
  ADVANCE = 'Advance',
  ACCESSORY = 'Accessory',
  LUMPER = 'Lumper',
  OTHER = 'Other',
}

export enum ExpenseEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

export interface ExpenseEvent {
  timestamp: number;
  status: ExpenseStatus;
  note?: string;
  user?: string;
  target?: Employee | Group;
  source?: any;
  added?: any;
  removed?: any;
  updated?: any;
  action: ExpenseEventAction;
}
