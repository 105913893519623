import { useSelector } from 'react-redux';
// import { useFirestore } from 'react-redux-firebase';

import { UpdateAPI } from '../UpdateAPI';

// import updatedDependentFields from './updatedDependentFields';

const useFirebaseUpdateAPI = (): UpdateAPI => {
  // const firestore = useFirestore();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const onUpdate = (
    data: any = {},
    company: any = defaultCompany,
    event: any
  ) => {};

  // const add = (path: string, data: any) => firestore.collection(path).add(formatUnitData(data));

  // const update = (path: string, data: any) => firestore.doc(path).update(formatUnitData(data));

  // const remove = (id: string) => firestore.doc(id).delete();

  // const addUpdate = (
  //   data: any = {},
  //   company: any = defaultCompany,
  //   event: any = defualtUnitAddEvent
  // ) =>
  //   add(`company/${company.companyId}/units`, {
  //     ...data,
  //     events: {
  //       [`${Date.now()}`]: event,
  //     },
  //   });

  // const updateUpdate = (
  //   id: string,
  //   data: any,
  //   company: any = defaultCompany,
  //   event: any = defualtUnitAddEvent
  // ) =>
  //   update(`company/${company.companyId}/units/${id}`, {
  //     ...data,
  //     events: {
  //       ...data.events,
  //       [`${Date.now()}`]: event,
  //     },
  //   });

  // const addUnitEvent = (event: any, companyId: string = defaultCompany.companyId) =>
  //   add(`company/${companyId}/unitsEvents`, event);

  // const removeUpdate = (id: string, companyId: string) => remove(`company/${companyId}/units/${id}`);

  // const onRemoveUnit = (unit: Unit, company: any = defaultCompany) =>
  //   removeUnit(unit.id, company.companyId).then(() =>
  //     addUnitEvent({
  //       [`${Date.now()}`]: {
  //         timestamp: Date.now(),
  //         user: company.employeeId,
  //         action: UnitEventAction.REMOVE,
  //         target: unit.id,
  //       },
  //     }).then(() => updatedDependentFields(unit, {}, firestore, defaultCompany))
  //   );

  // const onEditUnit = (
  //   unit: Unit,
  //   initialValues: Unit | undefined = undefined,
  //   company: any = defaultCompany
  // ) =>
  //   updateUnit(unit.id, unit, company).then(() =>
  //     updatedDependentFields(initialValues, unit, firestore, defaultCompany)
  //   );

  // const onAddUnit = (
  //   unit: Unit,
  //   initialValues: Unit | undefined = undefined,
  //   company: any = defaultCompany
  // ) =>
  //   addUnit(unit, company).then(() =>
  //     updatedDependentFields(initialValues, unit, firestore, defaultCompany)
  //   );

  return {
    onUpdate,
  };
};

export default useFirebaseUpdateAPI;
