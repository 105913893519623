import {
  ParticipantDamage,
  ParticipantFault,
  ParticipantInjury,
} from 'constants/Accident';

import { Card, Divider, Grid, Icon, IconButton } from '@mui/material';
import MDTypography from 'components/common/MDTypography';
import MDBadge from 'components/common/MDBadge';

interface ParticipantCardProps {
  index: number;
  participant: any;
  actions: any;
}

const getFaultColor = (fault: ParticipantFault) => {
  switch (fault) {
    case ParticipantFault.NONE:
      return 'success';
    case ParticipantFault.PARTIAL:
      return 'warning';
    case ParticipantFault.FULL:
      return 'error';
    default:
      return 'info';
  }
};

const getStatusColor = (status: ParticipantInjury | ParticipantDamage | undefined) => {
  switch (status) {
    case ParticipantInjury.NONE:
    case ParticipantDamage.NONE:
      return 'success';
    case ParticipantInjury.MINOR:
    case ParticipantDamage.MINOR:
      return 'warning';
    case ParticipantInjury.MAJOR:
    case ParticipantDamage.MAJOR:
    case ParticipantInjury.FATAL:
    case ParticipantDamage.TOTAL:
      return 'error';
    default:
      return 'info';
  }
};

const getStatusIcon = (status: ParticipantInjury | ParticipantDamage | undefined) => {
  switch (status) {
    case ParticipantInjury.NONE:
    case ParticipantDamage.NONE:
      return 'check_circle';
    case ParticipantInjury.MINOR:
    case ParticipantDamage.MINOR:
      return 'warning';
    case ParticipantInjury.MAJOR:
    case ParticipantDamage.MAJOR:
    case ParticipantInjury.FATAL:
    case ParticipantDamage.TOTAL:
      return 'error';
    default:
      return '';
  }
};

const ParticipantCard: React.FC<ParticipantCardProps> = ({ index, participant, actions }) => {
  const renderStatusIcon = (status: ParticipantInjury | ParticipantDamage | undefined) => (
    <Grid direction="row" container alignItems="center" gap={0.25}>
      <Icon fontSize="small" color={getStatusColor(status)}>
        {getStatusIcon(status)}
      </Icon>
      <MDTypography variant="overline" noWrap fontWeight="medium" color={getStatusColor(status)}>
        {status?.toUpperCase()}
      </MDTypography>
    </Grid>
  );

  const renderParticipantInfo = () => {
    return (
      <Grid item container direction="column" xs={10} gap={1}>
        <Grid item container direction="row" justifyContent="space-between">
          <MDTypography variant="h6">{participant?.type?.toUpperCase()}</MDTypography>
          {participant?.fault && <MDBadge
            badgeContent={participant?.fault}
            size="xs"
            color={getFaultColor(participant?.fault)}
            container
          />}
        </Grid>
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item container direction="column" xs={5}>
            <Grid item direction="row">
              <MDTypography variant="overline" noWrap fontWeight="bold">
                {participant?.name?.toUpperCase()}
              </MDTypography>
            </Grid>
            <Grid item direction="row">
              <MDTypography variant="overline" noWrap fontWeight="medium">
                {participant?.asset?.vin}
              </MDTypography>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={4.5}>
            <Grid item direction="row">
              <MDTypography variant="overline" noWrap fontWeight="medium" color="text">
                {participant?.contact?.phone}
              </MDTypography>{' '}
              <MDTypography variant="overline" noWrap fontWeight="medium" color="text">
                {participant?.contact?.email}
              </MDTypography>
            </Grid>
            <Grid item direction="row" spacing={1}>
              <MDTypography variant="overline" noWrap fontWeight="medium" color="text">
                {participant?.asset?.year}
              </MDTypography>{' '}
              <MDTypography variant="overline" noWrap fontWeight="medium" color="text">
                {participant?.asset?.color}
              </MDTypography>{' '}
              <MDTypography variant="overline" noWrap fontWeight="medium" color="text">
                {participant?.asset?.make}
              </MDTypography>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={2.5} justifyContent="space-around">
            {renderStatusIcon(participant?.injuries)}
            {renderStatusIcon(participant?.damages)}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderActions = () => {
    return (
      <Grid item container direction="row" justifyContent="right" xs={2}>
        <Divider orientation="vertical" flexItem />
        <Grid item container direction="column" justifyContent="space-around" xs={6}>
          <IconButton onClick={() => actions?.editParticipant(index, participant, actions)}>
            <Icon fontSize="small" color="inherit">
              edit
            </Icon>
          </IconButton>
          <IconButton onClick={() => actions?.deleteParticipant(index)}>
            <Icon fontSize="small" color="inherit">
              delete
            </Icon>
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card key={participant?.name}>
      <Grid container p={2} direction="row" justifyContent="space-between">
        {renderParticipantInfo()}
        {renderActions()}
      </Grid>
    </Card>
  );
};

export default ParticipantCard;
