import { Document } from '../constants/Document';

import { Connection } from './Connection';

enum AssetStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  REPAIR = 'REPAIR',
}

enum AssetType {
  TRUCK = 'Truck',
  TRAILER = 'Trailer',
  OTHER = 'Other',
}

enum AssetMake {
  FREIGHTLINER = 'Freightliner',
  VOLVO = 'Volvo',
  KENWORTH = 'Kenworth',
  PETERBILT = 'Peterbilt',
  OTHER = 'Other',
}

export interface AssetDimensions {
  length?: number;
  width?: number;
  height?: number;
}

enum AssetEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

interface AssetEvent {
  timestamp: number;
  status: AssetStatus;
  note?: string;
  user?: string;
  action: AssetEventAction;
}

interface Asset {
  id: string;
  type: AssetType;
  unitId: string;
  year: string;
  make: string;
  vin: string;
  miles?: number;
  color?: string;
  status: AssetStatus;
  version: number;
  events?: AssetEvent[];
  plate: string;
  state: string;
  unit: any;
  connections?: { [key: string]: Connection[] };
  groups?: any;
  files: { [key: string]: { [key: string]: Document } };
  info?: {
    dimensions: AssetDimensions;
  };
}

export { AssetStatus, AssetEventAction, AssetType, AssetMake };
export type { Asset };
