import PayStatus from 'constants/Accounting';
import { Document, DocumentConfig } from 'constants/Document';
import { FieldType, FormConfig, FormType, ValidationType } from 'constants/form/Form';
import { generateFilesTab, generateTypeTab } from 'constants/form/formConfig';
import { LoadPattern, LoadSize, LoadStatus, LoadType } from 'constants/Load';
import { RoleType } from 'constants/Roles';

import { formatUnitSelect } from 'screens/dispatch/board/schemas/unit/formatUnitValues';
import { formatDispatcherSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';
import { formatBrokerSelect } from 'screens/roster/groups/schemas/formatGroupValues';

import generateRouteConfig from './generateRouteConfig';

const ConfigName = 'Load';

export enum LoadDocumentType {
  RateCon = 'RateCon',
  BOL = 'BOL',
  Lumper = 'Lumper',
  Misc = 'Misc',
}

export const RateCon: Document = {
  name: 'RateCon',
  issueTimestamp: 0,
  type: LoadDocumentType.RateCon,
  role: {
    visibleFor: [RoleType.ADMIN, RoleType.DISPATCHER, RoleType.ACCOUNTANT],
    requiredFor: [
      LoadStatus.BOOKED,
      LoadStatus.SCHEDULED,
      LoadStatus.CANCELLED,
      LoadStatus.DELIVERED,
      LoadStatus.ARRIVED,
      LoadStatus.DISPATCHED,
      LoadStatus.ENROUTE,
      LoadStatus.LOADED,
      LoadStatus.UNLOADED,
      LoadStatus.LOADING,
      LoadStatus.UNLOADING,
    ],
  },
  version: '0',
};

export const BOL: Document = {
  name: 'BOL',
  issueTimestamp: 0,
  type: LoadDocumentType.BOL,
  role: {
    requiredFor: [LoadStatus.DELIVERED],
    visibleFor: [RoleType.DRIVER, RoleType.ADMIN, RoleType.DISPATCHER, RoleType.ACCOUNTANT],
  },
  note: '',
  version: '0',
};

export const Lumper: Document = {
  name: 'Lumper',
  issueTimestamp: 0,
  type: LoadDocumentType.Lumper,
  role: {
    visibleFor: [RoleType.DRIVER, RoleType.ADMIN, RoleType.DISPATCHER, RoleType.ACCOUNTANT],
    requiredFor: [],
  },
  version: '0',
};

export const LoadMisc: Document = {
  name: 'Misc',
  issueTimestamp: 0,
  id: '',
  type: LoadDocumentType.Misc,
  version: '0',
  role: {
    requiredFor: [],
  },
};

export const LoadDocumentConfig: DocumentConfig = {
  [LoadDocumentType.RateCon]: RateCon,
  [LoadDocumentType.BOL]: BOL,
  [LoadDocumentType.Lumper]: Lumper,
  [LoadDocumentType.Misc]: LoadMisc,
};

export const getExtendedLoadsFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        [
          {
            name: 'info.id',
            label: 'Load Id',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'Load Id is required.',
            },
          },
        ],
        [
          {
            name: 'info.type',
            label: 'Load Type',
            type: FieldType.SELECT,
            options: Object.values(LoadType),
            validation: {
              errorMsg: 'Load Type is required.',
            },
            composition: {
              weight: 6,
            },
          },
          {
            name: 'info.comodity',
            label: 'Commodity',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'info.weight',
            label: 'Weight',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
          {
            name: 'info.height',
            label: 'Height',
            type: FieldType.TEXT,
            composition: {
              weight: 2,
            },
          },
          {
            name: 'info.width',
            label: 'Width',
            type: FieldType.TEXT,
            composition: {
              weight: 2,
            },
          },
          {
            name: 'info.length',
            label: 'Length',
            type: FieldType.TEXT,
            composition: {
              weight: 2,
            },
          },
        ],
        [
          {
            name: 'info.size',
            label: 'Load Size',
            type: FieldType.SELECT,
            options: Object.values(LoadSize),
            composition: {
              weight: 3,
            },
            validation: {
              errorMsg: 'Load Size is required.',
            },
          },
          {
            name: 'info.pallets',
            label: 'Pallets',
            type: FieldType.TEXT,
            composition: {
              weight: 3,
            },
          },
          {
            name: 'info.pattern',
            label: 'Load Pattern',
            type: FieldType.SELECT,
            options: Object.values(LoadPattern),
            composition: {
              weight: 6,
            },
          },
        ],
      ],
    },
    Route: generateRouteConfig(ConfigName, values),
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName} Setup`,
      content: [
        [
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(LoadStatus),
            validation: {
              errorMsg: 'Status is required.',
            },
          },
        ],
        [
          {
            name: 'bookedBy',
            label: 'Booked By',
            type: FieldType.SELECT,
            options: values?.dispatchers.map((dispatcher: any) =>
              formatDispatcherSelect(dispatcher)
            ),
            validation: {
              errorMsg: 'Dispatcher is required.',
              type: ValidationType.OBJECT,
            },
            control: {
              editable: 'showEmployeeForm',
              addable: 'showEmployeeForm',
            },
          },
        ],
        [
          {
            name: 'assignedTo',
            label: 'Unit',
            type: FieldType.SELECT,
            options: values?.units.map((unit: any) => formatUnitSelect(unit)),
            select: {
              infoArray: [
                {
                  icon: 'inventory_2',
                  color: 'warning',
                  note: 'Unit is already assigned',
                  show: (unitId: string | undefined, load: any | undefined) => {
                    const unit = values?.units?.filter((unit: any) => unit.id === unitId)?.[0];

                    return unit?.load?.id && unit?.load?.id !== load?.id;
                  },
                },
              ],
            },
            control: {
              editable: 'showUnitForm',
              addable: 'showUnitForm',
            },
          },
        ],
        [
          {
            name: 'broker',
            label: 'Broker',
            type: FieldType.SELECT,
            options: values?.brokers.map((broker: any) => formatBrokerSelect(broker)),
            validation: {
              errorMsg: 'Broker is required.',
              type: ValidationType.OBJECT,
            },
            control: {
              editable: 'showGroupForm',
              addable: 'showGroupForm',
            },
          },
        ],
      ],
    },
    Accounting: {
      type: FormType.GENERAL,
      label: 'Accounting',
      title: `${ConfigName} Accounting`,
      content: [
        [
          {
            name: 'accounting.rate',
            label: 'Base Rate',
            type: FieldType.NUMBER,
            validation: {
              errorMsg: 'Rate is required.',
            },
            composition: {
              weight: 6,
            },
          },
          {
            name: 'accounting.rateTotal',
            label: 'Total Rate',
            type: FieldType.NUMBER,
            composition: {
              weight: 6,
            },
            disabled: true,
          },
        ],
        [
          {
            name: 'accounting.lumper.rate',
            label: 'Lumper Rate',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
          {
            name: 'accounting.lumper.payee',
            label: 'Paid By',
            type: FieldType.SELECT,
            options: ['Driver', 'Broker', 'Compony', 'Other'],
            composition: {
              weight: 4,
            },
          },
          {
            name: 'accounting.lumper.status',
            label: 'Pay Status',
            type: FieldType.SELECT,
            options: Object.values(PayStatus),
            composition: {
              weight: 4,
            },
          },
        ],
        [
          {
            name: 'accounting.overlay.rate',
            label: 'Overlay Rate',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
          {
            name: 'accounting.overlay.days',
            label: 'Overlay Days',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
          {
            name: 'accounting.overlay.status',
            label: 'Overlay Status',
            type: FieldType.SELECT,
            options: Object.values(PayStatus),
            composition: {
              weight: 4,
            },
          },
        ],
        [
          {
            name: 'accounting.detention.rate',
            label: 'Detention Rate',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
          {
            name: 'accounting.detention.hours',
            label: 'Detention Hours',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
          {
            name: 'accounting.detention.status',
            label: 'Detention Status',
            type: FieldType.SELECT,
            options: Object.values(PayStatus),
            composition: {
              weight: 4,
            },
          },
        ],
        [
          {
            name: 'accounting.stops.rate',
            label: 'Stop Rate',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
          {
            name: 'accounting.stops.extra',
            label: 'Extra Stops',
            type: FieldType.TEXT,
            composition: {
              weight: 4,
            },
          },
          {
            name: 'accounting.stops.status',
            label: 'Pay Status',
            type: FieldType.SELECT,
            options: Object.values(PayStatus),
            composition: {
              weight: 4,
            },
          },
        ],
        [
          {
            name: 'accounting.bonus.rate',
            label: 'Bonus',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
          {
            name: 'accounting.bonus.note',
            label: 'Note',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'accounting.deduction.rate',
            label: 'Deduction',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
          {
            name: 'accounting.deduction.note',
            label: 'Note',
            type: FieldType.TEXT,
            composition: {
              weight: 6,
            },
          },
        ],
      ],
    },
    Files: generateFilesTab(ConfigName, LoadDocumentConfig, values),
  } as FormConfig);

const generateSimpleFile = (values: any) => {
  const result = (
    generateTypeTab('files', LoadDocumentConfig, values)?.RateCon?.content as any
  )?.[0]?.content?.[0];

  return result;
};

export const getSimplifiedLoadsFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        generateSimpleFile(values),
        [
          {
            name: 'info.id',
            label: 'Load Id',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'Load Id is required.',
            },
          },
        ],
        [
          {
            name: 'info.type',
            label: 'Load Type',
            type: FieldType.SELECT,
            options: Object.values(LoadType),
            validation: {
              errorMsg: 'Load Type is required.',
            },
            composition: {
              weight: 6,
            },
          },
          {
            name: 'accounting.rate',
            label: 'Base Rate',
            type: FieldType.NUMBER,
            validation: {
              errorMsg: 'Rate is required.',
            },
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'bookedBy',
            label: 'Booked By',
            type: FieldType.SELECT,
            options: values?.dispatchers.map((dispatcher: any) =>
              formatDispatcherSelect(dispatcher)
            ),
            validation: {
              errorMsg: 'Dispatcher is required.',
              type: ValidationType.OBJECT,
            },
            control: {
              editable: 'showEmployeeForm',
              addable: 'showEmployeeForm',
            },
          },
        ],
        [
          {
            name: 'assignedTo',
            label: 'Unit',
            type: FieldType.SELECT,
            options: values?.units.map((unit: any) => formatUnitSelect(unit)),
            select: {
              infoArray: [
                {
                  icon: 'inventory_2',
                  color: 'warning',
                  note: 'Unit is already assigned',
                  show: (unitId: string | undefined, load: any | undefined) => {
                    const unit = values?.units?.filter((unit: any) => unit.id === unitId)?.[0];

                    return unit?.load?.id && unit?.load?.id !== load?.id;
                  },
                },
              ],
            },
            control: {
              editable: 'showUnitForm',
              addable: 'showUnitForm',
            },
          },
        ],
        [
          {
            name: 'broker',
            label: 'Broker',
            type: FieldType.SELECT,
            options: values?.brokers.map((broker: any) => formatBrokerSelect(broker)),
            validation: {
              errorMsg: 'Broker is required.',
              type: ValidationType.OBJECT,
            },
            control: {
              editable: 'showGroupForm',
              addable: 'showGroupForm',
            },
          },
        ],
      ],
    },
  } as FormConfig);

const getLoadsFormConfig = (values: any, extended: boolean) =>
  extended ? getExtendedLoadsFormConfig(values) : getSimplifiedLoadsFormConfig(values);

export const loadFormSetup = (id: any, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getLoadsFormConfig,
});
