import { getFunctions, httpsCallable } from 'firebase/functions';

import { getBase64 } from '../../../utils/fileUtil';

export const fetchCDLinfo = (file: File) =>
  getBase64(file)
    .then((base64) =>
      httpsCallable(
        getFunctions(),
        'parseCDL'
      )(
        JSON.stringify({
          content: base64.split(',')[1],
          mimeType: file.type,
        })
      ).then(
        (result: any) =>
          new Promise((resolve, reject) => {
            const data = result.data;

            if (data) {
              resolve(data);
            }

            reject();
          })
      )
    )
    .catch((error: any) => {
      const code = error.code;
      const message = error.message;
      const details = error.details;

      console.log('fetchCDLinfo error', code, message, details);
    });
