import { ReactNode, useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/common/MDBox';
import MDTypography from 'components/common/MDTypography';
import { Grid, Menu, MenuItem, Switch } from '@mui/material';
import MDBadge from 'components/common/MDBadge';

// Declaring prop types for the IntegrationCard
interface Props {
  logo?: string;
  title: string;
  description: ReactNode;
  tags: string[];
  enabled: boolean;
  menuItems: any[];
  [key: string]: any;
}

const renderMenu = (state: any, close: any, menuItems: any[] | undefined) => (
  <Menu
    anchorEl={state}
    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={Boolean(state)}
    onClose={close}
    keepMounted
  >
    {menuItems?.map((menu: any) => (
      <MenuItem
        key={menu.label}
        disabled={menu.disabled}
        onClick={() => {
          close();
          menu?.action();
        }}
      >
        {menu.label}
      </MenuItem>
    ))}
    <Divider />
    <MenuItem disabled onClick={close}>
      Delete
    </MenuItem>
  </Menu>
);

function IntegrationCard({
  logo,
  title,
  description,
  tags,
  enabled,
  menuItems,
}: Props): JSX.Element {
  const [menu, setMenu] = useState(null);

  const openMenu = (event: any) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const dropdown: {
    action?: (...arg: any) => void;
    menu?: ReactNode;
  } = {
    action: openMenu,
    menu: renderMenu(menu, closeMenu, menuItems),
  };

  return (
    <Card>
      <Grid container p={2} height="20rem" direction="column" justifyContent="space-between">
        <Grid container item direction="row" justifyContent="space-between">
          <Grid container item xs={11} direction="row">
            {logo && (
              <img src={logo} alt={title} style={{ maxHeight: '2rem', alignSelf: 'center' }} />
            )}
            <MDBox ml={2} lineHeight={0}>
              <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                {title}
              </MDTypography>
              {tags?.map((tag: any) => (
                <>
                  <MDBadge badgeContent={tag} size="xs" color="success" container />{' '}
                </>
              ))}
            </MDBox>
          </Grid>
          <Grid container item xs={1} direction="column" justifyContent="top">
            <Grid container item direction="row" alignItems="center" justifyContent="end">
              {dropdown && (
                <MDTypography color="secondary" onClick={openMenu}>
                  <Icon sx={{ cursor: 'pointer', fontWeight: 'bold' }}>more_vert</Icon>
                </MDTypography>
              )}
              {enabled && dropdown.menu}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lineHeight={1}>
          <MDTypography
            variant="button"
            fontWeight="light"
            color="text"
            display="flex"
            sx={{ wordBreak: 'break-word', overflow: 'hidden' }}
            maxHeight="8rem"
          >
            {description}
          </MDTypography>
        </Grid>
        <Grid container item direction="column">
          <Divider />
          <Grid container item alignItems="center" direction="row" justifyContent="right">
            <MDTypography variant="button" color="text">
              {enabled ? 'Enabled' : 'Disabled'}
            </MDTypography>
            <Switch checked={enabled} />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

IntegrationCard.defaultProps = {
  logo: null,
};

export default IntegrationCard;
