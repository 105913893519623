import React, { FC, forwardRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  PaperProps,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import Draggable from 'react-draggable';
import { TransitionProps } from '@mui/material/transitions';

export interface AlertDialogProps {
  isOpen: boolean;
  title?: string;
  text?: string;
  content?: any;
  actions?: {
    confirm: {
      text: string;
      action: (values?: any) => Promise<void>;
      disable?: any;
    };
    cancel: {
      text: string;
      action: () => void;
    };
  };
  [key: string]: any;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const AlertDialog: FC<AlertDialogProps> = forwardRef(
  ({ id, isOpen = true, title, text, actions, ref, content, values }) => {
    const [form, setForm] = useState<any>(values);

    console.log('AlertDialog', form);

    return (
      <Dialog
        open={isOpen}
        onClose={actions?.cancel?.action}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        TransitionComponent={Transition}
        ref={ref}
      >
        <DialogTitle style={{ cursor: 'move' }} id={id}>
          {title}
        </DialogTitle>
        <DialogContent>
          {text && <DialogContentText>{text}</DialogContentText>}
          {content && content(form, setForm)}
        </DialogContent>
        <DialogActions>
          {actions?.cancel?.action && (
            <Button autoFocus onClick={actions.cancel.action}>
              {actions?.cancel?.text}
            </Button>
          )}
          {actions?.confirm?.action && (
            <Button
              disabled={actions?.confirm?.disable && actions?.confirm?.disable(form)}
              onClick={() => actions.confirm.action(form)}
            >
              {actions.confirm?.text}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

export default AlertDialog;
