import styled from 'styled-components';

export const StatusContainer = styled.div`
  width: 133px;
`;

export const StyledLine = styled.div<{ color?: string }>`
  width: 3px;
  height: 50px;
  background: ${({ color }) => (color ? color : 'transparent')};
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0 2px 11px;
  gap: 15px;
`;

export const StyledInfo = styled.div`
  position: absolute;
  left: 165px;
`;

export const StyledStatusName = styled.div`
  position: relative;
  padding-right: 3px;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledData = styled.span`
  position: absolute;
  bottom: -5px;
  font-size: 6px;
`;

export const StyledName = styled.p`
  font-weight: bold;
  font-size: 12px;
`;

export const StyledAddress = styled.p`
  font-size: 16px;
  width: 160px;
`;
