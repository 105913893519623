import { directionsBaseUrl, directionsConfigUrl  } from '../../constants/helpers/constants';

const useMapboxAPI = () => {
  const directions = (coordinates: any) => {
    let directionsUrl = directionsBaseUrl;

    coordinates.forEach((coordinate: any) => {
      if (coordinate && coordinate?.lng && coordinate?.lat) {
        directionsUrl += `${coordinate.lng},${coordinate.lat};`;
      }
    });

    directionsUrl = directionsUrl.substring(0, directionsUrl.length - 1) + directionsConfigUrl;

    return fetch(directionsUrl, {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .catch((err) => {
        console.log(err.message);
      });
  };

  return {
    directions,
  };
};

export default useMapboxAPI;
