import withAuthorization from 'session/withAuthorization';
import { Card, Grid, Icon, IconButton } from '@mui/material';
import { useDeepCompareMemo } from 'use-deep-compare';

import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import DataTableInfoCell from 'components/DataTable/DataTableInfoCell';
import {
  InfoFilterGroup,
  ServiceStatusFilterGroup,
  SimpleDateRangeFilterGroup,
} from 'components/DataTable/Filters';
import MDButton from 'components/common/MDButton';

import useBaseForm from 'hooks/useBaseForm';

import useFirebaseServiceAPI from 'services/data/Firebase/firebaseServiceAPI';

import DataTableUnitCell from 'components/DataTable/DataTableUnitCell';

import MDBadge from 'components/common/MDBadge';

import { ExpenseDocumentConfig } from 'screens/accounting/expenses/configExpenses';

import { ServiceDocumentConfig } from './configService';

const serviceTable = {
  columns: [
    {
      Header: 'Asset id',
      accessor: 'asset',
      width: '10%',
      Cell: ({ value }: any) => <DataTableUnitCell unit={value} />,
    },
    {
      Header: 'status',
      accessor: 'status',
      width: '8%',
      ...ServiceStatusFilterGroup,
    },
    {
      Header: 'type',
      accessor: 'type',
      width: '7%',
    },
    {
      Header: 'items',
      accessor: 'items',
      width: '12%',
      Cell: ({ value }: any) => (
        <Grid container spacing="0.25rem" width="30rem">
          {value?.map((item: any, index: number) => (
            <Grid item key={index}>
              <MDBadge badgeContent={item.name} size="xs" color="dark" container />
            </Grid>
          ))}
        </Grid>
      ),
    },
    {
      Header: 'Schedule Date',
      accessor: 'scheduleDate',
      width: '8%',
      Cell: ({ value }: any) => value && new Date(value).toISOString()?.split('T')[0] || '',
      ...SimpleDateRangeFilterGroup,
    },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
      width: '8%',
      Cell: ({ value }: any) => value && new Date(value).toISOString()?.split('T')[0] || '',
    },
    { Header: 'notes', accessor: 'notes', width: '20%' },
    {
      Header: 'info',
      accessor: 'info',
      ...InfoFilterGroup(ServiceDocumentConfig),
      Cell: ({ row }: any) => (
        <DataTableInfoCell
          row={row.original}
          actions={row.values.actions}
          documentConfig={{...ExpenseDocumentConfig, ...ServiceDocumentConfig}}
        />
      ),
    },
    {
      Header: 'actions',
      accessor: 'actions',
      Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
      alignCell: 'right',
      isSortable: false,
      width: '5%',
    },
  ],
};

function ServicesScreen() {
  const { services } = useFirebaseServiceAPI();
  const { showServiceForm, showServiceDeleteDialog } = useBaseForm();

  const onHistory = (events: any) => console.log('handleHistory for', events);

  const mapServices =
    (services &&
      services?.map((service: any) => {
        if (!service) {
          return null;
        }

        return {
          ...service,
          issueTimestamp: service?.files?.Invoice?.[0]?.issueTimestamp,
          actions: {
            history: () => onHistory(service.events),
            edit: (values: any = service, tabs: any = {}) =>
              showServiceForm({ ...values, initialTabs: tabs }),
            delete: () => showServiceDeleteDialog(service),
          },
        };
      })) ||
    [];

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={serviceTable.columns}
        data={mapServices}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={() => showServiceForm()}
              sx={{
                ml: '0.5rem',
                p: 0,
                pl: '0.5rem',
                pr: '1rem',
                height: '25px',
                borderRadius: '5px',
              }}
            >
              <Icon>add</Icon>&nbsp; Service
            </MDButton>
          </>
        }
      />
    ),
    [services]
  );

  return (
    <DashboardLayout>
      <Card>{generateTable}</Card>
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(ServicesScreen);
