import { RoleType } from 'constants/Roles';

import { EmployeeDocumentType } from 'screens/roster/employees/schemas/employeeConfig';
import { GroupDocumentType } from 'screens/roster/groups/schemas/groupsConfig';
import { LoadDocumentType } from 'screens/freight/loads/schemas/loadsConfig';
import { AssetDocumentType } from 'screens/roster/assets/configAsset';

import { AccessoryDocumentType } from 'screens/roster/accessories/configAccessory';

import { ExpenseDocumentType } from 'screens/accounting/expenses/configExpenses';

import { ServiceDocumentType } from 'screens/fleet/service/configService';

import { AccidentDocumentType } from 'screens/compliance/accidents/configAccident';

import { FormField } from './form/Form';
import { AssetType } from './Asset';
import { LoadStatus } from './Load';
import { ExpenseStatus, ExpenseType } from './Expense';
import { AccidentType } from './Accident';

/* eslint-disable no-unused-vars */
export enum DocumentStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  MISSING = 'MISSING',
  PENDING = 'PENDING',
}

export type DocumentType =
  | EmployeeDocumentType
  | GroupDocumentType
  | LoadDocumentType
  | AssetDocumentType
  | AccessoryDocumentType
  | ExpenseDocumentType
  | ServiceDocumentType
  | AccidentDocumentType;

export enum DocumentFileExtension {
  PDF = 'application/pdf',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
}

export enum DocumentEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

export interface DocumentExpirattion {
  expireTimestamp?: number;
  expireInDays?: number;
  notifyInDays?: number;
}

export enum DocumentRoleType {
  ALLOWED = 'allowedFor',
  REQUIRED = 'requiredFor',
  VISIBLE = 'visibleFor',
}

export interface DocumentRole {
  [DocumentRoleType.ALLOWED]?: (RoleType | AssetType)[];
  [DocumentRoleType.VISIBLE]?: (RoleType | AssetType)[];
  [DocumentRoleType.REQUIRED]?: (RoleType | AssetType | LoadStatus | ExpenseType | AccidentType | ExpenseStatus)[];
}

export interface DocumentFile {
  name: string;
  extension: DocumentFileExtension;
  size?: number;
  url?: string;
  content?: any;
  uploadTimestamp?: number;
}

export interface DocumentEvent {
  timestamp: number;
  status: DocumentStatus;
  note?: string;
  user?: string;
  action: DocumentEventAction;
  role?: DocumentRole;
}

export interface Document {
  expiration?: DocumentExpirattion;
  file?: DocumentFile;
  name: string;
  id?: string;
  issueTimestamp?: number;
  role?: DocumentRole;
  status?: DocumentStatus;
  type: DocumentType;
  events?: DocumentEvent[];
  version: string;
  note?: string;
  actions?: any;
  extraFields?: { [key: string]: FormField };
}

export interface Info {
  type: DocumentType;
  status: DocumentStatus;
}

export interface DocumentConfig {
  [key: string]: Document;
}
