import React from 'react';
import styled from 'styled-components';

interface VerTabProps {
  value: string[];
  disableRipple?: boolean;
  activeTab: string;
  label: string;
  onTabChange: (tab: string) => void;
}

const VerTabWrapper = styled.div`
  display: flex;
`;

interface TabButtonProps {
  active: boolean;
}

const VerTabButton = styled.div<TabButtonProps>`
  background-color: ${(props) => (props.active ? '#ffffff' : '#ffffff')};
  color: ${(props) => (props.active ? '#344767' : '#666666')};
  padding: 14px 23px;
  border: none;
  cursor: pointer;
  border-bottom: ${(props) => (props.active ? '4px solid #344767' : '4px solid #f5f5f5')};
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
`;

const VerTab: React.FC<VerTabProps> = ({ value, activeTab, onTabChange }) => {
  const handleTabClick = (tab: string) => {
    onTabChange(tab);
  };

  return (
    <VerTabWrapper>
      {value.map((tab) => (
        <VerTabButton key={tab} active={tab === activeTab} onClick={() => handleTabClick(tab)}>
          {tab}
        </VerTabButton>
      ))}
    </VerTabWrapper>
  );
};

export default VerTab;
