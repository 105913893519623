import { Location } from './Location';
import { Document } from './Document';
import { Event } from './Event';

export interface Service {
  id: string;
  items: ServiceItem[];
  type: ServiceType;
  status: ServiceStatus;
  asset: { id: string } | null;
  expense: { id: string } | null;
  provider: { id: string } | null;
  location: Location;
  scheduleDate: number;
  dueDate: number;
  notes: string;
  version: number;
  events?: Event[] | null;
  files: { [key: string]: { [key: string]: Document } };
}

export enum ServiceStatus {
  SCHEDULED = 'SCHEDULED',
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum ServiceEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

export interface ServiceEvent {
  timestamp: number;
  status: ServiceStatus;
  note?: string;
  user?: string;
  source?: any;
  target?: any;
  action: ServiceEventAction;
}

export enum ServiceType {
  MAINTENANCE = 'Maintenance',
  REPAIR = 'Repair',
  INSPECTION = 'Inspection',
  TOWING = 'Towing',
  TIRE = 'Tire',
  WASH = 'Wash',
  OTHER = 'Other',
}

export interface ServiceItem {
  id: number;
  name: string;
}

export const ServiceItems: { [key: string]: ServiceItem } = {
  AC_COMPRESSOR: { id: 1, name: 'AC Compressor' },
  AIR_FILTER: { id: 2, name: 'Air Filter' },
  ALIGNMENT: { id: 3, name: 'Alignment' },
  ALTERNATOR: { id: 4, name: 'Alternator' },
  BRAKE_DRUM_SHOE: { id: 5, name: 'Brake Drum Shoe' },
  BRAKE_FLUID: { id: 6, name: 'Brake Fluid' },
  BRAKE_SHOES: { id: 7, name: 'Brake Shoes' },
  CAP_ROTAR_WIRE: { id: 8, name: 'Cap, Rotar & Wire' },
  CATALYTIC_CONVERTER: { id: 9, name: 'Catalytic Converter' },
  CHANGE_OIL_FILTER: { id: 10, name: 'Change Oil & Filter' },
  CHANGE_TRANSMISSION_FLUID: { id: 11, name: 'Change Transmission Fluid' },
  CHANGE_WINDSHIELD_WIPERS: { id: 12, name: 'Change Windshield Wipers' },
  CHECK_ABC_BRAKE_FLUID: { id: 13, name: 'Check ABC Brake Fluid' },
  CHECK_ANTIFREEZE_LEVEL: { id: 14, name: 'Check Antifreeze Level' },
  CHECK_BATTERY: { id: 15, name: 'Check Battery' },
  CHECK_BRAKE_FLUID: { id: 16, name: 'Check Brake Fluid' },
  CHECK_CLUTCH_FLUID: { id: 17, name: 'Check Clutch Fluid' },
  CHECK_COOLING_SYSTEM: { id: 18, name: 'Check Cooling System' },
  CHECK_ENGINE_COOLANT: { id: 19, name: 'Check Engine Coolant' },
  CHECK_FLASHLIGHT_BATTERIES: { id: 20, name: 'Check Flashlight Batteries' },
  CHECK_HEAD_BREAK_RUNNING_LIGHTS: { id: 21, name: 'Check Head/Break/Running Lights' },
  CHECK_OIL_LEVEL: { id: 22, name: 'Check Oil Level' },
  CHECK_PVC_VALVE: { id: 23, name: 'Check PVC Valve' },
  CHECK_POWER_STEERING_FLUID: { id: 24, name: 'Check Power Steering Fluid' },
  CHECK_TIRE_PRESSURE: { id: 25, name: 'Check Tire Pressure' },
  CHECK_TIRE_TREAD: { id: 26, name: 'Check Tire Tread' },
  CHECK_TRANSAXLE: { id: 27, name: 'Check Transaxle' },
  CHECK_TRANSMISSION_FLUID: { id: 28, name: 'Check Transmission Fluid' },
  CHECK_WINDSHIELD_WASHER_FLUID: { id: 29, name: 'Check Windshield Washer FLuid' },
  CHECK_WINDSHIELD_WIPERS: { id: 30, name: 'Check Windshield Wipers' },
  CLEAN_ENGINE: { id: 31, name: 'Clean Engine' },
  CLEAN_INJECTOR_CARBUERATOR: { id: 32, name: 'Clean Injector/Carburetor' },
  CLEAN_INTERIOR: { id: 33, name: 'Clean Interior' },
  CLUTCH_CYLINDER: { id: 34, name: 'Clutch Cylinder' },
  CONTROL_ARM_LOWER_BALL_JOINT: { id: 35, name: 'Control Arm Lower Ball Joint' },
  COOLING_SYSTEM_FLUSH: { id: 36, name: 'Cooling System Flush' },
  DISC_BRAKE_CALIPERS: { id: 37, name: 'Disc Brake Calipers' },
  DRUM_BRAKE_CYLINDERS: { id: 38, name: 'Drum Brake Cylinders' },
  DRUM_BRAKE_SHOES: { id: 39, name: 'Drum Brake Shoes' },
  ENGINE_BELTS: { id: 40, name: 'Engine Belts' },
  ENGINE_TUNE_UP: { id: 41, name: 'Engine Tune Up' },
  EXHAUST_PIPES: { id: 42, name: 'Exhaust Pipes' },
  FUEL_FILTER: { id: 43, name: 'Fuel Filter' },
  FUEL_INJECTORS: { id: 44, name: 'Fuel Injectors' },
  FUEL_PUMP: { id: 45, name: 'Fuel Pump' },
  HEATER_CORE: { id: 46, name: 'Heater Core' },
  INSPECT_BRAKES: { id: 47, name: 'Inspect Brakes' },
  INSPECT_DRIVE_SHAFT_BOOT: { id: 48, name: 'Inspect Drive Shaft Boot' },
  INSPECT_ENGINE_BELTS: { id: 49, name: 'Inspect Engine Belts' },
  INSPECT_HOSES: { id: 50, name: 'Inspect Hoses' },
  INSPECT_SUSPENSION: { id: 51, name: 'Inspect Suspension' },
  INSURANCE_RENEWAL: { id: 52, name: 'Insurance Renewal' },
  LIGHTS: { id: 53, name: 'Lights' },
  LUBE_CHASSIS: { id: 54, name: 'Lube Chassis' },
  MUFFLER: { id: 55, name: 'Muffler' },
  PUMP: { id: 56, name: 'Pump' },
  OXYGEN_SENSOR: { id: 57, name: 'Oxygen Sensor' },
  PCV_VALVE: { id: 58, name: 'PCV Valve' },
  POWER_STEERING_PUMP: { id: 59, name: 'Power Steering Pump' },
  RADIATOR: { id: 60, name: 'Radiator' },
  RADIATOR_HOSES: { id: 61, name: 'Radiator Hoses' },
  RECHARGE_AC: { id: 62, name: 'Recharge AC' },
  REPLACE_BRAKES: { id: 63, name: 'Replace Brakes' },
  REPLACE_PARKING_LIGHTS: { id: 64, name: 'Replace Parking Lights' },
  ROTATE_TIRES: { id: 65, name: 'Rotate Tires' },
  SHOCK_ABSORBERS: { id: 66, name: 'Shock Absorbers' },
  SPARK_PLUGS: { id: 67, name: 'Spark Plugs' },
  SPRINGS: { id: 68, name: 'Springs' },
  STARTER: { id: 69, name: 'Starter' },
  STRUTTS: { id: 70, name: 'Strutts' },
  THERMOSTAT: { id: 71, name: 'Thermostat' },
  TIE_RODS: { id: 72, name: 'Tie Rods' },
  TIMING_BELT: { id: 73, name: 'Timing Belt' },
  TIMING_CHAIN: { id: 74, name: 'Timing Chain' },
  TIRES_BALANCE: { id: 75, name: 'Tires & Balance' },
  TRANSMISSION_FILTER: { id: 76, name: 'Transmission Filter' },
  UNIVERSAL_CV_JOINT: { id: 77, name: 'Universal CV Joint' },
  VALVE_LIFTERS: { id: 78, name: 'Valve Lifters' },
  WASH_EXTERIOR: { id: 79, name: 'Wash Exterior' },
  WATER_PUMP: { id: 80, name: 'Water Pump' },
  WAX_EXTERIOR: { id: 81, name: 'Wax Exterior' },
  WHEEL_ALIGNMENT: { id: 82, name: 'Wheel Alignment' },
};
