import withAuthorization from 'session/withAuthorization';
import { Card, Icon, IconButton } from '@mui/material';
import { useDeepCompareMemo } from 'use-deep-compare';
import useFirebaseAccessoryAPI from 'services/data/Firebase/firebaseAccessoryAPI';

import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import DataTableInfoCell from 'components/DataTable/DataTableInfoCell';
import { AccessoryStatusFilterGroup, AccessoryTypeFilterGroup, InfoFilterGroup } from 'components/DataTable/Filters';
import DataTableUnitCell from 'components/DataTable/DataTableUnitCell';
import MDButton from 'components/common/MDButton';

import useBaseForm from 'hooks/useBaseForm';

import DataTableAccessoryCell from 'components/DataTable/DataTableAccessoryCell';

import { AccessoryDocumentConfig } from './configAccessory';

const accessoryTable = {
  columns: [
    {
      Header: 'accessory id',
      accessor: 'accessoryId',
      width: '15%',
    },
    {
      Header: 'status',
      accessor: 'status',
      width: '8%',
      ...AccessoryStatusFilterGroup,
    },
    {
      Header: 'type',
      accessor: 'type',
      width: '7%',
      Cell: ({ row }: any) => <DataTableAccessoryCell type={row.original.type} />,
      ...AccessoryTypeFilterGroup,
    },
    {
      Header: 'provider',
      accessor: 'info.provider',
      width: '10%',
    },
    {
      Header: 'unit',
      accessor: 'unitId',
      width: '10%',
      Cell: ({ data, row }: any) => (
        <DataTableUnitCell
          unit={{ unitId: row.original.unit?.truck?.unitId, year: row.original.unit?.truck?.year, make: row.original.unit?.truck?.make }}
        />
      ),
    },
    { Header: 'connection', accessor: 'connection', width: '10%' },
    { Header: 'notes', accessor: 'notes', width: '20%' },
    {
      Header: 'info',
      accessor: 'info',
      ...InfoFilterGroup(AccessoryDocumentConfig),
      Cell: ({ row }: any) => (
        <DataTableInfoCell
          row={row.original}
          actions={row.values.actions}
          documentConfig={AccessoryDocumentConfig}
        />
      ),
    },
    {
      Header: 'actions',
      accessor: 'actions',
      Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
      alignCell: 'right',
      isSortable: false,
      width: '5%',
    },
  ],
};

function Accessorys() {
  const { accessories } = useFirebaseAccessoryAPI();
  const { showAccessoryForm, showAccessoryDeleteDialog } = useBaseForm();

  const onHistory = (events: any) => console.log('handleHistory for', events);

  const mapAccessorys =
    (accessories &&
      accessories?.map((accessory: any) => {
        if (!accessory) {
          return null;
        }

        return {
          ...accessory,
          actions: {
            history: () => onHistory(accessory.events),
            edit: (values: any = accessory, tabs: any = {}) =>
              showAccessoryForm({ ...values, initialTabs: tabs }),
            delete: () => showAccessoryDeleteDialog(accessory),
          },
        };
      })) ||
    [];

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={accessoryTable.columns}
        data={mapAccessorys}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={() => showAccessoryForm()}
              sx={{
                ml: '0.5rem',
                p: 0,
                pl: '0.5rem',
                pr: '1rem',
                height: '25px',
                borderRadius: '5px',
              }}
            >
              <Icon>add</Icon>&nbsp; Accessory
            </MDButton>
          </>
        }
      />
    ),
    [accessories]
  );

  return (
    <DashboardLayout>
      <Card>{generateTable}</Card>
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(Accessorys);
