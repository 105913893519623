import useFirebaseLoadAPI from 'services/data/Firebase/firebaseLoadAPI';

import withAuthorization from 'session/withAuthorization';

import Card from '@mui/material/Card';

import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import { Grid, Icon, IconButton } from '@mui/material';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import DataTableRouteCell from 'components/DataTable/DataTableRouteCell';
import MDTypography from 'components/common/MDTypography';
import DataTableUnitCell from 'components/DataTable/DataTableUnitCell';
import { useDeepCompareMemo } from 'use-deep-compare';
import useBaseForm from 'hooks/useBaseForm';
import { getRoutes } from 'utils/getRoutes';
import {
  FreightDateRangeFilterGroup,
  InfoFilterGroup,
  LoadStatusFilterGroup,
} from 'components/DataTable/Filters';

import DataTableInfoCell from 'components/DataTable/DataTableInfoCell';

import dayjs from 'dayjs';

import MDButton from 'components/common/MDButton';

import { LoadDocumentConfig } from './schemas/loadsConfig';

const columns = [
  { Header: 'Id', accessor: 'info.id', width: '7%' },
  { Header: 'Rate', accessor: 'accounting.rate', width: '6%' },
  { Header: 'Status', accessor: 'status', width: '6%', ...LoadStatusFilterGroup },
  {
    Header: 'Broker',
    accessor: ({ broker }: any) =>
      broker?.authority
        ? `${broker.companyName} ${broker.authority.type} ${broker.authority.number}`
        : broker.companyName,
    width: '10%',
    Cell: ({ data, row }: any) => (
      <Grid container item direction="column">
        <Grid container item direction="column">
          <MDTypography variant="overline" color="text" fontWeight="bold" lineHeight="inherit">
            {data[row.id].broker.companyName}
          </MDTypography>
          {data[row.id].broker?.authority?.number && (
            <MDTypography variant="overline" color="text" lineHeight="inherit">
              {`${data[row.id].broker?.authority?.type || 'MC'} ${
                data[row.id].broker?.authority?.number
              }`}
            </MDTypography>
          )}
        </Grid>
      </Grid>
    ),
  },
  {
    Header: 'Unit',
    accessor: ({ assignedTo }: any) =>
      assignedTo
        ? `${assignedTo.truck?.unitId} ${assignedTo.truck?.year} ${assignedTo.truck?.make}`
        : '',
    width: '7%',
    Cell: ({ data, row }: any) => <DataTableUnitCell unit={data[row.id].assignedTo?.truck} />,
  },
  {
    Header: 'route',
    accessor: ({ route }: any) => getRoutes(route?.stops),
    Cell: ({ data, row }: any) => (
      <DataTableRouteCell values={data[row.id]} actions={data[row.id]?.actions} />
    ),
    width: '30%',
    ...FreightDateRangeFilterGroup,
  },
  {
    Header: 'info',
    accessor: 'info',
    width: '10%',
    ...InfoFilterGroup(LoadDocumentConfig),
    Cell: ({ row }: any) => (
      <DataTableInfoCell
        row={row.original}
        actions={row.values.actions}
        documentConfig={LoadDocumentConfig}
      />
    ),
  },
  {
    Header: 'actions',
    accessor: 'actions',
    Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
    alignCell: 'right',
    isSortable: false,
    width: '5%',
  },
];

function Loads() {
  const { loads } = useFirebaseLoadAPI();

  const { showLoadForm, showLoadDeleteDialog } = useBaseForm();

  const onHistory = (events: any) => console.log('handleHistory for', events);

  const mapLoads =
    (loads &&
      loads.map((load: any) => {
        if (!load) {
          return null;
        }

        return {
          ...load,
          actions: {
            history: () => onHistory(load.events),
            edit: (tabs: any = {}) => showLoadForm({ ...load, initialTabs: tabs }),
            delete: () => showLoadDeleteDialog(load),
          },
        };
      })) ||
    [];

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={columns}
        data={mapLoads}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            <IconButton disabled={process.env.FEATURE_EMPLOYEE_EVENTS !== 'true'}>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={() => showLoadForm()}
              sx={{ ml: '0.5rem', p: 0, pl: '0.5rem', pr: '1rem', height: '25px',  borderRadius: '5px' }}
            >
              <Icon>add</Icon>&nbsp; Load
            </MDButton>
          </>
        }
        initialState={{
          pageIndex: 0,
          filters: [
            {
              id: 'route',
              value: {
                startDate: dayjs().startOf('week').format('YYYY-MM-DD'),
                endDate: dayjs().endOf('week').format('YYYY-MM-DD'),
              },
            },
          ],
        }}
      />
    ),
    [loads]
  );

  return (
    <DashboardLayout>
      <Card>{generateTable}</Card>
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(Loads);
