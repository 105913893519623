import { Expense, ExpenseEventAction, ExpenseStatus } from 'constants/Expense';
import { FileUploadResult } from 'constants/Firebase';
import { intersectFilesUpload } from 'constants/Util';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import formatExpenseData from 'screens/accounting/expenses/migration';

import { formatExpensesValuesList } from 'screens/accounting/expenses/formatExpenses';

import { addedDiff, deletedDiff, updatedDiff } from 'deep-object-diff';

import { ExpenseAPI } from '../ExpenseAPI';

import useFirebaseFileAPI from './firebaseFileAPI';
import updatedDependentFields from './updatedDependentFields';

import { firestoreAdd, firestoreRemove, firestoreUpdate } from '.';

const API_PATH = 'expenses';

const useFirebaseExpenseAPI = (): ExpenseAPI => {
  const firestore = useFirestore();
  const { uploadBatch } = useFirebaseFileAPI();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const expenses: Expense[] = useSelector((state: any) => {
    const data = state?.firestore?.data;
    let result: Expense[] = [];

    if (data) {
      const { expenses, groups, employees, accessories, services } = data;
      result = formatExpensesValuesList(expenses, groups, employees, accessories, services);
    }

    return result;
  });

  const defualtExpenseAddEvent = {
    timestamp: Date.now(),
    status: ExpenseStatus.PENDING,
    user: defaultCompany?.employeeId,
    action: ExpenseEventAction.ADD,
  };

  const addExpense = (
    data: any = {},
    company: any = defaultCompany,
    event: any = defualtExpenseAddEvent
  ) =>
    firestoreAdd(
      firestore,
      `company/${company.companyId}/expenses`,
      formatExpenseData({
        ...data,
        events: {
          [`${Date.now()}`]: event,
        },
      })
    );

  const updateExpense = (
    id: string,
    data: any,
    company: any = defaultCompany,
    event: any = defualtExpenseAddEvent
  ) =>
    firestoreUpdate(
      firestore,
      `company/${company.companyId}/expenses/${id}`,
      formatExpenseData({
        ...data,
        events: {
          ...data.events,
          [event.timestamp]: event,
        },
      })
    );

  const addExpenseEvent = (event: any, companyId: string = defaultCompany.companyId) =>
    firestoreAdd(firestore, `company/${companyId}/expensesEvents`, event);

  const removeExpense = (id: string, companyId: string) =>
    firestoreRemove(firestore, `company/${companyId}/expenses/${id}`);

  const onRemoveExpense = (expense: Expense, company: any = defaultCompany) =>
    removeExpense(expense.id, company.companyId).then(() =>
      updatedDependentFields('expense', expense, {}, firestore, defaultCompany).then(() =>
        addExpenseEvent({
          [`${Date.now()}`]: {
            timestamp: Date.now(),
            user: company.employeeId,
            action: ExpenseEventAction.REMOVE,
            target: expense.id,
            source: formatExpenseData(expense),
          },
        })
      )
    );

  const onEditExpense = (
    { files, ...rest }: Expense,
    initialValues: Expense | undefined = undefined,
    company: any = defaultCompany
  ) =>
    uploadBatch(files, rest.id, API_PATH)
      .then((promiseResults: FileUploadResult[]) => {
        const fileUploadedExpense = {
          ...rest,
          files: intersectFilesUpload(promiseResults, files),
        };

        return updateExpense(rest.id, fileUploadedExpense, company, {
          timestamp: Date.now(),
          status: rest.status,
          user: company.employeeId,
          action: ExpenseEventAction.EDIT,
          added: addedDiff(
            formatExpenseData(initialValues) || {},
            formatExpenseData(fileUploadedExpense)
          ),
          updated: updatedDiff(
            formatExpenseData(initialValues) || {},
            formatExpenseData(fileUploadedExpense)
          ),
          removed: deletedDiff(
            formatExpenseData(initialValues) || {},
            formatExpenseData(fileUploadedExpense)
          ),
        });
      })
      .then(() =>
        updatedDependentFields('expense', initialValues, rest, firestore, defaultCompany)
      );

  const onAddExpense = (
    { files, ...rest }: Expense,
    initialValues: Expense | undefined = undefined,
    company: any = defaultCompany
  ) =>
    addExpense({}, company).then((createResult: any) =>
      uploadBatch(files, createResult.id, API_PATH).then((promiseResults: FileUploadResult[]) =>
        updateExpense(createResult.id, {
          ...rest,
          files: intersectFilesUpload(promiseResults, files),
          id: createResult.id,
        })
          .then(() =>
            updatedDependentFields('expense', initialValues, rest, firestore, defaultCompany)
          )
          .then(() =>
            addExpenseEvent({
              [`${Date.now()}`]: {
                timestamp: Date.now(),
                user: company.employeeId,
                action: ExpenseEventAction.ADD,
                target: createResult.id,
                source: formatExpenseData({
                  ...rest,
                  files: intersectFilesUpload(promiseResults, files),
                  id: createResult.id,
                }),
              },
            }).then(() => createResult)
          )
      )
    );

  return {
    onAddExpense,
    onEditExpense,
    onRemoveExpense,
    expenses,
  };
};

export default useFirebaseExpenseAPI;
