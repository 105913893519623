import { AssetMake, AssetType } from 'constants/Asset';

const baseUrl = 'https://vpic.nhtsa.dot.gov/api/';
const decodeVinUrl = 'vehicles/DecodeVinValues/';

const formatAssetType = (type: string): AssetType => {
  let result = AssetType.OTHER;

  if (type.toLowerCase().includes(AssetType.TRUCK.toLowerCase())) {
    result = AssetType.TRUCK;
  }

  if (type.toLowerCase().includes(AssetType.TRAILER.toLowerCase())) {
    result = AssetType.TRAILER;
  }

  return result;
};

const formatAssetMake = (make: string) => {
  let result = make;

  if (make.toLowerCase().includes(AssetMake.FREIGHTLINER.toLowerCase())) {
    result = AssetMake.FREIGHTLINER;
  }

  if (make.toLowerCase().includes(AssetMake.VOLVO.toLowerCase())) {
    result = AssetMake.VOLVO;
  }

  if (make.toLowerCase().includes(AssetMake.PETERBILT.toLowerCase())) {
    result = AssetMake.PETERBILT;
  }

  if (make.toLowerCase().includes(AssetMake.KENWORTH.toLowerCase())) {
    result = AssetMake.KENWORTH;
  }

  return result;
};

const useVehicleAPI = () => {
  const decodeVin = (vin: string) =>
    fetch(`${baseUrl + decodeVinUrl + vin}?format=json`, {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        const data = result?.Results?.[0];

        return {
          year: data.ModelYear,
          make: formatAssetMake(data.Make),
          type: formatAssetType(data.VehicleType),
          vin,
        };
      })
      .catch((err) => {
        console.log(err.message);
      });

  return {
    decodeVin,
  };
};

export default useVehicleAPI;
