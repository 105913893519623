import { DispatchStatusPriority } from '../../constants/Dispatch';
import { Load, LoadStatus } from '../../constants/Load';
import { UnitStatus } from '../../constants/Unit';


export const getPriorityStatus = (
    status1?: UnitStatus | LoadStatus,
    status2?: LoadStatus | UnitStatus
  ) => {
    if (!status1) {
      return status2;
    } else if (!status2) {
      return status1;
    }
  
    if (DispatchStatusPriority[status1] >= DispatchStatusPriority[status2]) {
      return status1;
    }
  
    return status2;
  };

export const getLoadStatus = (loads?: Load[]) => {
    let loadStatus: LoadStatus | UnitStatus | undefined = loads && loads[0]?.status;
  
    if (loads?.length) {
      loads.forEach((load: Load) => {
          loadStatus = getPriorityStatus(loadStatus, load.status);
      });
    }
  
    return loadStatus;
  };

export const getCurrentStatus = (unitStatus?: UnitStatus, loads?: Load[]) => {
  const loadStatus = getLoadStatus(loads);

  return getPriorityStatus(unitStatus, loadStatus);
};
