import { Group, GroupEventAction, GroupStatus, GroupType } from 'constants/Group';
import { FileUploadResult } from 'constants/Firebase';
import { intersectFilesUpload } from 'constants/Util';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import formatGroupData from 'screens/roster/groups/data/migration';
import { formatGroupsValuesList } from 'screens/roster/groups/schemas/formatGroupValues';

import { GroupAPI } from '../GroupAPI';

import useFirebaseCodeAPI from './firebaseCodeAPI';
import useFirebaseFileAPI from './firebaseFileAPI';
import updatedDependentFields from './updatedDependentFields';

const API_PATH_GROUPS = 'groups';

const useFirebaseGroupAPI = (): GroupAPI => {
  const firestore = useFirestore();
  const { uploadBatch } = useFirebaseFileAPI();
  const { addCode, deleteCode } = useFirebaseCodeAPI();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const groups: Group[] = useSelector((state: any) => {
    const data = state?.firestore?.data;
    let result: Group[] = [];

    if (data) {
      const { groups, employees, assets } = data;
      result = formatGroupsValuesList(groups, employees, assets);
    }

    return result;
  });

  const brokers: any = groups?.filter((group: Group) => group.type === GroupType.BROKER);

  const defualtGroupAddEvent = {
    timestamp: Date.now(),
    status: GroupStatus.PENDING,
    user: defaultCompany?.employeeId,
    action: GroupEventAction.ADD,
  };

  const add = (path: string, data: any) => firestore.collection(path).add(formatGroupData(data));

  const update = (path: string, data: any) => firestore.doc(path).update(formatGroupData(data));

  const remove = (id: string) => firestore.doc(id).delete();

  const addGroup = (
    data: any = {},
    company: any = defaultCompany,
    event: any = defualtGroupAddEvent
  ) =>
    add(`company/${company.companyId}/groups`, {
      ...data,
      events: {
        [`${Date.now()}`]: event,
      },
    });

  const updateGroup = (
    id: string,
    data: any,
    company: any = defaultCompany,
    event: any = defualtGroupAddEvent
  ) =>
    update(`company/${company.companyId}/groups/${id}`, {
      ...data,
      events: {
        ...data.events,
        [`${Date.now()}`]: event,
      },
    });

  const addGroupEvent = (event: any, companyId: string = defaultCompany.companyId) =>
    add(`company/${companyId}/groupsEvents`, event);

  const removeGroup = (id: string, companyId: string) =>
    remove(`company/${companyId}/groups/${id}`);

  const onRemoveGroup = (group: Group, company: any = defaultCompany) =>
    removeGroup(group.id, company.companyId).then(() => {
      updatedDependentFields('group', group, {}, firestore, defaultCompany);

      addGroupEvent({
        [`${Date.now()}`]: {
          timestamp: Date.now(),
          user: company.employeeId,
          action: GroupEventAction.REMOVE,
          target: group.id,
        },
      });
    });

  const onEditGroup = (
    { files, ...rest }: Group,
    initialValues: Group | undefined = undefined,
    company: any = defaultCompany
  ) =>
    uploadBatch(files, rest.id, API_PATH_GROUPS).then((promiseResults: FileUploadResult[]) =>
      updateGroup(
        rest.id,
        {
          ...rest,
          files: intersectFilesUpload(promiseResults, files),
        },
        company
      ).then(() => updatedDependentFields('group', initialValues, rest, firestore, defaultCompany))
    );

  const onAddGroup = (
    { files, ...rest }: Group,
    initialValues: Group | undefined = undefined,
    company: any = defaultCompany
  ) =>
    addGroup({}, company).then((createResult: any) =>
      addCode({
        companyId: company.companyId,
        groupId: createResult.id,
        roles: rest?.roles || {},
        createdAt: Date.now(),
      }).then((codeResult: any) =>
        uploadBatch(files, createResult.id, API_PATH_GROUPS).then((promiseResults: FileUploadResult[]) =>
          updateGroup(createResult.id, {
            ...rest,
            files: intersectFilesUpload(promiseResults, files),
            id: createResult.id,
            code: codeResult.id,
          }).then(() =>
            addGroupEvent({
              [`${Date.now()}`]: {
                timestamp: Date.now(),
                user: company.employeeId,
                action: GroupEventAction.ADD,
                target: createResult.id,
              },
            }).then(() => updatedDependentFields('group', initialValues, rest, firestore, defaultCompany))
          )
        )
      )
    );

  const resetGroupCode = (id: string, codeId: string) =>
    deleteCode(codeId).then(() =>
      addCode({
        companyId: defaultCompany.companyId,
        groupId: id,
        createdAt: Date.now(),
      }).then((codeResult: any) => updateGroup(id, { code: codeResult.id }))
    );

  return {
    onAddGroup,
    onEditGroup,
    onRemoveGroup,
    addGroupEvent,
    resetGroupCode,
    groups,
    brokers,
  };
};

export default useFirebaseGroupAPI;
