import { Group } from 'constants/Group';
import { formatFiles } from 'constants/Util';

import { GroupDocumentType } from '../schemas/groupsConfig';

export const GroupVersion: number = 1;

const formatGroupV1 = (data: any): Group => ({
  id: data?.id || null,
  companyName: data?.companyName || null,
  type: data?.type || null,
  roles: data?.roles || null,
  contact: data?.contact || null,
  address: data?.address || null,
  status: data?.status || null,
  members:
    data?.members?.map((member: any) => ({
      id: member.id,
    })) || null,
  assets:
    data?.assets?.map((asset: any) => ({
      id: asset.id,
    })) || null,
  authority: data?.authority || null,
  version: GroupVersion,
  files: formatFiles(GroupDocumentType, data?.files),
  code: data?.code || null,
  banking: data?.banking || null,
});

const formatGroupData = (data: any): Group => {
  const version = data?.version || GroupVersion;

  switch (version) {
    case 1: {
      return formatGroupV1(data);
    }

    default: {
      return formatGroupV1(data);
    }
  }
};

export const formatGroupsData = (data: any): Group[] =>
  Object.values(data).map((value: any) => formatGroupData(value));

export default formatGroupData;
