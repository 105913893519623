import { Service, ServiceEventAction, ServiceStatus } from 'constants/Service';
import { FileUploadResult } from 'constants/Firebase';
import { formatFiles, intersectFilesUpload } from 'constants/Util';

import { ExpenseType } from 'constants/Expense';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import formatServiceData from 'screens/fleet/service/migration';

import { formatServicesValuesList } from 'screens/fleet/service/formatService';

import { addedDiff, deletedDiff, updatedDiff } from 'deep-object-diff';

import { ServiceDocumentType } from 'screens/fleet/service/configService';

import { ServiceAPI } from '../ServiceAPI';

import useFirebaseFileAPI from './firebaseFileAPI';
import updatedDependentFields from './updatedDependentFields';

import useFirebaseExpenseAPI from './firebaseExpenseAPI';

import { firestoreAdd, firestoreRemove, firestoreUpdate } from '.';

const API_PATH_SERVICES = 'services';

const useFirebaseServiceAPI = (): ServiceAPI => {
  const firestore = useFirestore();
  const { uploadBatch } = useFirebaseFileAPI();
  const { onRemoveExpense } = useFirebaseExpenseAPI();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const services: Service[] = useSelector((state: any) => {
    const data = state?.firestore?.data;
    let result: Service[] = [];

    if (data) {
      const { services, groups, assets, expenses } = data;
      result = formatServicesValuesList(services, groups, assets, expenses);
    }

    return result;
  });

  const defualtServiceAddEvent = {
    timestamp: Date.now(),
    status: ServiceStatus.SCHEDULED,
    user: defaultCompany?.employeeId,
    action: ServiceEventAction.ADD,
  };

  const addService = (
    data: any = {},
    company: any = defaultCompany,
    event: any = defualtServiceAddEvent
  ) =>
    firestoreAdd(
      firestore,
      `company/${company.companyId}/${API_PATH_SERVICES}`,
      formatServiceData({
        ...data,
        events: {
          [`${Date.now()}`]: event,
        },
      })
    );

  const updateService = (
    id: string,
    data: any,
    company: any = defaultCompany,
    event: any = defualtServiceAddEvent
  ) =>
    firestoreUpdate(
      firestore,
      `company/${company.companyId}/${API_PATH_SERVICES}/${id}`,
      formatServiceData({
        ...data,
        events: {
          ...data.events,
          [event.timestamp]: event,
        },
      })
    );

  const addServiceEvent = (event: any, companyId: string = defaultCompany.companyId) =>
    firestoreAdd(firestore, `company/${companyId}/${API_PATH_SERVICES}Events`, event);

  const removeService = (id: string, companyId: string) =>
    firestoreRemove(firestore, `company/${companyId}/${API_PATH_SERVICES}/${id}`);

  const onRemoveService = (service: Service, company: any = defaultCompany) =>
    removeService(service.id, company.companyId).then(() =>
      updatedDependentFields('service', service, {}, firestore, defaultCompany).then(() =>
        addServiceEvent({
          [`${Date.now()}`]: {
            timestamp: Date.now(),
            user: company.employeeId,
            action: ServiceEventAction.REMOVE,
            target: service.id,
            source: formatServiceData({
              ...service,
              files: null,
            }),
          },
        }).then(() => {
          try {
            console.log('FIRESTORE:REMOVE', service?.expense?.id);
            service?.expense?.id && onRemoveExpense({ id: service.expense.id });
          } catch (error) {
            console.error('FIRESTORE:REMOVE error', service?.expense?.id, error);
          }
        })
      )
    );

  const onEditService = (
    { files, ...rest }: Service,
    initialValues: Service | undefined = undefined,
    company: any = defaultCompany
  ) =>
    uploadBatch(files, rest.id, API_PATH_SERVICES)
      .then((promiseResults: FileUploadResult[]) => {
        const fileUploadedService = {
          ...rest,
          files: intersectFilesUpload(promiseResults, files),
        };

        return updateService(rest.id, fileUploadedService, company, {
          timestamp: Date.now(),
          status: rest.status,
          user: company.employeeId,
          action: ServiceEventAction.EDIT,
          added: addedDiff(
            formatServiceData(initialValues) || {},
            formatServiceData(fileUploadedService)
          ),
          updated: updatedDiff(
            formatServiceData(initialValues) || {},
            formatServiceData(fileUploadedService)
          ),
          removed: deletedDiff(
            formatServiceData(initialValues) || {},
            formatServiceData(fileUploadedService)
          ),
        });
      })
      .then(() =>
        updatedDependentFields(
          API_PATH_SERVICES,
          initialValues,
          { ...rest, files },
          firestore,
          defaultCompany,
          uploadBatch
        )
      );

  const onAddService = (
    { files, ...rest }: Service,
    initialValues: Service | undefined = undefined,
    company: any = defaultCompany
  ) =>
    addService({}, company).then((addServiceResult: any) =>
      firestoreAdd(firestore, `company/${company.companyId}/expenses`, {
        type: ExpenseType.SERVICE,
      }).then((addExpenseResult: any) => {
        return uploadBatch(files, addServiceResult.id, API_PATH_SERVICES).then(
          (promiseResults: FileUploadResult[]) => {
            const updatedService = {
              ...rest,
              expense: {
                id: addExpenseResult.id,
              },
              files: intersectFilesUpload(promiseResults, files),
              id: addServiceResult.id,
            };

            return updateService(addServiceResult.id, updatedService)
              .then(() =>
                updatedDependentFields(
                  API_PATH_SERVICES,
                  initialValues,
                  { ...updatedService, files },
                  firestore,
                  defaultCompany,
                  uploadBatch
                )
              )
              .then(() =>
                addServiceEvent({
                  [`${Date.now()}`]: {
                    timestamp: Date.now(),
                    user: company.employeeId,
                    action: ServiceEventAction.ADD,
                    target: addServiceResult.id,
                    source: formatServiceData({
                      ...rest,
                      files: formatFiles(ServiceDocumentType, files),
                      id: addServiceResult.id,
                    }),
                  },
                })
              );
          }
        );
      })
    );

  return {
    onAddService,
    onEditService,
    onRemoveService,
    services,
  };
};

export default useFirebaseServiceAPI;
