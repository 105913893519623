import { AccessoryProviders, AccessoryStatus, AccessoryType } from 'constants/Accessory';
import { Document, DocumentConfig } from 'constants/Document';
import { FieldType, FormConfig, FormType } from 'constants/form/Form';
import { generateFilesTab } from 'constants/form/formConfig';

import { formatUnitSelect } from 'screens/dispatch/board/schemas/unit/formatUnitValues';

import { formatGroupSelect } from '../groups/schemas/formatGroupValues';

const ConfigName = 'Accessory';

export enum AccessoryDocumentType {
  Picture = 'Picture',
  Misc = 'Misc',
}

export const AccessoryPicture: Document = {
  name: AccessoryDocumentType.Picture,
  type: AccessoryDocumentType.Picture,
  role: {
    requiredFor: [],
  },
  version: 'New',
};

export const AccessoryMisc: Document = {
  name: AccessoryDocumentType.Misc,
  issueTimestamp: 0,
  id: '',
  type: AccessoryDocumentType.Misc,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [],
  },
  version: 'New',
};

export const AccessoryDocumentConfig: DocumentConfig = {
  [AccessoryDocumentType.Picture]: AccessoryPicture,
  [AccessoryDocumentType.Misc]: AccessoryMisc,
};

export const getExtendedAccessorysFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(AccessoryType),
            composition: {
              weight: 6,
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) =>
                  source.type && setFieldValue('info.provider', undefined),
              },
            },
          },
          {
            name: 'info.provider',
            label: 'Provider',
            type: FieldType.SELECT,
            options: Object.values(
              AccessoryProviders[
                (values?.type as keyof typeof AccessoryProviders) || AccessoryType.OTHER
              ]
            ),
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'accessoryId',
            label: 'Accessory Id',
            type: FieldType.TEXT,
            composition: {
              weight: 12,
            },
            validation: {
              errorMsg: 'Accessory Id is required.',
            },
          },
        ],
        [
          {
            name: 'notes',
            label: 'Notes',
            type: FieldType.TEXT,
          },
        ],
      ],
    },
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName} Setup`,
      content: [
        [
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(AccessoryStatus),
          },
        ],
        [
          {
            name: 'unit',
            label: 'Unit',
            type: FieldType.SELECT,
            options: values?.units?.map((unit: any) => formatUnitSelect(unit)),
            select: {
              infoArray: [
                {
                  icon: 'inventory_2',
                  color: 'warning',
                  note: 'Unit is already assigned',
                  show: (unitId: string | undefined, load: any | undefined) => {
                    const unit = values?.units?.filter((unit: any) => unit.id === unitId)?.[0];

                    return unit?.load?.id && unit?.load?.id !== load?.id;
                  },
                },
              ],
            },
            control: {
              editable: 'showUnitForm',
              addable: 'showUnitForm',
            },
          },
        ],
        [
          {
            name: 'groups',
            label: 'Groups',
            multiple: true,
            type: FieldType.SELECT,
            // Object.values??
            options: Object.values(values?.groups).map((group: any) => formatGroupSelect(group)),
            control: {
              editable: 'showGroupForm',
              addable: 'showGroupForm',
            },
          },
        ],
      ],
    },
    Files: generateFilesTab(ConfigName, AccessoryDocumentConfig, values),
  } as FormConfig);

export const getSimplifiedAccessorysFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(AccessoryType),
            composition: {
              weight: 6,
            },
            control: {
              onChange: {
                source: (value: any) => value,
                action: (target: any, source: any, setFieldValue: any = null) =>
                  setFieldValue('info.provider', undefined),
              },
            },
          },
          {
            name: 'info.provider',
            label: 'Provider',
            type: FieldType.SELECT,
            options: AccessoryProviders[values?.type as keyof typeof AccessoryProviders] || [],
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'accessoryId',
            label: 'Accessory Id',
            type: FieldType.TEXT,
            composition: {
              weight: 12,
            },
            validation: {
              errorMsg: 'Accessory Id is required.',
            },
          },
        ],
        [
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(AccessoryStatus),
          },
        ],
        [
          {
            name: 'unit',
            label: 'Unit',
            type: FieldType.SELECT,
            options: values?.units.map((unit: any) => formatUnitSelect(unit)),
            select: {
              infoArray: [
                {
                  icon: 'inventory_2',
                  color: 'warning',
                  note: 'Unit is already assigned',
                  show: (unitId: string | undefined, load: any | undefined) => {
                    const unit = values?.units?.filter((unit: any) => unit.id === unitId)?.[0];

                    return unit?.load?.id && unit?.load?.id !== load?.id;
                  },
                },
              ],
            },
            control: {
              editable: 'showUnitForm',
              addable: 'showUnitForm',
            },
          },
        ],
        [
          {
            name: 'notes',
            label: 'Notes',
            type: FieldType.TEXT,
          },
        ],
      ],
    },
  } as FormConfig);

const getAccessorysFormConfig = (values: string, extended: boolean) =>
  extended ? getExtendedAccessorysFormConfig(values) : getSimplifiedAccessorysFormConfig(values);

export const accessoryFormSetup = (id: number, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getAccessorysFormConfig,
});
