import { Expense, Payment } from 'constants/Expense';
import { formatFiles } from 'constants/Util';

import { ExpenseDocumentType } from './configExpenses';

export const ExpenseVersion: number = 1;

export const formatPayment = (payments: any): Payment[] =>
  payments.map((payment: any) => ({
    paidBy: payment.paidBy
      ? { type: payment.paidBy.type || null, transactor: payment.paidBy.transactor || null }
      : null,
    payTo: payment.payTo
      ? { type: payment.payTo.type || null, transactor: payment.payTo.transactor || null }
      : null,
    type: payment.type || null,
    paymentDate: payment.paymentDate || null,
    status: payment.status || null,
    reference: payment.reference || null,
    amount: payment.amount || null,
  }));

const formatExpenseV1 = (data: any): Expense => ({
  id: data?.id || null,
  type: data?.type || null,
  status: data?.status || null,
  notes: data?.notes || null,
  billTo: data?.billTo
    ? {
        type: data?.billTo?.type || null,
        transactor: data?.billTo?.transactor ? { id: data?.billTo?.transactor.id } : null,
      }
    : null,
  paidBy: data?.paidBy
    ? {
        type: data?.paidBy?.type || null,
        transactor: data?.paidBy?.transactor ? { id: data?.paidBy?.transactor.id } : null,
      }
    : null,
  payTo: data?.payTo
    ? {
        type: data?.payTo?.type || null,
        transactor: data?.payTo?.transactor ? { id: data?.payTo?.transactor.id } : null,
      }
    : null,
  payments: data?.payments ? formatPayment(data?.payments) : [],
  source: data?.source ? { id: data?.source.id } : null,
  reporter: data?.reporter || null,
  events: data?.events || null,
  version: ExpenseVersion,
  files: formatFiles(ExpenseDocumentType, data?.files),
});

const formatExpenseData = (data: any): Expense => {
  const version = data?.version || ExpenseVersion;

  switch (version) {
    case 1: {
      return formatExpenseV1(data);
    }

    default: {
      return formatExpenseV1(data);
    }
  }
};

export const formatExpenses = (expenses?: any): { [key: string]: Expense[] } | null => {
  if (!expenses || !Object.keys(expenses).length) {
    return null;
  }

  const result: { [key: string]: Expense[] } = {};

  Object.keys(expenses).forEach((key: any) => {
    result[key] = expenses[key]?.length
      ? expenses[key].map((Expense: Expense) => ({ id: Expense.id }))
      : null;
  });

  return result;
};

export const formatExpensesData = (data: any): Expense[] =>
  Object.values(data).map((value: any) => formatExpenseData(value));

export default formatExpenseData;
