/* eslint-disable no-return-assign */
import { Document, DocumentConfig, DocumentRoleType } from 'constants/Document';

import { GroupDocumentType } from 'screens/roster/groups/schemas/groupsConfig';
import { EmployeeDocumentType } from 'screens/roster/employees/schemas/employeeConfig';
import { LoadDocumentType } from 'screens/freight/loads/schemas/loadsConfig';
import { isArray, isEqual, isObject, transform } from 'lodash';
import { AssetDocumentType } from 'screens/roster/assets/configAsset';

import { AccessoryDocumentType } from 'screens/roster/accessories/configAccessory';

import { ExpenseDocumentType } from 'screens/accounting/expenses/configExpenses';

import { ServiceDocumentType } from 'screens/fleet/service/configService';

import { AccidentDocumentType } from 'screens/compliance/accidents/configAccident';

import { FileUploadResult } from './Firebase';

const showFileTab = (file: Document, roles: any[], key: DocumentRoleType) => {
  return roles.length
    ? roles.some((role) => !file?.role?.[key] || file?.role?.[key]?.includes(role))
    : !file?.role?.[key];
};

export const filterFilesByTypes = (files: DocumentConfig, types: string[], key: DocumentRoleType) =>
  Object.keys(files).filter((type) => showFileTab(files[type], types, key));

export const filterFilesByRoles = (files: DocumentConfig, roles: any[], key: DocumentRoleType) =>
  Object.keys(files).filter((type) => showFileTab(files[type], Object.keys(roles), key));

export const filterFilesByStatus = (
  files: DocumentConfig,
  status: string[],
  key: DocumentRoleType
) => Object.keys(files).filter((type) => showFileTab(files[type], status, key));

// TODO imort DocumentType from Document.tsx instead of EmployeeDocumentType | GroupDocumentType
export const formatFiles = (
  filesType:
    | typeof GroupDocumentType
    | typeof EmployeeDocumentType
    | typeof LoadDocumentType
    | typeof AssetDocumentType
    | typeof AccessoryDocumentType
    | typeof ExpenseDocumentType
    | typeof ServiceDocumentType
    | typeof ServiceDocumentType
    | typeof AccidentDocumentType,
  files: { [key: string]: { [key: string]: Document } }
) => {
  const fileNames: string[] = Object.keys(filesType);

  if (!files) {
    // eslint-disable-next-line no-param-reassign
    return {};
  }

  let formatedFiles: { [key: string]: { [key: string]: Document } } = {};

  fileNames.forEach((fileName) => {
    const file = files[fileName];

    if (file) {
      // Remove file content before uploading to Firestore
      Object.keys(file).forEach((version) => {
        if (file[version]?.file?.content) {
          delete file[version]?.file?.content;
        }
      });

      formatedFiles = { ...formatedFiles, [fileName]: file };
    }
  });

  return formatedFiles;
};

// Populate files with corresponding download url link
export const intersectFilesUpload = (promiseResults: FileUploadResult[], files: any) => {
  promiseResults.forEach((result: FileUploadResult) => {
    console.log('intersectFilesUpload', result, files);

    // eslint-disable-next-line no-param-reassign
    let { file } = files[result.key][result.version];

    if (file) {
      file.url = result.url;
    } else {
      file = { url: result.url };
    }
  });

  return files;
};

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export function difference(origObj: any, newObj: any): object {
  function changes(newObj: any, origObj: any) {
    let arrayIndexCounter = 0;

    return transform(newObj, (result: any, value: string, key: any) => {
      if (!isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? (arrayIndexCounter += 1) : key;

        // eslint-disable-next-line no-param-reassign
        result[resultKey] =
          isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value;
      }
    });
  }

  return changes(newObj, origObj);
}

export const cleanDataObject = (obj: any): any =>
  Object.entries(obj)
    .map(([k, v]: any) => [k, v && typeof v === 'object' ? cleanDataObject(v) : v])
    // eslint-disable-next-line no-param-reassign
    .reduce((a: any, [k, v]: any) => (v === null ? a : ((a[k] = v), a)), {});
