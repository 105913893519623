import { Document } from '../constants/Document';

export enum AccessoryType {
  TRANSPONDER = 'Transponder',
  BYPASS = 'Bypass',
  FUEL_CARD = 'Fuel Card',
  CAMERA = 'Camera',
  TABLET = 'Tablet',
  ELD = 'ELD',
  LOAD_ACCESSORY = 'Load Accessory',
  OTHER = 'Other',
}

export enum AccessoryStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  INACTIVE = 'Inactive',
  DAMAGED = 'Damaged',
  LOST = 'Lost',
  STOLEN = 'Stolen',
  DELETED = 'Deleted',
  HIDDEN = 'Hidden',
}

export enum AccessoryEventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

export enum EldProviders {
  KEEP_TRUCKIN = 'KeepTruckin',
  SAMSARA = 'Samsara',
  OMNITRACS = 'Omnitracs',
  GEO_TAB = 'Geotab',
  TRACK_ENSURE = 'TrackEnsure',
  TT_ELD = 'TT ELD',
  OTHER = 'Other',
}

export enum FuelCardProviders {
  COMDATA = 'Comdata',
  EFS = 'EFS',
  FUEL_MASTERS = 'Fuel Masters',
  TCS = 'TCS',
  TCH = 'TCH',
  WEX = 'WEX',
  OTHER = 'Other',
}

export enum CameraProviders {
  SAMSARA = 'Samsara',
  VERIZON = 'Verizon',
  SMART_DRIVE = 'SmartDrive',
  DRIVE_CAM = 'DriveCam',
  GUARDIAN = 'Guardian',
  OTHER = 'Other',
}

export enum TabletProviders {
  VERIZON = 'Verizon',
  OMNITRACS = 'Omnitracs',
  SAMSUNG = 'Samsung',
  TLC = 'TLC',
  OTHER = 'Other',
}

export enum TollProviders {
  BESTPASS = 'Bestpass',
  EZ_PASS = 'EZ Pass',
  SUN_PASS = 'Sun Pass',
  FASTRAK = 'FasTrak',
  PIKE_PASS = 'Pike Pass',
  OTHER = 'Other',
}

export enum BypassProviders {
  PRE_PASS = 'PrePass',
  OTHER = 'Other',
}

export enum LoadAccessoryProviders {
  LOAD_BAR = 'Load Bar',
  STRAP = 'Strap',
  TARP = 'Tarp',
  CHAIN = 'Chain',
}

export const AccessoryProviders = {
  [AccessoryType.ELD]: Object.values(EldProviders),
  [AccessoryType.FUEL_CARD]: Object.values(FuelCardProviders),
  [AccessoryType.TRANSPONDER]: Object.values(TollProviders),
  [AccessoryType.BYPASS]: Object.values(BypassProviders),
  [AccessoryType.CAMERA]: Object.values(CameraProviders),
  [AccessoryType.TABLET]: Object.values(TabletProviders),
  [AccessoryType.LOAD_ACCESSORY]: Object.values(LoadAccessoryProviders),
  [AccessoryType.OTHER]: [],
};

export interface AccessoryEvent {
  timestamp: number;
  status: AccessoryStatus;
  note?: string;
  user?: string;
  source?: string;
  target?: Accessory;
  action: AccessoryEventAction;
}

export interface Accessory {
  id: string;
  type: AccessoryType;
  accessoryId: string;
  notes?: string;
  status: AccessoryStatus;
  version: number;
  unit: any;
  info: {
    price: number;
    provider?: string;
    model?: string;
    serialNumber?: string;
  };
  expense: any;
  groups?: any;
  files: { [key: string]: { [key: string]: Document } };
}
