import { Grid } from '@mui/material';
import MDTypography from 'components/common/MDTypography';
import { useEffect } from 'react';
import MDBadgeDot from 'components/common/MDBadgeDot';
import { useDispatch, useSelector } from 'react-redux';
import { setETA } from 'redux/slices/unitsSlice';
import useMapboxAPI from 'services/map/MapboxAPI';
import moment from 'moment';

const statusColor = (timestamp: number | undefined) => {
  if (!timestamp) {
    return 'error';
  }

  const delay = new Date().getTime() - timestamp;

  if (delay < 60 * 1000) {
    return 'success';
  }

  if (delay < 15 * 60 * 1000) {
    return 'warning';
  }

  return 'info';
};

const getDistanceFlat = (currentCoordinates: any, nextCoordinate: any) =>
  Math.sqrt(
    (currentCoordinates.lat - nextCoordinate.lat) ** 2 +
      (currentCoordinates.lng - nextCoordinate.lng) ** 2
  );

const updateMilage = (eta: any, lastUnitInfo: any, nextStop: any) => {
  let distance = Number(eta.distance);
  const lastDistanceFlat = getDistanceFlat(
    lastUnitInfo?.location?.coordinates,
    nextStop?.location?.coordinates
  );

  if (eta.distanceFlat >= lastDistanceFlat) {
    distance -=
      lastUnitInfo.odometr && eta.odometr ? Number(lastUnitInfo.odometr - eta.odometr) : 0;
  } else {
    distance +=
      lastUnitInfo.odometr && eta.odometr ? Number(lastUnitInfo.odometr - eta.odometr) : 0;
  }

  return distance.toFixed(0);
};

const getSpeed = ({ speed, timestamp }: any) =>
  speed !== undefined &&
  !Number.isNaN(timestamp) && (
    <Grid container direction="row" alignItems="center">
      <MDBadgeDot size="md" badgeContent="" sx={{ p: 0 }} color={statusColor(timestamp)} />
      <MDTypography variant="overline" color="text" lineHeight="inherit">
        {`${speed} mph`}
      </MDTypography>
    </Grid>
  );

function UnitETACell({ unitId, nextStop }: any) {
  const dispatcher = useDispatch();
  const lastUnitInfo = useSelector((state: any) => state?.units?.lastUnitInfo?.[unitId]);
  const eta = useSelector((state: any) => state?.units?.eta[unitId]);

  const fetchDirections = (currentCoordinates: any, nextCoordinate: any) => {
    if (currentCoordinates && nextCoordinate) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMapboxAPI()
        .directions([currentCoordinates, nextCoordinate])
        .then((result) => {
          if (result.code === 'Ok') {
            const eta = {
              id: unitId,
              eta: {
                duration: result.routes[0].duration * 1000,
                distance: (result.routes[0].distance / 1609.344).toFixed(1),
                distanceFlat: getDistanceFlat(currentCoordinates, nextCoordinate),
                durationTypical: result.routes[0].duration_typical * 1000,
                timestamp: new Date().getTime(),
                odometr: lastUnitInfo.odometr,
              },
            };

            dispatcher(setETA(eta));
          }
        });
    }
  };

  // 0 - 15 ETA update every 5 minutes
  // 15 - 60 ETA update every 15 minutes
  // 60 - 180 ETA update every 30 minutes
  // 180 - 360 ETA update every 60 minutes
  // 360 - 720 ETA update every 120 minutes
  // 720 - 1260 ETA update every 180 minute
  // etc
  const shouldUpdateETA = (eta: any) => {
    if (!eta) {
      return true;
    }

    let delay = 360 * 60 * 1000;

    if (eta.durationTypical <= 15 * 60 * 1000) {
      delay = 5 * 60 * 1000;
    } else if (eta.durationTypical <= 60 * 60 * 1000) {
      delay = 15 * 60 * 1000;
    } else if (eta.durationTypical <= 180 * 60 * 1000) {
      delay = 30 * 60 * 1000;
    } else if (eta.durationTypical <= 360 * 60 * 1000) {
      delay = 60 * 60 * 1000;
    } else if (eta.durationTypical <= 720 * 60 * 1000) {
      delay = 120 * 60 * 1000;
    } else if (eta.durationTypical <= 1260 * 60 * 1000) {
      delay = 180 * 60 * 1000;
    }

    return new Date().getTime() > eta.timestamp + delay;
  };

  useEffect(() => {
    if (shouldUpdateETA(eta)) {
      fetchDirections(lastUnitInfo?.location?.coordinates, nextStop?.location?.coordinates);
    }
  }, [lastUnitInfo?.location?.coordinates, nextStop?.location?.coordinates]);

  return (
    <Grid container item direction="column" justifyContent="center" sx={{ minWidth: '7rem' }}>
      <MDTypography variant="overline" color="text" fontWeight="bold" lineHeight="inherit">
        {(eta && lastUnitInfo && nextStop) ? `${updateMilage(eta, lastUnitInfo, nextStop)} miles` : ''}
      </MDTypography>
      <MDTypography variant="overline" color="text" lineHeight="inherit">
        {(eta && lastUnitInfo && nextStop)
          ? `ETA ${moment()
              .add(eta.duration / 1000, 'seconds')
              .format('MM/DD HH:mm')}`
          : ''}
      </MDTypography>
      {lastUnitInfo && getSpeed(lastUnitInfo)}
    </Grid>
  );
}

export default UnitETACell;
