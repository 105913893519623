import { AccidentStatus } from 'constants/Accident';

import { formatUnitSelect } from 'screens/dispatch/board/schemas/unit/formatUnitValues';
import { getUnit } from 'screens/freight/loads/schemas/formatLoadValues';

import formatAccidentData, {
  AccidentVersion,
} from 'screens/compliance/accidents/migrationAccident';
import { formatDriverSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';
import { defaultParticipant } from 'components/BaseForm/sections/ParticipantSection';

const formatAccidentValues = (values: any) => ({
  id: values.id || '',
  type: values?.type || null,
  status: values?.status || AccidentStatus.OPEN,
  unit: values?.unit ? formatUnitSelect(values?.unit) : null,
  drivers: values?.drivers && values.drivers.map((driver: any) => formatDriverSelect(driver)),
  location: values?.location || null,
  citation: values?.citation || null,
  accidentDate: values?.accidentDate || Date.now(),
  participants: values?.participants?.length ? values?.participants : [defaultParticipant(values)],
  notes: values?.notes || null,
  version: values.version || AccidentVersion,
  initialTabs: values?.initialTabs || {},
  files: values?.files || {},
});

export const formatAccidentsValuesList = (
  accidents: any,
  assets: any,
  employees: any,
  units: any
) =>
  accidents
    ? Object.values(accidents)
        .filter((accident) => accident)
        .map((accident: any) => {
          const unit =
            units &&
            accident &&
            employees &&
            accident?.unit?.id &&
            getUnit(accident?.unit?.id, units, assets, employees);

          return formatAccidentValues({
            ...formatAccidentData(accident),
            unit: unit,
            drivers: employees && accident?.drivers?.map((driver: any) => employees[driver.id]),
          });
        })
    : [];

export default formatAccidentValues;
