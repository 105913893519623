import { ExpenseStatus, ExpenseType, PartyType, TransactionParty } from 'constants/Expense';

import { formatFiles } from 'constants/Util';

import { RoleType } from 'constants/Roles';

import formatExpenseData, {
  ExpenseVersion,
  formatPayment,
} from 'screens/accounting/expenses/migration';

import { formatBrokerSelect } from 'screens/roster/groups/schemas/formatGroupValues';
import { formatDriverSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';

import { formatAccessorySelect } from 'screens/roster/accessories/formatAccessory';

import { ExpenseDocumentType } from './configExpenses';

export const formatTransactor = (type: PartyType | null, member: any) => {
  switch (type) {
    case PartyType.BROKER:
      return member && formatBrokerSelect(member);
    case PartyType.DRIVER:
      return member && formatDriverSelect(member);
    case PartyType.CARRIER:
    default:
      return null;
  }
};

const getSourceSelect = (type: ExpenseType, accessoryId: string, values: any) => {
  switch (type) {
    case ExpenseType.ACCESSORY:
      return (
        values?.accessories?.[accessoryId] && formatAccessorySelect(values.accessories[accessoryId])
      );
    default:
      return null;
  }
};

const formatExpenseValues = (values: any) => ({
  id: values?.id,
  type: values?.type,
  status: values?.status || ExpenseStatus.PENDING,
  notes: values?.notes,
  billTo: values?.billTo,
  paidBy: values?.paidBy,
  payTo: values?.payTo,
  payments: values?.payments ? formatPayment(values?.payments) : [],
  source: values?.source,
  reporter: values?.reporter,
  version: values?.version || ExpenseVersion,
  files: formatFiles(ExpenseDocumentType, values?.files),
});

export const formatExpenseSelect = (expense: any) => ({
  ...expense,
  name: `${expense.files?.Invoice?.[0]?.invoiceId} | ${expense.type}${
    expense?.info?.provider ? ` | ${expense?.info?.provider}` : ''
  }`,
});

export const getExpenseParty = (
  party: TransactionParty,
  groups: any,
  employees: any
): TransactionParty => {
  const member =
    party?.type === PartyType.BROKER
      ? groups?.[party?.transactor?.id]
      : party?.type === PartyType.DRIVER
      ? employees?.[party?.transactor?.id]
      : null;

  return {
    type: party?.type,
    transactor: formatTransactor(party?.type, member),
  };
};

export const formatExpensesValuesList = (
  expenses: any,
  groups: any,
  employees: any,
  accessories: any,
  services: any
) => {
  const drivers =
    employees &&
    Object.values(employees)
      .filter(
        (employee: any) => employee && Object.keys(employee?.roles || {}).includes(RoleType.DRIVER)
      )
      .map((driver: any) => formatDriverSelect(driver));

  const brokers =
    groups &&
    Object.values(groups)
      .filter((group: any) => group && group?.type === PartyType.BROKER)
      .map((group: any) => formatBrokerSelect(group));

  return expenses
    ? Object.values(expenses)
        .filter((expense) => expense)
        .map((expense: any) => {
          return {
            ...formatExpenseValues({
              ...formatExpenseData(expense),
              billTo: groups && employees && getExpenseParty(expense?.billTo, groups, employees),
              paidBy: groups && employees && getExpenseParty(expense?.paidBy, groups, employees),
              payTo: groups && employees && getExpenseParty(expense?.payTo, groups, employees),
              source:
                services &&
                expense?.source?.id &&
                getSourceSelect(expense?.type, expense.source.id, { accessories, services }),
            }),
            drivers,
            brokers,
          };
        })
    : [];
};

export default formatExpenseValues;
