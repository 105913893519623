/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

// formik components
import { Form, Formik } from 'formik';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { Link } from 'react-router-dom';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/common/MDBox';
import MDButton from 'components/common/MDButton';
import MDTypography from 'components/common/MDTypography';

// NewUser page components
import UserInfo from 'screens/authentication/components/RegistrationCard/form/steps/UserInfo';
import Account from 'screens/authentication/components/RegistrationCard/form/steps/Account';
import Company from 'screens/authentication/components/RegistrationCard/form/steps/Company';
import Roles from 'screens/authentication/components/RegistrationCard/form/steps/Roles';

// NewUser layout schemas for form and form feilds
import validations from 'screens/authentication/components/RegistrationCard/form/schemas/validations';
import form from 'screens/authentication/components/RegistrationCard/form/schemas/form';
import initialValues from 'screens/authentication/components/RegistrationCard/form/schemas/initialValues';

import ROUTES from 'navigation/const';

function getSteps(): string[] {
  return ['User Info', 'Account', 'Company', 'Roles'];
}

function getStepContent(stepIndex: number, formData: any): JSX.Element {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} />;
    case 1:
      return <Account formData={formData} />;
    case 2:
      return <Company formData={formData} />;
    case 3:
      return <Roles formData={formData} />;
    default:
      return <UserInfo formData={formData} />;
  }
}

function RegistrationCard(props: any): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const { showSigninFlow } = props;

  const sleep = (ms: any) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values: any, actions: any) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values: any, actions: any) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <MDBox py={3} height="80vh">
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%', mt: 8 }}>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card sx={{ height: '100%' }}>
                  <MDBox mx={2} mt={-3}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <MDBox>
                            <MDTypography variant="button" color="text">
                              Already have an account?{' '}
                              <MDTypography
                                component={Link}
                                onClick={showSigninFlow}
                                to={ROUTES.AUTHENTICATION}
                                variant="button"
                                color="info"
                                fontWeight="medium"
                                textGradient
                              >
                                Sign in
                              </MDTypography>
                            </MDTypography>
                          </MDBox>
                        ) : (
                          <MDButton variant="gradient" color="light" onClick={handleBack}>
                            back
                          </MDButton>
                        )}
                        <MDButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="dark"
                        >
                          {isLastStep ? 'send' : 'next'}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default RegistrationCard;
