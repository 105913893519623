import { AccidentStatus, AccidentType } from 'constants/Accident';
import { Document, DocumentConfig } from 'constants/Document';
import { FieldType, FormConfig, FormType, usStates } from 'constants/form/Form';
import { generateFilesTab, generateTypeTab } from 'constants/form/formConfig';

import { zipRegExp } from 'components/BaseForm/Utils';

import { formatUnitSelect } from 'screens/dispatch/board/schemas/unit/formatUnitValues';
import { formatDriverSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';

const ConfigName = 'Accident';

export enum AccidentDocumentType {
  DriverStatement = 'Statement',
  PoliceReport = 'Report',
  Gallery = 'Gallery',
  Misc = 'Misc',
}

export const DriverStatement: Document = {
  name: AccidentDocumentType.DriverStatement,
  type: AccidentDocumentType.DriverStatement,
  extraFields: {
    issueTimestamp: {
      name: 'issueTimestamp',
      label: 'Date',
      type: FieldType.DATE,
    },
  },
  role: {
    requiredFor: [AccidentType.COLLISION, AccidentType.PROPERTY, AccidentType.INJURY],
  },
  version: 'New',
};

export const PoliceReport: Document = {
  name: AccidentDocumentType.PoliceReport,
  type: AccidentDocumentType.PoliceReport,
  id: '',
  extraFields: {
    issueTimestamp: {
      name: 'issueTimestamp',
      label: 'Date',
      type: FieldType.DATE,
    },
  },
  role: {
    requiredFor: [AccidentType.COLLISION, AccidentType.PROPERTY],
  },
  version: 'New',
};

export const AccidentGallery: Document = {
  name: AccidentDocumentType.Gallery,
  type: AccidentDocumentType.Gallery,
  role: {
    requiredFor: [],
  },
  version: 'New',
};

export const AccidentMisc: Document = {
  name: AccidentDocumentType.Misc,
  issueTimestamp: 0,
  id: '',
  type: AccidentDocumentType.Misc,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [],
  },
  version: 'New',
};

export const AccidentDocumentConfig: DocumentConfig = {
  [AccidentDocumentType.DriverStatement]: DriverStatement,
  [AccidentDocumentType.PoliceReport]: PoliceReport,
  [AccidentDocumentType.Gallery]: AccidentGallery,
  [AccidentDocumentType.Misc]: AccidentMisc,
};

const accidentTypeField = (values: any = null, weight: number = 12) => ({
  name: 'type',
  label: 'Type',
  type: FieldType.SELECT,
  options: Object.values(AccidentType),
  validation: {
    errorMsg: 'Type is required.',
  },
  compostion: {
    weight,
  },
});

const accidentReportIdField = (values: any = null, weight: number = 12) => ({
  name: 'files.Report.0.id',
  label: 'Report Id',
  type: FieldType.TEXT,
  compostion: {
    weight,
  },
});

const accidentStatusField = (values: any = null, weight: number = 12) => ({
  name: 'status',
  label: 'Status',
  type: FieldType.SELECT,
  options: Object.values(AccidentStatus),
});

const accidentLocationAddress1Field = (values: any = null, weight: number = 12) => ({
  name: 'location.address1',
  label: 'Address1',
  placeholder: 'Address1',
  type: FieldType.TEXT,
  validation: {
    errorMsg: 'Address is required.',
  },
});

const accidentLocationCityField = (values: any = null, weight: number = 12) => ({
  name: 'location.city',
  label: 'City',
  placeholder: 'City',
  type: FieldType.TEXT,
  validation: {
    errorMsg: 'City is required.',
  },
  composition: {
    weight: 6,
  },
});

const accidentLocationStateField = (values: any = null, weight: number = 12) => ({
  name: 'location.state',
  label: 'State',
  placeholder: 'State',
  type: FieldType.SELECT,
  options: usStates,
  validation: {
    errorMsg: 'State is required.',
    oneOf: usStates,
    invalidMsg: 'State is invalid.',
  },
  composition: {
    weight: 3,
  },
});

const accidentLocationZipField = (values: any = null, weight: number = 12) => ({
  name: 'location.zip',
  label: 'Zip',
  placeholder: 'Zip',
  type: FieldType.TEXT,
  validation: {
    errorMsg: 'Zip is required.',
    invalidMsg: 'Zip is not valid (e.g. 70000).',
    regex: zipRegExp,
  },
  composition: {
    weight: 3,
  },
});

const accidentNotesField = (values: any = null, weight: number = 12) => ({
  name: 'notes',
  label: 'Notes',
  type: FieldType.TEXT,
});

const accidentUnitField = (values: any = null, weight: number = 12) => ({
  name: 'unit',
  label: 'Unit',
  type: FieldType.SELECT,
  options: values?.units?.map((unit: any) => formatUnitSelect(unit)),
  select: {
    infoArray: [
      {
        icon: 'inventory_2',
        color: 'warning',
        note: 'Unit is already assigned',
        show: (unitId: string | undefined, load: any | undefined) => {
          const unit = values?.units?.filter((unit: any) => unit.id === unitId)?.[0];

          return unit?.load?.id && unit?.load?.id !== load?.id;
        },
      },
    ],
  },
  control: {
    editable: 'showUnitForm',
    addable: 'showUnitForm',
  },
});

const accidentDriverField = (values: any = null, weight: number = 12) => ({
  name: 'drivers',
  label: 'Drivers',
  type: FieldType.SELECT,
  multiple: true,
  options: values?.unit?.drivers?.map((driver: any) => formatDriverSelect(driver)) || [],
});

const generateReportFileField = (values: any) => {
  const result = (
    generateTypeTab('files', AccidentDocumentConfig, values)?.Report?.content as any
  )?.[0]?.content?.[0];

  return result || [];
};

export const getExtendedAccidentsFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        [accidentTypeField()],
        [accidentReportIdField()],
        [accidentLocationAddress1Field()],
        [accidentLocationCityField(), accidentLocationStateField(), accidentLocationZipField()],
        [accidentNotesField()],
      ],
    },
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName} Setup`,
      content: [
        [accidentStatusField()],
        [accidentUnitField(values)],
        [accidentDriverField(values)],
      ],
    },
    Parties: {
      type: FormType.PARTICIPANTS,
      label: 'Parties',
      title: `${ConfigName} Participants`,
      content: [[]],
    },
    Files: generateFilesTab(ConfigName, AccidentDocumentConfig, values),
  } as FormConfig);

export const getSimplifiedAccidentsFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        generateReportFileField(values),
        [accidentTypeField()],
        [accidentReportIdField()],
        [accidentStatusField()],
        [accidentUnitField(values)],
        [accidentLocationCityField(), accidentLocationStateField(), accidentLocationZipField()],
        [accidentNotesField()],
      ],
    },
  } as FormConfig);

const getAccidentsFormConfig = (values: string, extended: boolean) =>
  extended ? getExtendedAccidentsFormConfig(values) : getSimplifiedAccidentsFormConfig(values);

export const accidentFormSetup = (id: number, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getAccidentsFormConfig,
});
