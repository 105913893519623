import { Asset, AssetStatus } from 'constants/Asset';
import { Group } from 'constants/Group';

import { formatUnitSelect } from 'screens/dispatch/board/schemas/unit/formatUnitValues';
import { getUnit } from 'screens/freight/loads/schemas/formatLoadValues';

import formatAssetData, { AssetVersion } from 'screens/roster/assets/migration';

import { formatGroupSelect } from '../groups/schemas/formatGroupValues';

const formatAssetValues = (values: any) => ({
  unitId: values?.unitId,
  vin: values?.vin,
  year: values?.year,
  make: values?.make,
  type: values?.type,
  plate: values?.plate,
  state: values?.state,
  miles: values?.miles,
  color: values?.color,
  status: values?.status || AssetStatus.PENDING,
  unit: values?.unit ? formatUnitSelect(values?.unit) : undefined,
  files: values?.files || {},
  id: values.id || '',
  groups: values?.groups?.map((group: any) => formatGroupSelect(group)),
  version: values.version || AssetVersion,
  initialTabs: values?.initialTabs || {},
});

export const formatAssetSelect = (asset: Asset) => ({
  ...asset,
  name: `#${asset.unitId} ${asset.year} ${asset.make}`,
});

const formatAssetGroups = (assetGroups: Group[] | undefined, groups: Group[] | undefined) =>
  (assetGroups &&
    groups &&
    Object.values(assetGroups).map((employeeGroup: any) =>
      groups.find((group: any) => employeeGroup.id === group.id)
    )) ||
  [];

export const formatAssetsValuesList = (assets: any, units: any, employees: any, groups: any) =>
  assets
    ? Object.values(assets)
        .filter((asset) => asset)
        .map((asset: any) => {
          const assetUnit =
            units &&
            assets &&
            employees &&
            asset?.unit?.id &&
            getUnit(asset?.unit?.id, units, assets, employees);

          const assetGroups =
            groups && formatAssetGroups(asset?.groups, Object.values(groups));

          return formatAssetValues({
            ...formatAssetData(asset),
            unit: assetUnit,
            groups: assetGroups,
          });
        })
    : [];

export default formatAssetValues;
