import { LoadRouteStop } from 'constants/Load';

import { Grid, Icon, IconButton } from '@mui/material';
import MDBox from 'components/common/MDBox';
import MDTypography from 'components/common/MDTypography';
import { getStopAppointment } from 'utils/getDate';

const generateStopAddress = (stop: LoadRouteStop) => (
  <>
    {stop?.location?.name && (
      <MDTypography variant="overline" color="text" fontWeight="bold" lineHeight="inherit">
        {stop?.location?.name}
      </MDTypography>
    )}
    <MDTypography variant="overline" color="text" lineHeight="inherit">
      {stop?.location?.city ? `${stop?.location?.city} ` : ''}
      {stop?.location?.state ? `${stop?.location?.state}, ` : ''}
      {stop?.location?.zip ? `${stop?.location?.zip}` : ''}
    </MDTypography>
  </>
);

export function DataTableLoadCell({ stop }: any) {
  return (
    <Grid container item direction="column" justifyContent="center" sx={{ minWidth: '7rem' }}>
      {generateStopAddress(stop)}
      <MDTypography variant="overline" color="text" lineHeight="inherit">
        {getStopAppointment(stop)}
      </MDTypography>
    </Grid>
  );
}

function RouteButton({ actions: { edit } }: any): JSX.Element {
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minWidth: '4rem' }}>
      <Grid item>
        <IconButton disabled={edit === undefined} onClick={() => edit({ main: 'Route' })}>
          <Icon color="inherit">add_road</Icon>
        </IconButton>
      </Grid>
    </Grid>
  );
}

function DataTableRouteCell({ values, actions }: any): JSX.Element {
  const stops = values?.route?.stops;
  const firstStop: LoadRouteStop | null = stops?.length ? stops[0] : null;
  const lastStop: LoadRouteStop | null = stops?.length ? stops[stops.length - 1] : null;
  const extraStops = (stops?.length && stops.length - 2) || 0;

  return (
    <MDBox
      display="flex"
      flex-direction="row"
      justifyContent="space-between"
      sx={{ width: '23rem' }}
    >
      {firstStop ? (
        <DataTableLoadCell stop={firstStop} />
      ) : (
        <RouteButton values={values} actions={actions} />
      )}
      {(firstStop?.location || lastStop?.location) && (
        <MDBox
          display="flex"
          flex-direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: 'auto', p: 1 }}
          id="stops-arrow"
        >
          {firstStop ? (
            <div id="circle-filled" />
          ) : (
            <div style={{ width: '0.5rem' }} id="line-horizontal" />
          )}
          <div id="line-horizontal" />
          {extraStops ? (
            <div id="circle-empty">
              <MDTypography
                variant="icon"
                className="txt-top"
                lineHeight="inherit"
                overflow="visible"
              >
                {`+${extraStops} stops`}
              </MDTypography>
            </div>
          ) : (
            <div style={{ width: '0.5rem' }} id="line-horizontal" />
          )}
          <div id="line-horizontal" />
          {lastStop ? (
            <div id="triangle-right" />
          ) : (
            <div style={{ width: '0.5rem' }} id="line-horizontal" />
          )}
        </MDBox>
      )}
      {lastStop?.location ? (
        <DataTableLoadCell stop={lastStop} />
      ) : (
        <RouteButton values={values} actions={actions} />
      )}
    </MDBox>
  );
}

export default DataTableRouteCell;
