import { Load, LoadRouteStop, LoadStatus } from 'constants/Load';
import { UnitStatus } from 'constants/Unit';

import { resolvePath } from 'components/BaseForm/Utils';

import { formatLoadSelect } from 'screens/freight/loads/schemas/formatLoadValues';

import { isNextStop, isNextStopStatus } from 'utils/stop';

import { UpdateStatusList } from '../../constants/Dispatch';

const getNextStatusOptions = (status: UnitStatus | LoadStatus) => {
  const currentStatus = UpdateStatusList[status || UnitStatus.READY];

  return currentStatus?.next?.map((status: any) => status);
};

const showActiveStopInput = (formValues: any) =>
  [
    LoadStatus.DISPATCHED,
    LoadStatus.ENROUTE,
    LoadStatus.ARRIVED,
    LoadStatus.LOADING,
    LoadStatus.LOADED,
    LoadStatus.UNLOADING,
    LoadStatus.UNLOADED,
  ].includes(resolvePath(formValues, 'status', undefined));

const showNextStopInput = (formValues: any) =>
  isNextStopStatus(resolvePath(formValues, 'status', undefined));

const shouldShowLoadInput = (formValues: any) =>
  [...Object.values(LoadStatus), ...Object.values(UnitStatus)].includes(
    resolvePath(formValues, 'status', undefined)
  );

const showPickLocationInput = (formValues: any) =>
  [LoadStatus.BOOKED, LoadStatus.DISPATCHED].includes(resolvePath(formValues, 'status', undefined));

const showLoadRateInput = (formValues: any) =>
  [
    LoadStatus.QUOTED,
    LoadStatus.OFFERED,
    LoadStatus.MATCHED,
    LoadStatus.BOOKED,
    UnitStatus.READY,
    LoadStatus.DISPATCHED,
  ].includes(resolvePath(formValues, 'status', undefined));

const showNextLocationInput = (formValues: any) =>
  [LoadStatus.ENROUTE].includes(resolvePath(formValues, 'status', undefined));

const showStopInput = (formValues: any) =>
  [
    LoadStatus.ARRIVED,
    LoadStatus.LOADING,
    LoadStatus.LOADED,
    LoadStatus.UNLOADING,
    LoadStatus.UNLOADED,
    LoadStatus.DELIVERED,
    UnitStatus.STOP,
    UnitStatus.ACCIDENT,
    UnitStatus.BREAKDOWN,
    UnitStatus.EXHANGE,
    UnitStatus.INSPECTION,
    UnitStatus.SCALE,
    UnitStatus.FUEL,
    UnitStatus.OFF,
  ].includes(resolvePath(formValues, 'status', undefined));

const showCurrentLocationInput = (formValues: any) =>
  [
    LoadStatus.ARRIVED,
    LoadStatus.LOADING,
    LoadStatus.LOADED,
    LoadStatus.UNLOADING,
    LoadStatus.UNLOADED,
    LoadStatus.DELIVERED,
    LoadStatus.REJECTED,
    LoadStatus.CANCELLED,
  ].includes(resolvePath(formValues, 'status', undefined));

const showUnitInput = (formValues: any) =>
  [...Object.values(LoadStatus), ...Object.values(UnitStatus)].includes(
    resolvePath(formValues, 'status', undefined)
  );

const showDispatcherBrokerInput = (formValues: any) =>
  [
    LoadStatus.OFFERED,
    LoadStatus.INQUIRED,
    LoadStatus.QUOTED,
    LoadStatus.MATCHED,
    LoadStatus.BOOKED,
    LoadStatus.DISPATCHED,
  ].includes(resolvePath(formValues, 'status', undefined));

const showInvoiceInpt = (formValues: any) =>
  [UnitStatus.BREAKDOWN, UnitStatus.SCALE, UnitStatus.FUEL, UnitStatus.MAINTENANCE].includes(
    resolvePath(formValues, 'status', undefined)
  );

const getBrokerContactOptions = (values: any) =>
  resolvePath(values, 'broker', undefined)?.members?.map((member: any) => {
    const employee = values?.employees?.find((employee: any) => employee.id === member.id);

    return {
      ...employee,
      name: `${employee?.contact?.email || ''}${employee?.contact?.email ? ' | ' : ''}${
        employee?.contact?.phone || ''
      }${employee?.contact?.extension ? ' x' + employee.contact.extension : ''}`,
    };
  }) || [];

const getLocationOptions = (stops: LoadRouteStop[], unitLocation: any = null) => {
  const stopLocations =
    // load?.route?.
    stops?.map((stop: LoadRouteStop, index: number) => formatStopSelect(stop, (stop?.info?.id || index).toString())) || [];

  if (unitLocation) {
    return [
      { ...unitLocation, label: unitLocation.name, id: '-1', icon: UnitStatus.LOCATION },
      ...stopLocations,
    ];
  }

  return stopLocations;
};

const getNextStopOptions = (stops: LoadRouteStop[]) =>
  getLocationOptions(stops.filter((stop: LoadRouteStop) => isNextStop(stop)));

const getActiveStopOptions = (stops: LoadRouteStop[]) => getLocationOptions(stops);

const getUpdateLoadOptions = (values: any) => {
  return values.loads
    .filter((load: Load) => load?.assignedTo?.id === values?.unit?.id)
    ?.map((load: any) => formatLoadSelect(load));
};

export const formatStopSelect = (stop: LoadRouteStop, index: string) => ({
  ...stop,
  label: `${stop.location?.address ? stop.location?.address + ' ' : ''}${
    stop.location?.city ? stop.location?.city + ', ' : ''
  }${stop.location?.state ? stop.location?.state + ' ' : ''}${stop.location?.zip || ''}`,
  icon: stop.type,
  id: index,
});

export {
  getNextStatusOptions,
  showPickLocationInput,
  showNextLocationInput,
  showStopInput,
  showCurrentLocationInput,
  showUnitInput,
  showDispatcherBrokerInput,
  showInvoiceInpt,
  showLoadRateInput,
  shouldShowLoadInput,
  getBrokerContactOptions,
  getLocationOptions,
  getUpdateLoadOptions,
  showActiveStopInput,
  showNextStopInput,
  getNextStopOptions,
  getActiveStopOptions,
};
