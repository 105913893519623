import { Accessory, AccessoryType } from 'constants/Accessory';
import { Asset, AssetType } from 'constants/Asset';
import { Employee } from 'constants/Employee';
import { Expense, ExpenseType } from 'constants/Expense';
import { FileUploadResult } from 'constants/Firebase';
import { Group } from 'constants/Group';
import { Load } from 'constants/Load';
import { Service } from 'constants/Service';
import { Unit } from 'constants/Unit';
import { intersectFilesUpload } from 'constants/Util';

import { diff } from 'deep-object-diff';

import { arrayRemove, arrayUnion } from 'firebase/firestore';

function updatedDependentFields(
  type: string,
  initialValues: any,
  submitedValues: any,
  firestore: any,
  company: any,
  uploadBatch: any = null
) {
  const batch = firestore.batch();

  let filesUploadBatch: Promise<any> = new Promise<any>((resolve) => resolve(true));

  const addedValues: any = diff(initialValues, submitedValues);
  const removedValues: any = diff(submitedValues, initialValues);

  console.log('updatedDependentFields init', type, addedValues, removedValues);

  const getFirestoreFieldKey = (value: string) => {
    switch (value) {
      case AssetType.TRUCK:
        return 'truck';
      case AssetType.TRAILER:
        return 'trailer';
      case AccessoryType.BYPASS:
        return 'bypass';
      case AccessoryType.ELD:
        return 'eld';
      case AccessoryType.FUEL_CARD:
        return 'fuelCard';
      case AccessoryType.LOAD_ACCESSORY:
        return 'loadAccessory';
      case AccessoryType.TABLET:
        return 'tablet';
      case AccessoryType.TRANSPONDER:
        return 'transponder';
      case AccessoryType.CAMERA:
        return 'camera';
      case AssetType.OTHER:
      case AccessoryType.OTHER:
      default:
        return 'other';
    }
  };

  const updateLoadUnitValues = (loads: { [key: string]: Load }, unit: Unit, isAdding: boolean) => {
    formatValues(loads)?.forEach((load: Load) => {
      load?.id &&
        batchUpdate(`loads/${load.id}`, {
          assignedTo: isAdding ? { id: unit.id } : null,
        });

      // Remove Load from old Unit
      const oldUnit: Unit = load.assignedTo;

      if (oldUnit && isAdding) {
        updateUnitLoadValues(load, oldUnit, false);
      }
    });
  };

  const updateEmployeeUnitValues = (
    employees: { [key: number]: Employee },
    unit: Unit,
    isAdding: boolean
  ) => {
    formatValues(employees)?.forEach((employee: Employee) => {
      employee?.id &&
        batchUpdate(`employees/${employee.id}`, {
          unit: isAdding ? { id: unit.id } : null,
        });

      // Remove Employee from old Unit
      const oldUnit: Unit = employee.unit;

      if (oldUnit && isAdding) {
        updateUnitDriversValue(oldUnit, employee, false);
      }
    });
  };

  const updateAssetUnitValues = (asset: Asset, unit: Unit, isAdding: boolean) => {
    asset?.id &&
      batchUpdate(`assets/${asset.id}`, {
        unit: isAdding ? { id: unit.id } : null,
      });

    // Remove Asset from old Unit
    const oldUnit: Unit = asset.unit;

    if (oldUnit && isAdding) {
      updateUnitAssetValues(oldUnit, asset, false);
    }
  };

  const formatValues = (values: { [key: number]: any }) =>
    Object.values(values)
      .map((value) => value)
      .filter((value) => value);

  const updateEmployeeGroupValues = (
    employees: { [key: number]: Employee },
    group: Group,
    isAdding: boolean
  ) => {
    formatValues(employees)?.forEach((employee: Employee) => {
      employee?.id &&
        batchUpdate(`employees/${employee.id}`, {
          groups: isAdding ? arrayUnion({ id: group.id }) : arrayRemove({ id: group.id }),
        });
    });
  };

  const updateGroupMemberValues = (
    groups: { [key: number]: Group },
    employee: Employee,
    isAdding: boolean
  ) => {
    formatValues(groups)?.forEach((group: Group) => {
      group?.id &&
        batchUpdate(`groups/${group.id}`, {
          members: isAdding ? arrayUnion({ id: employee.id }) : arrayRemove({ id: employee.id }),
        });
    });
  };

  const updateAssetGroupValues = (
    assets: { [key: string]: Asset },
    group: Group,
    isAdding: boolean
  ) => {
    formatValues(assets)?.forEach((asset: Asset) => {
      asset?.id &&
        batchUpdate(`assets/${asset.id}`, {
          groups: isAdding ? arrayUnion({ id: group.id }) : arrayRemove({ id: group.id }),
        });
    });
  };

  // Batch Update
  const batchUpdate = (doc: string, data: any) => {
    try {
      batch.update(firestore.doc(`company/${company.companyId}/${doc}`), data);
    } catch (error) {
      console.error(`FIRESTORE:BATCH error updating ${doc}:`, error);
    }
  };

  // Add or Remove Load from Unit
  const updateUnitLoadValues = (load: Load, unit: Unit, isAdding: boolean) => {
    unit?.id &&
      batchUpdate(`units/${unit.id}`, {
        loads: isAdding ? arrayUnion({ id: load.id }) : arrayRemove({ id: load.id }),
      });

    // Remove Load from old Unit
    const oldUnit: Unit = load.assignedTo;

    if (oldUnit && isAdding) {
      updateUnitLoadValues(load, oldUnit, false);
    }
  };

  // Add or Remove Driver from Unit
  const updateUnitDriversValue = (unit: Unit, driver: Employee, isAdding: boolean) => {
    unit?.id &&
      batchUpdate(`units/${unit.id}`, {
        drivers: isAdding ? arrayUnion({ id: driver.id }) : arrayRemove({ id: driver.id }),
      });

    // Remove Driver from old Unit
    const oldUnit: Unit = driver.unit;

    if (oldUnit && isAdding) {
      updateUnitDriversValue(oldUnit, driver, false);
    }
  };

  const updateUnitAccessoryValues = (unit: Unit, accessory: Accessory, isAdding: boolean) => {
    unit?.id &&
      batchUpdate(`units/${unit.id}`, {
        [`accessories.${getFirestoreFieldKey(accessory.type)}`]: isAdding
          ? arrayUnion({ id: accessory.id })
          : arrayRemove({ id: accessory.id }),
      });

    // Remove Accessory from old Unit
    const oldUnit: Unit = accessory.unit;

    if (oldUnit && isAdding) {
      updateUnitAccessoryValues(oldUnit, accessory, false);
    }
  };

  const updateAccessoryUnit = (
    accessories: { [key: string]: { [key: number]: Accessory } },
    unit: Unit,
    isAdding: boolean
  ) => {
    accessories &&
      Object.keys(accessories)?.forEach((key: any) => {
        formatValues(accessories[key])?.forEach((accessory: Accessory) => {
          batchUpdate(`accessories/${accessory.id}`, {
            unit: isAdding ? { id: unit.id } : null,
          });

          // Remove Accessory from old Unit
          const oldUnit: Unit = accessory.unit;

          if (oldUnit && isAdding) {
            updateUnitAccessoryValues(oldUnit, accessory, false);
          }
        });
      });
  };

  const updateUnitAssetValues = (unit: Unit, asset: Asset, isAdding: boolean) => {
    unit?.id &&
      batchUpdate(`units/${unit.id}`, {
        [getFirestoreFieldKey(asset.type)]: isAdding ? { id: asset.id } : null,
      });

    // Remove Asset from old Unit
    const oldUnit: Unit = asset.unit;

    if (oldUnit && isAdding) {
      updateUnitAssetValues(oldUnit, asset, false);
    }
  };

  const updateGroupAssetValues = (
    groups: { [key: string]: Group },
    asset: Asset,
    isAdding: boolean
  ) => {
    formatValues(groups)?.forEach((group: Asset) => {
      group?.id &&
        batchUpdate(`groups/${asset.id}`, {
          assets: isAdding ? arrayUnion({ id: group.id }) : arrayRemove({ id: group.id }),
        });
    });

    // No need to remove Asset from old Group
  };

  const updateSourceExpenseValues = (source: any, expense: Expense, isAdding: boolean) => {
    let sourceLable;

    switch (expense.type) {
      case ExpenseType.ACCESSORY:
        sourceLable = 'accessories';
        break;
      default:
        sourceLable = null;
    }

    sourceLable &&
      source?.id &&
      batchUpdate(`${sourceLable}/${source.id}`, {
        expense: isAdding ? { id: expense.id } : null,
      });

    // Remove Expense from old Source
    const oldSource: any = source?.expense;

    if (oldSource && isAdding) {
      updateSourceExpenseValues(oldSource, expense, false);
    }
  };

  const updateExpenseSourceValues = (expense: Expense, source: any, isAdding: boolean) => {
    source?.id &&
      batchUpdate(`expenses/${expense.id}`, {
        source: isAdding ? { id: source.id } : null,
      });

    // Remove Expense from old Source
    const oldExpense: any = expense?.source;

    if (oldExpense && isAdding) {
      updateExpenseSourceValues(oldExpense, source, false);
    }
  };

  const updateFilesServiceValues = (expenseFiles: any, service: Service, isAdding: boolean) => {
    console.log('updateFilesServiceValues files', expenseFiles);

    if (expenseFiles?.Invoice && isAdding) {
      if (uploadBatch && expenseFiles.Invoice?.[0]?.file) {
        filesUploadBatch = new Promise<any>((resolve) => {
          uploadBatch({ files: expenseFiles.Invoice }, service.expense?.id, 'expenses').then(
            (promiseResults: FileUploadResult[]) => {
              const instersectedFile = intersectFilesUpload(promiseResults, {
                files: expenseFiles.Invoice,
              })?.files?.[0];

              instersectedFile.file = {
                extension: instersectedFile?.file.extension,
                name: instersectedFile?.file.name,
                url: instersectedFile?.file.url,
              };

              console.log('updateFilesServiceValues', instersectedFile);

              instersectedFile &&
                batchUpdate(`expenses/${service.expense?.id}`, {
                  'files.Invoice.0.file': instersectedFile.file,
                });

              resolve(true);
            }
          );
        });
      }

      const { file, ...rest } = expenseFiles.Invoice?.[0];

      if (rest && Object.keys(rest).length) {
        console.log('updateFilesServiceValues rest', rest);

        Object.keys(rest).forEach((key: string) => {
          batchUpdate(`expenses/${service.expense?.id}`, {
            [`files.Invoice.0.${key}`]: rest[key],
          });
        });
      }
    }
  };

  const addedDependentFieldMap: { [key: string]: { [key: string]: any } } = {
    unit: {
      loads: (unit: Unit, loads: any) => updateLoadUnitValues(loads, unit, true),
      drivers: (unit: Unit, drivers: any) => updateEmployeeUnitValues(drivers, unit, true),
      truck: (unit: Unit, truck: Asset) => updateAssetUnitValues(truck, unit, true),
      trailer: (unit: Unit, trailer: Asset) => updateAssetUnitValues(trailer, unit, true),
      accessories: (unit: Unit, accessories: any) => updateAccessoryUnit(accessories, unit, true),
    },
    load: {
      assignedTo: (load: Load, unit: Unit) => updateUnitLoadValues(load, unit, true),
    },
    employee: {
      groups: (employee: Employee, groups: Group[]) =>
        updateGroupMemberValues(groups, employee, true),
      unit: (employee: Employee, unit: Unit) => updateUnitDriversValue(unit, employee, true),
    },
    asset: {
      groups: (asset: Asset, groups: any) => updateGroupAssetValues(groups, asset, true),
      connections: {}, //TODO: updateAssetConnection
      unit: (asset: Asset, unit: Unit) => updateUnitAssetValues(unit, asset, true),
    },
    accessory: {
      unit: (accessory: Accessory, unit: Unit) => updateUnitAccessoryValues(unit, accessory, true),
    },
    group: {
      members: (group: Group, employees: any) => updateEmployeeGroupValues(employees, group, true),
      assets: (group: Group, assets: any) => updateAssetGroupValues(assets, group, true),
    },
    expense: {
      source: (expense: Expense, source: any) => updateSourceExpenseValues(source, expense, true),
    },
    services: {
      expense: (service: Service, expense: Expense) =>
        updateExpenseSourceValues(expense, service, true),
      files: (service: Service, files: any) => updateFilesServiceValues(files, service, true),
    },
  };

  const removedDependentFieldMap: { [key: string]: { [key: string]: any } } = {
    unit: {
      loads: (unit: Unit, loads: any) => updateLoadUnitValues(loads, unit, false),
      drivers: (unit: Unit, drivers: any) => updateEmployeeUnitValues(drivers, unit, false),
      truck: (unit: Unit, truck: Asset) => updateAssetUnitValues(truck, unit, false),
      trailer: (unit: Unit, trailer: Asset) => updateAssetUnitValues(trailer, unit, false),
      accessories: (unit: Unit, accessories: any) => updateAccessoryUnit(accessories, unit, false),
    },
    load: {
      assignedTo: (load: Load, unit: Unit) => updateUnitLoadValues(load, unit, false),
    },
    employee: {
      unit: (employee: Employee, unit: Unit) => updateUnitDriversValue(unit, employee, false),
      groups: (employee: Employee, groups: Group[]) =>
        updateGroupMemberValues(groups, employee, false),
    },
    asset: {
      unit: (asset: Asset, unit: Unit) => updateUnitAssetValues(unit, asset, false),
      groups: (asset: Asset, groups: any) => updateGroupAssetValues(groups, asset, false),
      connections: {}, //TODO: removeAssetConnection
    },
    accessory: {
      unit: (accessory: Accessory, unit: Unit) => updateUnitAccessoryValues(unit, accessory, false),
    },
    group: {
      members: (group: Group, employees: any) => updateEmployeeGroupValues(employees, group, false),
      assets: (group: Group, assets: any) => updateAssetGroupValues(assets, group, true),
    },
    expense: {
      source: (expense: Expense, source: any) => updateSourceExpenseValues(source, expense, false),
    },
    services: {
      expense: (service: Service, expense: Expense) =>
        updateExpenseSourceValues(expense, service, false),
    },
  };

  const addDependentKeys =
    addedDependentFieldMap[type] && Object.keys(addedDependentFieldMap[type]);

  console.log('addDependentKeys', addDependentKeys);

  addDependentKeys &&
    addedValues &&
    Object.keys(addedValues)?.forEach((addedValueKey: string) => {
      if (addDependentKeys.includes(addedValueKey)) {
        const addedValue = addedValues[addedValueKey];

        try {
          addedValue && addedDependentFieldMap[type][addedValueKey](submitedValues, addedValue);
        } catch (error) {
          console.error('FIRESTORE:BATCH error in updatedDependentFields:', error);
        }
      }
    });

  const removeDependentKeys =
    removedDependentFieldMap[type] && Object.keys(removedDependentFieldMap[type]);

  removeDependentKeys &&
    removedValues &&
    Object.keys(removedValues)?.forEach((removedValueKey: string) => {
      if (removeDependentKeys.includes(removedValueKey)) {
        const removedValue = removedValues[removedValueKey];

        try {
          removedValue &&
            removedDependentFieldMap[type][removedValueKey](initialValues, removedValue);
        } catch (error) {
          console.error('FIRESTORE:BATCH error in updatedDependentFields:', error);
        }
      }
    });

  try {
    return Promise.resolve(filesUploadBatch).then(() => batch.commit());
  } catch (error) {
    console.error('FIRESTORE:BATCH error in updatedDependentFields:', error);

    return Promise.reject(error);
  }
}

export default updatedDependentFields;
