/* eslint-disable no-unused-vars */
enum RoleType {
  ADMIN = 'ADMIN',
  DISPATCHER = 'DISPATCHER',
  ACCOUNTANT = 'ACCOUNTANT',
  SAFETY = 'SAFETY',
  DRIVER = 'DRIVER',
  HR = 'HR',
  SERVICE = 'SERVICE',
}

enum RolePermission {
  VIEW,
  ADD,
  EDIT,
  AUDIT,
  MANAGER,
}

interface Role {
  icon: string;
  permissions: RolePermission[];
  type: RoleType;
}

export { RolePermission, RoleType };
export type { Role };
