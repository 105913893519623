import { Accessory } from 'constants/Accessory';
import { formatFiles } from 'constants/Util';

import { AccessoryDocumentType } from './configAccessory';

export const AccessoryVersion: number = 1;

const formatAccessoryV1 = (data: any): Accessory => ({
  id: data?.id || null,
  type: data?.type || null,
  status: data?.status || null,
  version: AccessoryVersion,
  unit: data?.unit ? { id: data.unit.id } : null,
  accessoryId: data?.accessoryId || null,
  notes: data?.notes || null,
  info: {
    price: data?.info?.price || null,
    provider: data?.info?.provider || null,
    model: data?.info?.model || null,
    serialNumber: data?.info?.serialNumber || null,
  },
  expense: data?.expense ? { id: data.expense.id } : null,
  groups:
    data?.groups?.map((group: any) => ({
      id: group.id,
    })) || null,
  files: formatFiles(AccessoryDocumentType, data?.files),
});

const formatAccessoryData = (data: any): Accessory => {
  const version = data?.version || AccessoryVersion;

  switch (version) {
    case 1: {
      return formatAccessoryV1(data);
    }

    default: {
      return formatAccessoryV1(data);
    }
  }
};

export const formatAccessories = (accessories?: any): { [key: string]: Accessory[] } | null => {
  if (!accessories || !Object.keys(accessories).length) {
    return null;
  }

  const result: { [key: string]: Accessory[] } = {};

  Object.keys(accessories).forEach((key: any) => {
    result[key] = accessories[key]?.length
      ? accessories[key].map((accessory: Accessory) => ({ id: accessory.id }))
      : null;
  });

  return result;
};

export const formatAccessorysData = (data: any): Accessory[] =>
  Object.values(data).map((value: any) => formatAccessoryData(value));

export default formatAccessoryData;
