import { Employee, EmployeeEventAction, EmployeeStatus } from 'constants/Employee';
import { FileUploadResult } from 'constants/Firebase';
import { RoleType } from 'constants/Roles';
import { intersectFilesUpload } from 'constants/Util';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { formatEmployeesValuesList } from 'screens/roster/employees/schemas/formatEmployeeValues';
import { EmployeeAPI } from 'services/data/EmployeeAPI';
import formatEmployeeData from 'screens/roster/employees/data/migration';

import useFirebaseCodeAPI from './firebaseCodeAPI';
import useFirebaseFileAPI from './firebaseFileAPI';
import updatedDependentFields from './updatedDependentFields';

const API_PATH_EMPLOYEE = 'employees';

const useFirebaseEmpoyeeAPI = (): EmployeeAPI => {
  const firestore = useFirestore();
  const { uploadBatch } = useFirebaseFileAPI();
  const { addCode, deleteCode } = useFirebaseCodeAPI();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const employees: Employee[] = useSelector((state: any) => {
    const data = state?.firestore?.data;
    let result: Employee[] = [];

    if (data) {
      const { employees, units, groups, assets } = data;
      result = formatEmployeesValuesList(employees, units, groups, assets);
    }

    return result;
  });

  const dispatchers: Employee[] = employees?.filter((employee: Employee) =>
    Object.keys(employee?.roles || {}).includes(RoleType.DISPATCHER)
  );

  const drivers: Employee[] = employees?.filter((employee: Employee) =>
    Object.keys(employee?.roles || {}).includes(RoleType.DRIVER)
  );

  const defualtEmployeeAddEvent = {
    timestamp: Date.now(),
    status: EmployeeStatus.PENDING,
    user: defaultCompany?.employeeId,
    action: EmployeeEventAction.ADD,
  };

  const add = (path: string, data: any) => firestore.collection(path).add(formatEmployeeData(data));

  const update = (path: string, data: any) => {
    try {
      return firestore.doc(path).update(formatEmployeeData(data));
    } catch (error) {
      console.error('FIRESTORE:UPDATE error', error);

      return Promise.reject(error);
    }
  };

  const remove = (id: string) => firestore.doc(id).delete();

  const addEmployee = (
    data: any = {},
    company: any = defaultCompany,
    event: any = defualtEmployeeAddEvent
  ) =>
    add(`company/${company.companyId}/employees`, {
      ...data,
      events: {
        [`${Date.now()}`]: event,
      },
    });

  const updateEmployee = (
    id: string,
    data: any,
    company: any = defaultCompany,
    event: any = defualtEmployeeAddEvent
  ) =>
    update(`company/${company.companyId}/employees/${id}`, {
      ...data,
      events: {
        ...data.events,
        [`${Date.now()}`]: event,
      },
    });

  const addEmployeeEvent = (event: any, companyId: string = defaultCompany.companyId) =>
    add(`company/${companyId}/employeesEvents`, event);

  const removeEmployee = (id: string, companyId: string) =>
    remove(`company/${companyId}/employees/${id}`);

  const onRemoveEmployee = (employee: Employee, company: any = defaultCompany) =>
    removeEmployee(employee.id, company.companyId).then(() => {
      updatedDependentFields('employee', employee, {}, firestore, defaultCompany);

      addEmployeeEvent({
        [`${Date.now()}`]: {
          timestamp: Date.now(),
          user: company.employeeId,
          action: EmployeeEventAction.REMOVE,
          target: employee.id,
        },
      });
    });

  const onEditEmployee = (
    { files, ...rest }: Employee,
    initialValues: Employee | undefined = undefined,
    company: any = defaultCompany
  ) =>
    uploadBatch(files, rest.id, API_PATH_EMPLOYEE).then((promiseResults: FileUploadResult[]) =>
      updateEmployee(
        rest.id,
        {
          ...rest,
          files: intersectFilesUpload(promiseResults, files),
        },
        company
      ).then(() =>
        updatedDependentFields('employee', initialValues, rest, firestore, defaultCompany).then(
          () =>
            addEmployeeEvent({
              [`${Date.now()}`]: {
                timestamp: Date.now(),
                user: company.employeeId,
                action: EmployeeEventAction.EDIT,
                target: rest.id,
              },
            })
        )
      )
    );

  const onAddEmployee = async (
    { files, ...rest }: Employee,
    initialValues: Employee | undefined = undefined,
    company: any = defaultCompany
  ) => {
    const createResult: any = await addEmployee({}, company);

    const codeResult: any = await addCode({
      companyId: company.companyId,
      employeeId: createResult.id,
      roles: rest?.roles || {},
      createdAt: Date.now(),
    });

    const promiseResults: FileUploadResult[] = await uploadBatch(
      files,
      createResult.id,
      API_PATH_EMPLOYEE
    );

    updatedDependentFields('employee', initialValues, rest, firestore, defaultCompany);

    return updateEmployee(createResult.id, {
      ...rest,
      files: intersectFilesUpload(promiseResults, files),
      id: createResult.id,
      code: codeResult.id,
    });
  };

  const resetEmployeeCode = (id: string, codeId: string) =>
    deleteCode(codeId).then(() =>
      addCode({
        companyId: defaultCompany.companyId,
        employeeId: id,
        createdAt: Date.now(),
      }).then((codeResult: any) => updateEmployee(id, { code: codeResult.id }))
    );

  return {
    onAddEmployee,
    onEditEmployee,
    onRemoveEmployee,
    addEmployeeEvent,
    resetEmployeeCode,
    employees,
    dispatchers,
    drivers,
  };
};

export default useFirebaseEmpoyeeAPI;
