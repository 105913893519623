/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: 'new-user-form',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      errorMsg: 'First name is required.',
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      errorMsg: 'Last name is required.',
    },
    company: {
      name: 'company',
      label: 'Company',
      type: 'text',
    },
    phone: {
      name: 'phone',
      label: 'Phone',
      type: 'phone',
      errorMsg: 'Phone is required.',
      invalidMsg: 'Phone is not valid.',
    },
    extension: {
      name: 'extension',
      label: 'Extension',
      type: 'number',
      invalidMsg: 'Extension is not valid.',
    },
    email: {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      errorMsg: 'Email address is required.',
      invalidMsg: 'Your email address is invalid',
    },
    password: {
      name: 'password',
      label: 'Password',
      type: 'password',
      errorMsg: 'Password is required.',
      invalidMsg: 'Your password should be more than 6 characters.',
    },
    repeatPassword: {
      name: 'repeatPassword',
      label: 'Repeat Password',
      type: 'password',
      errorMsg: 'Password is required.',
      invalidMsg: "Your password doesn't match.",
    },
    address1: {
      name: 'address1',
      label: 'Address 1',
      type: 'text',
      errorMsg: 'Address is required.',
    },
    address2: {
      name: 'address2',
      label: 'Address 2',
      type: 'text',
    },
    city: {
      name: 'city',
      label: 'City',
      type: 'text',
      errorMsg: 'City is required.',
    },
    zip: {
      name: 'zip',
      label: 'Zip',
      type: 'number',
      errorMsg: 'Zip is required.',
      invalidMsg: 'Zipcode is not valid (e.g. 70000).',
    },
    twitter: {
      name: 'twitter',
      label: 'Twitter Handle',
      type: 'text',
      errorMsg: 'Twitter profile is required.',
    },
    facebook: {
      name: 'facebook',
      label: 'Facebook Account',
      type: 'text',
    },
    instagram: {
      name: 'instagram',
      label: 'Instagram Account',
      type: 'text',
    },
    publicEmail: {
      name: 'publicEmail',
      label: 'Public Email',
      type: 'email',
    },
    bio: {
      name: 'bio',
      label: 'Bio',
    },
    roles: {
      name: 'roles',
      label: 'Roles',
      type: 'text',
      errorMsg: 'Role is required.',
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'input',
      options: ['Active', 'Inactive', 'Terminated', 'Pending'],
    },
  },
};

export default form;
