export interface Event {
  timestamp: number;
  status: any;
  note?: string;
  user?: string;
  source?: any;
  target?: any;
  action: EventAction;
  added?: { [key: string]: any } | null;
  removed?: { [key: string]: any } | null;
  updated?: { [key: string]: any } | null;
}

export enum EventAction {
  SUBMIT = 'Submited',
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  EDIT = 'Edited',
  ADD = 'Added',
  REMOVE = 'Removed',
  REQUEST = 'Requested',
}

export const formatEventData = (data: { [key: string]: Event }): Event[] => {
    const formattedData: Event[] = [];

    Object.keys(data).forEach((key) => {
        const event = data[key];
        const formattedEvent: Event = {
            ...event,
            added: event?.added ? removeUndefinedValues(event.added) : null,
            removed: event?.removed ? removeUndefinedValues(event.removed) : null,
            updated: event?.updated ? removeUndefinedValues(event.updated) : null,
        };

        formattedData.push(formattedEvent);
    });

    return formattedData;
};

const removeUndefinedValues = (obj: { [key: string]: any }): { [key: string]: any } => {
  const newObj: { [key: string]: any } = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      newObj[key] = null;
    }
  });

  return newObj;
};
