import { ServiceItems, ServiceStatus, ServiceType } from 'constants/Service';
import { Document, DocumentConfig } from 'constants/Document';
import { FieldType, FormConfig, FormType, usStates } from 'constants/form/Form';
import { generateFilesTab, generateTypeTab } from 'constants/form/formConfig';

import { Asset } from 'constants/Asset';

import { zipRegExp } from 'components/BaseForm/Utils';
import { formatAssetSelect } from 'screens/roster/assets/formatAsset';
import { ExpenseDocumentConfig } from 'screens/accounting/expenses/configExpenses';

const ConfigName = 'Service';

export enum ServiceDocumentType {
  Gallery = 'Gallery',
  Misc = 'Misc',
}

export const ServicePicture: Document = {
  name: ServiceDocumentType.Gallery,
  type: ServiceDocumentType.Gallery,
  role: {
    requiredFor: [],
  },
  version: '0',
};

export const ServiceMisc: Document = {
  name: ServiceDocumentType.Misc,
  issueTimestamp: 0,
  id: '',
  type: ServiceDocumentType.Misc,
  expiration: {
    expireTimestamp: 0,
    notifyInDays: 30,
  },
  role: {
    requiredFor: [],
  },
  version: '0',
};

export const ServiceDocumentConfig: DocumentConfig = {
  [ServiceDocumentType.Gallery]: ServicePicture,
  [ServiceDocumentType.Misc]: ServiceMisc,
};

export const getExtendedServicesFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        [
          {
            name: 'items',
            label: 'Service Items',
            multiple: true,
            type: FieldType.SELECT,
            options: Object.values(ServiceItems),
          },
        ],
        [
          {
            name: 'scheduleDate',
            label: 'Scheduled Date',
            type: FieldType.DATE,
          },
        ],
        [
          {
            name: 'Due Date',
            label: 'Due Date',
            type: FieldType.DATE,
          },
        ],
        [
          {
            name: 'location.address1',
            label: 'Address1',
            placeholder: 'Address1',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'Address is required.',
            },
          },
        ],
        [
          {
            name: 'location.city',
            label: 'City',
            placeholder: 'City',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'City is required.',
            },
            composition: {
              weight: 6,
            },
          },

          {
            name: 'location.state',
            label: 'State',
            placeholder: 'State',
            type: FieldType.SELECT,
            options: usStates,
            validation: {
              errorMsg: 'State is required.',
              oneOf: usStates,
              invalidMsg: 'State is invalid.',
            },
            composition: {
              weight: 3,
            },
          },

          {
            name: 'location.zip',
            label: 'Zip',
            placeholder: 'Zip',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'Zip is required.',
              invalidMsg: 'Zip is not valid (e.g. 70000).',
              regex: zipRegExp,
            },
            composition: {
              weight: 3,
            },
          },
        ],
        [
          {
            name: 'notes',
            label: 'Notes',
            type: FieldType.TEXT,
          },
        ],
      ],
    },
    Setup: {
      type: FormType.GENERAL,
      label: 'Setup',
      title: `${ConfigName} Setup`,
      content: [
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(ServiceType),
          },
        ],
        [
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(ServiceStatus),
          },
        ],
        [
          {
            name: 'expense',
            label: 'Expense',
            type: FieldType.SELECT,
            options: [],
          },
        ],
        [
          {
            name: 'asset',
            label: 'Asset',
            type: FieldType.SELECT,
            options: values?.assets?.map((asset: Asset) => formatAssetSelect(asset)) || [],
          },
        ],
      ],
    },
    Files: generateFilesTab(ConfigName, { Invoice: ExpenseDocumentConfig.Invoice, ...ServiceDocumentConfig }, values),
  } as FormConfig);

const generateInvoiceFileField = (values: any) => {
  const result = (
    generateTypeTab('files', ExpenseDocumentConfig, values)?.Invoice?.content as any
  )?.[0]?.content?.[0];

  return result || [];
};

export const getSimplifiedServicesFormConfig = (values: any): FormConfig =>
  ({
    Info: {
      type: FormType.GENERAL,
      label: 'Info',
      title: `${ConfigName} Info`,
      content: [
        generateInvoiceFileField(values),
        [
          {
            name: 'type',
            label: 'Type',
            type: FieldType.SELECT,
            options: Object.values(ServiceType),
            composition: {
              weight: 6,
            },
          },
          {
            name: 'status',
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.values(ServiceStatus),
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'asset',
            label: 'Asset',
            type: FieldType.SELECT,
            options: values?.assets?.map((asset: Asset) => formatAssetSelect(asset)) || [],
            composition: {
              weight: 6,
            },
          },
          {
            name: 'scheduleDate',
            label: 'Appointment Date',
            type: FieldType.DATE,
            composition: {
              weight: 6,
            },
          },
        ],
        [
          {
            name: 'location.address1',
            label: 'Address1',
            placeholder: 'Address1',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'Address is required.',
            },
          },
        ],
        [
          {
            name: 'location.city',
            label: 'City',
            placeholder: 'City',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'City is required.',
            },
            composition: {
              weight: 6,
            },
          },

          {
            name: 'location.state',
            label: 'State',
            placeholder: 'State',
            type: FieldType.SELECT,
            options: usStates,
            validation: {
              errorMsg: 'State is required.',
              oneOf: usStates,
              invalidMsg: 'State is invalid.',
            },
            composition: {
              weight: 3,
            },
          },

          {
            name: 'location.zip',
            label: 'Zip',
            placeholder: 'Zip',
            type: FieldType.TEXT,
            validation: {
              errorMsg: 'Zip is required.',
              invalidMsg: 'Zip is not valid (e.g. 70000).',
              regex: zipRegExp,
            },
            composition: {
              weight: 3,
            },
          },
        ],
        [
          {
            name: 'notes',
            label: 'Notes',
            type: FieldType.TEXT,
          },
        ],
      ],
    },
  } as FormConfig);

const getServicesFormConfig = (values: string, extended: boolean) =>
  extended ? getExtendedServicesFormConfig(values) : getSimplifiedServicesFormConfig(values);

export const serviceFormSetup = (id: number, configValues: any = {}) => ({
  id,
  configValues,
  generateConfig: getServicesFormConfig,
});
