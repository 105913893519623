import { AssetStatus, AssetType } from 'constants/Asset';
import { EmployeeStatus } from 'constants/Employee';
import { GroupStatus, GroupType } from 'constants/Group';
import { RoleType } from 'constants/Roles';
import { LoadStatus } from 'constants/Load';
import { DocumentConfig, DocumentStatus } from 'constants/Document';
import { FieldType } from 'constants/form/Form';
import { AccessoryStatus, AccessoryType } from 'constants/Accessory';
import { ExpenseStatus, ExpenseType } from 'constants/Expense';

import { AccidentStatus } from 'constants/Accident';

import { ServiceStatus } from 'constants/Service';

import { Icon, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { ReactComponent as TruckIcon } from 'assets/images/truck.svg';
import { ReactComponent as TrailerIcon } from 'assets/images/trailer.svg';
import MDTypography from 'components/common/MDTypography';
import MDInput from 'components/common/MDInput';

import { InfoIcon, getFileInfo, getRequiredKeys } from './DataTableInfoCell';
import { getAccessoryIcon } from './DataTableAccessoryCell';
import { getExpenseIcon } from './DataTableExpenseCell';

export enum FilterType {
  STATUS,
  ROLES,
  FILES,
  DATE_RANGE,
}

const singleValueFilter = (rows: any[], columnIds: any, filterValue: string[]) => {
  if (!filterValue.length) {
    return rows;
  }

  return filterValue.length
    ? rows.filter((row) => filterValue.includes(row.original[columnIds]))
    : rows;
};

const multipleValueFilter = (rows: any[], columnIds: any, filterValue: string[]) => {
  if (!filterValue.length) {
    return rows;
  }

  return filterValue.length
    ? rows.filter((row) =>
        Object.keys(row.original[columnIds]).some((key: any) => !key || filterValue.includes(key))
      )
    : rows;
};

const fileInfoFilter = (
  rows: any[],
  columnIds: any,
  filterValue: string[],
  documentConfig: DocumentConfig
) => {
  if (!filterValue.length) {
    return rows;
  }

  return filterValue.length
    ? rows.filter((row) =>
        getRequiredKeys(documentConfig, row.original).some((key: any) =>
          filterValue.includes(getFileInfo(row.original.files, documentConfig, key)?.status || '')
        )
      )
    : rows;
};

const simpleDateRangeFilter = (rows: any[], columnIds: any, filterValue: any) => {
  console.log('simpleDateRangeFilter', rows, columnIds, filterValue);

  return rows
    .filter(
      (row) =>
        !row.original[columnIds] ||
        !filterValue.startDate ||
        row.original[columnIds] >= new Date(filterValue.startDate).getTime()
    )
    .filter(
      (row) =>
        !row.original[columnIds] ||
        !filterValue.endDate ||
        row.original[columnIds] <= new Date(filterValue.endDate).getTime()
    );
};

const freightDateRangeFilter = (rows: any[], columnIds: any, filterValue: any) => {
  return rows
    .filter(
      (row) =>
        !row.original[columnIds] ||
        !filterValue.startDate ||
        row.original[columnIds]?.stops?.[0]?.timestamp >= new Date(filterValue.startDate).getTime()
    )
    .filter(
      (row) =>
        !row.original[columnIds] ||
        !filterValue.endDate ||
        row.original[columnIds]?.stops?.[0]?.timestamp <= new Date(filterValue.endDate).getTime()
    );
};

const formatLoadValues = (filterValue: string[]) => {
  let formtattedValues: string[] = [];

  if (filterValue.includes('ACTIVE')) {
    formtattedValues = formtattedValues.concat([
      LoadStatus.SCHEDULED,
      LoadStatus.BOOKED,
      LoadStatus.INQUIRED,
      LoadStatus.MATCHED,
      LoadStatus.OFFERED,
      LoadStatus.QUOTED,
    ]);
  }

  if (filterValue.includes('ENROUTE')) {
    formtattedValues = formtattedValues.concat(
      LoadStatus.ENROUTE,
      LoadStatus.LOADED,
      LoadStatus.UNLOADED,
      LoadStatus.LOADING,
      LoadStatus.UNLOADING
    );
  }

  if (filterValue.includes('DELIVERED')) {
    formtattedValues = formtattedValues.concat([LoadStatus.DELIVERED]);
  }

  return formtattedValues;
};

const AssetTypeFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.type}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'type')}
  >
    <ToggleButton value={AssetType.TRUCK}>
      <TruckIcon fill="#7B809A" />
    </ToggleButton>
    <ToggleButton value={AssetType.TRAILER}>
      <TrailerIcon fill="#7B809A" />
    </ToggleButton>
  </ToggleButtonGroup>
);

const LoadStatusFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.status}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'status')}
  >
    <ToggleButton value="ACTIVE">
      <MDTypography variant="icon" color="inherit">
        ACTIVE
      </MDTypography>
    </ToggleButton>
    <ToggleButton value="ENROUTE">
      <MDTypography variant="icon" color="inherit">
        ENROUTE
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={LoadStatus.DELIVERED}>
      <MDTypography variant="icon" color="inherit">
        {LoadStatus.DELIVERED}
      </MDTypography>
    </ToggleButton>
  </ToggleButtonGroup>
);

const AssetStatusFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.status}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'status')}
  >
    <ToggleButton value={AssetStatus.ACTIVE}>
      <MDTypography variant="icon" color="inherit">
        {AssetStatus.ACTIVE}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={AssetStatus.PENDING}>
      <MDTypography variant="icon" color="inherit">
        {AssetStatus.PENDING}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={AssetStatus.INACTIVE}>
      <MDTypography variant="icon" color="inherit">
        {AssetStatus.INACTIVE}
      </MDTypography>
    </ToggleButton>
  </ToggleButtonGroup>
);

const EmployeeStatusFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.status}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'status')}
  >
    <ToggleButton value={EmployeeStatus.ACTIVE}>
      <MDTypography variant="icon" color="inherit">
        {EmployeeStatus.ACTIVE}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={EmployeeStatus.PENDING}>
      <MDTypography variant="icon" color="inherit">
        {EmployeeStatus.PENDING}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={EmployeeStatus.INACTIVE}>
      <MDTypography variant="icon" color="inherit">
        {EmployeeStatus.INACTIVE}
      </MDTypography>
    </ToggleButton>
  </ToggleButtonGroup>
);

const EmployeeRoleFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.roles}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'roles')}
  >
    <ToggleButton value={RoleType.DRIVER}>
      <Icon fontSize="small" color="secondary">
        local_shipping
      </Icon>
    </ToggleButton>
    <ToggleButton value={RoleType.DISPATCHER}>
      <Icon fontSize="small" color="secondary">
        explore
      </Icon>
    </ToggleButton>
    <ToggleButton value={RoleType.ACCOUNTANT}>
      <Icon fontSize="small" color="secondary">
        request_quote
      </Icon>
    </ToggleButton>
    <ToggleButton value={RoleType.HR}>
      <Icon fontSize="small" color="secondary">
        people_alt
      </Icon>
    </ToggleButton>
    <ToggleButton value={RoleType.SERVICE}>
      <Icon fontSize="small" color="secondary">
        build
      </Icon>
    </ToggleButton>
    <ToggleButton value={RoleType.SAFETY}>
      <Icon fontSize="small" color="secondary">
        shield
      </Icon>
    </ToggleButton>
    <ToggleButton value={RoleType.ADMIN}>
      <Icon fontSize="small" color="secondary">
        settings
      </Icon>
    </ToggleButton>
  </ToggleButtonGroup>
);

const GroupStatusFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.status}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'status')}
  >
    <ToggleButton value={GroupStatus.ACTIVE}>
      <MDTypography variant="icon" color="inherit">
        {GroupStatus.ACTIVE}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={GroupStatus.PENDING}>
      <MDTypography variant="icon" color="inherit">
        {GroupStatus.PENDING}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={GroupStatus.INACTIVE}>
      <MDTypography variant="icon" color="inherit">
        {GroupStatus.INACTIVE}
      </MDTypography>
    </ToggleButton>
  </ToggleButtonGroup>
);

const GroupTypeFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.type}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'type')}
  >
    <ToggleButton value={GroupType.BROKER}>
      <MDTypography variant="icon" color="inherit">
        {GroupType.BROKER.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={GroupType.INTERNAL}>
      <MDTypography variant="icon" color="inherit">
        {GroupType.INTERNAL.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={GroupType.CONTRACTOR}>
      <MDTypography variant="icon" color="inherit">
        {GroupType.CONTRACTOR.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={GroupType.LOCATION}>
      <MDTypography variant="icon" color="inherit">
        {GroupType.LOCATION.toUpperCase()}
      </MDTypography>
    </ToggleButton>
  </ToggleButtonGroup>
);

const InfoFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.info}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'info')}
  >
    <ToggleButton value={DocumentStatus.EXPIRING}>{InfoIcon(DocumentStatus.EXPIRING)}</ToggleButton>
    <ToggleButton value={DocumentStatus.EXPIRED}>{InfoIcon(DocumentStatus.EXPIRED)}</ToggleButton>
    <ToggleButton value={DocumentStatus.MISSING}>{InfoIcon(DocumentStatus.MISSING)}</ToggleButton>
  </ToggleButtonGroup>
);

const AccessoryStatusFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.status}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'status')}
  >
    <ToggleButton value={AccessoryStatus.ACTIVE}>
      <MDTypography variant="icon" color="inherit">
        {AccessoryStatus.ACTIVE.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={AccessoryStatus.PENDING}>
      <MDTypography variant="icon" color="inherit">
        {AccessoryStatus.PENDING.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={AccessoryStatus.INACTIVE}>
      <MDTypography variant="icon" color="inherit">
        {AccessoryStatus.INACTIVE.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={AccessoryStatus.DAMAGED}>
      <MDTypography variant="icon" color="inherit">
        {AccessoryStatus.DAMAGED.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={AccessoryStatus.LOST}>
      <MDTypography variant="icon" color="inherit">
        {AccessoryStatus.LOST.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={AccessoryStatus.STOLEN}>
      <MDTypography variant="icon" color="inherit">
        {AccessoryStatus.STOLEN.toUpperCase()}
      </MDTypography>
    </ToggleButton>
  </ToggleButtonGroup>
);

const DateRangeFilter = (filters: any, handleFilterChange: any, type: string) => (
  <div className={FilterType.DATE_RANGE.toString()}>
    <MDInput
      style={{ marginLeft: '1rem', marginRight: '0.5rem' }}
      type={FieldType.DATE}
      label="Start Date"
      name="startDate"
      size="small"
      value={filters?.startDate}
      onChange={(event: any, newFilter: any) =>
        handleFilterChange(event, newFilter, type, FieldType.DATE)
      }
      InputLabelProps={{
        shrink: true,
      }}
      error={false}
      success={false}
    />
    -
    <MDInput
      style={{ marginLeft: '0.5rem' }}
      type={FieldType.DATE}
      label="End Date"
      name="endDate"
      size="small"
      value={filters?.endDate}
      onChange={(event: any, newFilter: any) =>
        handleFilterChange(event, newFilter, type, FieldType.DATE)
      }
      InputLabelProps={{
        shrink: true,
      }}
      error={false}
      success={false}
    />
  </div>
);

const AccessoryTypeFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.type}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'type')}
  >
    <ToggleButton value={AccessoryType.FUEL_CARD}>
      <Icon fontSize="small" color="secondary">
        {getAccessoryIcon(AccessoryType.FUEL_CARD)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={AccessoryType.TRANSPONDER}>
      <Icon fontSize="small" color="secondary">
        {getAccessoryIcon(AccessoryType.TRANSPONDER)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={AccessoryType.BYPASS}>
      <Icon fontSize="small" color="secondary">
        {getAccessoryIcon(AccessoryType.BYPASS)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={AccessoryType.ELD}>
      <Icon fontSize="small" color="secondary">
        {getAccessoryIcon(AccessoryType.ELD)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={AccessoryType.TABLET}>
      <Icon fontSize="small" color="secondary">
        {getAccessoryIcon(AccessoryType.TABLET)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={AccessoryType.CAMERA}>
      <Icon fontSize="small" color="secondary">
        {getAccessoryIcon(AccessoryType.CAMERA)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={AccessoryType.LOAD_ACCESSORY}>
      <Icon fontSize="small" color="secondary">
        {getAccessoryIcon(AccessoryType.LOAD_ACCESSORY)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={AccessoryType.OTHER}>
      <Icon fontSize="small" color="secondary">
        {getAccessoryIcon(AccessoryType.OTHER)}
      </Icon>
    </ToggleButton>
  </ToggleButtonGroup>
);

const ExpenseTypeFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.type}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'type')}
  >
    <ToggleButton value={ExpenseType.FUEL}>
      <Icon fontSize="small" color="secondary">
        {getExpenseIcon(ExpenseType.FUEL)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={ExpenseType.TOLL}>
      <Icon fontSize="small" color="secondary">
        {getExpenseIcon(ExpenseType.TOLL)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={ExpenseType.SCALE}>
      <Icon fontSize="small" color="secondary">
        {getExpenseIcon(ExpenseType.SCALE)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={ExpenseType.PARKING}>
      <Icon fontSize="small" color="secondary">
        {getExpenseIcon(ExpenseType.PARKING)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={ExpenseType.SERVICE}>
      <Icon fontSize="small" color="secondary">
        {getExpenseIcon(ExpenseType.SERVICE)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={ExpenseType.REIMBURSEMENT}>
      <Icon fontSize="small" color="secondary">
        {getExpenseIcon(ExpenseType.REIMBURSEMENT)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={ExpenseType.ADVANCE}>
      <Icon fontSize="small" color="secondary">
        {getExpenseIcon(ExpenseType.ADVANCE)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={ExpenseType.ACCESSORY}>
      <Icon fontSize="small" color="secondary">
        {getExpenseIcon(ExpenseType.ACCESSORY)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={ExpenseType.LUMPER}>
      <Icon fontSize="small" color="secondary">
        {getExpenseIcon(ExpenseType.LUMPER)}
      </Icon>
    </ToggleButton>
    <ToggleButton value={ExpenseType.OTHER}>
      <Icon fontSize="small" color="secondary">
        {getExpenseIcon(ExpenseType.OTHER)}
      </Icon>
    </ToggleButton>
  </ToggleButtonGroup>
);

const ExpenseStatusFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.status}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'status')}
  >
    <ToggleButton value={ExpenseStatus.ACTIVE}>
      <MDTypography variant="icon" color="inherit">
        {ExpenseStatus.ACTIVE}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={ExpenseStatus.PENDING}>
      <MDTypography variant="icon" color="inherit">
        {ExpenseStatus.PENDING}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={ExpenseStatus.CHARGED}>
      <MDTypography variant="icon" color="inherit">
        {ExpenseStatus.CHARGED}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={ExpenseStatus.COLLECTED}>
      <MDTypography variant="icon" color="inherit">
        {ExpenseStatus.COLLECTED}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={ExpenseStatus.PAID}>
      <MDTypography variant="icon" color="inherit">
        {ExpenseStatus.PAID}
      </MDTypography>
    </ToggleButton>
  </ToggleButtonGroup>
);

const AccidentStatusFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.status}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'status')}
  >
    <ToggleButton value={AccidentStatus.OPEN}>
      <MDTypography variant="icon" color="inherit">
        {AccidentStatus.OPEN.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={AccidentStatus.REPORTED}>
      <MDTypography variant="icon" color="inherit">
        {AccidentStatus.REPORTED.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={AccidentStatus.CLOSED}>
      <MDTypography variant="icon" color="inherit">
        {AccidentStatus.CLOSED.toUpperCase()}
      </MDTypography>
    </ToggleButton>
  </ToggleButtonGroup>
);

const ServiceStatusFilter = (filters: any, handleFilterChange: any) => (
  <ToggleButtonGroup
    style={{ marginLeft: '1rem' }}
    size="small"
    value={filters?.status}
    onChange={(event: any, newFilter: any) => handleFilterChange(event, newFilter, 'status')}
  >
    <ToggleButton value={ServiceStatus.SCHEDULED}>
      <MDTypography variant="icon" color="inherit">
        {ServiceStatus.SCHEDULED.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={ServiceStatus.WAITING}>
      <MDTypography variant="icon" color="inherit">
        {ServiceStatus.WAITING.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={ServiceStatus.IN_PROGRESS}>
      <MDTypography variant="icon" color="inherit">
        {ServiceStatus.IN_PROGRESS.toUpperCase()}
      </MDTypography>
    </ToggleButton>

    <ToggleButton value={ServiceStatus.COMPLETED}>
      <MDTypography variant="icon" color="inherit">
        {ServiceStatus.COMPLETED.toUpperCase()}
      </MDTypography>
    </ToggleButton>
    <ToggleButton value={ServiceStatus.CANCELED}>
      <MDTypography variant="icon" color="inherit">
        {ServiceStatus.CANCELED.toUpperCase()}
      </MDTypography>
    </ToggleButton>
  </ToggleButtonGroup>
);

export const AssetStatusFilterGroup = {
  Filter: AssetStatusFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};

export const AssetTypeFilterGroup = {
  Filter: AssetTypeFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};

export const EmployeeStatusFilterGroup = {
  Filter: EmployeeStatusFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};

export const GroupStatusFilterGroup = {
  Filter: GroupStatusFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};

export const GroupTypeFilterGroup = {
  Filter: GroupTypeFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};

export const EmployeeRoleFilterGroup = {
  Filter: EmployeeRoleFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    multipleValueFilter(rows, columnIds, filterValue),
};

export const LoadStatusFilterGroup = {
  Filter: LoadStatusFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, formatLoadValues(filterValue)),
};

export const InfoFilterGroup = (documentConfig: DocumentConfig) => ({
  Filter: InfoFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    fileInfoFilter(rows, columnIds, filterValue, documentConfig),
});

export const FreightDateRangeFilterGroup = {
  Filter: (filters: any, handleFilterChange: any) =>
    DateRangeFilter(filters, handleFilterChange, 'route'),
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    freightDateRangeFilter(rows, columnIds, filterValue),
};

export const AccessoryStatusFilterGroup = {
  Filter: AccessoryStatusFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};

export const AccessoryTypeFilterGroup = {
  Filter: AccessoryTypeFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};

export const ExpenseTypeFilterGroup = {
  Filter: ExpenseTypeFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};

export const ExpenseStatusFilterGroup = {
  Filter: ExpenseStatusFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};

export const SimpleDateRangeFilterGroup = {
  Filter: (filters: any, handleFilterChange: any) =>
    DateRangeFilter(filters, handleFilterChange, 'issueTimestamp'),
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    simpleDateRangeFilter(rows, columnIds, filterValue),
};

export const AccidentStatusFilterGroup = {
  Filter: AccidentStatusFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};

export const ServiceStatusFilterGroup = {
  Filter: ServiceStatusFilter,
  filter: (rows: any[], columnIds: any, filterValue: string[]) =>
    singleValueFilter(rows, columnIds, filterValue),
};
