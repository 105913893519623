/* eslint-disable no-unused-vars */
import { PartyType, Payment, PaymentStatus, PaymentType } from 'constants/Expense';

import { Autocomplete, Divider, Grid, Stack, TextField } from '@mui/material';
import PaymentCard from 'components/common/card/ProjectCards/PaymentCard';
import MDInput from 'components/common/MDInput';

export const editPayment = (index: number, values: any, actions: any) => {
  setTimeout(() => {
    actions?.dialog((dialogId: any) => paymentDialogConfig(dialogId, index, values, actions));
  });
};

export const defaultPayment = (values: any): Payment => ({
  type: values?.type,
  status: PaymentStatus.DRAFT,
  paymentDate: new Date().getTime(),
  amount: 0,
  reference: undefined,
  paidBy: {
    type: PartyType.CARRIER,
    transactor: null,
  },
  payTo: null,
});

export const PaymentSection = ({ config, values, validation, actions }: any) => {
  const deletePayment = (index: number) => {
    const payments = [...values?.payments];
    payments[index] && payments.splice(index, 1);

    actions?.setFieldValue('payments', payments);
  };

  return (
    <Stack spacing={1}>
      {(values?.payments || [])
        ?.filter((payment: Payment) => payment)
        ?.map((payment: Payment, index: any) => {
          return (
            <PaymentCard
              key={index}
              index={index}
              payment={{ ...payment, brokers: values?.brokers, drivers: values?.drivers }}
              actions={{ ...actions, editPayment, deletePayment }}
            />
          );
        })}
    </Stack>
  );
};

export const paymentDialogConfig = (dialogId: any, index: number, values: any, actions: any) => ({
  id: dialogId,
  title: 'Add Payment',
  content: (form: any, setForm: any) => (
    <Grid container direction="column" gap={1} pt={1}>
      <Grid item direction="row" container justifyContent="space-between">
        <Grid item xs={5.9}>
          <Autocomplete
            disablePortal
            value={form?.type}
            options={Object.values(PaymentType)}
            renderInput={(params) => <TextField {...params} label="Type" error={!form?.type} />}
            onChange={(_, value) => setForm({ ...form, type: value })}
          />
        </Grid>
        <Grid item xs={5.9}>
          <Autocomplete
            disablePortal
            value={form?.status}
            options={Object.values(PaymentStatus)}
            onChange={(_, value) => setForm({ ...form, status: value })}
            renderInput={(params) => <TextField {...params} label="Status" error={!form?.status} />}
          />
        </Grid>
      </Grid>
      <Grid item direction="row" container justifyContent="space-between">
        <Grid item xs={5.9}>
          <MDInput
            type="date"
            label="Payment Date"
            value={form?.paymentDate}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onChange={(event: any) =>
              setForm({
                ...form,
                paymentDate: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={5.9}>
          <MDInput
            type="text"
            label="Amount"
            fullWidth
            value={form?.amount}
            onChange={(event: any) => setForm({ ...form, amount: event.target.value })}
            error={!form?.amount}
          />
        </Grid>
      </Grid>
      <MDInput
        type="text"
        label="Reference"
        fullWidth
        value={form?.reference}
        onChange={(event: any) => setForm({ ...form, reference: event.target.value })}
      />
      <Divider />
      <Grid item direction="row" container justifyContent="space-between">
        <Grid item xs={5.9}>
          <Autocomplete
            disablePortal
            value={form?.paidBy?.type}
            options={Object.values(PartyType)}
            onChange={(_, value) => setForm({ ...form, paidBy: { type: value } })}
            renderInput={(params) => <TextField {...params} label="Paid By" />}
          />
        </Grid>
        <Grid item xs={5.9}>
          {form.paidBy?.type === (PartyType.BROKER || PartyType.DRIVER) ? (
            <Autocomplete
              disablePortal
              value={form?.paidBy?.transactor}
              options={(form.paidBy?.type === PartyType.BROKER
                ? values?.brokers
                : values?.drivers
              )?.map((party: any) => party.name)}
              onChange={(_, value) => {
                const party = (
                  form.paidBy?.type === PartyType.BROKER ? values?.brokers : values?.drivers
                )?.find((d: any) => d.name === value);

                if (!party) {
                  return;
                }

                setForm({
                  ...form,
                  paidBy: { ...form.paidBy, transactor: party.name },
                });
              }}
              renderInput={(params) => <TextField {...params} label="Party" />}
            />
          ) : (
            <MDInput
              type="form.paidBy.transactor"
              label="Party"
              fullWidth
              value={form?.paidBy?.transactor}
              onChange={(event: any) => setForm({ ...form, paidBy: { ...form.paidBy, transactor: event.target.value }})}
              error={!form?.paidBy?.transactor}
            />
          )}
        </Grid>
      </Grid>
      <Grid item direction="row" container justifyContent="space-between">
        <Grid item xs={5.9}>
          <Autocomplete
            disablePortal
            value={form?.payTo?.type}
            options={Object.values(PartyType)}
            onChange={(_, value) => setForm({ ...form, payTo: { type: value } })}
            renderInput={(params) => <TextField {...params} label="Pay To" />}
          />
        </Grid>
        <Grid item xs={5.9}>
          {[PartyType.BROKER, PartyType.DRIVER].includes(form.payTo?.type) ? (
            <Autocomplete
              disablePortal
              value={form?.payTo?.transactor}
              options={(form.payTo?.type === PartyType.BROKER
                ? values?.brokers
                : values?.drivers
              )?.map((party: any) => party.name)}
              onChange={(_, value) => {
                const party = (
                  form.payTo?.type === PartyType.BROKER ? values?.brokers : values?.drivers
                )?.find((d: any) => d.name === value);

                if (!party) {
                  return;
                }

                setForm({
                  ...form,
                  payTo: { ...form.payTo, transactor: party.name },
                });
              }}
              renderInput={(params) => <TextField {...params} label="Party" />}
            />
          ) : (
            <MDInput
              type="form.payTo.transactor"
              label="Party"
              fullWidth
              value={form?.payTo?.transactor}
              onChange={(event: any) =>
                setForm({ ...form, payTo: { ...form.payTo, transactor: event.target.value } })
              }
              error={!form?.payTo?.transactor}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  ),
  config: {
    maxWidth: 'sm',
  },
  values,
  actions: {
    confirm: {
      text: 'Accept',
      action: (form: any) => {
        actions?.setFieldValue(`payments.${index}`, { ...values, ...form });
        actions.close(dialogId);
      },
      disable: (form: any) =>
        !form?.status?.length || !form?.type?.length || !form?.paidBy?.transactor?.length || !form?.payTo?.transactor?.length || !form?.amount?.length,
    },
    cancel: {
      text: 'Cancel',
      action: () => {
        actions.close(dialogId);
      },
    },
  },
});
