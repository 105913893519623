import {
  Document,
  DocumentConfig,
  DocumentExpirattion,
  DocumentRoleType,
  DocumentStatus,
} from 'constants/Document';
import { RoleType } from 'constants/Roles';
import { filterFilesByRoles } from 'constants/Util';

import { EmployeeGender } from 'constants/Employee';

import { emailRegExp, phoneRegExp, zipRegExp } from 'components/BaseForm/Utils';

import { FieldType, FormConfig, FormField, FormTab, FormType, usStates } from './Form';

const getExpirationTimestamp = (fileVersion: Document, config: Document) => {
  const expiration: DocumentExpirattion | undefined = fileVersion?.expiration || config?.expiration;
  let expireTimestamp: number = 0;

  if (expiration?.expireTimestamp) {
    expireTimestamp = expiration.expireTimestamp;
  } else if (expiration?.expireInDays) {
    if (fileVersion?.issueTimestamp && fileVersion?.issueTimestamp > 0) {
      expireTimestamp = fileVersion.issueTimestamp + expiration.expireInDays * 24 * 60 * 60 * 1000;
    }
  }

  return expireTimestamp;
};

const generateRow = (title: string, fileKey: string, version: string, formField: FormField) => {
  return {
    ...formField,
    name: `${title}.${fileKey}.${version}.${formField.name}`,
    label: `${fileKey} ${formField.label}`,
    type: formField.type,
  };
};

const generateImageGalleryTab = (
  title: string,
  fileKey: string,
  values: any,
  config: DocumentConfig
) => {
  const results: FormConfig = {};
  const result: FormField[][] = [];
  const version = '0';

  result.push([
    {
      name: `${title}.${fileKey}`,
      label: `${fileKey} Upload`,
      type: FieldType.IMAGE_GALLERY,
      validation: {
        nullable: true,
      },
      control: {
        actions: {
          parse: config[fileKey].actions?.parse,
        },
      },
    },
  ]);

  results[version] = {
    label: version,
    type: FormType.GENERAL,
    title: fileKey,
    content: result,
  };

  return results;
};

const generateVersionTabs = (
  title: string,
  fileKey: string,
  values: any,
  config: DocumentConfig
) => {
  const results: FormConfig = {};

  if (config[fileKey].type === 'Gallery') {
    return generateImageGalleryTab(title, fileKey, values, config);
  }

  Object.keys((values[title] && values[title][fileKey]) || { '0': config[fileKey] }).forEach(
    (version) => {
      const result: FormField[][] = [];

      result.push([
        {
          name: `${title}.${fileKey}.${version}`,
          label: `${fileKey} Upload`,
          type: FieldType.FILE,
          validation: {
            nullable: true,
          },
          control: {
            actions: {
              parse: config[fileKey].actions?.parse,
            },
          },
        },
      ]);

      if (config[fileKey]?.id !== undefined) {
        result.push([
          {
            name: `${title}.${fileKey}.${version}.id`,
            label: `${fileKey} ID`,
            type: FieldType.TEXT,
          },
        ]);
      }

      if (config[fileKey].status !== undefined) {
        result.push([
          {
            name: `${title}.${fileKey}.${version}.status`,
            label: 'Status',
            type: FieldType.SELECT,
            options: Object.keys(DocumentStatus),
          },
        ]);
      }

      let tempRow: FormField[] = [];

      config[fileKey].extraFields &&
        Object.keys(config[fileKey].extraFields!).forEach((key: string) => {
          const formField: FormField = config[fileKey].extraFields![key];

          if (
            tempRow.reduce((acc, field) => acc + (field.composition?.weight || 12), 0) +
              (formField.composition?.weight || 12) >
            12
          ) {
            result.push(tempRow);

            tempRow = [generateRow(title, fileKey, version, formField)];
          } else {
            tempRow.push(generateRow(title, fileKey, version, formField));
          }
        });

      tempRow.length && result.push(tempRow);

      const dateRow: FormField[] = [];

      if (config[fileKey].issueTimestamp !== undefined) {
        dateRow.push({
          name: `${title}.${fileKey}.${version}.issueTimestamp`,
          label: 'Issue Date',
          type: FieldType.DATE,
          composition: {
            weight: 6,
          },
        });
      }

      if (config[fileKey].expiration?.expireTimestamp !== undefined) {
        dateRow.push({
          name: `${title}.${fileKey}.${version}.expiration.${
            config[fileKey].expiration?.expireInDays ? 'expireInDays' : 'expireTimestamp'
          }`,
          label: 'Expire Date',
          type: FieldType.DATE,
          composition: {
            disabled: config[fileKey].expiration?.expireInDays !== undefined,
            weight: 6,
          },
          control: {
            formatBy: (source: Document) => getExpirationTimestamp(source, config[fileKey]),
            source: `${title}.${fileKey}.${version}`,
          },
        });

        if (config[fileKey]?.note !== undefined) {
          result.push([
            {
              name: `${title}.${fileKey}.${version}.note`,
              label: `${fileKey} Note`,
              type: FieldType.TEXT,
            },
          ]);
        }
      }

      if (dateRow.length) {
        result.push(dateRow);
      }

      results[version] = {
        label: version,
        type: FormType.GENERAL,
        title: fileKey,
        content: result,
      };
    }
  );

  return results;
};

export const generateTypeTab = (title: string, config: DocumentConfig, values: any) => {
  const results: FormConfig = {};

  filterFilesByRoles(config, (values.roles || []) as RoleType[], DocumentRoleType.ALLOWED).forEach(
    (fileKey: string) => {
      results[fileKey] = {
        type: FormType.GENERAL,
        label: fileKey,
        title: fileKey,
        content: generateVersionTabs(title, fileKey, values, config),
      };
    }
  );

  return results;
};

export const generateFilesTab = (title: string, config: DocumentConfig, values: any): FormTab => ({
  title: `${title} Files`,
  label: 'Files',
  type: FormType.FILES,
  content: generateTypeTab('files', config, values),
});

export const generateComplianceTab = (
  title: string,
  config: DocumentConfig,
  values: any
): FormTab => ({
  title: `${title} Compliance`,
  label: 'Compliance',
  type: FormType.FILES,
  content: generateTypeTab('files', config, values),
});

const AccountType = ['Personal', 'Business'];

export const generateBankingTab = (title: string): FormTab => ({
  type: FormType.GENERAL,
  label: 'Banking',
  title: `${title} Banking`,
  content: [
    [
      {
        name: 'banking.taxId',
        label: 'SSN/EIN',
        type: FieldType.TEXT,
        validation: {
          errorMsg: 'SSN/EIN is required.',
          regex: /^[0-9]{9}$/,
          invalidMsg: 'SSN/EIN is invalid.',
        },
      },
    ],
    [
      {
        name: 'banking.accountType',
        label: 'Account Type',
        type: FieldType.SELECT,
        options: AccountType,
        validation: {
          errorMsg: 'Type is required.',
          oneOf: AccountType,
          invalidMsg: 'Select one of the values.',
        },
      },
    ],
    [
      {
        name: 'banking.routingNumber',
        label: 'Routing Number',
        type: FieldType.TEXT,
        validation: {
          errorMsg: 'Routing is required.',
          invalidMsg: 'Routing should be 9 digits.',
          regex: /^[0-9]{9}$/,
        },
      },
    ],
    [
      {
        name: 'banking.accountNumber',
        label: 'Account Number',
        type: FieldType.TEXT,
        validation: {
          errorMsg: 'Account is required.',
          invalidMsg: 'Account should be digits only.',
          regex: /^[0-9]+$/,
        },
      },
    ],
  ],
});

export const generateRolesSection = (simplified: boolean = false): FormField => ({
  name: 'roles',
  label: 'Roles & Permissions',
  simplified,
  type: FieldType.ROLES,
  options: [
    {
      name: RoleType.DRIVER,
      icon: 'local_shipping',
    },
    {
      name: RoleType.DISPATCHER,
      icon: 'explore',
    },
    {
      name: RoleType.ACCOUNTANT,
      icon: 'request_quote',
    },
    {
      name: RoleType.HR,
      icon: 'people_alt',
    },
    {
      name: RoleType.SERVICE,
      icon: 'build',
    },
    {
      name: RoleType.SAFETY,
      icon: 'shield',
    },
    {
      name: RoleType.ADMIN,
      icon: 'settings',
    },
  ],
});

export const CompanyNameField: FormField = {
  name: 'companyName',
  label: 'Company Name',
  type: FieldType.TEXT,
  validation: {
    errorMsg: 'Company name is required.',
  },
};

export const FirstNameField: FormField = {
  name: 'firstName',
  label: 'First name',
  placeholder: 'First name',
  type: FieldType.TEXT,
  validation: {
    errorMsg: 'First name name is required.',
  },
  composition: {
    weight: 6,
  },
};

export const LastNameField: FormField = {
  name: 'lastName',
  label: 'Last name',
  placeholder: 'Last name',
  type: FieldType.TEXT,
  validation: {
    errorMsg: 'Last name is required.',
  },
  composition: {
    weight: 6,
  },
};

export const DobField: FormField = {
  name: 'dob',
  label: 'DOB',
  placeholder: 'Date Of Birth',
  type: FieldType.DATE,
  validation: {
    errorMsg: 'Date of Birth is required.',
  },
  composition: {
    weight: 6,
  },
};

export const GenderField: FormField = {
  name: 'gender',
  label: 'Gender',
  placeholder: 'State',
  type: FieldType.SELECT,
  options: Object.values(EmployeeGender),

  composition: {
    weight: 6,
  },
};

export const PhoneField: FormField = {
  name: 'contact.phone',
  label: 'Phone',
  placeholder: 'Phone',
  type: FieldType.PHONE,
  validation: {
    errorMsg: 'Phone is required.',
    invalidMsg: 'Phone is not valid (e.g. 3474502468).',
    regex: phoneRegExp,
  },
  composition: {
    weight: 8,
  },
};

export const ExtensionField: FormField = {
  name: 'contact.extension',
  label: 'Extension',
  placeholder: 'Extension',
  type: FieldType.NUMBER,
  composition: {
    weight: 4,
  },
};

export const EmailField: FormField = {
  name: 'contact.email',
  label: 'Email',
  placeholder: 'Email',
  type: FieldType.EMAIL,
  validation: {
    errorMsg: 'Email is required.',
    invalidMsg: 'Email is not valid.',
    regex: emailRegExp,
  },
  composition: {
    weight: 7,
  },
};

export const FaxField: FormField = {
  name: 'contact.fax',
  label: 'Fax',
  placeholder: 'Fax',
  type: FieldType.NUMBER,
  composition: {
    weight: 5,
  },
};

export const Address1Field: FormField = {
  name: 'address.address1',
  label: 'Address1',
  placeholder: 'Address1',
  type: FieldType.TEXT,
  validation: {
    errorMsg: 'Company name is required.',
  },
};

export const Address2Field: FormField = {
  name: 'address.address2',
  label: 'Address2',
  placeholder: 'Address2',
  type: FieldType.TEXT,
};

export const CityField: FormField = {
  name: 'address.city',
  label: 'City',
  placeholder: 'City',
  type: FieldType.TEXT,
  validation: {
    errorMsg: 'City is required.',
  },
  composition: {
    weight: 6,
  },
};

export const StateField: FormField = {
  name: 'address.state',
  label: 'State',
  placeholder: 'State',
  type: FieldType.SELECT,
  options: usStates,
  validation: {
    errorMsg: 'State is required.',
    oneOf: usStates,
    invalidMsg: 'State is invalid.',
  },
  composition: {
    weight: 3,
  },
};

export const ZipField: FormField = {
  name: 'address.zip',
  label: 'Zip',
  placeholder: 'Zip',
  type: FieldType.TEXT,
  validation: {
    errorMsg: 'Zip is required.',
    invalidMsg: 'Zip is not valid (e.g. 70000).',
    regex: zipRegExp,
  },
  composition: {
    weight: 3,
  },
};

export const FullNameRow: FormField[] = [FirstNameField, LastNameField];
export const CompanyNameRow: FormField[] = [CompanyNameField];
export const PhoneExtensionRow: FormField[] = [PhoneField, ExtensionField];
export const EmailFaxRow: FormField[] = [EmailField, FaxField];
export const CityStateZipRow: FormField[] = [CityField, StateField, ZipField];
export const AddressSection: FormField[][] = [[Address1Field], [Address2Field], CityStateZipRow];
