import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  auth: Object | null;
  profile: Object | null;
}

const initialState: UserState = {
  auth: localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')!) : null,
  profile: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Object>) => {
      state.auth = action.payload;
    },
    setProfile: (state, action: PayloadAction<Object>) => {
      state.profile = action.payload;
    },
    reset: (state) => {
      state.auth = initialState.auth;
      state.profile = initialState.profile;
    },
  },
});

export const { setAuth, setProfile, reset } = userSlice.actions;

export default userSlice.reducer;
