import { Contact } from './Contact';
import { Location } from './Location';
import { Event } from './Event';
import { Document } from './Document';
import { Employee } from './Employee';

export enum ParticipantFault {
  UNKNOWN = 'Unknown',
  NONE = 'None',
  PARTIAL = 'Partial',
  FULL = 'Full',
}

export interface ParticipantAsset {
  plate: string;
  state: string;
  vin: string;
  make: string;
  year: number;
  color: string;
}

export interface Participant {
  name: string;
  type: ParticipantType;
  involved?: boolean;
  fault: ParticipantFault;
  contact?: Contact;
  asset?: ParticipantAsset;
  injuries?: ParticipantInjury;
  damages?: ParticipantDamage;
}

export enum ParticipantInjury {
  NONE = 'None',
  MINOR = 'Minor',
  MAJOR = 'Major',
  FATAL = 'Fatal',
}

export enum ParticipantDamage {
  NONE = 'None',
  MINOR = 'Minor',
  MAJOR = 'Major',
  TOTAL = 'Total',
}

export enum ParticipantType {
  CARRIER = 'Carrier',
  DRIVER = 'Driver',
  LAW_ENFORCEMENT = 'Law Enforcement',
  BUSINESS = 'Business',
  WITNESS = 'Witness',
  OTHER = 'Other',
}

export interface Accident {
  id: string;
  type: AccidentType;
  status: AccidentStatus;
  unit: { [key: string]: string } | null;
  drivers: Employee[];
  location: Location;
  citation: { [key: string]: string };
  accidentDate: number;
  participants: Participant[];
  notes?: string;
  version: number;
  events?: Event[];
  files: { [key: string]: { [key: string]: Document } };
}

export enum AccidentType {
  COLLISION = 'COLLISION',
  PROPERTY = 'PROPERTY',
  INJURY = 'INJURY',
  OTHER = 'OTHER',
}

export enum AccidentStatus {
  OPEN = 'OPEN',
  REPORTED = 'REPORTED',
  CLOSED = 'CLOSED',
}
