import { Accessory } from 'constants/Accessory';
import { UnitStatus } from 'constants/Unit';

import { formatLoadSelect } from 'screens/freight/loads/schemas/formatLoadValues';
import { formatAccessorySelect } from 'screens/roster/accessories/formatAccessory';
import { formatAssetSelect } from 'screens/roster/assets/formatAsset';
import { formatDriverSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';
// import { useDeepCompareMemo } from 'use-deep-compare';

import { formatUnitData } from '../../data/migration';

const formatAccessories = (
  accessories?: any,
  unitAccessories?: any
): { [key: string]: Accessory[] } | null => {
  if (!accessories || !unitAccessories || !Object.keys(unitAccessories)?.length) {
    return null;
  }

  const result: { [key: string]: Accessory[] } = {};

  Object.keys(unitAccessories).forEach((key: any) => {
    result[key] =
      unitAccessories[key]
        ?.filter((accessory: Accessory) => accessory && accessory.id?.length)
        ?.map((accessory: Accessory) => formatAccessorySelect(accessories[accessory.id])) || null;
  });

  return result;
};

const formatUnitValues = (values: any) => ({
  id: values?.id || null,
  unitId: values?.unitId || null,
  status: values?.status || UnitStatus.READY,
  drivers: values?.drivers && values.drivers.map((driver: any) => formatDriverSelect(driver)),
  truck: values?.truck ? formatAssetSelect(values.truck) : null,
  trailer: values?.trailer ? formatAssetSelect(values.trailer) : null,
  loads: values?.loads?.filter((load: any) => load)?.map((load: any) => formatLoadSelect(load)),
  accessories: values?.accessories,
  version: values?.version,
  events: values?.events,
});

export const formatUnitSelect = (unit: any) => ({
  ...unit,
  name: `${unit?.truck ? `#${unit.truck.unitId} ${unit.truck.year} ${unit.truck.make} | ` : ''}${
    unit?.drivers
      ?.map((driver: any) => (driver.name ? driver.name : `${driver.firstName} ${driver.lastName}`))
      .join(' & ') || ''
  }`,
});

export const formatUnitsValuesList = (
  units: any,
  employees: any,
  assets: any,
  loads: any,
  accessories: any
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // useDeepCompareMemo(
  //   () =>
  units && employees && assets && loads
    ? Object.keys(units)
        .filter((key: any) => units?.[key])
        .map((key: string) => {
          const unit = units[key];
          const unitDrivers =
            employees && unit?.drivers && unit?.drivers?.map((driver: any) => employees[driver.id])?.filter((driver: any) => driver);

          const unitTrailer = assets && unit?.trailer?.id && assets[unit?.trailer?.id];
          const unitTruck = assets && unit?.truck?.id && assets[unit?.truck?.id];
          const unitLoads = loads && unit?.loads?.map((load: any) => loads[load?.id]);
          const unitAccessories = accessories && formatAccessories(accessories, unit?.accessories);

          return formatUnitValues({
            ...formatUnitData(unit),
            id: key,
            drivers: unitDrivers,
            trailer: unitTrailer,
            truck: unitTruck,
            loads: unitLoads,
            accessories: unitAccessories,
          });
        })
    : [];
//       , [units, employees, loads, assets]
// );

export default formatUnitValues;
