import { Group, GroupStatus, GroupType } from 'constants/Group';

import { formatAssetSelect } from 'screens/roster/assets/formatAsset';
import { formatEmployeeSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';
import formatGroupData, { GroupVersion } from 'screens/roster/groups/data/migration';

const formatGroupValues = (values: any) => ({
  companyName: values?.companyName || '',
  type: values?.type || GroupType.INTERNAL,
  contact: values?.contact || {
    phone: '',
    extension: '',
    email: '',
    fax: '',
  },
  address: values?.address || {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  },
  status: values?.status || GroupStatus.PENDING,
  roles: values?.roles || {},
  files: values?.files || {},
  id: values.id || '',
  members: values?.members,
  assets: values?.assets,
  authority: values?.authority || { type: 'MC' },
  code: values?.code,
  version: values.version || GroupVersion,
  initialTabs: values?.initialTabs || {},
  banking: values?.banking || null,
});

export const formatGroupSelect = (group: Group) => ({
  ...group,
  name: `${group.companyName} | ${group.type}`,
});

export const formatBrokerSelect = (group: Group) => ({
  ...group,
  name: group.companyName,
});

export const formatGroupsValuesList = (groups: any, employees: any, assets: any) =>
  groups
    ? Object.values(groups)
        .filter((group) => group)
        .map((group: any) => {
          const groupMembers =
            employees &&
            group?.members &&
            group.members.map((member: any) => formatEmployeeSelect(employees[member.id]));

          const groupAssets =
            assets && group?.assets?.map((asset: any) => formatAssetSelect(assets[asset.id]));

          return formatGroupValues({
            ...formatGroupData(group),
            members: groupMembers,
            assets: groupAssets,
          });
        })
    : [];

export default formatGroupValues;
