import { IconButton } from '@mui/material';
import pxToRem from 'assets/theme/functions/pxToRem';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

type Types = any;

const accordionSummary: Types = {
  defaultProps: {
    disableGutters: true,
    expandIcon: (
      <IconButton size="small" id="AccordionExpandButton" sx={{ pointerEvents: 'auto' }}>
        <ArrowForwardIosSharpIcon sx={{ pointerEvents: 'none' }} />
      </IconButton>
    ),
  },

  styleOverrides: {
    root: {
      padding: `${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(0)} !important`,
      '& .MuiBox-root': {
        margin: 0,
      },
      // Darkmode colors
      // "rgba(255, 255, 255, .05)"
      flexDirection: 'row-reverse',
      '& #AccordionExpandButton': {
        svg: {
          fontSize: 'small !important',
        },
      },
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      pointerEvents: 'none',
    },
    content: {
      margin: 0,
      padding: 0,
    },
  },
};

export default accordionSummary;
