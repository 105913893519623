/* eslint-disable no-unused-vars */
import {
  Connection,
  ConnectionEventAction,
  ConnectionStatus,
  ConnectionType,
} from 'constants/Connection';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import formatConnectionData from 'screens/connections/integrations/data/migration';
import { formatConnectionsValuesList } from 'screens/connections/integrations/schema/formatConnectionValues';
import { ConnectionAPI } from 'services/data/ConnectionAPI';

const useFirebaseConnectionAPI = (): ConnectionAPI => {
  const firestore = useFirestore();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const connections: Connection[] = useSelector((state: any) => {
    const data = state?.firestore?.data;
    let result: Connection[] = [];

    if (data) {
      const { connections } = data;
      result = formatConnectionsValuesList(connections);
    }

    return result;
  });

  const getELDToken = (unitId: string, provider: string) =>
    connections &&
    connections.find(
      (connection: Connection) =>
        connection.type.includes(ConnectionType.ELD) &&
        connection?.eld[unitId] &&
        connection.provider === provider
    )?.eld?.[unitId]?.token;

  const defualtConnectionAddEvent = {
    timestamp: Date.now(),
    status: ConnectionStatus.PENDING,
    user: defaultCompany?.employeeId,
    action: ConnectionEventAction.ADD,
  };

  const add = (path: string, data: any) =>
    firestore.collection(path).add(formatConnectionData(data));

  const update = (path: string, data: any) =>
    firestore.doc(path).update(formatConnectionData(data));

  const remove = (id: string) => firestore.doc(id).delete();

  const addConnection = (
    data: any = {},
    company: any = defaultCompany,
    event: any = defualtConnectionAddEvent
  ) =>
    add(`company/${company.companyId}/connections`, {
      ...data,
      events: {
        [`${Date.now()}`]: event,
      },
    });

  const updateConnection = (
    id: string,
    data: any,
    company: any = defaultCompany,
    event: any = defualtConnectionAddEvent
  ) =>
    update(`company/${company.companyId}/connections/${id}`, {
      ...data,
      events: {
        ...data.events,
        [`${Date.now()}`]: event,
      },
    });

  const addConnectionEvent = (event: any, companyId: string = defaultCompany.companyId) =>
    add(`company/${companyId}/connectionsEvents`, event);

  const removeConnection = (id: string, companyId: string) =>
    remove(`company/${companyId}/connections/${id}`);

  const onRemoveConnection = (connection: Connection, company: any = defaultCompany) =>
    removeConnection(connection.id, company.companyId).then(() => {
      addConnectionEvent({
        [`${Date.now()}`]: {
          timestamp: Date.now(),
          user: company.employeeId,
          action: ConnectionEventAction.REMOVE,
          target: connection.id,
        },
      });
    });

  const onEditConnection = (
    connection: Connection,
    initialValues: Connection | undefined = undefined,
    company: any = defaultCompany
  ) => updateConnection(connection.id, connection, company);

  const onAddConnection = (
    connection: Connection,
    initialValues: Connection | undefined = undefined,
    company: any = defaultCompany
  ) => addConnection(connection, company);

  return {
    onAddConnection,
    onEditConnection,
    onRemoveConnection,
    connections,
    getELDToken,
  };
};

export default useFirebaseConnectionAPI;
