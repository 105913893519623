export const firestoreAdd = (firestore: any, path: string, formatedData: any) => {
  try {
    return firestore.collection(path).add(formatedData);
  } catch (error) {
    console.error('FIRESTORE:ADD error', formatedData, error);

    return Promise.reject(error);
  }
};

export const firestoreUpdate = (firestore: any, path: string, formatedData: any) => {
  try {
    return firestore.doc(path).update(formatedData);
  } catch (error) {
    console.error('FIRESTORE:UPDATE error', formatedData, error);

    return Promise.reject(error);
  }
};

export const firestoreRemove = (firestore: any, id: string) => {
  try {
    return firestore.doc(id).delete();
  } catch (error) {
    console.error('FIRESTORE:UPDATE error', id, error);

    return Promise.reject(error);
  }
};
