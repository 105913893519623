import pxToRem from 'assets/theme/functions/pxToRem';

type Types = any;

const accordionDetails: Types = {
  styleOverrides: {
    root: {
      margin: 0,
      padding: `${pxToRem(0)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)} !important`,
      '& .MuiBox-root': {
        margin: 0,
      },
    },
  },
};

export default accordionDetails;
