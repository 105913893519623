/* eslint-disable react/jsx-no-constructed-context-values */
// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { actionTypes, createFirestoreInstance } from 'redux-firestore';
import { Provider } from 'react-redux';
import store from 'redux/stores';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Replay } from '@sentry/replay';

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from 'context';

import { SnackbarProvider } from 'notistack';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true, merge: true });

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  logErrors: true,
  enableLogging: true,
  useFirestoreForStorageMeta: true,
  onAuthStateChanged: (authData, firebase, dispatch) => {
    if (!authData) {
      dispatch({ type: actionTypes.CLEAR_DATA });
    }
  },
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

Sentry.init({
  environment: process.env.NODE_ENV,
  release: `aidispatcher-web@${process.env.npm_package_version}`,
  dsn: 'https://79f89c5baf9c42dfaf9db86c9de23beb@o4504332329811968.ingest.sentry.io/4504332334858240',
  integrations: [
    new BrowserTracing(),
    new Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
      // See below for all available options
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <MaterialUIControllerProvider>
        <Provider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ReactReduxFirebaseProvider>
        </Provider>
      </MaterialUIControllerProvider>
    </SnackbarProvider>
  </React.StrictMode>
);
