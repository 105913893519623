/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

// react-router-dom components
import { useNavigate } from 'react-router-dom';

// Authentication layout components
import BasicLayout from 'screens/authentication/components/ImageBackground';

import { useFirebase } from 'react-redux-firebase';

import ROUTES from 'navigation/const';
import SigninCard from 'screens/authentication/components/SigninCard';
import PasswordResetCard from 'screens/authentication/components/PasswordResetCard';
import RegistrationCard from 'screens/authentication/components/RegistrationCard';

import { useSnackbar } from 'notistack';

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';

enum AUTHENTTICATIONFLOW {
  SIGNIN,
  REGISTER,
  RESET,
}

function Basic(): JSX.Element {
  const navigate = useNavigate();

  const firebase = useFirebase();
  const { enqueueSnackbar } = useSnackbar();

  const handleSignIn = (email: string, password: string) =>
    firebase
      .login({
        email,
        password,
      })
      .then(() => {
        navigate(ROUTES.DASHBOARD);

        enqueueSnackbar('Successfully Loged in!', {
          preventDuplicate: true,
          variant: 'success',
        });
      })
      .catch((error: any) => {
        let errorMessage = 'Something went wrong...';

        if (error.code === 'auth/user-not-found') {
          errorMessage = 'User not found!';
        } else if (error.code === 'auth/wrong-password') {
          errorMessage = 'Wrong password!';
        } else if (error.code === 'auth/too-many-requests') {
          errorMessage = 'Too many requests!';
        }

        enqueueSnackbar(errorMessage, {
          preventDuplicate: true,
          variant: 'error',
        });
      });

  const [flow, setFlow] = useState<AUTHENTTICATIONFLOW>(AUTHENTTICATIONFLOW.SIGNIN);

  const showResetFlow = () => setFlow(AUTHENTTICATIONFLOW.RESET);
  const showSigninFlow = () => setFlow(AUTHENTTICATIONFLOW.SIGNIN);
  const showRegisterFlow = () => setFlow(AUTHENTTICATIONFLOW.REGISTER);

  return (
    <BasicLayout image={bgImage}>
      {flow === AUTHENTTICATIONFLOW.SIGNIN && (
        <SigninCard
          loading={false}
          handleSignin={handleSignIn}
          showRegisterFlow={showRegisterFlow}
          showResetFlow={showResetFlow}
        />
      )}
      {flow === AUTHENTTICATIONFLOW.RESET && (
        <PasswordResetCard
          loading={false}
          handleRecover={handleSignIn}
          showSigninFlow={showSigninFlow}
        />
      )}
      {flow === AUTHENTTICATIONFLOW.REGISTER && (
        <RegistrationCard showSigninFlow={showSigninFlow} />
      )}
    </BasicLayout>
  );
}

export default Basic;
