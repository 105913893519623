import { Accident, AccidentStatus } from 'constants/Accident';
import { FileUploadResult } from 'constants/Firebase';
import { intersectFilesUpload } from 'constants/Util';

import { EventAction } from 'constants/Event';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import formatAccidentData from 'screens/compliance/accidents/migrationAccident';
import { formatAccidentsValuesList } from 'screens/compliance/accidents/formatAccident';

import { AccidentAPI } from '../AccidentAPI';

import useFirebaseFileAPI from './firebaseFileAPI';
import updatedDependentFields from './updatedDependentFields';

const API_PATH_ACCIDENT = 'accidents';

const useFirebaseAccidentAPI = (): AccidentAPI => {
  const firestore = useFirestore();
  const { uploadBatch } = useFirebaseFileAPI();

  const defaultCompany = useSelector((state: any) => state.firebase.profile?.company);

  const accidents: Accident[] = useSelector((state: any) => {
    const data = state?.firestore?.data;
    let result: Accident[] = [];

    if (data) {
      const { accidents, assets, units, employees } = data;
      result = formatAccidentsValuesList(accidents, assets, employees, units);
    }

    return result;
  });

  const defualtAccidentAddEvent = {
    timestamp: Date.now(),
    status: AccidentStatus.OPEN,
    user: defaultCompany?.employeeId,
    action: EventAction.ADD,
  };

  const add = (path: string, data: any) => firestore.collection(path).add(formatAccidentData(data));

  const update = (path: string, data: any) => firestore.doc(path).update(formatAccidentData(data));

  const remove = (id: string) => firestore.doc(id).delete();

  const addAccident = (
    data: any = {},
    company: any = defaultCompany,
    event: any = defualtAccidentAddEvent
  ) =>
    add(`company/${company.companyId}/accidents`, {
      ...data,
      events: {
        [`${Date.now()}`]: event,
      },
    });

  const updateAccident = (
    id: string,
    data: any,
    company: any = defaultCompany,
    event: any = defualtAccidentAddEvent
  ) =>
    update(`company/${company.companyId}/accidents/${id}`, {
      ...data,
      events: {
        ...data.events,
        [`${Date.now()}`]: event,
      },
    });

  const addAccidentEvent = (event: any, companyId: string = defaultCompany.companyId) =>
    add(`company/${companyId}/accidentsEvents`, event);

  const removeAccident = (id: string, companyId: string) =>
    remove(`company/${companyId}/accidents/${id}`);

  const onRemoveAccident = async (accident: Accident, company: any = defaultCompany) =>
    await Promise.all([
      removeAccident(accident.id, company.companyId),
      updatedDependentFields('accident', accident, {}, firestore, defaultCompany),
      addAccidentEvent({
        [`${Date.now()}`]: {
          timestamp: Date.now(),
          user: company.employeeId,
          action: EventAction.REMOVE,
          target: accident.id,
          source: accident,
        },
      }),
    ]);

  const onEditAccident = async (
    { files, ...rest }: Accident,
    initialValues: Accident | undefined = undefined,
    company: any = defaultCompany
  ) => {
    const promiseResults: FileUploadResult[] = await uploadBatch(files, rest.id, API_PATH_ACCIDENT);

    return await Promise.all([
      updateAccident(
        rest.id,
        {
          ...rest,
          files: intersectFilesUpload(promiseResults, files),
        },
        company
      ),
      updatedDependentFields('accident', initialValues, rest, firestore, defaultCompany),
      addAccidentEvent({
        [`${Date.now()}`]: {
          timestamp: Date.now(),
          user: company.employeeId,
          action: EventAction.EDIT,
          target: rest.id,
          source: { files, ...rest },
        },
      }),
    ]);
  };

  const onAddAccident = async (
    { files, ...rest }: Accident,
    initialValues: Accident | undefined = undefined,
    company: any = defaultCompany
  ) => {
    const createResult = await addAccident({}, company);
    const promiseResults = await uploadBatch(files, createResult.id, API_PATH_ACCIDENT);

    return await Promise.all([
      updateAccident(createResult.id, {
        ...rest,
        files: intersectFilesUpload(promiseResults, files),
        id: createResult.id,
      }),
      updatedDependentFields('accident', initialValues, rest, firestore, defaultCompany),
      addAccidentEvent({
        [`${Date.now()}`]: {
          timestamp: Date.now(),
          user: company.employeeId,
          action: EventAction.ADD,
          target: createResult.id,
          source: { files, ...rest },
        },
      }),
    ]);
  };

  return {
    onAddAccident,
    onEditAccident,
    onRemoveAccident,
    accidents,
  };
};

export default useFirebaseAccidentAPI;
