import { Document, DocumentFile } from 'constants/Document';
import { FormField } from 'constants/form/Form';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import Grid from '@mui/material/Grid';
import { Icon, IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';

// Material Dashboard 2 PRO React TS components
// import MDBox from 'components/common/MDBox';
import MDInput from 'components/common/MDInput';
// import MDProgress from 'components/common/MDProgress';
import uploadPlaceholderImage from 'assets/images/file-preview.png';

import { resolvePath } from '../Utils';

const filesPlaceholder = [{}, {}, {}, {}, {}, {}];

interface FileUploadProps {
  fieldConfig: FormField;
  fileValues: Document;
  validation: { errors: any; touched: any };
  actions: {
    fileActions: any;
    setFieldValue: any;
    parse?: any;
  };
}

function ImageGallery({ fieldConfig, fileValues, validation, actions }: FileUploadProps) {
  // const [ uploadProgress, setUploadProgress] = useState<number>(0);

  // const onFileReset = () => {
  //   actions.setFieldValue(`${fieldConfig.name}.file.content`, undefined);
  //   actions.setFieldValue(`${fieldConfig.name}.file.uploadTimestamp`, Date.now());
  // };

  // const onFileUpload = (file: DocumentFile) =>
  //   actions.fileActions
  //     ?.upload(
  //       {
  //         content: file.content,
  //         extension: file.extension,
  //         name: file.name,
  //         path: `/${employeeId}/${[type]}/`,
  //         version: tab,
  //       },
  //       updateProgres
  //     )
  //     .then((url: string) => {
  //       actions.setFieldValue(`${fieldConfig.name}.file.url`, url);
  //       onFileReset();
  //     });

  console.log('ImageGallery', resolvePath(fileValues, fieldConfig.name, ''));

  const onFileDelete = (url: string) =>
    actions.fileActions
      ?.delete(url)
      .then(() => actions.setFieldValue(`${fieldConfig.name}.file.content`, undefined));

  const onFileRemove = (index: number) => {
    const files: any[] = Object.values(resolvePath(fileValues, fieldConfig.name, []));

    if (!files?.length) {
      return;
    }

    files.splice(index, 1);
    actions.setFieldValue(`${fieldConfig.name}`, files);
  };

  const onFileChange = (event: any) => {
    console.log(
      'onFileChange',
      fieldConfig,
      event.target.files,
      resolvePath(fileValues, fieldConfig.name, []).length
    );

    const files = event.target.files;

    if (!files) {
      return;
    }

    const initIndex = resolvePath(fileValues, fieldConfig.name, []).length;

    Array.from(files).forEach((file: any, index: any) => {
      actions.setFieldValue(`${event.target.name}.${index + initIndex}.file.extension`, file.type);
      actions.setFieldValue(`${event.target.name}.${index + initIndex}.file.content`, file);
      actions.setFieldValue(`${event.target.name}.${index + initIndex}.file.name`, file.name);
      // setUploadProgress(0);
    });
  };

  // const isEmptyFile = (file: any) => file === undefined || file === null || file === '';

  const renderImageSrc = (file: DocumentFile | undefined) =>
    (file && file.content && URL.createObjectURL(file.content)) ||
    (file && file.url) ||
    uploadPlaceholderImage;

  const renderImagePreview = (files: DocumentFile[] | undefined) => (
    <Grid item xs={12}>
      <ImageList cols={3} rowHeight={164}>
        {(files || filesPlaceholder).map((item: any, index: number) => (
          <ImageListItem key={item.img} sx={{ objectFit: 'cover', overflow: 'hidden' }}>
            <img
              // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={renderImageSrc(item.file)}
              alt={item?.title}
              loading="lazy"
            />
            {item?.file?.name && (
              <ImageListItemBar
                title={item?.file?.name}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255)' }}
                    aria-label={`info about ${item?.file?.name}`}
                    onClick={() => onFileRemove(index)}
                  >
                    <Icon fontSize="small">delete</Icon>
                  </IconButton>
                }
              />
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </Grid>
  );

  const renderInputSection = (fileVersion: Document) => (
    <Grid container item justifyContent="space-between" alignItems="center" xs={12} mb={1.5}>
      <Grid item xs={8}>
        <MDInput
          name={fieldConfig.name}
          type="file"
          inputProps={{ accept: 'image/png, image/gif, image/jpeg , image/jpg', multiple: true }}
          // accept="image/png, image/gif, image/jpeg , image/jpg"
          onChange={(event: any) => onFileChange(event)}
          key={fileVersion?.file?.uploadTimestamp || 0}
          variant="outlined"
        />
      </Grid>
      <Grid container item xs={4} justifyContent="end">
        <IconButton
          disabled={!fileVersion?.file?.content || !actions?.parse}
          onClick={() =>
            actions?.parse &&
            actions.parse(fileVersion?.file?.content, actions.setFieldValue, fieldConfig.name)
          }
        >
          <Icon fontSize="small" color="inherit">
            psychology
          </Icon>
        </IconButton>
        <IconButton
          disabled={!fileVersion?.file?.content}
          // onClick={() => onFileUpload(resolvePath(fileValues, fieldConfig.name, {}))}
        >
          <Icon fontSize="small" color="inherit">
            upload
          </Icon>
        </IconButton>
        <IconButton
          disabled={!fileVersion?.file?.url}
          onClick={() => onFileDelete(resolvePath(fileValues, `${fieldConfig.name}.file.url`, ''))}
        >
          <Icon fontSize="small" color="inherit">
            delete
          </Icon>
        </IconButton>
      </Grid>
    </Grid>
  );

  return (
    <>
      {renderInputSection(resolvePath(fileValues, fieldConfig.name, ''))}
      {renderImagePreview(resolvePath(fileValues, `${fieldConfig.name}`, filesPlaceholder))}
    </>
  );
}

export default ImageGallery;
