import { Asset, AssetType } from 'constants/Asset';

import withAuthorization from 'session/withAuthorization';

import Card from '@mui/material/Card';

import DashboardLayout from 'components/containers/DashboardLayout';
import DataTable from 'components/DataTable';
import { Grid, Icon, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import DataTableActionsCell from 'components/DataTable/DataTableActionsCell';
import { useDeepCompareMemo } from 'use-deep-compare';
import useBaseForm from 'hooks/useBaseForm';
import useFirebaseAssetAPI from 'services/data/Firebase/firebaseAssetAPI';
import { useState } from 'react';
import MDBox from 'components/common/MDBox';
import MDInput from 'components/common/MDInput';
import { AssetCard } from 'components/common/card/ProjectCards/AssetCard';
import DataTableInfoCell, { StyledIconButton } from 'components/DataTable/DataTableInfoCell';

import { ReactComponent as TruckIcon } from 'assets/images/truck.svg';
import { ReactComponent as TrailerIcon } from 'assets/images/trailer.svg';

import {
  AssetStatusFilterGroup,
  AssetTypeFilterGroup,
  InfoFilterGroup,
} from 'components/DataTable/Filters';

import MDTypography from 'components/common/MDTypography';

import DataTableUnitCell from 'components/DataTable/DataTableUnitCell';

import MDButton from 'components/common/MDButton';

import { AssetDocumentConfig, ComplianceDocumentConfig } from './configAsset';

const assetTable = {
  columns: [
    {
      Header: 'asset id',
      accessor: 'unitId',
      width: '10%',
      Cell: ({ data, row }: any) => (
        <DataTableUnitCell
          unit={{ unitId: row.original.unitId, year: row.original.year, make: row.original.make }}
        />
      ),
    },
    {
      Header: 'type',
      accessor: 'type',
      width: '7%',
      ...AssetTypeFilterGroup,
      Cell: ({ value }: any) =>
        value === AssetType.TRUCK ? (
          <StyledIconButton>
            <TruckIcon fill="#7B809A" />
            <MDTypography variant="icon" color="#7B809A">
              TRUCK
            </MDTypography>
          </StyledIconButton>
        ) : value === AssetType.TRAILER ? (
          <StyledIconButton>
            <TrailerIcon fill="#7B809A" />
            <MDTypography variant="icon" color="#7B809A">
              TRAILER
            </MDTypography>
          </StyledIconButton>
        ) : (
          'Other'
        ),
    },
    {
      Header: 'status',
      accessor: 'status',
      width: '8%',
      ...AssetStatusFilterGroup,
    },
    {
      Header: 'Unit',
      accessor: 'unit',
      width: '10%',
      Cell: ({ data, row }: any) => (
        <DataTableUnitCell
          unit={{ unitId: row.original.unit?.truck?.unitId }}
        />
      ),
    },
    { Header: 'vin', accessor: 'vin', width: '12%' },
    {
      Header: 'plate',
      accessor: 'plate',
      width: '10%',
      Cell: ({ row }: any) =>
        `${row.original.plate || ''}${row.original?.state ? ' ' + row.original?.state : ''}`,
    },
    // { Header: 'unit', accessor: 'unit', width: '8%' },
    {
      Header: 'compliance',
      accessor: 'compliance',
      filter: InfoFilterGroup(AssetDocumentConfig).filter,
      width: '12%',
      Cell: ({ row }: any) => (
        <DataTableInfoCell
          row={row.original}
          actions={row.values.actions}
          documentConfig={ComplianceDocumentConfig}
        />
      ),
    },
    {
      Header: 'info',
      accessor: 'info',
      ...InfoFilterGroup(AssetDocumentConfig),
      Cell: ({ row }: any) => (
        <DataTableInfoCell
          row={row.original}
          actions={row.values.actions}
          documentConfig={AssetDocumentConfig}
        />
      ),
    },
    {
      Header: 'actions',
      accessor: 'actions',
      Cell: ({ value }: any) => <DataTableActionsCell actions={value} />,
      alignCell: 'right',
      isSortable: false,
      width: '5%',
    },
  ],
};

function Assets() {
  const { assets } = useFirebaseAssetAPI();
  const { showAssetForm, showAssetDeleteDialog } = useBaseForm();

  const [search, setSearch] = useState('');

  const [viewType, setViewType] = useState('list');

  const onHistory = (events: any) => console.log('handleHistory for', events);

  const mapAssets =
    (assets &&
      assets?.map((asset: any) => {
        if (!asset) {
          return null;
        }

        return {
          ...asset,
          actions: {
            history: () => onHistory(asset.events),
            edit: (values: any = asset, tabs: any = {}) =>
              showAssetForm({ ...values, initialTabs: tabs }),
            delete: () => showAssetDeleteDialog(asset),
          },
        };
      })) ||
    [];

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setViewType(newAlignment);
  };

  const generateTable = useDeepCompareMemo(
    () => (
      <DataTable
        columns={assetTable.columns}
        data={mapAssets}
        canSearch
        showTotalEntries={false}
        actions={
          <>
            {/* <ToggleButtonGroup size="small" value={viewType} exclusive onChange={handleChange}>
              <ToggleButton value="list">
                <Icon fontSize="small" color="inherit">
                  list
                </Icon>
              </ToggleButton>
              <ToggleButton value="grid">
                <Icon fontSize="small" color="inherit">
                  grid_view
                </Icon>
              </ToggleButton>
            </ToggleButtonGroup> */}
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={() => showAssetForm()}
              sx={{
                ml: '0.5rem',
                p: 0,
                pl: '0.5rem',
                pr: '1rem',
                height: '25px',
                borderRadius: '5px',
              }}
            >
              <Icon>add</Icon>&nbsp; Asset
            </MDButton>
          </>
        }
      />
    ),
    [assets]
  );

  const generateCards = useDeepCompareMemo(
    () => (
      <>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <MDBox display="flex" alignItems="center" width="12rem">
            <MDInput
              placeholder="Search by name or type..."
              value={search}
              size="small"
              fullWidth
              onChange={({ currentTarget }: any) => {
                setSearch(currentTarget.value);
              }}
            />
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <ToggleButtonGroup size="small" value={viewType} exclusive onChange={handleChange}>
              <ToggleButton value="list">
                <Icon fontSize="small" color="inherit">
                  list
                </Icon>
              </ToggleButton>
              <ToggleButton value="grid">
                <Icon fontSize="small" color="inherit">
                  grid_view
                </Icon>
              </ToggleButton>
            </ToggleButtonGroup>
            <IconButton disabled={process.env.FEATURE_EMPLOYEE_EVENTS !== 'true'}>
              <Icon fontSize="small" color="inherit">
                manage_search
              </Icon>
            </IconButton>
            <IconButton disabled>
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </IconButton>
            <IconButton onClick={() => showAssetForm()}>
              <Icon fontSize="small">add_circle</Icon>
            </IconButton>
          </MDBox>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            {assets
              ?.filter(
                (asset: Asset) =>
                  !search || (search && [asset.unitId.toLowerCase()].includes(search.toLowerCase()))
              )
              ?.map((asset: Asset) => (
                <Grid item xs={12} md={6} lg={3} xl={2.4} key={asset.id}>
                  <AssetCard
                    asset={asset}
                    actions={{
                      edit: (values: any = asset, tabs: any = {}) =>
                        showAssetForm({ ...values, initialTabs: tabs }),
                      delete: showAssetDeleteDialog,
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        </MDBox>
      </>
    ),
    [assets, viewType]
  );

  return (
    <DashboardLayout>
      {viewType === 'grid' ? <>{generateCards}</> : <Card>{generateTable}</Card>}
    </DashboardLayout>
  );
}

const condition = (user: string) => user;

export default withAuthorization(condition)(Assets);
