import { Accident } from 'constants/Accident';
import { formatFiles } from 'constants/Util';

import { AccidentDocumentType } from './configAccident';

export const AccidentVersion: number = 1;

const formatAccidentV1 = (data: any): Accident => ({
  id: data?.id || null,
  type: data?.type || null,
  status: data?.status || null,
  unit: data?.unit ? { id: data.unit.id } : null,
  drivers: data?.drivers ? data.drivers.map((driver: any) => ({ id: driver.id })) : null,
  location: data?.location || null,
  citation: data?.citation || null,
  accidentDate: data?.accidentDate || Date.now(),
  participants: data?.participants?.map((participant: any) => ({
      name: participant?.name || null,
      type: participant?.type || null,
      involved: participant?.involved || null,
      fault: participant?.fault || null,
      contact: participant?.contact || null,
      asset: participant?.asset || null,
      injuries: participant?.injuries || null,
      damages: participant?.damages || null,
  })) || null,
  notes: data?.notes || null,
  version: AccidentVersion,
  files: formatFiles(AccidentDocumentType, data?.files),
});

const formatAccidentData = (data: any): Accident => {
  const version = data?.version || AccidentVersion;

  switch (version) {
    case 1: {
      return formatAccidentV1(data);
    }

    default: {
      return formatAccidentV1(data);
    }
  }
};

export const formatAccidents = (accidents?: any): { [key: string]: Accident[] } | null => {
  if (!accidents || !Object.keys(accidents).length) {
    return null;
  }

  const result: { [key: string]: Accident[] } = {};

  Object.keys(accidents).forEach((key: any) => {
    result[key] = accidents[key]?.length
      ? accidents[key].map((accident: Accident) => ({ id: accident.id }))
      : null;
  });

  return result;
};

export const formatAccidentsData = (data: any): Accident[] =>
  Object.values(data).map((value: any) => formatAccidentData(value));

export default formatAccidentData;
