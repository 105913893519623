import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface FormsState {
  formsStack: { [key: string]: any };
}

export interface FormsAction {
  form?: any;
  id: any;
}

const initialState: FormsState = {
  formsStack: {},
};

export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    addForm: (state, action: PayloadAction<FormsAction>) => {
      state.formsStack = { ...state.formsStack, [action.payload.id]: action.payload.form };
    },
    closeForm: (state, action: PayloadAction<FormsAction>) => {
      const { [action.payload.id]: tmp, ...rest } = state.formsStack;
      // delete state.formsStack[action.payload.id];
      state.formsStack = { ...rest };
    },
  },
});

export const { addForm, closeForm } = formsSlice.actions;

export default formsSlice.reducer;
