/* eslint-disable no-unused-vars */
import {
  Participant,
  ParticipantDamage,
  ParticipantFault,
  ParticipantInjury,
  ParticipantType,
} from 'constants/Accident';

import { Autocomplete, Divider, Grid, Stack, TextField } from '@mui/material';
import ParticipantCard from 'components/common/card/ProjectCards/ParticipantCard';
import MDInput from 'components/common/MDInput';
import { formatDriverSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';

export const editParticipant = (index: number, values: any, actions: any) => {
  console.log('editParticipant', index, values, actions);

  setTimeout(() => {
    actions?.dialog((dialogId: any) => participantDialogConfig(dialogId, index, values, actions));
  });
};

export const defaultParticipant = (values: any): Participant => ({
  name: values?.driver?.name,
  type: ParticipantType.CARRIER,
  fault: ParticipantFault.UNKNOWN,
  contact: {
    phone: values?.driver?.phone,
    email: values?.driver?.email,
  },
  asset: {
    plate: values?.unit?.plate,
    state: values?.unit?.state,
    vin: values?.unit?.vin,
    make: values?.unit?.make,
    year: values?.unit?.year,
    color: values?.unit?.color,
  },
});

export const ParticipantSection = ({ config, values, validation, actions }: any) => {
  const deleteParticipant = (index: number) => {
    const participants = [...values?.participants];
    participants[index] && participants.splice(index, 1);

    actions?.setFieldValue('participants', participants);
  };

  console.log('ParticipantSection', values, actions);

  return (
    <Stack spacing={1}>
      {(values?.participants || [defaultParticipant(values)])
        ?.filter((participant: Participant) => participant)
        ?.map((participant: Participant, index: any) => {
          return (
            <ParticipantCard
              key={index}
              index={index}
              participant={{ ...participant, unit: values?.unit, drivers: values?.drivers }}
              actions={{ ...actions, editParticipant, deleteParticipant }}
            />
          );
        })}
    </Stack>
  );
};

export const participantDialogConfig = (
  dialogId: any,
  index: number,
  values: any,
  actions: any
) => ({
  id: dialogId,
  title: 'Add Participant',
  content: (form: any, setForm: any) => (
    <Grid container direction="column" gap={1} pt={1}>
      <Grid item direction="row" container justifyContent="space-between">
        <Grid item xs={5.9}>
          <Autocomplete
            disablePortal
            value={form?.type}
            options={Object.values(ParticipantType)}
            renderInput={(params) => <TextField {...params} label="Type" error={!form?.type} />}
            onChange={(_, value) => setForm({ ...form, type: value })}
          />
        </Grid>
        <Grid item xs={5.9}>
          <Autocomplete
            disablePortal
            value={form?.fault}
            options={Object.values(ParticipantFault)}
            onChange={(_, value) => setForm({ ...form, fault: value })}
            renderInput={(params) => <TextField {...params} label="Fault" />}
          />
        </Grid>
      </Grid>
      <Divider />
      {form.type === ParticipantType.CARRIER ? (
        <Grid item xs={5.9}>
          <Autocomplete
            disablePortal
            value={form?.name}
            options={(values?.drivers || [])?.map((driver: any) => driver.name)}
            onChange={(_, value) => {
              const driver = values?.drivers?.find((d: any) => d.name === value);

              setForm({
                ...form,
                name: value,
                contact: { phone: driver?.contact?.phone, email: driver?.contact?.email },
              });
            }}
            renderInput={(params) => <TextField {...params} label="Full Name" />}
          />
        </Grid>
      ) : (
        <Grid item direction="row" container gap={1}>
          <MDInput
            type="text"
            label="Full Name"
            fullWidth
            value={form?.name}
            onChange={(event: any) => setForm({ ...form, name: event.target.value })}
            error={!form?.name}
          />
        </Grid>
      )}
      <Grid item direction="row" container justifyContent="space-between">
        <Grid item xs={5.9}>
          <MDInput
            type="tel"
            label="Phone Number"
            value={form?.contact?.phone}
            InputLabelProps={{
              shrink: form?.contact?.phone?.length,
            }}
            onChange={(event: any) =>
              setForm({
                ...form,
                contact: {
                  ...form?.contact,
                  phone: event.target.value,
                },
              })
            }
            error={!form?.contact?.phone}
          />
        </Grid>
        <Grid item xs={5.9}>
          <Autocomplete
            disablePortal
            value={form?.injuries}
            options={Object.values(ParticipantInjury)}
            onChange={(_, value) => setForm({ ...form, injuries: value })}
            renderInput={(params) => <TextField {...params} label="Injuries" />}
          />
        </Grid>
      </Grid>

      <MDInput
        type="email"
        label="Email"
        value={form?.contact?.email}
        InputLabelProps={{
          shrink: form?.contact?.email?.length,
        }}
        onChange={(event: any) =>
          setForm({
            ...form,
            contact: {
              ...form?.contact,
              email: event.target.value,
            },
          })
        }
      />
      <Divider />
      {form.type === ParticipantType.CARRIER ? (
        <Grid item xs={5.9}>
          <Autocomplete
            disablePortal
            value={form?.vin}
            options={[values?.unit?.truck, values?.unit?.trailer]
              ?.filter((asset: any) => asset)
              ?.map((asset: any) => asset.vin)}
            onChange={(_, value) => {
              const asset = [values?.unit?.truck, values?.unit?.trailer]
                ?.filter((asset: any) => asset)
                ?.find((a: any) => a.vin === value);

              setForm({
                ...form,
                asset: {
                  vin: value,
                  plate: asset?.plate,
                  state: asset?.state,
                  make: asset?.make,
                  year: asset?.year,
                  color: asset?.color,
                },
              });
            }}
            renderInput={(params) => <TextField {...params} label="VIN" />}
          />
        </Grid>
      ) : (
        <MDInput
          type="text"
          label="VIN"
          value={form?.asset?.vin}
          onChange={(event: any) =>
            setForm({
              ...form,
              asset: {
                ...form?.asset,
                vin: event.target.value,
              },
            })
          }
        />
      )}
      <Grid item direction="row" container justifyContent="space-between">
        <Grid item xs={5.9}>
          <MDInput
            type="text"
            label="Make"
            value={form?.asset?.make}
            InputLabelProps={{
              shrink: form?.asset?.make?.length,
            }}
            onChange={(event: any) =>
              setForm({
                ...form,
                asset: {
                  ...form?.asset,
                  make: event.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={5.9}>
          <Autocomplete
            disablePortal
            value={form?.damages}
            options={Object.values(ParticipantDamage)}
            renderInput={(params) => <TextField {...params} label="Damage" />}
            onChange={(_, value) => setForm({ ...form, damages: value })}
          />
        </Grid>
      </Grid>
      <Grid item direction="row" container gap={1}>
        <MDInput
          type="number"
          label="Year"
          value={form?.asset?.year}
          InputLabelProps={{
            shrink: form?.asset?.year?.length,
          }}
          onChange={(event: any) =>
            setForm({
              ...form,
              asset: {
                ...form?.asset,
                year: event.target.value,
              },
            })
          }
        />
        <MDInput
          type="text"
          label="Color"
          value={form?.asset?.color}
          InputLabelProps={{
            shrink: form?.asset?.color?.length,
          }}
          onChange={(event: any) =>
            setForm({
              ...form,
              asset: {
                ...form?.asset,
                color: event.target.value,
              },
            })
          }
        />
      </Grid>
    </Grid>
  ),
  config: {
    maxWidth: 'sm',
  },
  values,
  actions: {
    confirm: {
      text: 'Accept',
      action: (form: any) => {
        actions?.setFieldValue(`participants.${index}`, { ...values, ...form });
        actions.close(dialogId);
      },
      disable: (form: any) =>
        !form?.name?.length || !form?.type?.length || !form?.contact?.phone?.length,
    },
    cancel: {
      text: 'Cancel',
      action: () => {
        actions.close(dialogId);
      },
    },
  },
});
