import styled from 'styled-components';

export const StyledTrailerContainer = styled.div`
    position: absolute;
    width: 268px;
    height: 46px;
    top: 3.5px;
    left: 107px;
    display: flex;
    flex-direction: row;
`;

export const StyledPalletRow = styled.div`
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: end;
    justify-content: center;
`;

export const StyledPalletCapacity = styled.div<{ color?: string }>`
    background-color: ${({ color }) => (color ? color : 'transparent')};
    opacity: 0.5;
    display: flex;
    align-content: center;
    align-items: end;
    justify-content: center;
`;

export const StyledCombo = styled.img`
    opacity: 0.4;
    height: 75px;
`;

export const StyledMainContainer = styled.div`
    position: relative;
    width: 380px;
`;
