import { LoadRouteType, LoadSize, LoadStatus } from 'constants/Load';
import { Location } from 'constants/Location';
import { UnitStatus } from 'constants/Unit';

import { formatUnitSelect } from 'screens/dispatch/board/schemas/unit/formatUnitValues';
import { LoadVersion, formatLoadData } from 'screens/freight/loads/data/migration';
import { formatDispatcherSelect } from 'screens/roster/employees/schemas/formatEmployeeValues';
import { formatBrokerSelect } from 'screens/roster/groups/schemas/formatGroupValues';
// import { useDeepCompareMemo } from 'use-deep-compare';

const formatLoadValues = (values: any) => ({
  id: values?.id || '',
  info: {
    id: values?.info?.id || '',
    type: values?.info?.type || '',
    size: values?.info?.size || LoadSize.Full,
    milesLoaded: values?.info?.milesLoaded || '',
  },
  accounting: {
    rate: values?.accounting?.rate || '',
  },
  status: values?.status || LoadStatus.BOOKED,
  bookedBy: values.bookedBy?.id ? formatDispatcherSelect(values.bookedBy) : undefined,
  assignedTo: values?.assignedTo?.id ? formatUnitSelect(values?.assignedTo) : undefined,
  broker: values?.broker?.id ? formatBrokerSelect(values?.broker) : undefined,
  route: values?.route || {
    stops: [
      { type: LoadRouteType.Pick, location: '', timestamp: '' },
      { type: LoadRouteType.Drop, location: '', timestamp: '' },
    ],
  },
  version: LoadVersion,
  files: values?.files,
  events: values?.events,
});

export const formatLoadSelect = (load: any) => ({
  name: `#${load.info?.id} ${load.broker?.name || ''} $${load.accounting?.rate}`,
  ...load,
});

export const formatUnitLocationSelect = (location: Location) => ({
  ...location,
  label: location.name,
  id: '-1',
  icon: UnitStatus.LOCATION,
});

export const getUnit = (id: string, units: any, assets: any, employees: any) => {
  const unit = units[id];

  return {
    ...unit,
    truck: assets[unit.truck?.id],
    drivers: unit.drivers?.map((driver: any) => ({
      ...employees[driver.id],
    })),
    id,
  };
};

export const formatLoadsValuesList = (
  loads: any,
  employees: any,
  units: any,
  groups: any,
  assets: any
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // useDeepCompareMemo(
  //   () =>
  loads
    ? Object.values(loads)
        .filter((load: any) => load)
        .map((load: any) => {
          const loadDispatcher = employees && load?.bookedBy?.id && employees[load?.bookedBy?.id];

          const loadUnit =
            units &&
            assets &&
            employees &&
            load?.assignedTo?.id &&
            getUnit(load?.assignedTo?.id, units, assets, employees);

          const loadBroker = groups && load?.broker?.id && groups[load?.broker?.id];

          return formatLoadValues({
            ...formatLoadData(load),
            bookedBy: loadDispatcher,
            assignedTo: loadUnit,
            broker: loadBroker,
          });
        })
    : [];
//       , [loads, employees, units, groups, assets]
// );

export default formatLoadValues;
